import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { identity } from 'lodash'
import { autobind } from 'core-decorators'

import './Checkbox.scss'

/**
 * Checkbox
 */
class Checkbox extends Component {
  static propTypes = {
    isEnabled: PropTypes.bool,
    isChecked: PropTypes.bool.isRequired,
    isInverted: PropTypes.bool,
    isTextClickable: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    isEnabled: true,
    isInverted: false,
    isTextClickable: true,
    onChange: identity,
    className: '',
    children: null,
  }

  @autobind
  handleClick() {
    const { isEnabled, isChecked, onChange } = this.props

    if (isEnabled) {
      onChange(!isChecked)
    }
  }

  render() {
    const {
      isEnabled,
      isChecked,
      isInverted,
      isTextClickable,
      className,
      children,
      ...props
    } = this.props

    // Don't add onChange this to the <span> tag, it causes Proxy events to bubble up
    delete props.onChange

    return (
      <span
        className={cx('Checkbox', className, { isEnabled, isChecked, isInverted, isTextClickable })}
        {...props}
      >
        <span className="Checkbox-checkmark" onClick={this.handleClick}>
          <svg width="13px" height="10px" viewBox="0 0 13 10">
            <path d="M1,5.92704311 L4.0379307,9 C4.0379307,9 12,1.02483534 12,1" />
          </svg>
        </span>

        {children && (
          <span className="Checkbox-text" onClick={isTextClickable ? this.handleClick : identity}>
            {children}
          </span>
        )}
      </span>
    )
  }
}

export default Checkbox
