import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'
import { autobind } from 'core-decorators'

import { deleteAccount } from 'src/actions/user.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'

class DeleteAccountButton extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  @autobind
  handleClick() {
    const { userId, onConfirm, t } = this.props

    if (window.confirm(t('Are you sure you want to delete your account?'))) {
      onConfirm(userId)
    }
  }

  render() {
    return (
      <Button loudness={Loudness.WARN} size={Size.NORMAL} onClick={this.handleClick}>
        <T>Delete account</T>
      </Button>
    )
  }
}

export default connect(
  state => ({
    userId: state.getIn(['user', 'user', '_id']),
  }),
  dispatch => ({
    onConfirm: userId => dispatch(deleteAccount(userId)),
  })
)(withTranslators(DeleteAccountButton))
