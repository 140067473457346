import React from 'react'
import { connect } from 'react-redux'

import Visualizer from './Visualizer.js'

const VisualizerContainer = ({ isEnabled }) => {
  if (isEnabled) {
    return <Visualizer />
  }

  return null
}

export default connect(state => ({
  isEnabled: state.getIn(['visualizer', 'isEnabled']),
}))(VisualizerContainer)
