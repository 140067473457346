/* eslint camelcase: 0 */
/* eslint new-cap: 0 */
import context from 'src/audio/context.js'
import DetunaEffect from 'src/audio/detuna/DetunaEffect.js'

// Toolkit
// import { CHearingAidSim, CStereoBuffer, FloatVector } from '3dti-toolkit'
// import 'lib/3dti-toolkit/build/3dti-toolkit-has.min.js'
const { CHearingAidSim, CStereoBuffer, FloatVector } = window.Module

function getFloatVectorFromArray(arr) {
  const vector = new FloatVector()
  for (let i = 0; i < arr.length; i++) {
    vector.push_back(arr[i])
  }
  return vector
}

const SAMPLING_RATE = 44100
const NUM_EQ_LEVELS = 3
const INITIAL_FREQ = 125
const NUM_EQ_BANDS = 7
const OCTAVE_BAND_STEP = 1
const LPF_FREQ = 20000
const HPF_FREQ = 0
const Q_LPF = 0.7
const Q_BPF = 0.7
const Q_HPF = 0.7
const dBs_SPL_for_0_dBs_fs = 100

export default class HearingAid extends DetunaEffect {
  constructor(params) {
    super()

    this.has = new CHearingAidSim()
    this.has.Setup(
      SAMPLING_RATE,
      NUM_EQ_LEVELS,
      INITIAL_FREQ,
      NUM_EQ_BANDS,
      OCTAVE_BAND_STEP,
      LPF_FREQ,
      HPF_FREQ,
      Q_LPF,
      Q_BPF,
      Q_HPF
    )

    this.processingNode = context.createScriptProcessor(512, 2, 2)

    const inputStereoBuffer = new CStereoBuffer()
    const outputStereoBuffer = new CStereoBuffer()
    inputStereoBuffer.resize(1024, 0)
    outputStereoBuffer.resize(1024, 0)

    this.processingNode.onaudioprocess = audioProcessingEvent => {
      const { inputBuffer, outputBuffer } = audioProcessingEvent

      const inputDataL = inputBuffer.getChannelData(0)
      const inputDataR = inputBuffer.getChannelData(1)

      for (let i = 0; i < inputDataL.length; i++) {
        inputStereoBuffer.set(i * 2, inputDataL[i])
        inputStereoBuffer.set(i * 2 + 1, inputDataR[i])
      }

      this.has.Process(inputStereoBuffer, outputStereoBuffer, true, true)

      const outputDataLeft = outputBuffer.getChannelData(0)
      const outputDataRight = outputBuffer.getChannelData(1)

      for (let i = 0; i < outputDataLeft.length; i++) {
        outputDataLeft[i] = outputStereoBuffer.get(i * 2)
        outputDataRight[i] = outputStereoBuffer.get(i * 2 + 1)
      }
    }

    this.input = this.processingNode
    this.output = this.processingNode

    this.initialise(params)
  }

  set leftEarAudiogram(audiogram) {
    this.has.ApplyFig6Alg(getFloatVectorFromArray(audiogram), dBs_SPL_for_0_dBs_fs, true)
  }

  set rightEarAudiogram(audiogram) {
    this.has.ApplyFig6Alg(getFloatVectorFromArray(audiogram), dBs_SPL_for_0_dBs_fs, false)
  }
}
