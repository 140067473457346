import React from 'react'
import cx from 'classnames'
import { T } from 'lioness'

import FancyOrderedList from 'src/components/FancyOrderedList.js'

/**
 * About Aims
 */
const AboutAims = ({ className = '', ...props }) => (
  <div className={cx('AboutAims', className)} {...props}>
    <h2>
      <T>Our aims</T>
    </h2>
    <T>
      Musiclarity aims to provide you with a facilitative tool for helping you further enhance your music listening experience. 
      To get the best out of your Musiclarity experience, we encourange you to work with your hearing health care professional 
      to ensure that your hearing aids are optimised for your hearing loss, or, if using the headphone option in place of hearing 
      aids, that an up-to-date and accurate audiogram has been submitted by your hearing healthcare professional.</T>
  </div>
)

export default AboutAims