import React from 'react'

import Button, { Size } from 'src/components/Button.js'

const TrackSoloButton = ({ track, children, onToggle }) => (
  <Button size={Size.SMALL} isActive={track.get('isSoloed')} onClick={onToggle}>
    {children}
  </Button>
)

export default TrackSoloButton
