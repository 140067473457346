import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'
import { values } from 'lodash'

import { ActionTypes, ListeningMethods, OnboardingTours, Positions } from 'src/constants.js'
import { setTourPrerequisiteCompleted, abortTour } from 'src/actions/onboarder.actions.js'
import BinauralPresetPicker from 'src/components/BinauralPresetPicker.js'
import Button, { Loudness, Size, Span } from 'src/components/Button.js'
import DependantPanel from 'src/components/DependantPanel.js'
import Icon from 'src/components/Icon.js'
import Modal, { ModalStyles, ModalSizes } from 'src/components/Modal.js'
import SongMixer from 'src/components/SongMixer.js'
import SongBinauralMixer from 'src/components/SongBinauralMixer.js'
import { Legend } from 'src/components/forms/Form.js'
import OnboardingStep from 'src/components/onboarder/OnboardingStep.js'
import makeOnboardable from 'src/components/onboarder/makeOnboardable.js'
import { MixerTourSteps, MixerTourStepNames } from 'src/tours/mixer.tour.js'

import './MixerInterface.scss'

const HeadphonesEnsuranceModal = ({ isOpen, onAccept, onReject, onCancel }) => (
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    contentLabel="Wear headphones"
    size={ModalSizes.MINIMAL}
    modalStyle={ModalStyles.YELL}
    className="isMinimal"
  >
    <div style={{ textAlign: 'center', padding: 16 }}>
      <Icon name="headphones" style={{ marginTop: 24, marginBottom: 16, filter: 'invert(100%)' }} />
      <p>
        <T>
          You are about to take a tour through Musiclarity! Make sure you are wearing good
          headphones.
        </T>
      </p>
      <p>
        <T>
          If you are wearing hearing aids, remove them first unless you are sure they work inside
          your headphones.
        </T>
      </p>
      <div>
        <Button
          loudness={Loudness.SCREAM}
          size={Size.NORMAL}
          span={Span.AUTO}
          onClick={onAccept}
          style={{ marginTop: 24, marginBottom: 8 }}
        >
          <T>Start the tour</T>
        </Button>
      </div>
      <div>
        <Button
          style={{ color: 'white', opacity: 0.5 }}
          loudness={Loudness.WHISPER}
          size={Size.SMALL}
          onClick={onReject}
        >
          <T>Cancel</T>
        </Button>
      </div>
    </div>
  </Modal>
)

const PrerequisiteHeadphonesEnsuranceModal = connect(
  state => {
    const tourState = state.getIn(['onboarder', 'tours', OnboardingTours.MIXER])
    return {
      isOpen:
        tourState.getIn(['prerequisiteActions', ActionTypes.SET_HEADPHONES_ENSURED]) === false &&
        tourState.get('isActive') === true,
    }
  },
  dispatch => ({
    onAccept: () =>
      dispatch(
        setTourPrerequisiteCompleted(
          OnboardingTours.MIXER,
          ActionTypes.SET_HEADPHONES_ENSURED,
          true
        )
      ),
    onReject: () => dispatch(abortTour(OnboardingTours.MIXER)),
    onCancel: () => console.log('quietly cancelling'),
  })
)(HeadphonesEnsuranceModal)

const LevelsMixerArea = () => (
  <div>
    <Legend style={{ marginBottom: 0 }}>
      <T>Volume</T>
    </Legend>
    <p>
      <T>Change the volume of individual instruments.</T>
    </p>

    <SongMixer />
  </div>
)

const OnboardableLevelsMixerArea = makeOnboardable(
  LevelsMixerArea,
  MixerTourSteps[MixerTourStepNames.LEVELS_MIXER],
  props => (
    <OnboardingStep
      tour={OnboardingTours.MIXER}
      step={MixerTourSteps[MixerTourStepNames.LEVELS_MIXER].step}
      text={MixerTourSteps[MixerTourStepNames.LEVELS_MIXER].text(props)}
      padding={{ top: 10, right: -20, bottom: 10, left: 20 }}
      tooltipPosition={Positions.RIGHT}
      tooltipAlignment={{ y: Positions.TOP }}
      tooltipOffset={{ top: -10, left: 0 }}
      scrollAnchorPoint={Positions.BOTTOM}
    >
      <LevelsMixerArea />
    </OnboardingStep>
  )
)

const BinauralMixerArea = () => (
  <div>
    <Legend style={{ marginBottom: 0 }}>
      <T>Positions</T>
    </Legend>
    <p>
      <T>Change the position of individual instruments</T>
    </p>
    <BinauralPresetPicker />

    <SongBinauralMixer />
  </div>
)

const OnboardableBinauralMixerArea = makeOnboardable(
  BinauralMixerArea,
  MixerTourSteps[MixerTourStepNames.BINAURAL_MIXER],
  props => (
    <OnboardingStep
      tour={OnboardingTours.MIXER}
      step={MixerTourSteps[MixerTourStepNames.BINAURAL_MIXER].step}
      text={MixerTourSteps[MixerTourStepNames.BINAURAL_MIXER].text(props)}
      padding={{ top: 10, right: -20, bottom: 10, left: 10 }}
      tooltipPosition={Positions.LEFT}
      tooltipAlignment={{ y: Positions.TOP }}
      tooltipOffset={{ top: -10, left: -30 }}
      scrollAnchorPoint={Positions.BOTTOM}
    >
      <BinauralMixerArea />
    </OnboardingStep>
  )
)

class MixerInterface extends Component {
  static propTypes = {
    listeningMethod: PropTypes.oneOf(values(ListeningMethods)).isRequired,
  }

  render() {
    const { listeningMethod } = this.props

    return (
      <div className="MixerInterface">
        <div className="MixerInterface-panels">
          <div className="MixerInterface-panel">
            <OnboardableLevelsMixerArea />
          </div>

          <DependantPanel
            maxHeight={320}
            isDisabled={listeningMethod === ListeningMethods.HEARING_LOOP}
            className="MixerInterface-panel"
          >
            <OnboardableBinauralMixerArea />
          </DependantPanel>
        </div>
        <PrerequisiteHeadphonesEnsuranceModal />
      </div>
    )
  }
}

export default connect(state => ({
  listeningMethod: state.getIn(['vha', 'listeningMethod']),
}))(MixerInterface)
