import React from 'react'
import classNames from 'classnames'

const icons = {
  prev: require('./icons/back.js').default,
  next: require('./icons/for.js').default,
  play: require('./icons/play.js').default,
  pause: require('./icons/pause.js').default,
  loading: require('./icons/loading.js').default,
  audio: require('./icons/audio.js').default,
  ear: require('./icons/ear.js').default,
  fft: require('./icons/fft.js').default,
  subtitles: require('./icons/subtitles.js').default,
  clarity: require('./icons/clarity.js').default,
  mixer: require('./icons/mixer.js').default,
  earLeft: require('./icons/earLeft.js').default,
  earRight: require('./icons/earRight.js').default,
  headphones: require('./icons/headphones.js').default,
}

const Icon = ({ name, size, style, className, ...props }) => (
  <span
    className={classNames('Icon', className, `name-${name}`)}
    style={{ display: 'inline-block', ...style }}
    {...props}
  >
    {React.createElement(icons[name], { size })}
  </span>
)

export default Icon
