import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { T } from 'lioness'
import { Link } from 'react-router-dom'

import * as CustomPropTypes from 'src/prop-types.js'
import { fetchPatients } from 'src/actions/consultant.actions.js'
import UserAvatar from 'src/components/UserAvatar.js'

import './PatientList.scss'

/**
 * Patient List
 */
class PatientList extends PureComponent {
  static propTypes = {
    consultantId: PropTypes.string.isRequired,
    fetchPatients: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    patients: CustomPropTypes.patientList.isRequired,
    error: ImmutablePropTypes.map,
  }

  static defaultProps = {
    error: null,
  }

  componentDidMount() {
    this.props.fetchPatients(this.props.consultantId)
  }

  render() {
    const { isFetching, patients, error } = this.props

    return (
      <div className="PatientList">
        {isFetching === true && <T>Fetching patients...</T>}
        {isFetching === false && patients.size === 0 && <T>No patients to show</T>}
        {error !== null && <pre>{JSON.stringify(error, null, 2)}</pre>}

        {patients
          .map(patient => (
            <div key={patient.get('userId')} className="PatientList-item">
              <UserAvatar
                className="PatientList-item-image"
                email={patient.getIn(['user', 'email'])}
                size={40}
              />
              <Link
                className="PatientList-item-info"
                to={`/me/consultant-dashboard/patients/${patient.get('userId')}`}
              >
                <span className="PatientList-item-info-name">
                  {patient.getIn(['user', 'firstName'])} {patient.getIn(['user', 'lastName'])}
                </span>
                <span className="PatientList-item-info-email">
                  {patient.getIn(['user', 'email'])}
                </span>
              </Link>
            </div>
          ))
          .toArray()}
      </div>
    )
  }
}

export default connect(
  state => ({
    consultantId: state.getIn(['user', 'user', '_id']),
    isFetching: state.getIn(['consultant', 'patientList', 'isFetching']),
    patients: state.getIn(['consultant', 'patientList', 'data']),
    error: state.getIn(['consultant', 'patientList', 'error']),
  }),
  dispatch => ({
    fetchPatients: consultantId => dispatch(fetchPatients(consultantId)),
  })
)(PatientList)
