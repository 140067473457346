import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { capitalize, values } from 'lodash'

import './ButtonGroup.scss'

export const Directions = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
}

/**
 * Button Group
 */
class ButtonGroup extends Component {
  static propTypes = {
    direction: PropTypes.oneOf(values(Directions)),
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    direction: Directions.HORIZONTAL,
    className: '',
  }

  render() {
    const { children, direction, className, ...props } = this.props

    return (
      <div className={cx('ButtonGroup', className, `mod-${capitalize(direction)}`)} {...props}>
        {children}
      </div>
    )
  }
}

export default ButtonGroup
