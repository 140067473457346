import { delay } from 'redux-saga'
import { take, select, call, put } from 'redux-saga/effects'

import { ActionTypes, ErrorTypes } from 'src/constants.js'
import { getFeedbacks, postFeedback, withToken } from 'src/api.js'
import { displayError, displayErrorOfType } from 'src/actions/errors.actions.js'
import {
  setFeedbackFormOpen,
  submitFeedbackSuccess,
  submitFeedbackError,
  fetchFeedbacksSuccess,
  fetchFeedbacksError,
} from 'src/actions/feedback.actions.js'

function* performFeedbackSubmissions() {
  while (true) {
    const { payload: { email, freeText } } = yield take(ActionTypes.SUBMIT_FEEDBACK)

    const { data, errors } = yield call(postFeedback({ email, freeText }))

    if (errors) {
      yield put(submitFeedbackError(errors[0]))
      yield put(displayErrorOfType(ErrorTypes.FAILED_TO_SUBMIT_FEEDBACK, errors[0]))
    } else {
      yield put(submitFeedbackSuccess(data))
      yield delay(3000)
      yield put(setFeedbackFormOpen(false))
    }
  }
}

function* performFeedbackFetches() {
  while (true) {
    yield take(ActionTypes.FETCH_FEEDBACKS)

    const token = yield select(state => state.getIn(['auth', 'token']))
    const getFeedbacksUsingToken = yield call(withToken, getFeedbacks(), token)
    const { data, errors } = yield call(getFeedbacksUsingToken)

    if (errors) {
      yield put(fetchFeedbacksError(errors[0]))
      yield put(displayError(`Couldn't fetch feedbacks`, errors[0]))
    } else {
      yield put(fetchFeedbacksSuccess(data))
    }
  }
}

export default function* feedbackSagas() {
  yield [performFeedbackSubmissions(), performFeedbackFetches()]
}
