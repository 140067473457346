import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedTime, ProgressBar } from 'react-player-controls'
import cx from 'classnames'

import { seekTo } from 'src/actions/player.actions.js'

/**
 * Player Progress
 */
class PlayerProgress extends Component {
  static propTypes = {
    totalTime: PropTypes.number.isRequired,
    timeElapsed: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSeek: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const { totalTime, timeElapsed, isLoading, onSeek, className } = this.props

    return (
      <div className={cx('PlayerProgress', className)}>
        <code>
          <FormattedTime numSeconds={timeElapsed} />
        </code>

        <ProgressBar
          extraClasses={cx('Player-progress-bar', { 'is-loading': isLoading })}
          totalTime={totalTime}
          currentTime={timeElapsed}
          isSeekable={totalTime > 0}
          onSeek={onSeek}
        />

        <code>
          -<FormattedTime numSeconds={totalTime - timeElapsed} />
        </code>
      </div>
    )
  }
}

export default connect(
  state => {
    const currentSongId = state.getIn(['player', 'song'])
    const song = state.get('songs').find(x => x.get('id') === currentSongId)

    return {
      totalTime: song ? song.get('length') : 0,
      timeElapsed: state.getIn(['player', 'currentTime']),
      isLoading: state.getIn(['player', 'isLoading']),
    }
  },
  dispatch => ({
    onSeek: seconds => dispatch(seekTo(seconds)),
  })
)(PlayerProgress)
