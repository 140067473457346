import { connect } from 'react-redux'

import { HearingAidFittings } from 'src/constants.js'
import { setVhaAudiogramFrequencyValue } from 'src/actions/vha.actions.js'
import AudiogramForm from 'src/components/AudiogramForm.js'

export default connect(
  (state, { ear }) => ({
    values: state.getIn(['vha', 'aid', 'values', HearingAidFittings.AUDIOGRAMS, ear]),
  }),
  (dispatch, { ear }) => ({
    onValueChange: (frequency, value) =>
      dispatch(setVhaAudiogramFrequencyValue(ear, frequency, value)),
  })
)(AudiogramForm)
