import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { T, withTranslators } from 'lioness'

import { createPatient } from 'src/actions/consultant.actions.js'
import SignUpForm from 'src/components/SignUpForm.js'

/**
 * Create Patient View
 */
class CreatePatientView extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isPending: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { onSubmit, isPending, t } = this.props

    return (
      <div className="CreatePatientView">
        <SignUpForm
          preSubmitText={
            <p>
              <T
                message="By assigning this user to you as your patient, you agree to the {{ link:End-user license agreement }} on their behalf."
                link={<Link to="/eula" />}
              />
            </p>
          }
          submitLabel={isPending === true ? t('Creating patient...') : t('Create patient')}
          onSubmit={onSubmit}
          isSubmitting={isPending}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    isPending: state.getIn(['consultant', 'createPatient', 'isPending']),
  }),
  dispatch => ({
    onSubmit: ({ email, password, firstName, lastName }) =>
      dispatch(createPatient({ email, password, firstName, lastName })),
  })
)(withTranslators(CreatePatientView))
