import { createBrowserHistory } from 'history'
import { push } from 'react-router-redux'

const history = createBrowserHistory()

export default history

export const redirectTo = pathname => {
  return push(pathname)
}
