import { delay } from 'redux-saga'
import { take, call, fork, race, cancel, cancelled, select, put } from 'redux-saga/effects'
import { every } from 'lodash'

import { ActionTypes, Ears, ListeningMethods, OnboardingTours } from 'src/constants.js'
import { setLyricsEnabled } from 'src/actions/lyrics.actions.js'
import {
  setTourActive,
  setTourStep,
  setTourActionCompleted,
} from 'src/actions/onboarder.actions.js'
import { requestPlaySong } from 'src/actions/player.actions.js'
import { resetVha } from 'src/actions/vha.actions.js'
import { hasCompletedPrerequisiteActions } from 'src/reducers/onboarder.reducer.js'

let mainTourTask = null
let actionCompletionTask = null

function* performMainTour() {
  while (true) {
    const { payload: { listeningMethod, hearingInfo, songId } } = yield take(
      ActionTypes.START_MAIN_ONBOARDING
    )
    const currentSong = yield select(state => state.getIn(['player', 'song']))

    // Apply hearing aid settings
    const initialVha =
      listeningMethod === ListeningMethods.HEADPHONES
        ? {
            listeningMethod,
            aid: {
              fittingMode: hearingInfo.getIn([Ears.LEFT, 'type']),
              values: {
                [hearingInfo.getIn([Ears.LEFT, 'type'])]: {
                  [Ears.LEFT]: hearingInfo.getIn([Ears.LEFT, 'value']),
                  [Ears.RIGHT]: hearingInfo.getIn([Ears.RIGHT, 'value']),
                },
              },
            },
          }
        : {
            listeningMethod,
          }
    yield put(resetVha(initialVha))

    // Go to mixer page
    const redirectTo = require('src/history.js').redirectTo
    yield call(redirectTo, '/mixer')

    // Play song
    yield put(requestPlaySong(songId))

    // Start tour when song has begun playing
    if (currentSong !== songId) {
      yield take(ActionTypes.BEGIN_PLAYBACK)
    }

    // Start the tour in a separate thread
    mainTourTask = yield fork(performTourStart, OnboardingTours.MIXER, 1)
  }
}

function* performTourStarts() {
  while (true) {
    const { payload: { tourName, startingStep } } = yield take(ActionTypes.START_ONBOARDING_TOUR)

    mainTourTask = yield fork(performTourStart, tourName, startingStep)
  }
}

function* performTourStart(tourName, startingStep) {
  // Open the VHA modal if we need to
  if (tourName === OnboardingTours.VHA) {
    const isVhaOpen = yield select(state => state.getIn(['vha', 'isActive']))
    if (isVhaOpen === false) {
      const redirectTo = require('src/history.js').redirectTo
      yield call(redirectTo, '/vha')
      yield delay(1000)
    }
  }

  // Open the mixer modal if we need to
  if (tourName === OnboardingTours.MIXER) {
    const isMixerOpen = yield select(state => state.getIn(['mixer', 'isActive']))
    if (isMixerOpen === false) {
      const redirectTo = require('src/history.js').redirectTo
      yield call(redirectTo, '/mixer')
      yield delay(1000)
    }
  }

  // Cancel this when tour is quitted or completed
  if (actionCompletionTask) {
    yield cancel(actionCompletionTask)
  }
  actionCompletionTask = yield fork(keepTrackOfCompletedActionsInTours, tourName)

  // Hide lyrics
  yield put(setLyricsEnabled(false))

  // Set the tour as active
  yield put(setTourStep(tourName, null))
  yield put(setTourActive(tourName, true))

  // a) Complete all prerequisite actions before showing step 1
  // b) Abort the tour if the user's rejects any prerequisite action
  const { complete } = yield race({
    complete: call(waitForAllPrerequisiteActionsToComplete, tourName),
    abort: take(ActionTypes.ABORT_ONBOARDING_TOUR),
  })

  // If the prerequisite actions were completed, show the first step
  if (complete) {
    yield delay(200)
    yield put(setTourStep(tourName, startingStep))
  }
}

function* waitForAllPrerequisiteActionsToComplete(tourName) {
  let allPrerequisitesCompleted = yield select(state =>
    hasCompletedPrerequisiteActions(state.get('onboarder'), tourName)
  )
  while (allPrerequisitesCompleted === false) {
    yield take(ActionTypes.SET_TOUR_PREREQUISITE_ACTION_COMPLETED)
    allPrerequisitesCompleted = yield select(state =>
      hasCompletedPrerequisiteActions(state.get('onboarder'), tourName)
    )
  }

  return true
}

function* performTourEnds() {
  while (true) {
    yield take(ActionTypes.END_ONBOARDING_TOUR)

    if (mainTourTask) {
      cancel(mainTourTask)
    }
  }
}

function* keepTrackOfCompletedActionsInTours(tourName) {
  const trackedActions = yield select(state =>
    state.getIn(['onboarder', 'tours', tourName, 'completedActions'])
  )

  if (trackedActions) {
    const trackActionTypes = Object.keys(trackedActions.toJS())

    while (every(trackedActions) === false) {
      const { type } = yield take(trackActionTypes)
      const hasCompletedAction = yield select(state =>
        state.getIn(['onboarder', 'tours', tourName, 'completedActions', type])
      )

      if (yield cancelled()) {
        break
      } else if (hasCompletedAction === false) {
        yield delay(600)
        yield put(setTourActionCompleted(tourName, type, true))
      }
    }
  }
}

export default function* onboarderSagas() {
  yield [performMainTour(), performTourStarts(), performTourEnds()]
}
