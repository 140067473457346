/* global window */
import qs from 'qs'

import { AppEnvironments } from 'src/constants.js'
import environment from 'src/environment.js'

const queryParams = qs.parse(window.location.search.replace(/^\?/, ''))

const s3BucketUrl = 'https://s3-eu-west-1.amazonaws.com/musiclarity-content/dash'

const configs = {
  [AppEnvironments.LOCAL]: {
    api: {
      baseUrl: 'http://localhost:8645',
    },
    s3: {
      bucketUrl: s3BucketUrl,
    },
  },
  [AppEnvironments.DEVELOPMENT]: {
    api: {
      baseUrl: 'http://dev.api.musiclarity.com',
    },
    s3: {
      bucketUrl: s3BucketUrl,
    },
  },
  [AppEnvironments.STAGE]: {
    api: {
      baseUrl: 'https://stage.api.musiclarity.com',
    },
    s3: {
      bucketUrl: s3BucketUrl,
    },
  },
  [AppEnvironments.PRODUCTION]: {
    api: {
      baseUrl: 'https://stage.api.musiclarity.com',
    },
    s3: {
      bucketUrl: s3BucketUrl,
    },
  },
}

export default configs[environment]
