import React from 'react'
import { T } from 'lioness'

import Checkbox from 'src/components/forms/Checkbox.js'

/**
 * Ear Mirroring Form
 */
const EarMirroringForm = ({ isMirrored, onChange }) => (
  <div className="EarMirroringForm" style={{ marginBottom: 16 }}>
    <Checkbox isChecked={isMirrored} onChange={value => onChange(value)}>
      <T>Make identical for both ears</T>
    </Checkbox>
  </div>
)

export default EarMirroringForm
