import React from 'react'
import { getColorForTrackNumber } from 'src/utils/colors.js'

/**
 * A track spectrum graph label
 */
const SpectrumLabel = ({ track }) => (
  <span className="SpectrumLabel">
    <span
      className="SpectrumLabel-color"
      style={{ backgroundColor: getColorForTrackNumber(track.get('trackNumber')) }}
    />

    {track.get('name')}
  </span>
)

export default SpectrumLabel
