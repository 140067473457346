import { fromJS } from 'immutable'
import { isEqual, reduce, sortBy } from 'lodash'

import { BinauralPresets, VolumeModifications } from 'src/constants.js'

export const isTrackEnabled = (tracks, track) => {
  if (tracks.count(x => x.get('isSoloed')) > 0) {
    return track.get('isSoloed') === true && track.get('isMuted') === false
  }

  return track.get('isMuted') === false
}

export const getDefaultZForTrackWithNumber = trackNumber => {
  return -0.01 - (trackNumber - 1) * 0.1
}

export const BinauralPresetValues = {
  [BinauralPresets.SURROUND_A]: fromJS([
    { x: -0.2, y: 0, z: 0.2 },
    { x: 0.2, y: 0, z: 0.2 },
    { x: 0.2, y: 0, z: -0.2 },
    { x: -0.2, y: 0, z: -0.2 },
  ]),
  [BinauralPresets.SURROUND_B]: fromJS([
    { x: 0, y: 0, z: 0.5 },
    { x: 0, y: 0, z: -0.5 },
    { x: -0.5, y: 0, z: 0 },
    { x: 0.5, y: 0, z: 0 },
  ]),
  [BinauralPresets.RADIAL_IN_FRONT]: fromJS([
    { x: -0.3, y: 0, z: -0.1 },
    { x: -0.17, y: 0, z: -0.25 },
    { x: 0.17, y: 0, z: -0.25 },
    { x: 0.3, y: 0, z: -0.1 },
  ]),
  [BinauralPresets.EVERYTHING_CENTERED]: fromJS([
    { x: 0, y: 0, z: getDefaultZForTrackWithNumber(1) },
    { x: 0, y: 0, z: getDefaultZForTrackWithNumber(2) },
    { x: 0, y: 0, z: getDefaultZForTrackWithNumber(3) },
    { x: 0, y: 0, z: getDefaultZForTrackWithNumber(4) },
  ]),
}

export const getPresetFromPositions = positions => {
  const positionsJs = sortBy(positions.toJS(), ['x', 'y', 'z'])

  return reduce(
    BinauralPresetValues,
    (aggr, preset, name) => {
      return isEqual(positionsJs, sortBy(preset.toJS(), ['x', 'y', 'z'])) === true ? name : aggr
    },
    null
  )
}

export const getVolumeChangeForModification = modification => {
  switch (modification) {
    case VolumeModifications.BIG_INCREASE:
      return 0.1
    case VolumeModifications.SMALL_INCREASE:
      return 0.02
    case VolumeModifications.SMALL_DECREASE:
      return -0.02
    case VolumeModifications.BIG_DECREASE:
      return -0.1
    default:
      return 0
  }
}
