import React, { Component } from 'react'

/**
 * Admin Dashboard View
 */
class AdminDashboardView extends Component {
  render() {
    return (
      <div className="AdminDashboardView">
        <h2>Dashboard</h2>
      </div>
    )
  }
}

export default AdminDashboardView
