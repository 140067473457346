import React, { PureComponent } from 'react'
import { withTranslators } from 'lioness'
import Markdown from 'react-markdown'

import { Locales } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'

/**
 * Currently, this cannot be translated using Lioness, since the
 * configured string transformations remove white-space from it.
 *
 * @see https://github.com/alexanderwallin/lioness/issues/29
 */
const EULA_EN = `
# End-User License Agreement ("Agreement")

*Last updated: (add date)*

Please read this End-User License Agreement ("Agreement") carefully before clicking the "I
Agree" button, downloading or using My Application (change this) ("Application").

By clicking the "I Agree" button, downloading or using the Application, you are agreeing
to be bound by the terms and conditions of this Agreement.

If you do not agree to the terms of this Agreement, do not click on the "I Agree" button
and do not download or use the Application.

## License

My Company (change this) grants you a revocable, non-exclusive, non-transferable, limited
license to download, install and use the Application solely for your personal,
non-commercial purposes strictly in accordance with the terms of this Agreement.

## Restrictions

You agree not to, and you will not permit others to:

a) license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or
otherwise commercially exploit the Application or make the Application available to any
third party.

## Modifications to Application

My Company (change this) reserves the right to modify, suspend or discontinue, temporarily
or permanently, the Application or any service to which it connects, with or without
notice and without liability to you.

## Term and Termination

This Agreement shall remain in effect until terminated by you or My Company (change this).

My Company (change this) may, in its sole discretion, at any time and for any or no
reason, suspend or terminate this Agreement with or without prior notice.

This Agreement will terminate immediately, without prior notice from My Company (change
this), in the event that you fail to comply with any provision of this Agreement. You may
also terminate this Agreement by deleting the Application and all copies thereof from your
mobile device or from your desktop.

Upon termination of this Agreement, you shall cease all use of the Application and delete
all copies of the Application from your mobile device or from your desktop.

## Severability

If any provision of this Agreement is held to be unenforceable or invalid, such provision
will be changed and interpreted to accomplish the objectives of such provision to the
greatest extent possible under applicable law and the remaining provisions will continue
in full force and effect.

## Amendments to this Agreement

My Company (change this) reserves the right, at its sole discretion, to modify or replace
this Agreement at any time. If a revision is material we will provide at least 30 (changes
this) days' notice prior to any new terms taking effect. What constitutes a material
change will be determined at our sole discretion.

## Contact Information

If you have any questions about this Agreement, please contact us.
`

const LocalisedEulas = {
  [Locales.EN]: EULA_EN,
  [Locales.ES]: EULA_EN,
  [Locales.IT]: EULA_EN,
  [Locales.SV]: EULA_EN,
}

/**
 * Eula Document
 */
class EulaDocument extends PureComponent {
  static propTypes = {
    locale: CustomPropTypes.locale.isRequired,
  }

  render() {
    const { locale } = this.props

    return (
      <div className="EulaDocument">
        <Markdown source={LocalisedEulas[locale]} />
      </div>
    )
  }
}

export default withTranslators(EulaDocument)
