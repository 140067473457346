import { delay } from 'redux-saga'
import { take, select, put } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'

import { ActionTypes, Barks } from 'src/constants.js'
import { setBarkActive } from 'src/actions/barker.actions.js'
import { playSong } from 'src/actions/player.actions.js'
import { isLoggedIn } from 'src/reducers/auth.reducer.js'

function* barkAboutSignUpAfterVhaConfigs() {
  const [isUserLoggedIn, hasBarkedAboutVha] = yield [
    select(state => isLoggedIn(state.get('auth'))),
    select(state => state.getIn(['barker', Barks.VHA, 'isCompleted'])),
  ]

  if (isUserLoggedIn === false && hasBarkedAboutVha === false) {
    yield take(ActionTypes.SET_VHA_INITIALIZED)

    while (true) {
      yield take([
        ActionTypes.SET_VHA_FITTING_MODE,
        ActionTypes.SET_VHA_PRESET,
        ActionTypes.SET_VHA_HEARING_LOSS_CODE,
        ActionTypes.SET_VHA_AUDIOGRAM,
        ActionTypes.SET_VHA_AUDIOGRAM_FREQUENCY_VALUE,
        ActionTypes.SET_TONE_CONTROL_MIRRORED,
        ActionTypes.SET_TONE_CONTROL_GAIN,
        ActionTypes.SET_COMPRESSION_MIRRORED,
        ActionTypes.SET_COMPRESSION_AMOUNT,
        ActionTypes.SET_LEVEL_ADJUSTMENT,
      ])
      yield take(LOCATION_CHANGE)

      yield delay(1000)
      yield put(setBarkActive(Barks.VHA, true))
      break
    }
  }
}

function* barkAboutEulaWhenPlayingForTheFirstTime() {
  yield take(ActionTypes.APP_BOOTED)

  while (true) {
    const { payload: { songId } } = yield take(ActionTypes.REQUEST_PLAY_SONG)
    const hasAcceptedEula = yield select(state =>
      state.getIn(['barker', Barks.EULA, 'isCompleted'])
    )

    if (hasAcceptedEula === false) {
      yield put(setBarkActive(Barks.EULA, true))

      // Here we naively assume that no other barkers will be
      // activated or interacted with concurrently with the
      // EULA prompt
      const { payload: { isCompleted } } = yield take(ActionTypes.SET_BARK_COMPLETED)
      if (isCompleted === true) {
        yield put(playSong(songId))
      }
    } else {
      yield put(playSong(songId))
    }
  }
}

export default function* barkerSagas() {
  yield [barkAboutSignUpAfterVhaConfigs(), barkAboutEulaWhenPlayingForTheFirstTime()]
}
