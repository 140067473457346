import React from 'react'

import SvgIcon from './SvgIcon.js'

export default () => (
  <SvgIcon>
    <path
      d="M26.9899998,9.475 C25.0999998,9.475 25.3699998,16.765 23.2099998,16.765 C21.0499998,16.765 21.5899998,12.9850003 18.8899998,12.985 C16.1899998,12.9849997 16.1899998,14.335 14.5699998,14.335 C12.9499998,14.335 13.2199998,11.3649999 10.52,11.3649999 C7.82000021,11.3649999 7.28000004,22.4349997 3.5,24.8649998 L30.5,24.865 C29.15,22.9749992 28.8799998,9.475 26.9899998,9.475 Z"
      id="Combined-Shape"
    />
  </SvgIcon>
)
