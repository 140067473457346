import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { GatewayProvider } from 'react-gateway'

// Styles
import './styles/app.scss'

import audioContext from 'src/audio/context.js'
import dispatchBufferStateChanges from 'src/audio/dispatchBufferStateChanges.js'
import store from 'src/store/store.js'
import App from 'src/components/App.js'
import AppBooter from 'src/components/AppBooter.js'
import AppRouter from 'src/components/AppRouter.js'
import DinosaurBrowserNotice from 'src/components/DinosaurBrowserNotice.js'
import ConfiguredLionessProvider from 'src/components/l10n/ConfiguredLionessProvider.js'

dispatchBufferStateChanges(store)

const Application = () => (
  <Provider store={store}>
    <AppBooter>
      <GatewayProvider>
        <ConfiguredLionessProvider>
          {audioContext === null ? (
            <DinosaurBrowserNotice />
          ) : (
            <AppRouter>
              <App />
            </AppRouter>
          )}
        </ConfiguredLionessProvider>
      </GatewayProvider>
    </AppBooter>
  </Provider>
)

function transitionToApp() {
  setTimeout(() => {
    document.body.classList.remove('is-loading')

    setTimeout(() => {
      ReactDOM.render(<Application />, document.querySelector('.app-root'))
    }, 1000)
  }, 2000)
}

transitionToApp()

// Hot module replacement support
if (module.hot) {
  module.hot.accept('src/components/App.js', () => {
    ReactDOM.render(<Application />, document.querySelector('.app-root'))
  })
}
