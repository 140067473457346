import { ActionTypes } from 'src/constants.js'

export const setSongsListingStyle = listingStyle => ({
  type: ActionTypes.SET_SONGS_LISTING_STYLE,
  payload: { listingStyle },
})

export const setSongsFilter = (group, filter, value) => ({
  type: ActionTypes.SET_SONGS_FILTER,
  payload: { group, filter, value },
})

export const setSongsSorting = (field, direction) => ({
  type: ActionTypes.SET_SONGS_SORTING,
  payload: { field, direction },
})
