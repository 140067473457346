import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { values } from 'lodash'

import { KeyboardKeys, VolumeModifications } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import KeyPressIndicator from 'src/components/KeyPressIndicator.js'

import './TrackVolumeKeyControlsIndicators.scss'

const trackNumberToKeySetMap = {
  '1': [KeyboardKeys.NUMBER_1, KeyboardKeys.Q, KeyboardKeys.A, KeyboardKeys.Z],
  '2': [KeyboardKeys.NUMBER_2, KeyboardKeys.W, KeyboardKeys.S, KeyboardKeys.X],
  '3': [KeyboardKeys.NUMBER_3, KeyboardKeys.E, KeyboardKeys.D, KeyboardKeys.C],
  '4': [KeyboardKeys.NUMBER_4, KeyboardKeys.R, KeyboardKeys.F, KeyboardKeys.V],
}

/**
 * Track Volume Key Controls Indicators
 */
class TrackVolumeKeyControlsIndicators extends Component {
  static propTypes = {
    trackNumber: CustomPropTypes.trackNumber.isRequired,
    modifications: PropTypes.arrayOf(PropTypes.oneOf(values(VolumeModifications))).isRequired,
    isEnabled: PropTypes.bool,
    isClickable: PropTypes.bool,
    className: PropTypes.string,
    onVolumeModification: PropTypes.func,
  }

  static defaultProps = {
    isEnabled: true,
    isClickable: false,
    className: '',
    onVolumeModification: () => {},
  }

  handleActionClick(modification) {
    const { isEnabled, isClickable, onVolumeModification } = this.props

    if (isEnabled === true && isClickable === true) {
      onVolumeModification(modification)
    }
  }

  render() {
    const { trackNumber, modifications, isEnabled, isClickable, className } = this.props

    const volumeKeys = trackNumberToKeySetMap[`${trackNumber}`]

    return (
      <div className={cx('TrackVolumeKeyControlsIndicators', className, { isEnabled })}>
        {(modifications.includes(VolumeModifications.BIG_INCREASE) ||
          modifications.includes(VolumeModifications.SMALL_INCREASE)) && (
          <div className="TrackVolumeKeyControlsIndicators-group">
            {modifications.includes(VolumeModifications.BIG_INCREASE) && (
              <div
                className={cx('TrackVolumeKeyControlsIndicators-action', { isClickable })}
                onClick={() => this.handleActionClick(VolumeModifications.BIG_INCREASE)}
              >
                <KeyPressIndicator
                  keyCode={volumeKeys[0]}
                  isEnabled={isEnabled}
                  className="TrackVolumeKeyControlsIndicators-key"
                  activeClassName="isActive"
                />
                <span className="TrackVolumeKeyControlsIndicators-effect">+ 10</span>
              </div>
            )}

            {modifications.includes(VolumeModifications.SMALL_INCREASE) && (
              <div
                className={cx('TrackVolumeKeyControlsIndicators-action', { isClickable })}
                onClick={() => this.handleActionClick(VolumeModifications.SMALL_INCREASE)}
              >
                <KeyPressIndicator
                  keyCode={volumeKeys[1]}
                  isEnabled={isEnabled}
                  className="TrackVolumeKeyControlsIndicators-key"
                  activeClassName="isActive"
                />
                <span className="TrackVolumeKeyControlsIndicators-effect">+ 2</span>
              </div>
            )}
          </div>
        )}

        {(modifications.includes(VolumeModifications.SMALL_DECREASE) ||
          modifications.includes(VolumeModifications.BIG_DECREASE)) && (
          <div className="TrackVolumeKeyControlsIndicators-group">
            {modifications.includes(VolumeModifications.SMALL_DECREASE) && (
              <div
                className={cx('TrackVolumeKeyControlsIndicators-action', { isClickable })}
                onClick={() => this.handleActionClick(VolumeModifications.SMALL_DECREASE)}
              >
                <KeyPressIndicator
                  keyCode={volumeKeys[2]}
                  isEnabled={isEnabled}
                  className="TrackVolumeKeyControlsIndicators-key"
                  activeClassName="isActive"
                />
                <span className="TrackVolumeKeyControlsIndicators-effect">- 2</span>
              </div>
            )}

            {modifications.includes(VolumeModifications.BIG_DECREASE) && (
              <div
                className={cx('TrackVolumeKeyControlsIndicators-action', { isClickable })}
                onClick={() => this.handleActionClick(VolumeModifications.BIG_DECREASE)}
              >
                <KeyPressIndicator
                  keyCode={volumeKeys[3]}
                  isEnabled={isEnabled}
                  className="TrackVolumeKeyControlsIndicators-key"
                  activeClassName="isActive"
                />
                <span className="TrackVolumeKeyControlsIndicators-effect">- 10</span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default TrackVolumeKeyControlsIndicators
