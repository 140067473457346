import { ActionTypes } from 'src/constants.js'

export const setVisualizerEnabled = isEnabled => ({
  type: ActionTypes.SET_VISUALIZER_ENABLED,
  payload: isEnabled,
})

export const setVisualizerType = type => ({
  type: ActionTypes.SET_VISUALIZER_TYPE,
  payload: type,
})

export const setVisualizerMode = mode => ({
  type: ActionTypes.SET_VISUALIZER_MODE,
  payload: mode,
})

export const setVisualizerMirroring = shouldMirror => ({
  type: ActionTypes.SET_VISUALIZER_MIRRORING,
  payload: shouldMirror,
})

export const setVisualizerSilenceRendering = shouldRender => ({
  type: ActionTypes.SET_VISUALIZER_SILENCE_RENDERING,
  payload: shouldRender,
})

export const setVisualizerZeroEdges = setToZero => ({
  type: ActionTypes.SET_VISUALIZER_ZERO_EDGES,
  payload: setToZero,
})
