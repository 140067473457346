import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createLogger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'react-router-redux'

import history from 'src/history.js'
import rootReducer from 'src/reducers/rootReducer.js'
import { createPersistorEnhancer, rehydrateReducer } from 'src/store/createPersistorEnhancer.js'
import rootSaga from 'src/sagas/rootSaga.js'

const logger = createLogger({
  collapsed: true,
  predicate: () => process.env.LOG_ACTIONS === true,
})

const historicalRouterMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const initialState = rootReducer(undefined, { type: '' })

const store = createStore(
  rehydrateReducer(rootReducer),
  initialState,
  compose(
    applyMiddleware(thunk, logger, historicalRouterMiddleware, sagaMiddleware),
    createPersistorEnhancer(),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)

sagaMiddleware.run(rootSaga)

// Enable Webpack hot module replacement for reducers
if (module.hot) {
  module.hot.accept('../reducers/rootReducer', () => {
    const nextRootReducer = require('../reducers/rootReducer').default
    store.replaceReducer(nextRootReducer)
  })
}

export default store
