import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as FeatherIcon from 'react-feather'
import { values } from 'lodash'
import cx from 'classnames'

import { ListingStyles } from 'src/constants.js'
import { setSongsListingStyle } from 'src/actions/browsing.actions.js'

import './SongsListingStylePicker.scss'

/**
 * Songs Listing Style Picker
 */
class SongsListingStylePicker extends PureComponent {
  static propTypes = {
    listingStyle: PropTypes.oneOf(values(ListingStyles)).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { listingStyle, onChange } = this.props

    return (
      <div className="SongsListingStylePicker">
        <button
          className={cx('SongsListingStylePicker-button', {
            isSelected: listingStyle === ListingStyles.LIST,
          })}
          onClick={() => onChange(ListingStyles.LIST)}
        >
          <FeatherIcon.List />
        </button>

        <button
          className={cx('SongsListingStylePicker-button', {
            isSelected: listingStyle === ListingStyles.GRID,
          })}
          onClick={() => onChange(ListingStyles.GRID)}
        >
          <FeatherIcon.Grid />
        </button>
      </div>
    )
  }
}

export default connect(
  state => ({
    listingStyle: state.getIn(['browsing', 'listingStyle']),
  }),
  dispatch => ({
    onChange: listingStyle => dispatch(setSongsListingStyle(listingStyle)),
  })
)(SongsListingStylePicker)
