import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Async } from 'react-select'
import { T } from 'lioness'
import { debounce } from 'lodash'
import cx from 'classnames'
import { autobind } from 'core-decorators'

import { findUsers, withToken } from 'src/api.js'
import { selectAuthToken } from 'src/selectors.js'

import './UserSearchPicker.scss'

async function fetchOptions(token, input, callback) {
  let result = { options: [] }

  if (input !== '') {
    try {
      const fn = withToken(findUsers(input), token)
      const { data } = await fn()
      const options = data.map(user => ({
        value: user,
        label: `${user.firstName} ${user.lastName} - ${user.email}`,
      }))
      result = { options }
    } catch (err) {
      console.log('Could not perform user search:')
      console.log(err)
    }
  }

  callback(null, result)
}

const debouncedFetchOptions = debounce(fetchOptions, 500)

/**
 * User Search Picker Option
 */
class UserSearchPickerOption extends PureComponent {
  static propTypes = {
    isInline: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }

  render() {
    const { isInline, email, firstName, lastName } = this.props

    return (
      <div className={cx('UserSearchPickerOption', { isInline })}>
        <div className="UserSearchPickerOption-name">
          {firstName} {lastName}
        </div>
        <div className="UserSearchPickerOption-email">{email}</div>
      </div>
    )
  }
}

/**
 * User Search Picker
 */
class UserSearchPicker extends PureComponent {
  static propTypes = {
    token: PropTypes.string.isRequired,
    filterUser: PropTypes.func,
    selectedUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    onSelect: PropTypes.func.isRequired,
  }

  static defaultProps = {
    filterUser: x => x,
    selectedUser: null,
  }

  @autobind
  handleLoadOptions(input, callback) {
    const { token, filterUser } = this.props

    if (input === '') {
      callback(null, { options: [] })
    } else {
      debouncedFetchOptions(token, input, (err, result) => {
        const filteredOptions = result.options.filter(option => filterUser(option.value))
        callback(null, { options: filteredOptions })
      })
    }
  }

  render() {
    const { selectedUser, onSelect } = this.props

    return (
      <Async
        name="user"
        autoload={false}
        cache={false}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        value={selectedUser}
        loadOptions={this.handleLoadOptions}
        onChange={option => onSelect(option === null ? null : option.value)}
        placeholder={<T>Search users...</T>}
        searchPromptText={<T>Type a name or email address</T>}
        loadingPlaceholder={<T>Searching...</T>}
        noResultsText={<T>No users found</T>}
        optionRenderer={option => <UserSearchPickerOption isInline={false} {...option.value} />}
        valueRenderer={user => <UserSearchPickerOption isInline={true} {...user} />}
      />
    )
  }
}

export default connect(state => ({
  token: selectAuthToken(state),
}))(UserSearchPicker)
