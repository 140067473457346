import { Component, Children } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { bootApp } from 'src/actions/boot.actions.js'

/**
 * Placeholder component for when the app is booting
 */
class AppBooter extends Component {
  static propTypes = {
    hasBooted: PropTypes.bool.isRequired,
    boot: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.boot()
  }

  render() {
    const { hasBooted, children } = this.props

    return hasBooted ? Children.only(children) : null
  }
}

export default connect(
  state => ({
    hasBooted: state.getIn(['boot', 'hasBooted']),
  }),
  dispatch => ({
    boot: () => dispatch(bootApp()),
  })
)(AppBooter)
