import { startCase } from 'lodash'

export const getUserFirstName = user => {
  return user.get('firstName') || getNameFromEmail(user.get('email'))
}

export const getNameFromEmail = email => {
  const [names] = email.split('@')
  return startCase(names.split('.'))
}
