import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'

import * as CustomPropTypes from 'src/prop-types.js'
import { startPatientSession } from 'src/actions/consultant.actions.js'
import Button from 'src/components/Button.js'

/**
 * Start Patient Session Button
 */
class StartPatientSessionButton extends PureComponent {
  static propTypes = {
    patient: CustomPropTypes.sessionPatient.isRequired,
    onStart: PropTypes.func.isRequired,
  }

  render() {
    const { patient, onStart, ...buttonProps } = this.props

    return (
      <Button onClick={() => onStart(patient)} {...buttonProps}>
        <T>Start session</T>
      </Button>
    )
  }
}

export default connect(null, dispatch => ({
  onStart: patient => dispatch(startPatientSession(patient)),
}))(StartPatientSessionButton)
