import { ActionTypes } from 'src/constants.js'

export const login = ({ email, password }) => ({
  type: ActionTypes.LOGIN,
  payload: { email, password },
})

export const loginSuccess = token => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: token,
})

export const loginError = () => ({
  type: ActionTypes.LOGIN_ERROR,
})

export const logout = () => ({
  type: ActionTypes.LOGOUT,
})
