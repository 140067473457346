import React from 'react'
import cx from 'classnames'

export const createComponentWithClassName = (fixedClassName, component = 'div') => {
  return ({ className, ...props }) => {
    return React.createElement(component, {
      className: cx(fixedClassName, className),
      ...props,
    })
  }
}
