import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Button, { Loudness, Size } from 'src/components/Button.js'
import Checkbox from 'src/components/forms/Checkbox.js'
import View from 'src/components/View.js'
import { BLACK } from 'src/styles/colors.js'

import './UIInventoryView.scss'

const noop = () => {}

class CheckboxForm extends Component {
  state = {
    firstChecked: false,
    secondChecked: true,
  }

  render() {
    const { firstChecked, secondChecked } = this.state

    return (
      <div>
        <p>
          <Checkbox
            isChecked={firstChecked}
            onChange={newVal => this.setState(() => ({ firstChecked: newVal }))}
          >
            Some text
          </Checkbox>
        </p>

        <p style={{ padding: 20, backgroundColor: BLACK }}>
          <Checkbox
            isInverted
            isChecked={secondChecked}
            onChange={newVal => this.setState(() => ({ secondChecked: newVal }))}
          >
            Some text
          </Checkbox>
        </p>
      </div>
    )
  }
}

/**
 * UI Inventory View
 */
class UIInventoryView extends Component {
  render() {
    return (
      <View className="UIInventoryView">
        <Helmet title="UI Inventory" />

        <h1>UI Inventory</h1>

        <h2>Buttons</h2>

        <table>
          <thead>
            <tr>
              <th />
              <th>Scream</th>
              <th>Yell</th>
              <th>Talk</th>
              <th>Whisper</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Normal</strong>
              </td>
              <td>
                <Button loudness={Loudness.SCREAM} onClick={noop}>
                  Scream button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.YELL} onClick={noop}>
                  Yell button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.TALK} onClick={noop}>
                  Talk button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.WHISPER} onClick={noop}>
                  Whisper button
                </Button>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Small</strong>
              </td>
              <td>
                <Button loudness={Loudness.SCREAM} size={Size.SMALL} onClick={noop}>
                  Scream button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.YELL} size={Size.SMALL} onClick={noop}>
                  Yell button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.TALK} size={Size.SMALL} onClick={noop}>
                  Talk button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.WHISPER} size={Size.SMALL} onClick={noop}>
                  Whisper button
                </Button>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Tiny</strong>
              </td>
              <td>
                <Button loudness={Loudness.SCREAM} size={Size.TINY} onClick={noop}>
                  Scream button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.YELL} size={Size.TINY} onClick={noop}>
                  Yell button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.TALK} size={Size.TINY} onClick={noop}>
                  Talk button
                </Button>
              </td>
              <td>
                <Button loudness={Loudness.WHISPER} size={Size.TINY} onClick={noop}>
                  Whisper button
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          <Button loudness={Loudness.TALK} size={Size.NORMAL} isActive onClick={noop}>
            Active state
          </Button>
        </p>

        <h2>Forms</h2>

        <h3>Checkbox</h3>
        <p>
          <Checkbox isChecked={false} />
        </p>
        <p>
          <Checkbox isChecked={true} />
        </p>

        <h3>CheckboxField</h3>
        <CheckboxForm />
      </View>
    )
  }
}

export default UIInventoryView
