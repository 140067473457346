import React, { Component } from 'react'

import { getNormalizedDb, getEmptyFftData } from 'src/utils/fft-helpers.js'
import withFftData from 'src/audio/withFftData.js'
import LineSpectrumGraph from 'src/components/LineSpectrumGraph.js'

import './TrackMiniVisualizer.scss'

const SimpleFftGraph = ({ tracksFftData, track }) => {
  const trackData =
    tracksFftData && tracksFftData.size > 0
      ? tracksFftData.first().map(getNormalizedDb)
      : getEmptyFftData()

  return (
    <LineSpectrumGraph
      track={track}
      values={trackData}
      width={90}
      height={20}
      resolution={3}
      mirrorGraph={false}
      renderSilence={true}
      showDots={false}
    />
  )
}

class TrackMiniVisualizer extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.track.get('id') !== nextProps.track.get('id')
  }

  render() {
    const { track } = this.props

    const Graph = withFftData(x => x.get('id') === track.get('id'), SimpleFftGraph)

    return (
      <div className="TrackMiniVisualizer">
        <Graph track={track} />
      </div>
    )
  }
}

export default TrackMiniVisualizer
