import { ActionTypes } from 'src/constants.js'

/**
 * Me
 */
export const getMe = () => ({
  type: ActionTypes.GET_ME,
})

export const getMeSuccess = user => ({
  type: ActionTypes.GET_ME_SUCCESS,
  payload: user,
})

export const getMeFailure = error => ({
  type: ActionTypes.GET_ME_ERROR,
  payload: error,
})

export const resetMe = () => ({
  type: ActionTypes.RESET_ME,
})

/**
 * Account
 */
export const createAccount = ({ firstName, lastName, email, password }) => ({
  type: ActionTypes.CREATE_ACCOUNT,
  payload: { firstName, lastName, email, password },
})

export const createAccountSuccess = user => ({
  type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
  payload: user,
})

export const createAccountFailure = error => ({
  type: ActionTypes.CREATE_ACCOUNT_ERROR,
  payload: error,
})

export const deleteAccount = id => ({
  type: ActionTypes.DELETE_ACCOUNT,
  payload: { id },
})

export const deleteAccountSuccess = () => ({
  type: ActionTypes.DELETE_ACCOUNT_SUCCESS,
})

export const deleteAccountFailure = error => ({
  type: ActionTypes.DELETE_ACCOUNT_ERROR,
  payload: error,
})

export const updateUserSettings = ({ id, firstName, lastName }) => ({
  type: ActionTypes.UPDATE_USER_SETTINGS,
  payload: { id, firstName, lastName },
})

/**
 * User settings
 */
export const updateUserSettingsSuccess = ({ firstName, lastName }) => ({
  type: ActionTypes.UPDATE_USER_SETTINGS_SUCCESS,
  payload: { firstName, lastName },
})

export const updateUserSettingsError = error => ({
  type: ActionTypes.UPDATE_USER_SETTINGS_ERROR,
  payload: error,
})

export const getUserVha = id => ({
  type: ActionTypes.GET_USER_VHA,
  payload: { id },
})

export const resetPassword = userId => ({
  type: ActionTypes.RESET_PASSWORD,
  payload: { userId },
})

export const resetPasswordSuccess = () => ({
  type: ActionTypes.RESET_PASSWORD_SUCCESS,
})

export const resetPasswordError = err => ({
  type: ActionTypes.RESET_PASSWORD_ERROR,
  error: err,
})

/**
 * User VHA & Musiclarity profile
 */
export const getUserVhaSuccess = vha => ({
  type: ActionTypes.GET_USER_VHA_SUCCESS,
  payload: vha,
})

export const getUserVhaError = error => ({
  type: ActionTypes.GET_USER_VHA_ERROR,
  payload: error,
})

export const updateUserVha = ({ id, vha }) => ({
  type: ActionTypes.UPDATE_USER_VHA,
  payload: { id, vha },
})

export const updateUserVhaSuccess = ({ id, vha }) => ({
  type: ActionTypes.UPDATE_USER_VHA_SUCCESS,
  payload: { id, vha },
})

export const updateUserVhaError = error => ({
  type: ActionTypes.UPDATE_USER_VHA_ERROR,
  payload: error,
})

export const sendUserProfile = ({ id, recipientEmail }) => ({
  type: ActionTypes.SEND_USER_PROFILE,
  payload: { id, recipientEmail },
})

export const sendUserProfileSuccess = () => ({
  type: ActionTypes.SEND_USER_PROFILE_SUCCESS,
  payload: true,
})

export const sendUserProfileError = error => ({
  type: ActionTypes.SEND_USER_PROFILE_ERROR,
  payload: error,
})
