import { forEach, last } from 'lodash'

import context from 'src/audio/context.js'
import DetunaEffect from 'src/audio/detuna/DetunaEffect.js'

export default class Splitter extends DetunaEffect {
  constructor(params) {
    super()

    this.splitter = context.createChannelSplitter(2)
    this.merger = context.createChannelMerger(2)

    this.splitter.connect(this.merger, 0, 0)
    this.splitter.connect(this.merger, 1, 1)

    this.input = this.splitter
    this.output = this.merger

    this.initialise(params)
  }

  reconnect(effects) {
    this.splitter.disconnect()

    const reconnectChain = (chain, channel) => {
      if (chain.length > 0) {
        this.splitter.connect(chain[0], channel)

        forEach(chain, (effect, i) => {
          if (i < chain.length - 1) {
            effect.connect(chain[i + 1])
          }
          last(chain).connect(this.merger, 0, channel)
        })
      } else {
        this.splitter.connect(this.merger, channel, channel)
      }
    }

    reconnectChain(effects[0], 0)
    reconnectChain(effects[1], 1)
  }

  set effects(effects) {
    this.reconnect(effects)
  }
}
