'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = []
    var _n = true
    var _d = false
    var _e = undefined
    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value)
        if (i && _arr.length === i) break
      }
    } catch (err) {
      _d = true
      _e = err
    } finally {
      try {
        if (!_n && _i['return']) _i['return']()
      } finally {
        if (_d) throw _e
      }
    }
    return _arr
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i)
    } else {
      throw new TypeError('Invalid attempt to destructure non-iterable instance')
    }
  }
})()

// TODO: Don't depend on window.Module, use import instead
var _window$Module = window.Module,
  HRIR = _window$Module.HRIR,
  CMonoBuffer = _window$Module.CMonoBuffer,
  HRIRVector = _window$Module.HRIRVector

/**
 * Returns an object with azimuth and elevation angles extracted
 * from a URL.
 */

var getAnglesFromUrl = function getAnglesFromUrl(url) {
  var _url$split$map = url.split(/.+T(\d{3})_P(\d{3})/).map(function(x) {
      return parseInt(x)
    }),
    _url$split$map2 = _slicedToArray(_url$split$map, 3),
    azimuth = _url$split$map2[1],
    elevation = _url$split$map2[2]

  return { azimuth: azimuth, elevation: elevation }
}

/**
 * Fetches a wav file and returns it as an array buffer.
 */
var fetchWavFile = function fetchWavFile(url) {
  return new Promise(function(resolve) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'arraybuffer'
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}

/**
 * Returns a Promise resolving with an AudioBuffer decoded from
 * an ArrayBuffer.
 */
var decodeBuffer = function decodeBuffer(buffer, audioCtx) {
  return audioCtx.decodeAudioData(buffer)
}

/**
 * Returns an array of HRIR-looking objects from a set of HRIR
 * wav URLs
 */
var fetchHrirFiles = function fetchHrirFiles(urls, audioCtx) {
  return Promise.all(urls.map(fetchWavFile))
    .then(function(buffers) {
      return Promise.all(
        buffers.map(function(buffer) {
          return decodeBuffer(buffer, audioCtx)
        })
      )
    })
    .then(function(buffers) {
      return buffers.map(function(x, i) {
        return Object.assign({}, getAnglesFromUrl(urls[i]), { buffer: x })
      })
    })
}

/**
 *
 */
var fetchHrirsVector = (exports.fetchHrirsVector = function fetchHrirsVector(urls, audioCtx) {
  return fetchHrirFiles(urls, audioCtx).then(function(results) {
    var hrirs = results.map(function(_ref) {
      var buffer = _ref.buffer,
        azimuth = _ref.azimuth,
        elevation = _ref.elevation

      var leftBuffer = new CMonoBuffer()
      var rightBuffer = new CMonoBuffer()

      var leftAudio = buffer.getChannelData(0)
      var rightAudio = buffer.getChannelData(1)

      leftBuffer.resize(leftAudio.length, 0)
      rightBuffer.resize(rightAudio.length, 0)

      for (var i = 0; i < leftAudio.length; i++) {
        leftBuffer.set(i, leftAudio[i])
        rightBuffer.set(i, rightAudio[i])
      }

      return new HRIR(leftBuffer, rightBuffer, azimuth, elevation)
    })

    var hrirsVector = new HRIRVector()
    var _iteratorNormalCompletion = true
    var _didIteratorError = false
    var _iteratorError = undefined

    try {
      for (
        var _iterator = hrirs[Symbol.iterator](), _step;
        !(_iteratorNormalCompletion = (_step = _iterator.next()).done);
        _iteratorNormalCompletion = true
      ) {
        var hrir = _step.value

        hrirsVector.push_back(hrir)
      }
    } catch (err) {
      _didIteratorError = true
      _iteratorError = err
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return()
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError
        }
      }
    }

    return hrirsVector
  })
})
