import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'
import cx from 'classnames'
import * as FeatherIcon from 'react-feather'
import { autobind } from 'core-decorators'

import { Positions } from 'src/constants.js'
import { setFeedbackFormOpen } from 'src/actions/feedback.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import { AnimatedTooltip, TooltipContent, TooltipStyles } from 'src/components/Tooltip.js'

import './FeedbackButton.scss'

/**
 * Feedback Button
 */
class FeedbackButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    isHovered: false,
  }

  @autobind
  handleMouseEnter() {
    this.setState({
      isHovered: true,
    })
  }

  @autobind
  handleMouseLeave() {
    this.setState({
      isHovered: false,
    })
  }

  render() {
    const { onClick, className } = this.props
    const { isHovered } = this.state

    return (
      <Button
        className={cx('FeedbackButton', className)}
        loudness={Loudness.WHISPER}
        size={Size.SMALL}
        onClick={onClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <FeatherIcon.MessageCircle className="Button-icon" />

        <AnimatedTooltip
          position={Positions.BOTTOM}
          offset={{
            top: 4,
            left: -6,
          }}
          isVisible={isHovered}
          transitionSize={10}
          className="FeedbackButton-tooltip"
        >
          <TooltipContent>
            <T>Give us feedback!</T>
          </TooltipContent>
        </AnimatedTooltip>
      </Button>
    )
  }
}

export default connect(null, dispatch => ({
  onClick: () => dispatch(setFeedbackFormOpen(true)),
}))(FeedbackButton)
