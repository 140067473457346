import { ActionTypes } from 'src/constants.js'

export const setBarkActive = (name, isActive) => ({
  type: ActionTypes.SET_BARK_ACTIVE,
  payload: { name, isActive },
})

export const setBarkCompleted = (name, isCompleted) => ({
  type: ActionTypes.SET_BARK_COMPLETED,
  payload: { name, isCompleted },
})
