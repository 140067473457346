import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import Gravatar from 'react-gravatar'

import { getUserFirstName } from 'src/utils/names.js'

const UserNavigationItem = ({ user, className, activeClassName }) => (
  <NavLink to="/me" className={className} activeClassName={activeClassName}>
    <span style={{ display: 'flex' }}>
      <span>
        <Gravatar
          size={48}
          email={user.get('email')}
          style={{
            display: 'inline-block',
            width: 24,
            height: 24,
            marginTop: -6,
            marginRight: 8,
            borderRadius: 24,
            verticalAlign: 'middle',
          }}
        />
      </span>
      <span>{getUserFirstName(user)}</span>
    </span>
  </NavLink>
)

export default withRouter(
  connect(state => ({
    user: state.getIn(['user', 'user']),
  }))(UserNavigationItem)
)
