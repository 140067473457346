import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sortBy, values } from 'lodash'
import cx from 'classnames'
import Select from 'react-select'

import { LocaleNames, Locales } from 'src/constants.js'
import { setLocale } from 'src/actions/l10n.actions.js'

import './LocalePicker.scss'

const LocaleFlag = ({ locale, ...props }) => (
  <img src={`/img/flag-${locale}.png`} alt={locale} {...props} />
)

/**
 * Locale Picker
 */
class LocalePicker extends Component {
  static propTypes = {
    locales: PropTypes.arrayOf(PropTypes.string),
    activeLocale: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  static defaultProps = {
    locales: values(Locales),
  }

  render() {
    const { locales, activeLocale, onSelect } = this.props

    const sortedLocales = sortBy(locales, locale => LocaleNames[locale])

    return (
      <Select
        className="LocalePicker"
        value={activeLocale}
        options={sortedLocales.map(locale => ({
          value: locale,
          label: (
            <div>
              <LocaleFlag locale={locale} className="LocalePicker-localeFlag" />
              <span
                className={cx('LocalePicker-optionText', {
                  isSelected: activeLocale === locale,
                })}
              >
                {LocaleNames[locale]}
              </span>
            </div>
          ),
        }))}
        valueRenderer={({ value }) => (
          <LocaleFlag locale={value} className="LocalePicker-localeFlag" />
        )}
        onChange={({ value }) => onSelect(value)}
        searchable={false}
        clearable={false}
        multi={false}
      />
    )
  }
}

export default connect(
  state => ({
    activeLocale: state.getIn(['l10n', 'locale']),
  }),
  dispatch => ({
    onSelect: locale => dispatch(setLocale(locale)),
  })
)(LocalePicker)
