import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Transition from 'react-motion-ui-pack'

/**
 * Onboarding Step Tooltip Text Set
 *
 *  Comma-separated list of prop names: children
 */
export class OnboardingStepTooltipTextSet extends Component {
  static propTypes = {
    texts: PropTypes.arrayOf(
      PropTypes.shape({
        isActive: PropTypes.bool.isRequired,
        text: PropTypes.any.isRequired,
      })
    ).isRequired,
  }

  render() {
    const { texts } = this.props

    return (
      <div className="OnboardingStepTooltipTextSet">
        <div style={{ visibility: 'hidden', pointerEvents: 'none' }}>
          {texts.reduce(
            (longestText, text) => (text.text.length > longestText ? text.text : longestText),
            ''
          )}
        </div>

        <Transition component="div" enter={{ opacity: 1 }} leave={{ opacity: 0 }}>
          {texts.filter(text => text.isActive === true).map(text => (
            <span key={text.text} style={{ position: 'absolute', top: 20 }}>
              {text.text}
            </span>
          ))}
        </Transition>
      </div>
    )
  }
}

export default OnboardingStepTooltipTextSet
