import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'

import { setEnabled } from 'src/actions/vha.actions.js'
import Checkbox from 'src/components/forms/Checkbox.js'

/**
 * VHA On/Off Button
 */
class VHASwitch extends PureComponent {
  static propTypes = {
    isEnabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { isEnabled, onChange } = this.props

    return (
      <div className="VHASwitch">
        <Checkbox isChecked={isEnabled} onChange={onChange}>
          {isEnabled ? <T>VHA On</T> : <T>VHA Off</T>}
        </Checkbox>
      </div>
    )
  }
}

export default connect(
  state => ({
    isEnabled: state.getIn(['vha', 'isEnabled']),
  }),
  dispatch => ({
    onChange: isEnabled => dispatch(setEnabled(isEnabled)),
  })
)(VHASwitch)
