import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { T } from 'lioness'

import { VolumeModifications } from 'src/constants.js'
import Button, { Loudness } from 'src/components/Button.js'
import ButtonGroup, { Directions } from './ButtonGroup.js'
import TrackVolumeKeyControlsIndicators from 'src/components/TrackVolumeKeyControlsIndicators.js'

import './TrackControlsPlaceholder.scss'

const showKeyboardVolumeControls = false

/**
 * Track Controls Placeholder
 *
 * Uses mockup and the blackest SCSS sorcery to spawn a <TrackControls />
 * placeholder from the dark lower world.
 */
class TrackControlsPlaceholder extends Component {
  static propTypes = {
    trackNumber: PropTypes.number.isRequired,
    label: PropTypes.node,
    volume: PropTypes.number,
    showFftGraphs: PropTypes.bool,
    isSoloMuteEnabled: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    label: '',
    volume: 0.7,
    showFftGraphs: true,
    isSoloMuteEnabled: true,
    className: '',
  }

  render() {
    const { trackNumber, label, volume, showFftGraphs, isSoloMuteEnabled, className } = this.props

    return (
      <div className={cx('TrackControlsPlaceholder', 'is-muted', className)}>
        <div className="TrackControlsPlaceholder-label">{label}</div>

        {showFftGraphs && <div className="TrackControlsPlaceholder-fftGraph" />}

        {showKeyboardVolumeControls === true && (
          <TrackVolumeKeyControlsIndicators
            trackNumber={trackNumber}
            modifications={[VolumeModifications.BIG_INCREASE, VolumeModifications.SMALL_INCREASE]}
            isEnabled={false}
          />
        )}

        <div className="TrackControlsPlaceholder-volumeSlider">
          <div
            className="TrackControlsPlaceholder-volumeSlider-bar"
            style={{ height: `${volume * 100}%` }}
          />
        </div>

        {showKeyboardVolumeControls === true && (
          <TrackVolumeKeyControlsIndicators
            trackNumber={trackNumber}
            modifications={[VolumeModifications.SMALL_DECREASE, VolumeModifications.BIG_DECREASE]}
            isEnabled={false}
          />
        )}

        {isSoloMuteEnabled && (
          <ButtonGroup className="TrackControlsPlaceholder-toggles" direction={Directions.VERTICAL}>
            <Button
              disabled
              loudness={Loudness.WHISPER}
              className="TrackControlsPlaceholder-soloButton"
            >
              <T>Isolate</T>
            </Button>
            <Button
              disabled
              loudness={Loudness.WHISPER}
              className="TrackControlsPlaceholder-muteButton"
            >
              <T>Mute</T>
            </Button>
          </ButtonGroup>
        )}
      </div>
    )
  }
}

export default TrackControlsPlaceholder
