import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslators } from 'lioness'
import Select from 'react-select'
import { autobind } from 'core-decorators'

import { SortingDirections } from 'src/constants.js'
import { setSongsSorting } from 'src/actions/browsing.actions.js'

/**
 * Songs Sorting Picker
 */
class SongsSortingPicker extends PureComponent {
  static propTypes = {
    field: PropTypes.string,
    direction: PropTypes.oneOf([SortingDirections.ASC, SortingDirections.DESC]),
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  @autobind
  handleChange({ value }) {
    const { onChange } = this.props

    const [field, direction] = value.split('-')
    onChange(field, direction)
  }

  render() {
    const { field, direction, t } = this.props

    return (
      <Select
        value={`${field}-${direction}`}
        options={[
          { value: `title-${SortingDirections.ASC}`, label: t('Title (ascending)') },
          { value: `title-${SortingDirections.DESC}`, label: t('Title (descending)') },
          { value: `artist-${SortingDirections.ASC}`, label: t('Artist (ascending)') },
          { value: `artist-${SortingDirections.DESC}`, label: t('Artist (descending)') },
          { value: `genre-${SortingDirections.ASC}`, label: t('Genre (ascending)') },
          { value: `genre-${SortingDirections.DESC}`, label: t('Genre (descending)') },
        ]}
        onChange={this.handleChange}
        searchable={false}
        clearable={false}
      />
    )
  }
}

export default connect(
  state => ({
    field: state.getIn(['browsing', 'songsSorting', 'field']),
    direction: state.getIn(['browsing', 'songsSorting', 'direction']),
  }),
  dispatch => ({
    onChange: (field, direction) => dispatch(setSongsSorting(field, direction)),
  })
)(withTranslators(SongsSortingPicker))
