import audiogramSagas from 'src/sagas/audiogram.sagas.js'
import effectSagas from 'src/sagas/effects.sagas.js'
import analyserSagas from 'src/sagas/analyser.sagas.js'
import authSagas from 'src/sagas/auth.sagas.js'
import userSagas from 'src/sagas/user.sagas.js'
import bootSagas from 'src/sagas/boot.sagas.js'
import createPlayerSagas from 'src/sagas/player.sagas.js'
import * as playerApi from 'src/audio/dashPlayer.js'
import vhaSagas from 'src/sagas/vha.sagas.js'
import onboarderSagas from 'src/sagas/onboarder.sagas.js'
import mixerSagas from 'src/sagas/mixer.sagas.js'
import barkerSagas from 'src/sagas/barker.sagas.js'
import routingSagas from 'src/sagas/routing.sagas.js'
import feedbackSagas from 'src/sagas/feedback.sagas.js'
import a11ySagas from 'src/sagas/a11y.sagas.js'
import consultantSagas from 'src/sagas/consultant.sagas.js'
import userVhaSagas from 'src/sagas/user-vha.sagas.js'
import userMixSagas from 'src/sagas/user-mix.sagas.js'

const playerSagas = createPlayerSagas(playerApi)

export default function* rootSaga() {
  yield [
    audiogramSagas(),
    playerSagas(),
    effectSagas(),
    analyserSagas(),
    authSagas(),
    userSagas(),
    bootSagas(),
    vhaSagas(),
    onboarderSagas(),
    mixerSagas(),
    barkerSagas(),
    routingSagas(),
    feedbackSagas(),
    a11ySagas(),
    consultantSagas(),
    userVhaSagas(),
    userMixSagas(),
  ]
}
