import { fromJS } from 'immutable'
import { map, values } from 'lodash'

import {
  ActionTypes,
  // AppEnvironments,
  AudiogramFrequencies,
  EffectTypes,
  EffectUnits,
  EqBands,
  Ears,
  LeftMonoEffectTypes,
  VhaLibraries,
} from 'src/constants.js'
// import env from 'src/environment.js'

const bandFrequencies = {
  [EqBands.LOW]: 250,
  [EqBands.MID]: 800,
  [EqBands.HIGH]: 2500,
}

const DEFAULT_LIBRARY = VhaLibraries.WAAPI
// [AppEnvironments.LOCAL, AppEnvironments.DEVELOPMENT].indexOf(env) >= 0
//   ? VhaLibraries.TOOLKIT
//   : VhaLibraries.WAAPI

// Temporary lists of left and right ear filters
// to fill the initial state with
const leftEarFilters = AudiogramFrequencies.map((freq, i) => ({
  id: 20 + i,
  type: EffectTypes.FILTER,
  name: `Left ${i + 1}`,
  isEnabled: DEFAULT_LIBRARY === VhaLibraries.WAAPI,
  params: {
    frequency: freq,
    Q: 0.7,
    gain: 0,
    filterType: 'peaking',
  },
}))

const rightEarFilters = leftEarFilters.map((filter, i) => ({
  ...filter,
  id: filter.id + leftEarFilters.length,
  name: `Right ${i + 1}`,
}))

// Hearing aid
const hearingAid = {
  id: 666,
  type: EffectTypes.HEARING_AID,
  name: 'Hearing aid',
  isEnabled: DEFAULT_LIBRARY === VhaLibraries.TOOLKIT,
  params: {
    leftEarAudiogram: [0, 0, 0, 0, 0, 0, 0],
    rightEarAudiogram: [0, 0, 0, 0, 0, 0, 0],
  },
}

// Tone control filters
const leftEarToneControls = map(bandFrequencies, (freq, band) => ({
  id: 40 + values(bandFrequencies).indexOf(freq),
  type: EffectTypes.FILTER,
  band: band,
  name: `Tone Control - Left - ${freq}`,
  isEnabled: true,
  params: {
    frequency: freq,
    Q: 0.7,
    gain: 0,
    filterType: 'peaking',
    bypass: 0,
  },
}))

const rightEarToneControls = leftEarToneControls.map(filter => ({
  ...filter,
  id: filter.id + leftEarToneControls.length,
  name: `Tone Control - Right - ${filter.params.frequency}`,
}))

// Per-ear compressors
const leftCompressor = {
  id: 80,
  type: EffectTypes.COMPRESSOR,
  name: 'Compressor - Left',
  isEnabled: true,
  ear: Ears.LEFT,
  params: {
    threshold: 0,
    attack: 10,
    release: 50,
    ratio: 1,
    knee: 5,
    automakeup: true,
    bypass: 0,
  },
}

const rightCompressor = {
  ...leftCompressor,
  id: leftCompressor.id + 1,
  name: 'Compressor - Right',
  ear: Ears.RIGHT,
}

// Per-ear levels
const leftGain = {
  id: 100,
  type: EffectTypes.GAIN,
  name: 'Gain - Left',
  isEnabled: true,
  ear: Ears.LEFT,
  params: {
    gain: 1,
  },
}

const rightGain = {
  ...leftGain,
  id: leftGain.id + 1,
  name: 'Gain - Right',
  ear: Ears.RIGHT,
}

/**
 * Compose effects state
 */
const initialState = fromJS({
  isEnabled: true,
  isActive: false,
  isMounted: false,
  vhaLibrary: DEFAULT_LIBRARY,
  binauralLibrary: DEFAULT_LIBRARY,
  effects: [
    ...leftEarFilters,
    ...rightEarFilters,
    hearingAid,
    {
      id: 8288,
      type: EffectTypes.SPLITTER,
      name: 'VHA Filter Splitter',
      isEnabled: true,
      params: {
        effects: [leftEarFilters.map(x => x.id), rightEarFilters.map(x => x.id)],
      },
    },
    ...leftEarToneControls,
    ...rightEarToneControls,
    {
      id: 2731,
      type: EffectTypes.SPLITTER,
      name: 'Tone Control Splitter',
      isEnabled: true,
      params: {
        effects: [leftEarToneControls.map(x => x.id), rightEarToneControls.map(x => x.id)],
      },
    },
    leftCompressor,
    rightCompressor,
    {
      id: 3662,
      type: EffectTypes.SPLITTER,
      name: 'Compressor Splitter',
      isEnabled: true,
      params: {
        effects: [[leftCompressor.id], [rightCompressor.id]],
      },
    },
    leftGain,
    rightGain,
    {
      id: 4567,
      type: EffectTypes.SPLITTER,
      name: 'Gain Splitter',
      isEnabled: true,
      params: {
        effects: [[leftGain.id], [rightGain.id]],
      },
    },
    {
      id: 5432,
      type: EffectTypes.MONO,
      name: 'Mono',
      isEnabled: true,
      params: {
        type: LeftMonoEffectTypes.TO_CENTER,
      },
    },
  ],
  units: [
    {
      id: 'left-ear-eq',
      name: 'Left Ear EQ',
      unit: EffectUnits.LEFT_EAR_EQ,
      effects: leftEarFilters.map(x => x.id),
      meta: {
        ear: Ears.LEFT,
      },
    },
    {
      id: 'right-ear-eq',
      name: 'Right Ear EQ',
      unit: EffectUnits.RIGHT_EAR_EQ,
      effects: rightEarFilters.map(x => x.id),
      meta: {
        ear: Ears.RIGHT,
      },
    },
    {
      id: 'left-ear-tone-control',
      name: 'Left Ear Tone Control',
      unit: EffectUnits.LEFT_EAR_TONE_CONTROL,
      effects: leftEarToneControls.map(x => x.id),
    },
    {
      id: 'right-ear-tone-control',
      name: 'Right Ear Tone Control',
      unit: EffectUnits.RIGHT_EAR_TONE_CONTROL,
      effects: rightEarToneControls.map(x => x.id),
    },
  ],
  chain: [8288, 666, 2731, 3662, 4567, 5432],
})

/**
 * Effects reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EFFECTS_MOUNTED:
      return state.set('isMounted', true)
    case ActionTypes.SET_EFFECTS_ENABLED:
      return state.set('isEnabled', action.payload)
    case ActionTypes.SET_EFFECTS_ACTIVE:
      return state.set('isActive', action.payload)
    case ActionTypes.SET_VHA_LIBRARY:
      return state.set('vhaLibrary', action.payload)
    case ActionTypes.SET_BINAURAL_LIBRARY:
      return state.set('binauralLibrary', action.payload)
    case ActionTypes.SET_EFFECT_ENABLED:
      return state.update('effects', effects =>
        effects.map(effect => {
          if (effect.get('id') !== action.payload.id) {
            return effect
          }

          return effect.set('isEnabled', action.payload.isEnabled)
        })
      )
    case ActionTypes.SET_EFFECT_PARAM:
      return state.update('effects', effects => {
        const { id, param, value } = action.payload

        return effects.map(effect => {
          if (effect.get('id') !== id) {
            return effect
          }

          return effect.setIn(['params', param], value)
        })
      })
    default:
      return state
  }
}
