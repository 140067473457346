import { fromJS } from 'immutable'

import {
  ActionTypes,
  FilterGroups,
  Genres,
  ListingStyles,
  SongTags,
  SortingDirections,
} from 'src/constants.js'

const initialState = fromJS({
  listingStyle: ListingStyles.GRID,
  songsSorting: {
    field: 'title',
    direction: SortingDirections.ASC,
  },
  filters: {
    [FilterGroups.LISTENING_LEVEL]: {
      [SongTags.EASY_LISTENING]: false,
      [SongTags.MEDIUM_LISTENING]: false,
      [SongTags.NON_EASY_LISTENING]: false,
    },
    [FilterGroups.VOCALS]: {
      [SongTags.INSTRUMENTAL]: false,
      [SongTags.VOCALIZED]: false,
    },
    [FilterGroups.GENRE]: {
      [Genres.BALLAD]: false,
      [Genres.BIGBAND]: false,
      [Genres.BLUES]: false,
      [Genres.CLASSICAL]: false,
      [Genres.COUNTRY]: false,
      [Genres.DANCE]: false,
      [Genres.ELECTRONIC]: false,
      [Genres.FOLK]: false,
      [Genres.JAZZ]: false,
      [Genres.POP]: false,
      [Genres.RNB]: false,
      [Genres.RAP]: false,
      [Genres.REGGAE]: false,
      [Genres.ROCK]: false,
      [Genres.SOUL]: false,
    },
  },
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_SONGS_LISTING_STYLE:
      return state.set('listingStyle', payload.listingStyle)
    case ActionTypes.SET_SONGS_FILTER:
      return state.setIn(['filters', payload.group, payload.filter], payload.value)
    case ActionTypes.SET_SONGS_SORTING:
      return state
        .setIn(['songsSorting', 'field'], payload.field)
        .setIn(['songsSorting', 'direction'], payload.direction)
    default:
      return state
  }
}
