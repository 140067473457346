import { call, take, select, put, fork } from 'redux-saga/effects'
import { debounceFor } from 'redux-saga-debounce-effect'
import { toGain } from 'decibels'
import { fromJS } from 'immutable'

import {
  ActionTypes,
  Ears,
  EffectUnits,
  EffectTypes,
  EqBands,
  HearingAidFittings,
} from 'src/constants.js'
import { setAudiogram, setAudiogramFrequencyValue } from 'src/actions/audiogram.actions.js'
import { setEffectParam } from 'src/actions/effects.actions.js'
import {
  resetVha,
  setCompressionAmount,
  setHasVhaUndoActions,
  setLevelAdjustment,
  setToneControlGain,
  setToneControlGains,
  setVha,
  setVhaInitialised,
} from 'src/actions/vha.actions.js'
import { setBypassed } from 'src/audio/effectChain.js'
import { cascade, isCascaded } from 'src/sagas/saga-utils.js'
import {
  getEmptyAudiogram,
  getAudiogramForGrade,
  getAudiogramForCode,
} from 'src/utils/audiogram-presets.js'

let undoActionHistory = []
let isUndoing = false

function* applyEnabled() {
  yield take(ActionTypes.APP_BOOTED)
  const isVhaEnabled = yield select(state => state.getIn(['vha', 'isEnabled']))
  yield call(setBypassed, isVhaEnabled === false)

  while (true) {
    const { payload } = yield take(ActionTypes.SET_VHA_ENABLED)
    yield call(setBypassed, payload === false)
  }
}

function* applyAudiogram(ear, audiogram) {
  yield put(cascade(setAudiogram(ear, fromJS(audiogram))))
}

function* applyHearingAidFittingMode(fittingMode) {
  let audiogramLeft
  let audiogramRight

  if (fittingMode === HearingAidFittings.PRESETS) {
    const gradeLeft = yield select(state =>
      state.getIn(['vha', 'aid', 'values', HearingAidFittings.PRESETS, Ears.LEFT])
    )
    const gradeRight = yield select(state =>
      state.getIn(['vha', 'aid', 'values', HearingAidFittings.PRESETS, Ears.RIGHT])
    )

    audiogramLeft = getAudiogramForGrade(gradeLeft)
    audiogramRight = getAudiogramForGrade(gradeRight)
  } else if (fittingMode === HearingAidFittings.CODES) {
    const codeLeft = yield select(state =>
      state.getIn(['vha', 'aid', 'values', HearingAidFittings.CODES, Ears.LEFT])
    )
    const codeRight = yield select(state =>
      state.getIn(['vha', 'aid', 'values', HearingAidFittings.CODES, Ears.RIGHT])
    )

    audiogramLeft = getAudiogramForCode(codeLeft) || getEmptyAudiogram()
    audiogramRight = getAudiogramForCode(codeRight) || getEmptyAudiogram()
  } else if (fittingMode === HearingAidFittings.AUDIOGRAMS) {
    const audiogramLeftDict = yield select(state =>
      state.getIn(['vha', 'aid', 'values', HearingAidFittings.AUDIOGRAMS, Ears.LEFT])
    )
    const audiogramRightDict = yield select(state =>
      state.getIn(['vha', 'aid', 'values', HearingAidFittings.AUDIOGRAMS, Ears.RIGHT])
    )

    audiogramLeft = audiogramLeftDict.toObject()
    audiogramRight = audiogramRightDict.toObject()
  }

  // Apply left ear
  yield applyAudiogram(Ears.LEFT, audiogramLeft)
  yield applyAudiogram(Ears.RIGHT, audiogramRight)
}

function* applyHearingAidFittingModeOnBoot() {
  yield take(ActionTypes.SET_EFFECTS_MOUNTED)
  const fittingMode = yield select(state => state.getIn(['vha', 'aid', 'fittingMode']))
  yield applyHearingAidFittingMode(fittingMode)
}

function* applyAudiogramOnFittingModeChange() {
  while (true) {
    const { payload: fittingMode } = yield take(ActionTypes.SET_VHA_FITTING_MODE)
    yield applyHearingAidFittingMode(fittingMode)
  }
}

function* applyVhaReset() {
  while (true) {
    yield take(ActionTypes.RESET_VHA)
    const fittingMode = yield select(state => state.getIn(['vha', 'aid', 'fittingMode']))
    yield applyHearingAidFittingMode(fittingMode)
  }
}

function* setAudiogramFromGradeOfLoss() {
  while (true) {
    const { payload: { ear, preset } } = yield take(ActionTypes.SET_VHA_PRESET)
    const audiogram = getAudiogramForGrade(preset)
    yield applyAudiogram(ear, audiogram)
  }
}

function* setAudiogramFromHearingLossCode() {
  while (true) {
    const { payload: { ear, code } } = yield take(ActionTypes.SET_VHA_HEARING_LOSS_CODE)
    const audiogram = getAudiogramForCode(code)
    if (audiogram) {
      yield applyAudiogram(ear, audiogram)
    }
  }
}

function* forwardAudiogramFromInput() {
  while (true) {
    const { payload: { ear, audiogram } } = yield take(ActionTypes.SET_VHA_AUDIOGRAM)
    yield applyAudiogram(ear, audiogram)
  }
}

function* forwardAudiogramFrequencyValues() {
  while (true) {
    const { payload: { ear, frequency, value } } = yield take(
      ActionTypes.SET_VHA_AUDIOGRAM_FREQUENCY_VALUE
    )
    yield put(setAudiogramFrequencyValue(ear, `${frequency}`, value))
  }
}

function* initializeManualVhaControls() {
  yield take(ActionTypes.SET_EFFECTS_MOUNTED)

  // Apply initial manual VHA controls
  const vha = yield select(state => state.getIn(['vha']))
  for (const ear of [Ears.LEFT, Ears.RIGHT]) {
    // Tone controls
    for (const band of [EqBands.LOW, EqBands.MID, EqBands.HIGH]) {
      yield put(setToneControlGain(ear, band, vha.getIn(['toneControl', 'values', ear, band])))
    }

    // Compression
    yield put(setCompressionAmount(ear, vha.getIn(['compression', 'values', ear])))

    // Levels adjustment
    yield put(setLevelAdjustment(ear, vha.getIn(['levels', 'values', ear])))
  }

  yield put(setVhaInitialised())
}

function* setRightEarToneControlGainWhenMirrored() {
  while (true) {
    const { type, payload } = yield take([
      ActionTypes.SET_TONE_CONTROL_MIRRORED,
      ActionTypes.SET_TONE_CONTROL_GAIN,
    ])
    const isMirrored = yield select(state => state.getIn(['vha', 'toneControl', 'isMirrored']))

    if (type === ActionTypes.SET_TONE_CONTROL_MIRRORED && payload === true) {
      const leftValues = yield select(state =>
        state.getIn(['vha', 'toneControl', 'values', Ears.LEFT])
      )
      yield put(cascade(setToneControlGains(Ears.RIGHT, leftValues)))
    } else if (
      type === ActionTypes.SET_TONE_CONTROL_GAIN &&
      isMirrored === true &&
      payload.ear === Ears.LEFT
    ) {
      yield put(cascade(setToneControlGain(Ears.RIGHT, payload.band, payload.gain)))
    }
  }
}

function* setRightEarCompressionAmountWhenMirrored() {
  while (true) {
    const { type, payload } = yield take([
      ActionTypes.SET_COMPRESSION_MIRRORED,
      ActionTypes.SET_COMPRESSION_AMOUNT,
    ])
    const isMirrored = yield select(state => state.getIn(['vha', 'compression', 'isMirrored']))

    if (
      (type === ActionTypes.SET_COMPRESSION_MIRRORED && payload === true) ||
      (type === ActionTypes.SET_COMPRESSION_AMOUNT &&
        isMirrored === true &&
        payload.ear === Ears.LEFT)
    ) {
      const leftValue = yield select(state =>
        state.getIn(['vha', 'compression', 'values', Ears.LEFT])
      )
      yield put(cascade(setCompressionAmount(Ears.RIGHT, leftValue)))
    }
  }
}

function* forwardToneControlEffectParams() {
  while (true) {
    const { payload: { ear, band, gain } } = yield take(ActionTypes.SET_TONE_CONTROL_GAIN)

    const unit =
      ear === Ears.LEFT ? EffectUnits.LEFT_EAR_TONE_CONTROL : EffectUnits.RIGHT_EAR_TONE_CONTROL

    const filterIds = yield select(state => {
      return state
        .getIn(['effects', 'units'])
        .find(x => x.get('unit') === unit)
        .get('effects')
    })
    const filters = yield select(state =>
      state.getIn(['effects', 'effects']).filter(x => filterIds.includes(x.get('id')))
    )
    const filter = filters.find(x => x.get('band') === band)

    yield put(setEffectParam(filter.get('id'), 'gain', gain))
  }
}

function* forwardCompressionEffectParams() {
  while (true) {
    const { payload: { ear, amount } } = yield take(ActionTypes.SET_COMPRESSION_AMOUNT)

    const compressor = yield select(state => {
      return state
        .getIn(['effects', 'effects'])
        .find(x => x.get('type') === EffectTypes.COMPRESSOR && x.get('ear') === ear)
    })

    const threshold = -(amount * 3)
    const ratio = amount

    yield put(setEffectParam(compressor.get('id'), 'threshold', threshold))
    yield put(setEffectParam(compressor.get('id'), 'ratio', ratio))
  }
}

function* forwardLevelsEffectParams() {
  while (true) {
    const { payload: { ear, amount } } = yield take(ActionTypes.SET_LEVEL_ADJUSTMENT)

    const gainEffect = yield select(state => {
      return state
        .getIn(['effects', 'effects'])
        .find(x => x.get('type') === EffectTypes.GAIN && x.get('ear') === ear)
    })

    const gain = toGain(amount)

    yield put(setEffectParam(gainEffect.get('id'), 'gain', gain))
  }
}

function* applyUserVhaAfterFetch() {
  while (true) {
    const { payload: vha } = yield take(ActionTypes.GET_USER_VHA_SUCCESS)

    // The RESET_VHA action trigger re-application of audiograms,
    // so we pass the new VHA to it (and not `setVha(vha)`) so
    // that the new values get set before doing all that magic.
    yield put(resetVha(vha))
  }
}

function* setupVhaUndo() {
  yield fork(trackUndoableVhaActions)

  const initialState = yield select(state => {
    return state
      .get('vha')
      .filter((value, key) => ['isActive', 'hasUndoActions'].includes(key) === false)
  })

  while (true) {
    yield take(ActionTypes.UNDO_VHA_ACTION)

    undoActionHistory = undoActionHistory.slice(0, -1)
    isUndoing = true

    yield put(setVha(initialState))
    for (const action of undoActionHistory) {
      yield put(action)
    }

    isUndoing = false

    if (undoActionHistory.length === 0) {
      yield put(setHasVhaUndoActions(false))
    }
  }
}

function* trackUndoableVhaActions() {
  yield debounceFor(
    [
      ActionTypes.SET_VHA_LISTENING_METHOD,
      ActionTypes.SET_VHA_FITTING_MODE,
      ActionTypes.SET_VHA_PRESET,
      ActionTypes.SET_VHA_HEARING_LOSS_CODE,
      ActionTypes.SET_VHA_AUDIOGRAM,
      ActionTypes.SET_VHA_AUDIOGRAM_FREQUENCY_VALUE,
      ActionTypes.SET_NOISE_REDUCTION_AMOUNT,
      ActionTypes.SET_TONE_CONTROL_MIRRORED,
      ActionTypes.SET_TONE_CONTROL_GAIN,
      ActionTypes.SET_COMPRESSION_MIRRORED,
      ActionTypes.SET_COMPRESSION_AMOUNT,
      ActionTypes.SET_LEVEL_ADJUSTMENT,
    ],
    storeUndoableAction,
    1000
  )
}

function* storeUndoableAction(action) {
  if (isUndoing === false && isCascaded(action) !== true) {
    undoActionHistory = [...undoActionHistory, action]
    yield put(setHasVhaUndoActions(true))
  }
}

export default function* vhaSagas() {
  yield [
    applyEnabled(),
    applyHearingAidFittingModeOnBoot(),
    applyAudiogramOnFittingModeChange(),
    applyVhaReset(),
    setAudiogramFromGradeOfLoss(),
    setAudiogramFromHearingLossCode(),
    forwardAudiogramFromInput(),
    forwardAudiogramFrequencyValues(),
    initializeManualVhaControls(),
    setRightEarToneControlGainWhenMirrored(),
    setRightEarCompressionAmountWhenMirrored(),
    forwardToneControlEffectParams(),
    forwardCompressionEffectParams(),
    forwardLevelsEffectParams(),
    applyUserVhaAfterFetch(),
    setupVhaUndo(),
  ]
}
