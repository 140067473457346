import context from 'src/audio/context.js'
import DetunaEffect from 'src/audio/detuna/DetunaEffect.js'

export default class Mono extends DetunaEffect {
  constructor(params) {
    super()

    this.input = context.createGain()
    this.merger = context.createChannelMerger(2)

    this.input.connect(this.merger, 0, 0)
    this.input.connect(this.merger, 0, 1)

    this.output = this.merger

    this.initialise(params)
  }
}
