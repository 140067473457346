import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { T } from 'lioness'

import { fetchFeedbacks } from 'src/actions/feedback.actions.js'
import PrettyDate from 'src/components/PrettyDate.js'

/**
 * Admin Feedback List
 */
class AdminFeedbackList extends Component {
  static propTypes = {
    feedbacks: ImmutablePropTypes.list.isRequired,
    onMount: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.onMount()
  }

  render() {
    const { feedbacks } = this.props

    return (
      <div className="AdminFeedbackList">
        <p>
          <T
            message="Showing 1 entry"
            messagePlural="Showing {{ count }} entries"
            count={feedbacks.size}
          />
        </p>

        {feedbacks
          .map((feedback, i) => (
            <p key={i}>
              <strong>{feedback.get('email')}</strong> at{' '}
              <PrettyDate date={feedback.get('createdAt')} />
              <br />
              {feedback.get('freeText')}
            </p>
          ))
          .toArray()}
      </div>
    )
  }
}

export default connect(
  state => ({
    feedbacks: state.getIn(['feedback', 'data', 'entities']),
  }),
  dispatch => ({
    onMount: () => dispatch(fetchFeedbacks()),
  })
)(AdminFeedbackList)
