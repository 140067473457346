import { values } from 'lodash'

import { GREEN, TURQUOISE, ROYALBLUE, ORCHIDPURPLE } from 'src/styles/colors.js'

const Colors = {
  GREEN,
  TURQUOISE,
  ROYALBLUE,
  ORCHIDPURPLE,
}

export const getColorForTrackNumber = trackNumber => values(Colors)[trackNumber - 1]
