import React from 'react'
import cx from 'classnames'
import { T } from 'lioness'

import './AboutHorizons2020.scss'

/**
 * About Horizons 2020
 */
const AboutHorizons2020 = ({ className = '', ...props }) => (
  <div className={cx('AboutHorizons2020', className)} {...props}>
    <h2>
      <T>Horizons 2020</T>
    </h2>
    <p>
      <T
        message={`
          Musiclarity is a part of {{ link3dti:3D Tune-In }}, a European Commission funded project (H2020 - contract number 644051) with the aims of creating a high-quality, cross-platform binaural audio library, and a suite of games and applications that train hearing aid users on how to best use their devices.
        `}
        link3dti={<a href="http://3d-tune-in.eu" target="_blank" />}
      />
    </p>
    <p>
      <img src="/img/eu.svg" alt="EU" />
      <a href="http://3d-tune-in.eu" title="3D Tune-In" target="_blank">
        <img src="/img/3dtunein.svg" alt="3D Tune-In" />
      </a>
    </p>
  </div>
)

export default AboutHorizons2020
