import { fromJS } from 'immutable'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  isEnabled: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LYRICS_ENABLED:
      return state.set('isEnabled', action.payload)
    default:
      return state
  }
}
