import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'

import { startTour } from 'src/actions/onboarder.actions.js'

const OnboardingTourStarter = ({ tour, onStart, component, className, children, ...props }) =>
  React.createElement(
    component,
    {
      ...props,
      className: cx('OnboardingTourStarter', className),
      onClick: onStart,
    },
    children
  )

OnboardingTourStarter.propTypes = {
  tour: PropTypes.string.isRequired,
  onStart: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
}

OnboardingTourStarter.defaultProps = {
  component: 'button',
  className: '',
}

export default connect(null, (dispatch, { tour }) => ({
  onStart: () => dispatch(startTour(tour)),
}))(OnboardingTourStarter)
