import React from 'react'

require('./SongList.scss')
import SongLink from './SongLink.js'

const SongList = ({ songs }) => (
  <div className="SongList">{songs.map(x => <SongLink key={x.get('id')} song={x} />)}</div>
)

export default SongList
