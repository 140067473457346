import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clamp, pick, values } from 'lodash'

import { KeyboardKeys, VolumeModifications } from 'src/constants.js'
import { setTrackVolume } from 'src/actions/mixer.actions.js'
import { togglePlayback } from 'src/actions/player.actions.js'
import { canTogglePlayback } from 'src/reducers/player.reducer.js'
import { getVolumeChangeForModification } from 'src/utils/track-controls.js'

const keyToTrackNumberMap = {
  [KeyboardKeys.NUMBER_1]: 1,
  [KeyboardKeys.Q]: 1,
  [KeyboardKeys.A]: 1,
  [KeyboardKeys.Z]: 1,
  [KeyboardKeys.NUMBER_2]: 2,
  [KeyboardKeys.W]: 2,
  [KeyboardKeys.S]: 2,
  [KeyboardKeys.X]: 2,
  [KeyboardKeys.NUMBER_3]: 3,
  [KeyboardKeys.E]: 3,
  [KeyboardKeys.D]: 3,
  [KeyboardKeys.C]: 3,
  [KeyboardKeys.NUMBER_4]: 4,
  [KeyboardKeys.R]: 4,
  [KeyboardKeys.F]: 4,
  [KeyboardKeys.V]: 4,
}

const keyToVolumeChange = {
  [KeyboardKeys.NUMBER_1]: getVolumeChangeForModification(VolumeModifications.BIG_INCREASE),
  [KeyboardKeys.NUMBER_2]: getVolumeChangeForModification(VolumeModifications.BIG_INCREASE),
  [KeyboardKeys.NUMBER_3]: getVolumeChangeForModification(VolumeModifications.BIG_INCREASE),
  [KeyboardKeys.NUMBER_4]: getVolumeChangeForModification(VolumeModifications.BIG_INCREASE),
  [KeyboardKeys.Q]: getVolumeChangeForModification(VolumeModifications.SMALL_INCREASE),
  [KeyboardKeys.W]: getVolumeChangeForModification(VolumeModifications.SMALL_INCREASE),
  [KeyboardKeys.E]: getVolumeChangeForModification(VolumeModifications.SMALL_INCREASE),
  [KeyboardKeys.R]: getVolumeChangeForModification(VolumeModifications.SMALL_INCREASE),
  [KeyboardKeys.A]: getVolumeChangeForModification(VolumeModifications.SMALL_DECREASE),
  [KeyboardKeys.S]: getVolumeChangeForModification(VolumeModifications.SMALL_DECREASE),
  [KeyboardKeys.D]: getVolumeChangeForModification(VolumeModifications.SMALL_DECREASE),
  [KeyboardKeys.F]: getVolumeChangeForModification(VolumeModifications.SMALL_DECREASE),
  [KeyboardKeys.Z]: getVolumeChangeForModification(VolumeModifications.BIG_DECREASE),
  [KeyboardKeys.X]: getVolumeChangeForModification(VolumeModifications.BIG_DECREASE),
  [KeyboardKeys.C]: getVolumeChangeForModification(VolumeModifications.BIG_DECREASE),
  [KeyboardKeys.V]: getVolumeChangeForModification(VolumeModifications.BIG_DECREASE),
}

class KeyboardControls extends Component {
  static propTypes = {
    shortcutMap: PropTypes.arrayOf(
      PropTypes.shape({
        keys: PropTypes.arrayOf(PropTypes.oneOf(values(KeyboardKeys))).isRequired,
        handler: PropTypes.func.isRequired,
      })
    ),
    canTogglePlayback: PropTypes.bool.isRequired,
    onTogglePlayback: PropTypes.func.isRequired,
  }

  static defaultProps = {
    shortcutMap: [],
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown.bind(this))
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(evt) {
    const { shortcutMap } = this.props

    if (document.activeElement && document.activeElement !== document.body) {
      return
    }

    for (const shortcut of shortcutMap) {
      if (shortcut.keys.includes(evt.keyCode)) {
        shortcut.handler(evt.keyCode)
      }
    }

    if (evt.which === KeyboardKeys.SPACE) {
      evt.preventDefault()

      if (this.props.canTogglePlayback) {
        this.props.onTogglePlayback()
      }
    }
  }

  render() {
    return null
  }
}

export default connect(
  state => ({
    canTogglePlayback: canTogglePlayback(state.get('player')),
    tracks: state.getIn(['mixer', 'tracks']),
  }),
  dispatch => ({
    onTogglePlayback: () => dispatch(togglePlayback()),
    onSetTrackVolume: (trackId, volume) => dispatch(setTrackVolume(trackId, volume)),
  }),
  (stateProps, dispatchProps) => ({
    ...pick(stateProps, ['canTogglePlayback']),
    ...dispatchProps,
    shortcutMap: [
      {
        keys: Object.keys(keyToTrackNumberMap).map(x => parseInt(x)), //[KeyboardKeys.NUMBER_1],
        handler: key => {
          const { tracks } = stateProps

          if (tracks === null) {
            return
          }

          const trackNumber = keyToTrackNumberMap[key]
          const track = tracks.toList().get(trackNumber - 1)
          const volumeChange = keyToVolumeChange[key]
          const newVolume = clamp(track.get('volume') + volumeChange, 0, 1)

          dispatchProps.onSetTrackVolume(track.get('id'), newVolume)
        },
      },
    ],
  })
)(KeyboardControls)
