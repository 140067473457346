import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { values, isFinite } from 'lodash'
import isDateString from 'is-datestring'

import {
  AudiogramFrequencies,
  AudiogramFrequencyValues,
  Ears,
  HearingAidFittings,
  HearingLossGrades,
  Locales,
  Positions,
} from 'src/constants.js'

const makeRequirable = propChecker => {
  Object.defineProperty(propChecker, 'isRequired', {
    value: (props, propName, componentName, ...args) => {
      if (props.hasOwnProperty(propName) === false || props[propName] === undefined) {
        return new Error(
          `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${
            props[propName]
          }\`.`
        )
      }

      return propChecker(props, propName, componentName, ...args)
    },
  })

  return propChecker
}

export const bounds = PropTypes.shape({
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
})

export const position = PropTypes.oneOf([
  Positions.TOP,
  Positions.RIGHT,
  Positions.BOTTOM,
  Positions.LEFT,
])

export const alignment = PropTypes.shape({
  x: PropTypes.oneOf(values(Positions)),
  y: PropTypes.oneOf(values(Positions)),
})

export const boxSpacing = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
])

export const track = ImmutablePropTypes.mapContains({
  id: PropTypes.string.isRequired,
  filepath: PropTypes.string.isRequired,
  trackNumber: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  position: ImmutablePropTypes.mapContains({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    z: PropTypes.number.isRequired,
  }),
  isSoloed: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
})

export const song = ImmutablePropTypes.mapContains({
  title: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  featured: PropTypes.number,
  image: PropTypes.string.isRequired,
  tracks: ImmutablePropTypes.mapOf(track, PropTypes.string),
})

export const audiogram = ImmutablePropTypes.mapContains(
  AudiogramFrequencies.reduce(
    (shape, frequency) => ({
      ...shape,
      [frequency]: PropTypes.oneOf(AudiogramFrequencyValues),
    }),
    {}
  )
)

export const hearingLossGrade = PropTypes.oneOf(values(HearingLossGrades))

export const vha = ImmutablePropTypes.mapContains({
  aid: ImmutablePropTypes.mapContains({
    fittingMode: PropTypes.oneOf(values(HearingAidFittings)).isRequired,
    values: ImmutablePropTypes.mapContains({
      [Ears.LEFT]: PropTypes.oneOfType([audiogram, hearingLossGrade]),
      [Ears.RIGHT]: PropTypes.oneOfType([audiogram, hearingLossGrade]),
    }).isRequired,
  }).isRequired,
})

export const date = makeRequirable((props, propName, componentName) => {
  const prop = props[propName]

  const isValid = prop instanceof Date || (isFinite(prop) && prop >= 0) || isDateString(prop)

  if (isValid === false) {
    return new Error(
      `Invalid prop ${propName} sent to ${componentName}. Expected Date instance, timestamp integer or a date string.`
    )
  }

  return null
})

export const trackNumber = PropTypes.oneOf([1, 2, 3, 4])

export const sessionPatient = ImmutablePropTypes.contains({
  _id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
})

export const basicUser = ImmutablePropTypes.contains({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
})

export const patientList = ImmutablePropTypes.listOf(
  ImmutablePropTypes.contains({
    user: basicUser.isRequired,
  })
)

export const locale = PropTypes.oneOf(values(Locales))
