import React from 'react'
import { PlayButton, PauseButton } from 'react-player-controls'

import PlaybackLoadingIndicator from 'src/components/PlaybackLoadingIndicator.js'

const PlaybackButton = ({ isPlayable, isPlaying, isLoading, onTogglePlayback, ...props }) => {
  if (isPlaying === true && isLoading === false) {
    return <PauseButton onClick={() => onTogglePlayback(false)} {...props} />
  } else if (isLoading === true) {
    return <PlaybackLoadingIndicator {...props} />
  } else {
    return <PlayButton isEnabled={isPlayable} onClick={() => onTogglePlayback(true)} {...props} />
  }
}

export default PlaybackButton
