import { takeEvery, delay } from 'redux-saga'
import { take, call, put, select, fork, cancel, cancelled } from 'redux-saga/effects'

import { ActionTypes, ErrorTypes, VhaLibraries } from 'src/constants.js'
import { setMixerTracks } from 'src/actions/mixer.actions.js'
import {
  setCurrentSong,
  loadSongTracks,
  abortPlayingSong,
  beginPlayback,
  setPlaybackState,
  setPlaybackTime,
  playNextSong,
} from 'src/actions/player.actions.js'
import { displayErrorOfType } from 'src/actions/errors.actions.js'
import { getCurrentTime } from 'src/audio/dashPlayer.js'
import { isTrackEnabled } from 'src/utils/track-controls.js'

export default function createPlayerSagas(playerApi) {
  const {
    setupBinauralSpatialiserProxy,
    setTracks,
    play,
    pause,
    isPlaying,
    setVolume,
    setTrackVolume,
    setMuted,
    setMutedTracks,
    setTrackPosition,
    seek,
  } = playerApi

  /**
   * Applies master volume and mute state after the audio effects
   * chain has been mounted
   */
  function* applyInitialMasterVolume() {
    yield take(ActionTypes.SET_EFFECTS_MOUNTED)

    const master = yield select(state => state.getIn(['mixer', 'master']))
    yield call(setVolume, master.get('volume'))
    yield call(setMuted, master.get('isMuted'))
  }

  /**
   * Initialises the 3DTI binaural proxy
   */
  function* initBinauralSpatialiserProxy() {
    yield take(ActionTypes.SET_EFFECTS_MOUNTED)

    const binauralLibrary = yield select(state => state.getIn(['effects', 'binauralLibrary']))
    if (binauralLibrary === VhaLibraries.TOOLKIT) {
      yield call(setupBinauralSpatialiserProxy)
    }
  }

  /**
   * Handles play intents
   */
  function* handlePlay() {
    let loadTask = null

    while (true) {
      const { payload: { songId } } = yield take(ActionTypes.PLAY_SONG)

      // Cancel any ongoing load task
      if (loadTask) {
        yield cancel(loadTask)
        loadTask = null
      }

      // Get the current song. If the ids are the same, we'll just
      // resume playback.
      const currentSongId = yield select(state => state.getIn(['player', 'song']))
      if (songId === currentSongId) {
        yield put(setPlaybackState(true))
      } else {
        const song = yield select(state => state.get('songs').find(x => x.get('id') === songId))

        yield put(setCurrentSong(songId))
        yield put(setMixerTracks(song.get('tracks')))
        loadTask = yield fork(loadTracksOnPlay)
      }
    }
  }

  /**
   * Loads and plays tracks when a song is being played.
   *
   * TODO: Cancel currently loading tracks if a new song is
   * played while loading
   */
  function* loadTracksOnPlay() {
    yield put(loadSongTracks())

    // TODO: Use an imported selector so that we decouple saga logic from
    // the shape of the state
    const tracks = yield select(state => state.getIn(['mixer', 'tracks']))

    try {
      yield call(setTracks, tracks, {})
    } catch (error) {
      yield put(displayErrorOfType(ErrorTypes.FAILED_TO_LOAD_TRACKS, error))
      yield put(abortPlayingSong())
    } finally {
      if (yield cancelled()) {
        // Do nothing?
      } else {
        yield put(beginPlayback())
        yield call(play)
      }
    }
  }

  function* updatePlaybackState() {
    const isPlayingState = yield select(state => state.getIn(['player', 'isPlaying']))

    if (isPlaying() !== isPlayingState) {
      yield call(isPlayingState ? play : pause)
    }
  }

  function* updateVolume({ payload: volume }) {
    yield call(setVolume, volume)
  }

  function* updateMuted({ payload: isMuted }) {
    yield call(setMuted, isMuted)
  }

  function* updateTrackVolume({ payload: { trackId, volume } }) {
    yield call(setTrackVolume, trackId, volume)
  }

  function* updateMutedTracks() {
    const tracks = yield select(state => state.getIn(['mixer', 'tracks']))
    const mutedTrackIds = tracks
      .filter(track => isTrackEnabled(tracks, track) === false)
      .map(track => track.get('id'))

    yield call(setMutedTracks, mutedTrackIds)
  }

  function* updateTrackPan({ payload: { trackId, pan } }) {
    yield call(setTrackPosition, trackId, { x: pan })
  }

  function* updateTrackDistance({ payload: { trackId, distance } }) {
    yield call(setTrackPosition, trackId, { z: distance })
  }

  function* updateTrackPosition({ payload: { trackId, position } }) {
    yield call(setTrackPosition, trackId, position.toJS())
  }

  function* updateSeek({ payload: time }) {
    yield call(seek, time)
  }

  function* continuouslyUpdateTime() {
    while (true) {
      const isPlayingSong = yield select(state => state.getIn(['player', 'isPlaying']))

      if (isPlayingSong === true) {
        const currentTime = yield call(getCurrentTime)
        yield put(setPlaybackTime(currentTime))

        // Play the next song if we've reached the end
        const song = yield select(state =>
          state.get('songs').find(x => x.get('id') === state.getIn(['player', 'song']))
        )
        if (Math.ceil(currentTime) >= song.get('length')) {
          yield put(playNextSong())
        }
      }

      yield delay(1000)
    }
  }

  return function* playerSagas() {
    yield [
      applyInitialMasterVolume(),
      initBinauralSpatialiserProxy(),
      handlePlay(),
      takeEvery(ActionTypes.TOGGLE_PLAYBACK, updatePlaybackState),
      takeEvery(ActionTypes.SET_PLAYBACK_STATE, updatePlaybackState),
      takeEvery(ActionTypes.SET_MASTER_VOLUME, updateVolume),
      takeEvery(ActionTypes.SET_MASTER_MUTED, updateMuted),
      takeEvery(ActionTypes.SET_TRACK_VOLUME, updateTrackVolume),
      takeEvery([ActionTypes.SET_TRACK_SOLOED, ActionTypes.SET_TRACK_MUTED], updateMutedTracks),
      takeEvery(ActionTypes.SET_TRACK_PAN, updateTrackPan),
      takeEvery(ActionTypes.SET_TRACK_DISTANCE, updateTrackDistance),
      takeEvery(ActionTypes.SET_TRACK_POSITION, updateTrackPosition),
      takeEvery(ActionTypes.SEEK_TO, updateSeek),
      continuouslyUpdateTime(),
    ]
  }
}
