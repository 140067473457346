import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslators } from 'lioness'
import { values } from 'lodash'
import Select from 'react-select'

import { BinauralPresets } from 'src/constants.js'
import { setBinauralPreset } from 'src/actions/mixer.actions.js'
import { getPresetFromPositions } from 'src/utils/track-controls.js'

/**
 * Binaural Preset Picker
 */
class BinauralPresetPicker extends Component {
  static propTypes = {
    songId: PropTypes.number,
    preset: PropTypes.oneOf(values(BinauralPresets)),
    onSelect: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    songId: null,
    preset: null,
  }

  render() {
    const { songId, preset, onSelect, t } = this.props

    return (
      <div className="BinauralPresetPicker">
        <Select
          placeholder={t('Pick a preset...')}
          value={preset || ''}
          disabled={songId === null}
          options={[
            { value: BinauralPresets.SURROUND_A, label: t('Surrounding you A') },
            { value: BinauralPresets.SURROUND_B, label: t('Surrounding you B') },
            { value: BinauralPresets.RADIAL_IN_FRONT, label: t('Spread in front of you') },
            { value: BinauralPresets.EVERYTHING_CENTERED, label: t('Everything centered') },
          ]}
          onChange={newPreset => onSelect(songId, newPreset.value)}
          searchable={false}
          clearable={false}
          style={{
            width: 320,
          }}
        />
      </div>
    )
  }
}

export default connect(
  state => {
    const songId = state.getIn(['player', 'song'])

    let preset = null
    const tracks = state.getIn(['mixer', 'tracks'])

    if (tracks) {
      preset = getPresetFromPositions(tracks.map(track => track.get('position')).toList())
    }

    return { songId, preset }
  },
  dispatch => ({
    onSelect: (songId, preset) => dispatch(setBinauralPreset(songId, preset)),
  })
)(withTranslators(BinauralPresetPicker))
