import { fromJS } from 'immutable'
import { isString } from 'lodash'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  token: null,
  email: null,
  isPending: false,
})

export const isLoggedIn = state => {
  return (
    isString(state.get('token')) &&
    state.get('token').trim().length > 0 &&
    state.get('isPending') === false
  )
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOGIN:
      return state.set('email', payload.email).set('isPending', true)
    case ActionTypes.LOGIN_SUCCESS:
      return state.set('token', payload).set('isPending', false)
    case ActionTypes.LOGIN_ERROR:
      return state.set('isPending', false)
    case ActionTypes.LOGOUT:
      return state.set('token', null).set('isPending', false)
    default:
      return state
  }
}
