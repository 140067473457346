/* global window */
import { compose } from 'redux'
import persistState, { mergePersistedState, transformState } from 'redux-localstorage'
import adapter from 'redux-localstorage/lib/adapters/localStorage'
import debounce from 'redux-localstorage-debounce'
import { fromJS } from 'immutable'
import { flatten } from 'lodash'

import { AppEnvironments } from 'src/constants.js'
import env from 'src/environment.js'
import getObjectPathMatches from 'src/utils/glob-object-paths.js'

const VERSION_KEY = 'STORAGE_VERSION'
const STORAGE_KEY = 'musiclarity_state'
const STORAGE_VERSION = 3

/**
 * Migrates the one-entry-per-slice data from v1 into one single
 * entry.
 */
const migrateToV2 = sliceKeys => {
  return storage => ({
    ...storage,
    get: (key, callback) => {
      const currentVersion = window.localStorage.getItem(VERSION_KEY)

      // v1 -> v2
      if (currentVersion === null) {
        const oldPersistedState = sliceKeys.reduce(
          (aggr, sliceKey) => ({
            ...aggr,
            ...JSON.parse(window.localStorage.getItem(sliceKey)),
          }),
          {}
        )

        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(oldPersistedState))
        window.localStorage.setItem(VERSION_KEY, STORAGE_VERSION)

        for (const sliceKey of sliceKeys) {
          window.localStorage.removeItem(sliceKey)
        }
      }

      storage.get(key, callback)
    },
  })
}

/**
 * Makes redux-localstorage write a slice of the state that is matched
 * using the provided glob paths.
 */
const globFilter = paths => {
  const finalPaths = flatten(paths)

  return storage => ({
    ...storage,
    put: (key, state, callback) => {
      const stateObj = state.toJS()

      const filteredState = finalPaths.reduce((aggr, path) => {
        const matchingStatePaths = getObjectPathMatches(path.replace(/^!/, ''), stateObj)

        return matchingStatePaths.reduce((sliceAggr, matchedPath) => {
          const keyPath = matchedPath.replace(/^!/, '').split('.')

          if (/^!/.test(path) === true) {
            return sliceAggr.deleteIn(keyPath)
          } else if (state.hasIn(keyPath) === true) {
            return sliceAggr.setIn(keyPath, state.getIn(keyPath))
          }

          return sliceAggr
        }, aggr)
      }, fromJS({}))

      storage.put(key, filteredState, callback)
    },
  })
}

/**
 * Immutable serializer
 */
const serialize = subset => subset.toJS()

/**
 * Immutable deserializer
 */
const deserialize = serializedData => fromJS(serializedData)

/**
 * Returns the initial state merged with the persisted state
 */
const merge = (initialState, persistedState) =>
  (initialState || fromJS({})).mergeDeep(persistedState)

/**
 * Returns a reducer function the merges an initial state
 * with a persisted one.
 */
export const rehydrateReducer = reducer => mergePersistedState(merge)(reducer)

/**
 * Returns a configured redux-localstorage store enhancer.
 */
export const createPersistorEnhancer = () => {
  const devSlices = ['effects.binauralLibrary', 'effects.vhaLibrary']

  const slices = [
    ['audiogram'],
    ['visualizer', '!visualizer.isEnabled'],
    ['mixer.master'],
    ['lyrics'],
    ['auth.token'],
    ['auth.email'],
    ['user', '!user.*.isPending', '!user.*.error'],
    ['vha', '!vha.isActive'],
    ['onboarder.tours.*.isCompleted'],
    ['onboarder.tours.*.prerequisiteActions'],
    ['barker.*.isCompleted'],
    ['l10n.locale'],
    ['browsing'],
    ['consultant.session'],
    ...([AppEnvironments.LOCAL, AppEnvironments.DEVELOPMENT].indexOf(env) >= 0 ? devSlices : []),
  ]

  const storage = compose(
    migrateToV2(slices.map(slicePaths => slicePaths[0])),
    debounce(1000),
    globFilter(slices),
    transformState(serialize, deserialize)
  )(adapter(window.localStorage))

  return persistState(storage, STORAGE_KEY)
}
