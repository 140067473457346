import { fromJS } from 'immutable'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  isLoading: false,
  user: null,
  vhaProfileShare: {
    isPending: false,
    lastSent: 0,
    lastRecipientEmail: null,
  },
  passwordReset: {
    isPending: false,
    error: null,
    successAt: null,
  },
})

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    // Me
    case ActionTypes.GET_ME:
      return state.set('isLoading', true)
    case ActionTypes.GET_ME_SUCCESS:
      return state.set('user', fromJS(payload)).set('isLoading', false)
    case ActionTypes.GET_ME_ERROR:
      return state.set('isLoading', false)
    case ActionTypes.RESET_ME:
      return state.set('user', null).set('isLoading', false)

    // Delete account
    case ActionTypes.DELETE_ACCOUNT:
      return state.set('isLoading', true)
    case ActionTypes.DELETE_ACCOUNT_SUCCESS:
      return state.set('user', null).set('isLoading', false)
    case ActionTypes.DELETE_ACCOUNT_ERROR:
      return state.set('isLoading', false)

    // User settings
    case ActionTypes.UPDATE_USER_SETTINGS_SUCCESS:
      return state
        .setIn(['user', 'firstName'], payload.firstName)
        .setIn(['user', 'lastName'], payload.lastName)

    // Password reset
    case ActionTypes.RESET_PASSWORD:
      return state
        .setIn(['passwordReset', 'isPending'], true)
        .setIn(['passwordReset', 'successAt'], null)
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return state
        .setIn(['passwordReset', 'isPending'], false)
        .setIn(['passwordReset', 'error'], null)
        .setIn(['passwordReset', 'lastResetAt'], Date.now())
    case ActionTypes.RESET_PASSWORD_ERROR:
      return state
        .setIn(['passwordReset', 'isPending'], false)
        .setIn(['passwordReset', 'error'], fromJS(error))

    // Sending user VHA profile
    case ActionTypes.SEND_USER_PROFILE:
      return state.setIn(['vhaProfileShare', 'isPending'], true)
    case ActionTypes.SEND_USER_PROFILE_SUCCESS:
      return state.updateIn(['vhaProfileShare'], profile => {
        return profile
          .set('isPending', false)
          .set('lastSent', Date.now())
          .set('lastRecipient', payload.recipientEmail)
      })
    case ActionTypes.SEND_USER_PROFILE_ERROR:
      return state.setIn(['vhaProfileShare', 'isPending'], false)

    // Do nothing
    default:
      return state
  }
}
