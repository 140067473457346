import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { T } from 'lioness'
import { set } from 'lodash/fp'
import cx from 'classnames'

import { ConsultantOccupation } from 'src/constants.js'
import Checkbox from 'src/components/forms/Checkbox.js'

import './ConsultantOccupationField.scss'

/**
 * Consultant Occupation Field
 */
class ConsultantOccupationField extends PureComponent {
  static propTypes = {
    value: PropTypes.shape({
      [ConsultantOccupation.AUDIOLOGIST]: PropTypes.bool,
      [ConsultantOccupation.HEARING_THERAPIST]: PropTypes.bool,
      [ConsultantOccupation.OTHER]: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    ref: PropTypes.func.isRequired,
  }

  static defaultProps = {
    value: {
      [ConsultantOccupation.AUDIOLOGIST]: false,
      [ConsultantOccupation.HEARING_THERAPIST]: false,
      [ConsultantOccupation.OTHER]: '',
    },
  }

  state = {
    isOtherChecked: false,
  }

  handleChange(key, newValue) {
    const { value, onChange } = this.props
    const updatedValue = set(key, newValue, value)

    const evt = {
      target: {
        value: updatedValue,
      },
    }
    onChange(evt)
  }

  render() {
    const { value, className, ref } = this.props
    const { isOtherChecked } = this.state

    return (
      <div className={cx('ConsultantOccupationField', className)} ref={ref}>
        <Checkbox
          isChecked={value[ConsultantOccupation.AUDIOLOGIST] === true}
          onChange={newValue => this.handleChange(ConsultantOccupation.AUDIOLOGIST, newValue)}
        >
          <T>Audiologist</T>
        </Checkbox>

        <Checkbox
          isChecked={value[ConsultantOccupation.HEARING_THERAPIST] === true}
          onChange={newValue => this.handleChange(ConsultantOccupation.HEARING_THERAPIST, newValue)}
        >
          <T>Hearing therapist</T>
        </Checkbox>

        <Checkbox
          isChecked={isOtherChecked === true}
          onChange={newValue => this.setState({ isOtherChecked: newValue })}
        >
          <T>Other (please specify)</T>
        </Checkbox>

        {isOtherChecked && (
          <div className="ConsultantOccupationField-customTextInput">
            <input
              type="text"
              placeholder="Describe your occupation"
              value={value[ConsultantOccupation.OTHER]}
              onChange={evt => this.handleChange(ConsultantOccupation.OTHER, evt.target.value)}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ConsultantOccupationField
