import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './AlbumCover.scss'

class AlbumCover extends Component {
  static propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
  }

  static defaultProps = {
    src: '',
    alt: '',
  }

  state = {
    isLoaded: false,
  }

  componentDidMount() {
    this.loadImage()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({ isLoaded: false }, () => this.loadImage())
    }
  }

  componentWillUnmount() {
    this.img.onload = null
  }

  render() {
    const { src, className, alt, ...rest } = this.props
    const { isLoaded } = this.state

    return (
      <img
        className={classNames('AlbumCover', className, { 'is-loaded': isLoaded })}
        src={src}
        alt={alt || ''}
        {...rest}
      />
    )
  }

  loadImage() {
    this.img = new Image()
    this.img.onload = () => this.setState({ isLoaded: true })
    this.img.src = this.props.src
  }
}

export default AlbumCover
