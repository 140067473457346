import { clamp, fill } from 'lodash'

import { normalize } from 'src/utils/math.js'
import { MIN_DECIBEL } from 'src/constants.js'

/**
 * Returns the sum of the values at index `bandIndex` in each row of `rows`
 */
export const sumBands = (ffts, bandIndex) => ffts.reduce((sum, fft) => sum + fft[bandIndex], 0)

/**
 * Normalizes a decibel value
 */
export const getNormalizedDb = db => normalize(0, MIN_DECIBEL, clamp(db, MIN_DECIBEL, 0))

/**
 * Returns a list of FFT arrays, with each FFT array has the previous
 * arrays values added to it.
 *
 * `fftData` is expected to be normalized to [0-1]
 */
export const getAdditiveFftData = (fftData, evenOut = true) => {
  let i = 0

  return fftData.map(trackData => {
    const prevTracks = fftData.slice(0, i)

    const summedMagnitudes = trackData.map((freqMagnitude, f) => {
      const prevFreqMags = sumBands(prevTracks, f)
      let magnitudeSum = freqMagnitude + prevFreqMags

      if (evenOut) {
        magnitudeSum /= fftData.length || fftData.size
      }

      return magnitudeSum
    })

    i++
    return summedMagnitudes
  })
}

/**
 * TODO: Docs plz
 *
 * @param  {[type]} db       [description]
 * @param  {Number} numBands [description]
 * @return {[type]}          [description]
 */
export const getNormalizedHeightForDb = (db, numBands = 1) => Math.pow(10, db / (20 * numBands))

/**
 * Returns an FFT array representing silence
 */
export const getEmptyFftData = (numBands = 8) => fill(Array(numBands), MIN_DECIBEL)
