import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { T } from 'lioness'

import { setLyricsEnabled as setLyricsActive } from 'src/actions/lyrics.actions.js'
import { setVisualizerEnabled as setVisualizerActive } from 'src/actions/visualizer.actions.js'
import { currentSongHasLyrics } from 'src/utils/lyrics-helpers.js'
import Icon from 'src/components/Icon.js'

import './PlayerMenu.scss'

class PlayerMenu extends Component {
  static propTypes = {
    isVisualizerActive: PropTypes.bool.isRequired,
    isVisualizerEnabled: PropTypes.bool.isRequired,
    isLyricsActive: PropTypes.bool.isRequired,
    isLyricsEnabled: PropTypes.bool.isRequired,
    onToggleVisualizer: PropTypes.func.isRequired,
    onToggleLyrics: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const {
      isVisualizerActive,
      isVisualizerEnabled,
      isLyricsActive,
      isLyricsEnabled,
      onToggleVisualizer,
      onToggleLyrics,
      className,
    } = this.props

    return (
      <menu className={cx('PlayerMenu', className)}>
        <ul>
          <li
            className={cx('PlayerMenuItem', {
              isEnabled: isLyricsEnabled,
              isActive: isLyricsActive,
            })}
            onClick={() => onToggleLyrics(!isLyricsActive)}
          >
            <Icon name="subtitles" />
            <span className="PlayerMenuItem-label">
              <T>Lyrics</T>
              <span className="PlayerMenuItem-label-status">
                {isLyricsActive ? <T>On</T> : <T>Off</T>}
              </span>
            </span>
          </li>

          <li
            className={cx('PlayerMenuItem', {
              isEnabled: isVisualizerEnabled,
              isActive: isVisualizerActive,
            })}
            onClick={() => onToggleVisualizer(!isVisualizerActive)}
          >
            <Icon name="fft" />
            <span className="PlayerMenuItem-label">
              <T>Visualizer</T>
              <span className="PlayerMenuItem-label-status">
                {isVisualizerActive ? <T>On</T> : <T>Off</T>}
              </span>
            </span>
          </li>
        </ul>
      </menu>
    )
  }
}

export default connect(
  state => ({
    isVisualizerActive: state.getIn(['visualizer', 'isEnabled']),
    isVisualizerEnabled: !!state.getIn(['player', 'song']),
    isLyricsActive: state.getIn(['lyrics', 'isEnabled']),
    isLyricsEnabled: currentSongHasLyrics(state),
  }),
  dispatch => ({
    onToggleVisualizer: isActive => dispatch(setVisualizerActive(isActive)),
    onToggleLyrics: isActive => dispatch(setLyricsActive(isActive)),
  })
)(PlayerMenu)
