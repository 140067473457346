import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { round, values } from 'lodash'

import { FilterTypes, Positions } from 'src/constants.js'
import SliderControl from 'src/components/SliderControl.js'
// import ThrottledSliderControl from 'src/components/ThrottledSliderControl.js'

import './FxFilter.scss'

const FILTER_TYPES = values(FilterTypes)

class FxFilter extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf([Positions.TOP, Positions.BOTTOM]),
    isEnabled: PropTypes.bool.isRequired,
    frequency: PropTypes.number.isRequired,
    type: PropTypes.oneOf(FILTER_TYPES).isRequired,
    gain: PropTypes.number.isRequired,
    maxGain: PropTypes.number,
    minGain: PropTypes.number,
    Q: PropTypes.number.isRequired,
    onToggle: PropTypes.func.isRequired,
    onGainChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    label: null,
    labelPosition: Positions.BOTTOM,
    maxGain: 40,
    minGain: -40,
  }

  formatGain(gain) {
    const rounded = round(gain, 1)
    return rounded >= 0 ? `+${rounded}` : rounded
  }

  formatFrequency(frequency) {
    return frequency >= 1000 ? `${frequency / 1000}  kHz` : `${frequency} Hz`
  }

  render() {
    const {
      label,
      labelPosition,
      isEnabled,
      frequency,
      type,
      gain,
      maxGain,
      minGain,
      onToggle,
      onGainChange,
    } = this.props

    const labelNode = label ? <div className="FxFilter-label">{label}</div> : null

    return (
      <div className={classNames('FxFilter', { isEnabled })}>
        {labelPosition === Positions.TOP && labelNode ? labelNode : null}

        <div className="FxFilter-type">{type}</div>

        <input type="checkbox" readOnly checked={isEnabled} onClick={() => onToggle(!isEnabled)} />

        <SliderControl
          className="FxFilter-slider"
          isEnabled={isEnabled}
          initialValue={gain}
          upperThreshold={maxGain}
          lowerThreshold={minGain}
          valueFormatter={this.formatGain}
          onValueChange={onGainChange}
        />

        <div className="FxFilter-frequency">{this.formatFrequency(frequency)}</div>

        {labelPosition === Positions.BOTTOM && labelNode ? labelNode : null}
      </div>
    )
  }
}

export default FxFilter
