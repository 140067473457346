import { forEach } from 'lodash'

export default class DetunaEffect {
  isEnabled = true
  input = null
  output = null

  initialise(params) {
    forEach(params, (value, key) => {
      this[key] = value
    })
  }

  connect(destination, ...xlets) {
    this.output.connect(destination, ...xlets)
  }

  disconnect(destination, ...xlets) {
    this.output.disconnect(destination, ...xlets)
  }
}
