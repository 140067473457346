import React from 'react'

import { resovleBoxSizing } from 'src/utils/box-sizing.js'

import './OnboardingStepRect.scss'

export default ({ bounds, padding = 0, borderRadius = 0 }) => {
  if (!bounds) {
    return null
  }

  const paddings = resovleBoxSizing(padding)

  return (
    <div
      className="OnboardingStepRect"
      style={{
        top: bounds.top - paddings.top,
        left: bounds.left - paddings.left,
        width: bounds.width + paddings.left + paddings.right,
        height: bounds.height + paddings.top + paddings.bottom,
        borderRadius: borderRadius,
      }}
    />
  )
}
