import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { values } from 'lodash'
import { autobind } from 'core-decorators'

import { KeyboardKeys } from 'src/constants.js'

/**
 * Key Press Indicator
 */
class KeyPressIndicator extends Component {
  static propTypes = {
    keyCode: PropTypes.oneOf(values(KeyboardKeys)).isRequired,
    isEnabled: PropTypes.bool,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
  }

  static defaultProps = {
    isEnabled: true,
    className: '',
    activeClassName: '',
  }

  state = {
    isPressed: false,
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  @autobind
  handleKeyDown(evt) {
    const { keyCode, isEnabled } = this.props

    if (document.activeElement && document.activeElement !== document.body) {
      return
    }

    if (isEnabled === true && evt.keyCode === keyCode) {
      this.setState(() => ({ isPressed: true }))
    }
  }

  @autobind
  handleKeyUp(evt) {
    const { keyCode, isEnabled } = this.props

    if (document.activeElement && document.activeElement !== document.body) {
      return
    }

    if (isEnabled === true && evt.keyCode === keyCode) {
      this.setState(() => ({ isPressed: false }))
    }
  }

  render() {
    const { keyCode, className, activeClassName } = this.props
    const { isPressed } = this.state

    return (
      <span className={cx('KeyPressIndicator', className, { [activeClassName]: isPressed })}>
        {String.fromCharCode(keyCode)}
      </span>
    )
  }
}

export default KeyPressIndicator
