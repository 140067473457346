import { Map } from 'immutable'
import { isNumber } from 'lodash'

import { ActionTypes, MediaBufferingStates } from 'src/constants.js'

const initialState = Map({
  song: null,
  isLoading: false,
  isPlaying: false,
  isMuted: false,
  currentTime: 0,
})

export const canTogglePlayback = state => {
  return isNumber(state.get('song')) && state.get('isLoading') === false
}

export const hasLoadedSong = state => {
  return state.get('song') !== null && state.get('isLoading') === false
}

const playerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_CURRENT_SONG:
      return state.set('song', payload)
    case ActionTypes.BEGIN_PLAYBACK:
      return state.set('isPlaying', true)
    case ActionTypes.SET_PLAYER_BUFFERING_STATE:
      return state.set('isLoading', payload.bufferingState === MediaBufferingStates.BUFFERING)
    case ActionTypes.ABORT_PLAYING_SONG:
      return state
        .set('isLoading', false)
        .set('isPlaying', false)
        .set('song', null)
    case ActionTypes.SET_PLAYBACK_STATE:
      return state.set('isPlaying', payload)
    case ActionTypes.TOGGLE_PLAYBACK:
      return state.update('isPlaying', isPlaying => !isPlaying)
    case ActionTypes.SET_MUTE:
      return state.set('isMuted', payload)
    case ActionTypes.SET_PLAYBACK_TIME:
      return state.set('currentTime', payload)
    default:
      return state
  }
}

export default playerReducer
