import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'

import { undoVhaAction } from 'src/actions/vha.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'

/**
 * VHA Undo Button
 */
class VHAUndoButton extends Component {
  static propTypes = {
    onUndo: PropTypes.func.isRequired,
  }

  render() {
    const { onUndo, ...props } = this.props

    return (
      <Button loudness={Loudness.TALK} size={Size.NORMAL} onClick={onUndo} {...props}>
        <T>Undo last action</T>
      </Button>
    )
  }
}

export default connect(
  state => ({
    isEnabled: state.getIn(['vha', 'hasUndoActions']) === true,
  }),
  dispatch => ({
    onUndo: () => dispatch(undoVhaAction()),
  })
)(VHAUndoButton)
