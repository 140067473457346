import { Positions } from 'src/constants.js'

export const resovleBoxSizing = sizing => {
  const sizingObj =
    typeof sizing === 'number'
      ? { top: sizing, right: sizing, bottom: sizing, left: sizing }
      : sizing

  return {
    top: sizingObj.top || 0,
    right: sizingObj.right || 0,
    bottom: sizingObj.bottom || 0,
    left: sizingObj.left || 0,
  }
}

export const getAbsoluteBounds = boundingRect => {
  return {
    top: boundingRect.top + window.scrollY,
    right: boundingRect.right,
    bottom: boundingRect.bottom + window.scrollY,
    left: boundingRect.left,
    width: boundingRect.width,
    height: boundingRect.height,
  }
}

export const getStylesAligningTo = (bounds, position, alignment, offset) => {
  const offsets = resovleBoxSizing(offset)

  const cssPosition = {
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
  }

  const translate = {
    x: 0,
    y: 0,
  }

  if (position === Positions.LEFT) {
    cssPosition.right = window.innerWidth - bounds.left - (offset.left + offset.right)
  } else if (position === Positions.RIGHT) {
    cssPosition.left = bounds.left + bounds.width + offset.left + offset.right
  } else {
    if (alignment.x === Positions.LEFT) {
      cssPosition.left = bounds.left + offset.left + offset.right
    } else if (alignment.x === Positions.CENTER) {
      cssPosition.left = bounds.left + bounds.width / 2 + offset.left + offset.right
      translate.x = '-50%'
    } else if (alignment.x === Positions.RIGHT) {
      cssPosition.right = window.innerWidth - bounds.right - offset.left + offset.right
    }
  }

  if (position === Positions.TOP) {
    cssPosition.top = bounds.top + offsets.top + offsets.bottom
    translate.y = '-100%'
  } else if (position === Positions.BOTTOM) {
    cssPosition.top = bounds.top + bounds.height + offsets.top + offsets.bottom
  } else {
    if (alignment.y === Positions.TOP) {
      cssPosition.top = bounds.top + offsets.top + offsets.bottom
    } else if (alignment.y === Positions.CENTER) {
      cssPosition.top = bounds.top + bounds.height / 2 + offsets.top + offset.bottom
      translate.y = '-50%'
    } else if (alignment.y === Positions.BOTTOM) {
      cssPosition.top = bounds.top + bounds.height + offsets.top + offset.bottom
      translate.y = '-100%'
    }
  }

  return {
    position: cssPosition,
    translate: translate,
  }
}

export const getStylesRelativeTo = (position, alignment, offset) => {
  const offsets = resovleBoxSizing(offset)

  const cssPosition = {
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
  }

  const translate = {
    x: `${offsets.left + offsets.right}px`,
    y: `${offsets.top + offsets.bottom}px`,
  }

  if (position === Positions.LEFT) {
    cssPosition.right = `100%`
  } else if (position === Positions.RIGHT) {
    cssPosition.left = '100%'
  } else {
    if (alignment.x === Positions.LEFT) {
      cssPosition.left = 0
    } else if (alignment.x === Positions.CENTER) {
      cssPosition.left = '50%'
      translate.x = `calc(-50% + ${offsets.left + offsets.right}px)`
    } else if (alignment.x === Positions.RIGHT) {
      cssPosition.right = 0
    }
  }

  if (position === Positions.TOP) {
    cssPosition.bottom = '100%'
  } else if (position === Positions.BOTTOM) {
    cssPosition.top = '100%'
  } else {
    if (alignment.y === Positions.TOP) {
      cssPosition.top = 0
    } else if (alignment.y === Positions.CENTER) {
      cssPosition.top = '50%'
      translate.y = `calc(-50% ${offsets.top + offsets.bottom})`
    } else if (alignment.y === Positions.BOTTOM) {
      cssPosition.bottom = 0
    }
  }

  return {
    position: cssPosition,
    translate: translate,
  }
}
