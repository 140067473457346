import { fromJS } from 'immutable'

import { ActionTypes, Locales } from 'src/constants.js'

const initialState = fromJS({
  locale: Locales.EN,
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_LOCALE:
      return state.set('locale', payload.locale)
    default:
      return state
  }
}
