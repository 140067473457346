import React, { PureComponent } from 'react'
import { T } from 'lioness'

import AddExistingUser from 'src/components/consultant/AddExistingUser.js'
import CreatePatientView from 'src/components/consultant/CreatePatientView.js'

/**
 * Add New Patient View
 */
class AddNewPatientView extends PureComponent {
  render() {
    return (
      <div className="AddNewPatientView">
        <h3 className="Legend">
          <T>Add an existing user</T>
        </h3>
        <AddExistingUser />

        <h3 className="Legend" style={{ marginTop: 48 }}>
          <T>Create a new patient</T>
        </h3>
        <CreatePatientView />
      </div>
    )
  }
}

export default AddNewPatientView
