import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'

import EulaDocument from 'src/components/EulaDocument.js'
import View from 'src/components/View.js'

import './EulaView.scss'

/**
 * Eula View
 */
class EulaView extends PureComponent {
  render() {
    return (
      <View className="EulaView">
        <Helmet title="End-user license agreement" />

        <div className="EulaView-content">
          <EulaDocument />
        </div>
      </View>
    )
  }
}

export default EulaView
