import React from 'react'
import { connect } from 'react-redux'
import { T } from 'lioness'
import * as FeatherIcon from 'react-feather'

import { logout } from 'src/actions/auth.actions.js'
import Button, { Loudness, Size } from './Button.js'

const LogoutButton = ({ onLogout }) => (
  <Button loudness={Loudness.TALK} size={Size.SMALL} onClick={onLogout}>
    <FeatherIcon.LogOut className="Button-icon" />
    <T>Logout</T>
  </Button>
)

export default connect(null, dispatch => ({
  onLogout: () => dispatch(logout()),
}))(LogoutButton)
