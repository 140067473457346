import { ActionTypes } from 'src/constants.js'

/* Feedback submission */

export const setFeedbackFormOpen = isOpen => ({
  type: ActionTypes.SET_FEEDBACK_FORM_OPEN,
  payload: isOpen,
})

export const submitFeedback = form => ({
  type: ActionTypes.SUBMIT_FEEDBACK,
  payload: form,
})

export const submitFeedbackSuccess = result => ({
  type: ActionTypes.SUBMIT_FEEDBACK_SUCCESS,
  payload: result,
})

export const submitFeedbackError = error => ({
  type: ActionTypes.SUBMIT_FEEDBACK_ERROR,
  payload: error,
})

/* Feedback browsing */

export const fetchFeedbacks = () => ({
  type: ActionTypes.FETCH_FEEDBACKS,
})

export const fetchFeedbacksSuccess = result => ({
  type: ActionTypes.FETCH_FEEDBACKS_SUCCESS,
  payload: result,
})

export const fetchFeedbacksError = error => ({
  type: ActionTypes.FETCH_FEEDBACKS_ERROR,
  payload: error,
})
