import React from 'react'
import PropTypes from 'prop-types'
import { chunk, flatten, clamp } from 'lodash'
import { getCurvePoints } from 'cardinal-spline-js'

import { getColorForTrackNumber } from 'src/utils/colors.js'
import SpectrumLabel from 'src/components/SpectrumLabel.js'

// The default number of points to insert between each data point
// when smoothening graphs
const DEFAULT_LINE_GRAPH_RESOLUTION = 10

/**
 * Smoothes a set of values and returns the smoothened curve
 * as a list of points.
 *
 * @param  {number}  w              The width of the graph
 * @param  {number}  h              The height of the graph
 * @param  {array}   values         An array of values
 * @param  {boolean} setEdgesToZero Whether to set start and end points to y = 0
 * @param  {number}  resolution     The number of extra points between
 *                                  each data point
 * @return {array}                  [description]
 */
const getSmoothedPointsFromValues = (
  w,
  h,
  values,
  setEdgesToZero,
  resolution = DEFAULT_LINE_GRAPH_RESOLUTION
) => {
  const x = idx =>
    setEdgesToZero ? (idx + 0.5) * w / (values.length + 1) : idx / (values.length - 1) * w

  const segments = values.map((val, i) => [x(i), (1 - val) * h])

  if (setEdgesToZero) {
    segments.unshift([0, h])
    segments.push([w, h])
  }

  // `getCurvePoints` blows up if given an empty list of points
  if (segments.length === 0) {
    return []
  }

  // The curve tension
  // @see https://github.com/epistemex/cardinal-spline-js/blob/master/src/curve_calc.js#L24
  const tension = 0.5

  const points = chunk(getCurvePoints(flatten(segments), tension, resolution), 2).map(p => [
    p[0],
    clamp(p[1], 0, h),
  ])

  return points
}

/**
 * Filled, smoothened line graph for display of a tracks
 * frequency amplitudes.
 */
const LineSpectrumGraph = ({
  track,
  values,
  width,
  height,
  resolution,
  mirrorGraph,
  renderSilence,
  setEdgesToZero,
  showDots,
  ...rest
}) => {
  let pathStrings = []
  const silenceVal = renderSilence ? (mirrorGraph ? 0.5 : 1) : 0

  if (mirrorGraph) {
    const centerY = height / 2
    const points = getSmoothedPointsFromValues(width, centerY - silenceVal, values, setEdgesToZero)
    const mirroredPoints = points.map(point => [point[0], 2 * centerY - point[1] + silenceVal])

    pathStrings = [points, mirroredPoints].map(pointList => {
      return `M0,${centerY} ${pointList.map(p => p.join(',')).join(' ')} ${width},${centerY}`
    })
  } else {
    const points = getSmoothedPointsFromValues(
      width,
      height - silenceVal,
      values,
      setEdgesToZero,
      resolution
    )
    const pointsString = points.map(p => p.join(',')).join(' ')
    const pathStr = `M0,${height} ${pointsString} ${width},${height}`
    pathStrings = [pathStr]
  }

  return (
    <div className="LineSpectrumGraph" {...rest}>
      <SpectrumLabel track={track} />

      <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none">
        {pathStrings.map((pathStr, i) => {
          return (
            <path key={i} d={pathStr} fill={getColorForTrackNumber(track.get('trackNumber'))} />
          )
        })}

        {showDots &&
          values.map((val, i) => (
            <circle cx={(i + 0.5) / (values.length + 1) * width} cy={(1 - val) * height} r="5" />
          ))}
      </svg>
    </div>
  )
}

LineSpectrumGraph.propTypes = {
  track: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  resolution: PropTypes.number,
  mirrorGraph: PropTypes.bool,
  renderSilence: PropTypes.bool,
  setEdgesToZero: PropTypes.bool,
  showDots: PropTypes.bool,
}

LineSpectrumGraph.defaultProps = {
  resolution: DEFAULT_LINE_GRAPH_RESOLUTION,
  mirrorGraph: false,
  renderSilence: false,
  setEdgesToZero: false,
  showDots: false,
}

export default LineSpectrumGraph
