/* eslint space-infix-ops: 0 */

import { zipObject } from 'lodash'

import { AudiogramFrequencies, HearingLossGrades } from 'src/constants.js'

/**
 * Maps hearing loss code curve types to relative audiogram values
 */
const CodeCurveTypeScales = {
  A: [0, 0, 0, 0, 0, 1 / 2, 1],
  B: [0, 0, 0, 0, 1 / 2, 1, 1],
  C: [0, 0, 0, 1 / 2, 1, 1, 1],
  D: [0, 0, 1 / 2, 1, 1, 1, 1],
  E: [0, 1 / 2, 1, 1, 1, 1, 1],
  F: [0, 1 / 6, 2 / 6, 3 / 6, 4 / 6, 5 / 6, 1],
  G: [0, 0, 1 / 5, 2 / 5, 3 / 5, 4 / 5, 1],
  H: [0, 0, 0, 1 / 4, 2 / 4, 3 / 4, 1],
  I: [0, 0, 0, 0, 1 / 3, 2 / 3, 1],
}

/**
 * Maps hearing loss code severity levels to a maximum dB HL value
 */
const CodeSeverityValues = {
  '0': 10,
  '1': 21,
  '2': 33,
  '3': 48,
  '4': 63,
  '5': 81,
  '6': 91,
}

/**
 * Returns an empty audiogram
 *
 * @return {Object} An empty audiogram
 */
export const getEmptyAudiogram = () => {
  return zipObject(AudiogramFrequencies.map(x => `${x}`), [0, 0, 0, 0, 0, 0, 0])
}

/**
 * Returns whether a hearing loss code is correctly formatted
 *
 * @param  {[type]} code [description]
 * @return {[type]}      [description]
 */
export const isValidHearingLossCode = code => {
  return /^[A-I][1-6]$/.test(code)
}

/**
 * Returns audiogram as an array given a hearing loss code
 *
 * @param  {String} code A hearing loss code
 * @return {Object}      An audiogram in the form of an array
 */
export const getAudiogramForCode = code => {
  if (isValidHearingLossCode(code) === false) {
    return null
  }

  const [curveType, severity] = code.split('')
  const audiogramValues = CodeCurveTypeScales[curveType].map(x => x * CodeSeverityValues[severity])

  return zipObject(AudiogramFrequencies.map(x => `${x}`), audiogramValues)
}

/**
 * Maps hearing loss grades to audiograms
 */
const NamedPresetAudiograms = {
  [HearingLossGrades.NONE]: getEmptyAudiogram(),
  [HearingLossGrades.MILD]: getAudiogramForCode('B1'),
  [HearingLossGrades.MODERATE]: getAudiogramForCode('D4'),
  [HearingLossGrades.SEVERE]: getAudiogramForCode('E5'),
  [HearingLossGrades.PROFOUND]: getAudiogramForCode('E6'),
}

/**
 * Returns an audiogram in the form of an array of dB HL values
 *
 * @param  {String} grade A grade of hearing loss (HearingLossGrades enum)
 * @return {Object}       An audiogram
 */
export const getAudiogramForGrade = grade => {
  return NamedPresetAudiograms[grade]
}
