import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Positions } from 'src/constants.js'
import Button, { Loudness } from 'src/components/Button.js'
import { AnimatedTooltip, TooltipContent, TooltipActions } from 'src/components/Tooltip.js'

/**
 * Confirmation Dialog
 */
class ConfirmationDialog extends PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    canConfirm: PropTypes.bool.isRequired,
    confirmLabel: PropTypes.node.isRequired,
    dismissLabel: PropTypes.node.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    onDismiss: () => {},
  }

  render() {
    const {
      isVisible,
      canConfirm,
      confirmLabel,
      dismissLabel,
      onConfirm,
      onDismiss,
      children,
    } = this.props

    return (
      <AnimatedTooltip
        isVisible={isVisible}
        position={Positions.BOTTOM}
        alignment={{ x: Positions.CENTER }}
        offset={{ top: 16 }}
        className="ResetPasswordButton-prompt"
      >
        <TooltipContent>{children}</TooltipContent>

        <TooltipActions>
          <Button loudness={Loudness.YELL} isEnabled={canConfirm} onClick={onConfirm}>
            {confirmLabel}
          </Button>

          <Button loudness={Loudness.WHISPER} isEnabled={canConfirm} onClick={onDismiss}>
            {dismissLabel}
          </Button>
        </TooltipActions>
      </AnimatedTooltip>
    )
  }
}

export default ConfirmationDialog
