import React from 'react'

import SvgIcon from './SvgIcon.js'

export default () => (
  <SvgIcon>
    <g>
      <path d="M14.7309157,7.32812335 L5.20834941,12.0823632 C3.60586219,12.8824215 3.30107151,14.7298119 4.56837086,15.9952365 L14.8510961,26.2627506 C16.0238872,27.4338068 17.9227364,27.4344541 19.0961757,26.2627506 L29.3789009,15.9952365 C30.6457253,14.7302861 30.3437072,12.8835686 28.7389224,12.0823632 L19.2163561,7.32812335 C17.9421034,6.6919395 16.0064628,6.69129325 14.7309157,7.32812335 L14.7309157,7.32812335 Z M27.8455542,13.8717467 C28.229166,14.0632688 28.2648277,14.2813258 27.9657346,14.5799765 L17.6830093,24.8474906 C17.2905944,25.2393251 16.6564609,25.2391089 16.2642625,24.8474906 L5.98153725,14.5799765 C5.68298235,14.2818633 5.71913153,14.0627567 6.10171763,13.8717467 L15.6242839,9.11750682 C16.3371335,8.76160931 17.6109902,8.76203461 18.3229879,9.11750682 L27.8455542,13.8717467 L27.8455542,13.8717467 Z" />
      <path d="M11.7261934,13.5921325 L11.5437568,13.8198409 L11.6522587,14.0906943 L16.4212181,25.9954562 L16.8853616,27.1540983 L17.349505,25.9954562 L22.1184644,14.0906943 L22.2269663,13.8198409 L22.0445297,13.5921325 L17.2755703,7.63975152 L16.8853616,7.15271217 L16.4951528,7.63975152 L11.7261934,13.5921325 L11.7261934,13.5921325 Z M16.4951528,8.26501039 L21.2641123,14.2173913 L21.1901775,13.7188295 L16.4212181,25.6235914 L17.349505,25.6235914 L12.5805456,13.7188295 L12.5066108,14.2173913 L17.2755703,8.26501039 L16.4951528,8.26501039 L16.4951528,8.26501039 Z" />
      <polygon
        id="Line"
        points="17.3927698 16.0897747 17.6015473 16.0897747 28.943503 14.8992985 29.9380396 14.7949098 29.7292622 12.8058366 28.7347256 12.9102253 17.3927698 14.1007015 17.6015473 14.1007015 6.25959153 12.9102253 5.26505496 12.8058366 5.05627754 14.7949098 6.05081411 14.8992985"
      />
    </g>
  </SvgIcon>
)
