import React from 'react'

require('./View.scss')

const View = ({ children, className, ...props }) => (
  <div className={['View', className || ''].join(' ')} {...props}>
    {children}
  </div>
)

export default View
