import { takeEvery } from 'redux-saga'
import { take, select, call, put } from 'redux-saga/effects'

import { ActionTypes, ListeningMethods, VhaLibraries } from 'src/constants.js'
import { setEffectEnabled, setEffectsMounted, setVhaLibrary } from 'src/actions/effects.actions.js'
import { setTrackPosition } from 'src/audio/dashPlayer.js'
import {
  initializeEffects,
  mountEffects,
  setEffectParam,
  batchSetEffectParams,
} from 'src/audio/effectChain.js'

/**
 * Initializes effect chain after the first actual
 * audio playback
 */
export function* initializeEffectsOnBoot() {
  yield take(ActionTypes.APP_BOOTED)

  // Let the effect engine create all the effects it needs
  const effects = yield select(state => state.getIn(['effects', 'effects']))
  yield call(initializeEffects, effects)

  // Now mount them
  const enabledEffects = yield selectEnabledEffects()
  yield call(mountEffects, enabledEffects.map(x => x.get('id')))

  // Enabled the selected VHA library
  const vhaLibrary = yield select(state => state.getIn(['effects', 'vhaLibrary']))
  yield put(setVhaLibrary(vhaLibrary))

  // Apply listening method
  const listeningMethod = yield select(state => state.getIn(['vha', 'listeningMethod']))
  yield applyListeningMethod({ payload: { listeningMethod } })

  // Set state to mounted
  yield put(setEffectsMounted())
}

/**
 * Returns a List of effects from the `chain` array and filters out
 * the enabled ones.
 */
function* selectEnabledEffects() {
  const effectIds = yield select(state => state.getIn(['effects', 'chain']))
  const allEffects = yield select(state => state.getIn(['effects', 'effects']))
  return effectIds
    .map(id => allEffects.find(x => x.get('id') === id))
    .filter(x => x.get('isEnabled') === true)
}

/**
 * Update the effect parameters to reflect the state
 */
export function* updateEffectParam({ payload: { id, param, value } }) {
  yield call(setEffectParam, id, param, value)
}

/**
 * Applies (forwards) a set of effect param changes
 */
export function* batchUpdateEffectParams({ payload }) {
  yield call(batchSetEffectParams, payload)
}

function* reloadPageWhenChangingBinauralLibrary() {
  // Delay this by 1.5s so that the state persistor writes the
  // new setting to localStorage.
  setTimeout(() => window.location.reload(), 1500)
}

/**
 *
 */
function* activateSelectedVhaLibrary({ payload }) {
  const listeningMethod = yield select(state => state.getIn(['vha', 'listeningMethod']))
  const isVhaEnabled = listeningMethod === ListeningMethods.HEADPHONES

  yield put(setEffectEnabled(666, isVhaEnabled === true && payload === VhaLibraries.TOOLKIT))
  yield put(setEffectEnabled(8288, isVhaEnabled === true && payload === VhaLibraries.WAAPI))
}

/**
 * Toggles mono audio when changing listening method
 */
function* applyListeningMethod({ payload: { listeningMethod } }) {
  yield call(setVhaEffectsEnabled, listeningMethod === ListeningMethods.HEADPHONES)

  const isMono = listeningMethod === ListeningMethods.HEARING_LOOP
  yield put(setEffectEnabled(5432, isMono))

  const tracks = yield select(state => state.getIn(['mixer', 'tracks']))
  if (tracks !== null) {
    for (const track of tracks.toArray()) {
      const position = isMono === true ? { x: 0, y: 0, z: 0.01 } : track.get('position').toJS()
      yield call(setTrackPosition, track.get('id'), position)
    }
  }
}

/**
 * Enables or disables the VHA effect(s) that corresponds to
 * the currently selected VHA library.
 */
function* setVhaEffectsEnabled(enableEffects) {
  const vhaLibrary = yield select(state => state.getIn(['effects', 'vhaLibrary']))
  const vhaEffectId = vhaLibrary === VhaLibraries.TOOLKIT ? 666 : 8288

  yield put(setEffectEnabled(vhaEffectId, enableEffects))
}

/**
 * Re-mounts effect chain with enabled effects whenever an effect
 * is set as enabled or disabled.
 */
function* forwardEffectEnabled() {
  // If the VHA is bypassed, wait until it is activated again
  let isVhaEnabled = yield select(state => state.getIn(['vha', 'isEnabled']))
  while (isVhaEnabled === false) {
    const { payload } = yield take(ActionTypes.SET_VHA_ENABLED)
    isVhaEnabled = payload
  }

  const enabledEffects = yield selectEnabledEffects()
  yield call(mountEffects, enabledEffects.map(x => x.get('id')))
}

/**
 * Main effect saga
 */
export default function* effectSaga() {
  yield [
    initializeEffectsOnBoot(),
    takeEvery(ActionTypes.SET_EFFECT_PARAM, updateEffectParam),
    takeEvery(ActionTypes.BATCH_SET_EFFECT_PARAM, batchUpdateEffectParams),
    takeEvery(ActionTypes.SET_BINAURAL_LIBRARY, reloadPageWhenChangingBinauralLibrary),
    takeEvery(ActionTypes.SET_VHA_LIBRARY, activateSelectedVhaLibrary),
    takeEvery(ActionTypes.SET_VHA_LISTENING_METHOD, applyListeningMethod),
    takeEvery(ActionTypes.SET_EFFECT_ENABLED, forwardEffectEnabled),
  ]
}
