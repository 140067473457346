import React from 'react'

import SongMixer from './SongMixer.js'
import './SongMiniMixer.scss'

/**
 * SongMiniMixer is a trimmed SongMixer
 */
const SongMiniMixer = props => (
  <SongMixer {...props} isSoloMuteEnabled={true} showFftGraphs={false} className="SongMiniMixer" />
)

export default SongMiniMixer
