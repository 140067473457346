import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { autobind } from 'core-decorators'

import './FaqEntry.scss'

export default class FaqEntry extends Component {
  static propTypes = {
    question: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  }

  state = {
    isOpen: true,
  }

  @autobind
  toggleOpened() {
    this.setState(() => ({ isOpen: !this.state.isOpen }))
  }

  render() {
    const { question, children } = this.props
    const { isOpen } = this.state

    return (
      <div className="FaqEntry">
        <h3 className="FaqEntry-question" onClick={this.toggleOpened}>
          {question}
        </h3>

        {isOpen && <div className="FaqEntry-answer">{children}</div>}
      </div>
    )
  }
}
