import { curry, inRange, isFinite, reduce } from 'lodash'

const { round, pow } = Math

export const roundDecimals = curry(
  (numDecimals, val) => round(val * pow(10, numDecimals)) / pow(10, numDecimals)
)

/**
 * Projects a value from one range to another
 */
export const cast = curry((toUpper, toLower, fromUpper, fromLower, val) => {
  if (toUpper === toLower) {
    return toUpper
  }

  return (val - fromLower) * (toUpper - toLower) / (fromUpper - fromLower) + toLower
})

export const castTrigonometric = cast(1, -1)
export const normalize = cast(1, 0)

/**
 * Multiplies a vector by some scalar
 */
export const multiplyVector = (vec, scalar = 1) => ({
  x: vec.x * scalar,
  y: vec.y * scalar,
  z: vec.z * scalar,
})

/**
 *
 */
export const multiplyValues = (obj, scalar) =>
  reduce(
    obj,
    (result, value, key) => ({
      ...result,
      [key]: value * scalar,
    }),
    {}
  )

/**
 * Returns a number that is ensured to be outside the given range
 */
export const clampOuter = (number, lower, upper) => {
  if (inRange(number, lower, upper)) {
    const distLower = Math.abs(number - lower)
    const distUpper = Math.abs(upper - number)

    return distUpper < distLower ? upper : lower
  }

  return number
}

/**
 * Returns the first number that is finite
 */
export const getFirstFinite = values => {
  return values.find(x => isFinite(x))
}
