import { Genres, SongTags } from 'src/constants.js'
import { getDefaultZForTrackWithNumber } from 'src/utils/track-controls.js'

const getTrackDefaults = trackNumber => ({
  volume: 0.8,
  position: { x: 0, y: 0, z: getDefaultZForTrackWithNumber(trackNumber) },
  isSoloed: false,
  isMuted: false,
})

export default [
  {
    title: 'Hot Toddy',
    artist: 'Native Instruments',
    featured: 0,
    image: `/audio/NR_HotToddy/worstalbumcovers03.jpeg`,
    length: 234,
    genre: [Genres.ELECTRONIC],
    tags: [SongTags.MEDIUM_LISTENING],
    tracks: {
      Bass: {
        ...getTrackDefaults(1),
        id: 'Bass',
        filepath: `/audio/NR_HotToddy/NR_HotToddy_Bass_dash`,
        trackNumber: 1,
        name: 'Bass',
      },
      Beat: {
        ...getTrackDefaults(2),
        id: 'Beat',
        filepath: `/audio/NR_HotToddy/NR_HotToddy_Beat_dash`,
        trackNumber: 2,
        name: 'Beat',
      },
      Synth1: {
        ...getTrackDefaults(3),
        id: 'Synth1',
        filepath: `/audio/NR_HotToddy/NR_HotToddy_Synth1_dash`,
        trackNumber: 3,
        name: 'Synth1',
      },
      Synth2: {
        ...getTrackDefaults(4),
        id: 'Synth2',
        filepath: `/audio/NR_HotToddy/NR_HotToddy_Synth2_dash`,
        trackNumber: 4,
        name: 'Synth2',
      },
    },
  },
]
