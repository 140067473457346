'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})
exports.TabPanel = exports.Tab = exports.TabList = exports.Tabs = undefined

var _Tabs = require('./components/Tabs')

var _Tabs2 = _interopRequireDefault(_Tabs)

var _TabList = require('./components/TabList')

var _TabList2 = _interopRequireDefault(_TabList)

var _Tab = require('./components/Tab')

var _Tab2 = _interopRequireDefault(_Tab)

var _TabPanel = require('./components/TabPanel')

var _TabPanel2 = _interopRequireDefault(_TabPanel)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

exports.Tabs = _Tabs2.default
exports.TabList = _TabList2.default
exports.Tab = _Tab2.default
exports.TabPanel = _TabPanel2.default

// For bc we also export a default object, remove in 1.0

exports.default = {
  Tabs: _Tabs2.default,
  TabList: _TabList2.default,
  Tab: _Tab2.default,
  TabPanel: _TabPanel2.default,
}
