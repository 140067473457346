import { ActionTypes } from 'src/constants.js'

const getSongByOffset = (songs, songId, offset) => {
  const currentSong = songs.find(x => x.get('id') === songId)

  let nextSongIndex = (songs.indexOf(currentSong) + offset) % songs.count()
  if (nextSongIndex < 0) {
    nextSongIndex = songs.count() + nextSongIndex
  } else if (nextSongIndex >= songs.count()) {
    nextSongIndex = nextSongIndex - songs.count()
  }

  return songs.get(nextSongIndex)
}

export const requestPlaySong = songId => ({
  type: ActionTypes.REQUEST_PLAY_SONG,
  payload: { songId },
})

export const playSong = (songId, navigate = true) => ({
  type: ActionTypes.PLAY_SONG,
  payload: { songId, navigate },
})

export const setCurrentSong = songId => ({
  type: ActionTypes.SET_CURRENT_SONG,
  payload: songId,
})

export const playPrevSong = () => (dispatch, getState) => {
  const state = getState()
  const prevSong = getSongByOffset(state.get('songs'), state.getIn(['player', 'song']), -1)

  dispatch(playSong(prevSong.get('id')))
}

export const playNextSong = () => (dispatch, getState) => {
  const state = getState()
  const nextSong = getSongByOffset(state.get('songs'), state.getIn(['player', 'song']), 1)

  dispatch(playSong(nextSong.get('id')))
}

export const togglePlayback = () => ({
  type: ActionTypes.TOGGLE_PLAYBACK,
})

export const setPlaybackState = playbackState => ({
  type: ActionTypes.SET_PLAYBACK_STATE,
  payload: playbackState,
})

export const loadSongTracks = () => ({
  type: ActionTypes.LOAD_SONG_TRACKS,
})

export const setPlayerBufferingState = bufferingState => ({
  type: ActionTypes.SET_PLAYER_BUFFERING_STATE,
  payload: { bufferingState },
})

export const abortPlayingSong = () => ({
  type: ActionTypes.ABORT_PLAYING_SONG,
})

export const beginPlayback = () => ({
  type: ActionTypes.BEGIN_PLAYBACK,
})

export const seekTo = seconds => ({
  type: ActionTypes.SEEK_TO,
  payload: seconds,
})

export const setPlaybackTime = seconds => ({
  type: ActionTypes.SET_PLAYBACK_TIME,
  payload: seconds,
})
