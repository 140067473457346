import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Gravatar from 'react-gravatar'

import { SILVER } from 'src/styles/colors.js'

/**
 * User Avatar
 */
class UserAvatar extends PureComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    isRound: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    isRound: false,
    className: '',
  }

  render() {
    const { email, size, isRound, className } = this.props

    return (
      <Gravatar
        className={className}
        size={size}
        email={email}
        style={{
          display: 'inline-block',
          width: size,
          height: size,
          backgroundColor: SILVER,
          borderRadius: isRound ? size : 2,
          verticalAlign: 'middle',
        }}
      />
    )
  }
}

export default UserAvatar
