import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './DependantPanel.scss'

/**
 * Dependant Panel
 *
 * An element that can be disabled depending on whatever condition.
 */
class DependantPanel extends PureComponent {
  static propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    maxHeight: PropTypes.number,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    maxHeight: 240,
    className: '',
  }

  render() {
    const { isDisabled, maxHeight, className, children, ...props } = this.props

    return (
      <div
        className={cx('DependantPanel', className, { isDisabled })}
        style={{
          maxHeight: isDisabled ? maxHeight : 1000,
        }}
        {...props}
      >
        {children}
      </div>
    )
  }
}

export default DependantPanel
