import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { autobind } from 'core-decorators'
import scrollTo from 'animated-scrollto'
import { Gateway } from 'react-gateway'
import ContainerDimensions from 'react-container-dimensions'
import { T } from 'lioness'
import cx from 'classnames'

import { Positions } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import { startTour, endTour, setTourStep } from 'src/actions/onboarder.actions.js'
import { getAbsoluteBounds, resovleBoxSizing } from 'src/utils/box-sizing.js'
import {
  AnimatedTooltip,
  TooltipContent,
  TooltipActions,
  TooltipStyles,
} from 'src/components/Tooltip.js'
import OnboardingStepRect from 'src/components/onboarder/OnboardingStepRect.js'

class OnboardingStep extends Component {
  static propTypes = {
    tour: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
    text: PropTypes.node.isRequired,
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    padding: CustomPropTypes.boxSpacing,
    tooltipPosition: CustomPropTypes.position,
    tooltipAlignment: CustomPropTypes.alignment,
    tooltipOffset: CustomPropTypes.boxSpacing,
    scrollAnchorPoint: PropTypes.oneOf([Positions.TOP, Positions.BOTTOM]),
    scrollAnchorOffset: PropTypes.shape({
      top: PropTypes.number,
      bottom: PropTypes.number,
    }),
    quitOnEsc: PropTypes.bool,
    onStart: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onQuit: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    padding: 10,
    tooltipPosition: Positions.TOP,
    tooltipAlignment: {
      x: Positions.LEFT,
      y: Positions.TOP,
    },
    tooltipOffset: 0,
    scrollAnchorPoint: Positions.TOP,
    scrollAnchorOffset: {
      top: 0,
      bottom: 0,
    },
    quitOnEsc: true,
    className: '',
  }

  state = {
    elementBounds: null,
  }

  componentDidMount() {
    if (this.props.quitOnEsc === true) {
      window.addEventListener('keyup', this.quitOnEsc)
    }
  }

  componentWillUnmount() {
    if (this.props.quitOnEsc === true) {
      window.removeEventListener('keyup', this.quitOnEsc)
    }
  }

  @autobind
  quitOnEsc(evt) {
    if (this.props.isActive && evt.which === 27) {
      this.props.onQuit()
    }
  }

  componentWillReceiveProps() {
    if (this.targetElement) {
      this.updateTargetBounds(this.targetElement)
    }
  }

  componentDidUpdate(prevProps) {
    const { isActive, scrollAnchorPoint, scrollAnchorOffset } = this.props
    const { absoluteBounds } = this.state

    if (absoluteBounds && (prevProps.isActive === false && isActive === true)) {
      const maxScroll = document.body.scrollHeight - window.innerHeight

      const scrollTop =
        scrollAnchorPoint === Positions.TOP
          ? absoluteBounds.top
          : absoluteBounds.bottom - window.innerHeight

      const resolvedOffsets = resovleBoxSizing(scrollAnchorOffset)
      const scrollDestination = scrollTop + resolvedOffsets.top + resolvedOffsets.bottom
      const clampedScrollDestination = Math.min(scrollDestination, maxScroll)

      scrollTo(document.scrollingElement || document.body, clampedScrollDestination, 600)
    }
  }

  @autobind
  storeTargetRef(element) {
    if (!element) {
      return
    }

    this.targetElement = element
  }

  updateTargetBounds(element) {
    const boundingRect = element.getBoundingClientRect()
    const absoluteBounds = getAbsoluteBounds(boundingRect)

    this.setState({ boundingRect, absoluteBounds })
  }

  render() {
    const {
      text,
      isFirst,
      isLast,
      isActive,
      padding,
      tooltipPosition,
      tooltipAlignment,
      tooltipOffset,
      onPrevious,
      onNext,
      onQuit,
      className,
      children,
    } = this.props
    const { absoluteBounds } = this.state

    return (
      <div className={cx('OnboardingStep', className)} ref={this.storeTargetRef}>
        <Gateway into="onboarding">
          <div>
            {isActive && (
              <OnboardingStepRect
                bounds={absoluteBounds}
                padding={padding}
                borderRadius={2}
                key="rect"
              />
            )}

            <AnimatedTooltip
              isVisible={isActive}
              bounds={absoluteBounds}
              position={tooltipPosition}
              alignment={tooltipAlignment}
              offset={tooltipOffset}
              tooltipStyle={TooltipStyles.YELL}
              hasPrevious={isFirst === false}
              hasNext={isLast === false}
              onPrevious={onPrevious}
              onNext={onNext}
              onQuit={onQuit}
              key="tooltip"
            >
              <TooltipContent>{text}</TooltipContent>

              <TooltipActions>
                {isLast === false && (
                  <div className="TooltipActions-skip" onClick={onQuit}>
                    {isLast === false ? <T>Skip</T> : <T>Close</T>}
                  </div>
                )}

                <div className="TooltipActions-navigation">
                  {isFirst === false && (
                    <span className="TooltipActions-back" onClick={onPrevious}>
                      <T>Back</T>
                    </span>
                  )}
                  {isLast === false ? (
                    <span className="TooltipActions-next" onClick={onNext}>
                      <T>Next</T>
                    </span>
                  ) : (
                    <span className="TooltipActions-next" onClick={onQuit}>
                      <T>Done</T>
                    </span>
                  )}
                </div>
              </TooltipActions>
            </AnimatedTooltip>
          </div>
        </Gateway>

        {children}
      </div>
    )
  }
}

const AutoResizingOnboardingStep = props => (
  <ContainerDimensions>
    <OnboardingStep {...props} />
  </ContainerDimensions>
)

export default connect(
  (state, { tour, step }) => {
    const tourState = state.getIn(['onboarder', 'tours', tour])
    return {
      isFirst: step === 1,
      isLast: step === tourState.get('numSteps'),
      isActive: tourState.get('isActive') && tourState.get('currentStep') === step,
    }
  },
  (dispatch, { tour, step }) => ({
    onStart: () => dispatch(startTour(tour, step)),
    onPrevious: () => dispatch(setTourStep(tour, step - 1)),
    onNext: () => dispatch(setTourStep(tour, step + 1)),
    onQuit: () => dispatch(endTour(tour)),
  })
)(AutoResizingOnboardingStep)
