/* eslint react/no-multi-comp: 0 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Transition from 'react-motion-ui-pack'
import cx from 'classnames'
import { capitalize, values } from 'lodash'

import { Positions } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import { resovleBoxSizing, getStylesAligningTo, getStylesRelativeTo } from 'src/utils/box-sizing.js'

import './Tooltip.scss'

export const TooltipStyles = {
  TALK: 'TALK',
  YELL: 'YELL',
  SCREAM: 'SCREAM',
}

export class Tooltip extends Component {
  static propTypes = {
    bounds: CustomPropTypes.bounds,
    position: CustomPropTypes.position,
    alignment: CustomPropTypes.alignment,
    offset: CustomPropTypes.boxSpacing,
    tooltipStyle: PropTypes.oneOf(values(TooltipStyles)),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    bounds: null,
    position: Positions.TOP,
    alignment: {
      x: Positions.LEFT,
      y: Positions.TOP,
    },
    offset: 0,
    tooltipStyle: TooltipStyles.TALK,
    className: '',
    style: {},
  }

  render() {
    const {
      bounds,
      position,
      alignment,
      offset,
      tooltipStyle,
      children,
      className,
      style,
    } = this.props

    const resolvedAlignment = {
      ...Tooltip.defaultProps.alignment,
      ...alignment,
    }

    const styles = bounds
      ? getStylesAligningTo(bounds, position, alignment, resovleBoxSizing(offset))
      : getStylesRelativeTo(position, alignment, resovleBoxSizing(offset))

    return (
      <div
        className={cx(
          className,
          'Tooltip',
          `position-${capitalize(position)}`,
          `alignmentX-${capitalize(resolvedAlignment.x)}`,
          `alignmentY-${capitalize(resolvedAlignment.y)}`,
          `style-${capitalize(tooltipStyle)}`
        )}
        style={{
          ...style,
          top: styles.position.top,
          right: styles.position.right,
          bottom: styles.position.bottom,
          left: styles.position.left,
          transform: `translate3d(${styles.translate.x}, ${styles.translate.y}, 0)`,
        }}
      >
        <div className="TooltipCaret" />

        {children}
      </div>
    )
  }
}

export class TooltipContent extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children } = this.props

    return <div className="TooltipContent">{children}</div>
  }
}

export class TooltipActions extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children } = this.props

    return <div className="TooltipActions">{children}</div>
  }
}

export class AnimatedTooltip extends Component {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    transitionSize: PropTypes.number,
  }

  static defaultProps = {
    transitionSize: 40,
  }

  render() {
    const { isVisible, alignment, transitionSize, ...props } = this.props

    const resolvedAlignment = {
      ...Tooltip.defaultProps.alignment,
      ...alignment,
    }

    return (
      <Transition
        component={false}
        appear={{
          opacity: 0,
          marginTop: resolvedAlignment.y === Positions.TOP ? transitionSize : -transitionSize,
        }}
        enter={{ opacity: 1, marginTop: 0 }}
        leave={{ opacity: 0, marginTop: 0 }}
      >
        {isVisible && <Tooltip key="tooltip" alignment={alignment} {...props} />}
      </Transition>
    )
  }
}

export default Tooltip
