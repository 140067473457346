import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { T, withTranslators } from 'lioness'

import FaqEntry from 'src/components/FaqEntry.js'
// import View from 'src/components/View.js'

import './Faq.scss'

class Faq extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props

    return (
      <div className="Faq">
        <div className="Faq-content">
          <h2>
            <T>Frequently asked questions</T>
          </h2>

          <FaqEntry question={t('I am profoundly deaf. Can I use Musiclarity?')}>
            <p>
              <T>
                Musiclarity may be of help to those who currently derive benefit from their hearing aid.
                If you are not a hearing aid user because you have complete or profound levels of deafness, 
                Musiclarity will not be of use to you.
              </T>
            </p>
            <p>
              <T>Everyone is, of course, still welcome to enjoy Musiclarity in any way they feel they can.</T>
            </p>
          </FaqEntry>

          <FaqEntry question={t('How do I know whether headphones or hearing aids are best for me to use with Musiclarity?')}>
            <p>
              <T>
                Your choice of listening method with Musiclarity can depend on a number of factors such as individual preference, style of hearing aid and level of loss. 
              </T>
            </p>
            <p>
              <T>
              Headphones may be the best option for people with mild or moderate hearing loss whereas bluetooth enabled hearing aids will be best for more severe levels.
              </T>
            </p>
            <p>
              <T>
                Many hearing aids cause feedback when used together with over-ear headphones, so should you decide to remove them Musiclarity can automatically compensate
                based on your audiogram.
              </T>
            </p>
          </FaqEntry>

          <FaqEntry
            question={t(
              'Can I use Musiclarity with another music service such as iTunes or Spotify?'
            )}
          >
            <p>
              <T>
                At the moment, there is no way to apply the Virtual Hearing Aid on music being
                streamed in other music services.
              </T>
            </p>
            <p>
              <T>
                Controlling individual instruments in music from other sources will not be possible.
              </T>
            </p>
          </FaqEntry>

          <FaqEntry question={t('Can I use Musiclarity on my smartphone or tablet?')}>
            <p>
              <T>At the moment, Musiclarity is only available on desktop web browsers.</T>
            </p>
          </FaqEntry>
        </div>
      </div>
    )
  }
}

export default withTranslators(Faq)
