import React from 'react'
import classNames from 'classnames'

import './TrackControlsLabel.scss'

export default ({ children, className, ...props }) => (
  <div className={classNames('TrackControlsLabel', className)} {...props}>
    {children}
  </div>
)
