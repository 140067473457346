import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'
import { Link } from 'react-router-dom'
import { autobind } from 'core-decorators'

import * as CustomPropTypes from 'src/prop-types.js'
import { addPatient, fetchPatients } from 'src/actions/consultant.actions.js'
import Button, { Loudness } from 'src/components/Button.js'
import UserSearchPicker from 'src/components/user/UserSearchPicker.js'

/**
 * Add Existing User
 */
class AddExistingUser extends PureComponent {
  static propTypes = {
    consultantId: PropTypes.string.isRequired,
    isPending: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    patients: CustomPropTypes.patientList.isRequired,
    fetchPatients: PropTypes.func.isRequired,
  }

  state = {
    selectedUser: null,
  }

  componentDidMount() {
    this.props.fetchPatients(this.props.consultantId)
  }

  @autobind
  handleSelectUser(selectedUser) {
    this.setState({ selectedUser })
  }

  @autobind
  handleAdd() {
    this.props.onAdd(this.state.selectedUser._id)
  }

  render() {
    const { isPending, patients } = this.props
    const { selectedUser } = this.state

    return (
      <div className="AddExistingUser">
        <UserSearchPicker
          filterUser={user =>
            patients.find(x => x.getIn(['user', '_id']) === user._id) === undefined
          }
          selectedUser={selectedUser}
          onSelect={this.handleSelectUser}
        />

        <p>
          <T
            message="By assigning this user to you as your patient, you agree to the {{ link:End-user license agreement }} on their behalf."
            link={<Link to="/eula" />}
          />
        </p>

        <p>
          <Button
            isEnabled={isPending === false && selectedUser !== null}
            loudness={Loudness.YELL}
            onClick={this.handleAdd}
          >
            {isPending ? <T>Adding user...</T> : <T>Add user as a patient</T>}
          </Button>
        </p>
      </div>
    )
  }
}

export default connect(
  state => ({
    consultantId: state.getIn(['user', 'user', '_id']),
    isPending: state.getIn(['consultant', 'addPatient', 'isPending']),
    patients: state.getIn(['consultant', 'patientList', 'data']),
  }),
  dispatch => ({
    onAdd: userId => dispatch(addPatient(userId)),
    fetchPatients: consultantId => dispatch(fetchPatients(consultantId)),
  })
)(AddExistingUser)
