import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'
import { Link } from 'react-router-dom'

import { Barks } from 'src/constants.js'
import { setBarkCompleted } from 'src/actions/barker.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import EulaDocument from 'src/components/EulaDocument.js'
import { Modal, ModalHeader, ModalTitle, ModalContent, ModalActions } from 'src/components/Modal.js'

import './EulaPrompt.scss'

/**
 * Eula Prompt
 */
class EulaPrompt extends PureComponent {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    onAnswer: PropTypes.func.isRequired,
  }

  render() {
    const { isActive, onAnswer } = this.props

    if (isActive === false) {
      return null
    }

    return (
      <Modal isOpen onClose={() => onAnswer(false)} className="EulaPrompt">
        <ModalHeader>
          <ModalTitle>
            <T>Before you continue</T>
          </ModalTitle>
        </ModalHeader>

        <ModalContent>
          <div>
            <T
              message="You need to read and agree to our {{ link:End-user license agreement }} before continuing to use Musiclarity."
              link={<Link to="/eula" />}
            />
          </div>

          <div className="EulaPrompt-document">
            <EulaDocument />
          </div>
        </ModalContent>

        <ModalActions>
          <Button loudness={Loudness.YELL} size={Size.SMALL} onClick={() => onAnswer(true)}>
            <T>I agree</T>
          </Button>
          <Button loudness={Loudness.TALK} size={Size.SMALL} onClick={() => onAnswer(false)}>
            <T>I do not agree</T>
          </Button>
        </ModalActions>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    isActive: state.getIn(['barker', Barks.EULA, 'isActive']),
  }),
  dispatch => ({
    onAnswer: answer => dispatch(setBarkCompleted(Barks.EULA, answer)),
  })
)(EulaPrompt)
