import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'
import { values } from 'lodash'

import { Ears, ListeningMethods } from 'src/constants.js'
import { setToneControlMirrored } from 'src/actions/vha.actions.js'
import EarMirroringForm from 'src/components/vha/EarMirroringForm.js'
import EarToneControls from 'src/components/vha/EarToneControls.js'
import { VHASplitPane, Pane } from 'src/components/vha/VHASplitPane.js'

/**
 * Tone Controls
 */
class ToneControls extends Component {
  static propTypes = {
    isMirrored: PropTypes.bool.isRequired,
    onSetMirrored: PropTypes.func.isRequired,
    listeningMethod: PropTypes.oneOf(values(ListeningMethods)),
  }

  render() {
    const { isMirrored, onSetMirrored, listeningMethod } = this.props

    return (
      <div className="ToneControls">
        {listeningMethod === ListeningMethods.HEARING_LOOP ? (
          <EarToneControls ear={Ears.LEFT} />
        ) : (
          <div>
            <EarMirroringForm isMirrored={isMirrored} onChange={onSetMirrored} />

            <VHASplitPane>
              <Pane>
                <label>
                  <T>Left ear</T>
                </label>

                <EarToneControls ear={Ears.LEFT} />
              </Pane>

              <Pane isActive={isMirrored === false}>
                <label>
                  <T>Right ear</T>
                </label>

                <EarToneControls ear={Ears.RIGHT} />
              </Pane>
            </VHASplitPane>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  state => ({
    isMirrored: state.getIn(['vha', 'toneControl', 'isMirrored']),
    listeningMethod: state.getIn(['vha', 'listeningMethod']),
  }),
  dispatch => ({
    onSetMirrored: isMirrored => dispatch(setToneControlMirrored(isMirrored)),
  })
)(ToneControls)
