import { ErrorTypes } from 'src/constants.js'

export const getMessageForError = errorType => {
  switch (errorType) {
    case ErrorTypes.EMAIL_ALREADY_EXISTS:
      return `An account with the provided email already exists. Please register using another email address.`
    case ErrorTypes.FAILED_TO_CREATE_ACCOUNT:
      return `We couldn't create an account for you at this moment. Please try again in a while.`
    case ErrorTypes.FAILED_TO_CREATE_PATIENT:
      return `We could not create the patient just now. Please try again in a little bit.`
    case ErrorTypes.FAILED_TO_DELETE_ACCOUNT:
      return `We couldn't delete your account at this moment. Please try again in a moment or two.`
    case ErrorTypes.FAILED_TO_LOAD_TRACKS:
      return `We failed to load this song for you. Please try another one while we try to fix this issue.`
    case ErrorTypes.FAILED_TO_LOGIN:
      return `We couldn't log you in as the email and/or password were incorrect. Check your spelling and try again.`
    case ErrorTypes.FAILED_TO_SEND_USER_PROFILE:
      return `We could'nt send your profile at this time. Sorry about that! Please try again in a moment or two.`
    case ErrorTypes.FAILED_TO_SUBMIT_FEEDBACK:
      return `We couldn't submit your feedback at this time. Sorry about that! Please try again in a moment or two.`
    default:
      return `An error occurred.`
  }
}
