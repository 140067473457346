import { connect } from 'react-redux'

import { setVhaListeningMethod } from 'src/actions/vha.actions.js'
import ListeningMethodPicker from 'src/components/vha/ListeningMethodPicker.js'

export default connect(
  state => ({
    listeningMethod: state.getIn(['vha', 'listeningMethod']),
  }),
  dispatch => ({
    onChange: listeningMethod => dispatch(setVhaListeningMethod(listeningMethod)),
  })
)(ListeningMethodPicker)
