import { fromJS } from 'immutable'
import { ActionTypes, Ears } from 'src/constants.js'

const audiogramEntry = {
  '125': 0,
  '250': 0,
  '500': 0,
  '1000': 0,
  '2000': 0,
  '4000': 0,
  '8000': 0,
}

const initialState = fromJS({
  hasSeenIntro: true, // false,
  isEnabled: true, // false,
  values: {
    [Ears.LEFT]: { ...audiogramEntry },
    [Ears.RIGHT]: { ...audiogramEntry },
  },
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_AUDIOGRAM_INTRO_AS_SEEN:
      return state.set('hasSeenIntro', true)
    case ActionTypes.SET_AUDIOGRAM_ENABLED:
      return state.set('isEnabled', payload)
    case ActionTypes.SET_AUDIOGRAM:
      return state.setIn(['values', payload.ear], payload.values)
    case ActionTypes.SET_AUDIOGRAM_FREQUENCY_VALUE:
      return state.setIn(['values', payload.ear, payload.frequency], parseInt(payload.value))
    default:
      return state
  }
}
