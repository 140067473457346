import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import View from 'src/components/View.js'
import ConsultantSignUp from 'src/components/user/ConsultantSignUp.js'
import UserSignUp from 'src/components/user/UserSignUp.js'
import { whenNotLoggedIn } from 'src/components/composers/auth-composers.js'
import { isLoggedIn } from 'src/reducers/auth.reducer.js'

/**
 * Sign Up View
 */
class SignUpView extends PureComponent {
  render() {
    return (
      <View className="SignUpView">
        <Switch>
          <Route exact path="/signup/user" component={UserSignUp} />
          <Route path="/signup/consultant" component={ConsultantSignUp} />
          <Redirect to="/signup/user" />
        </Switch>
      </View>
    )
  }
}

export default whenNotLoggedIn(
  connect(state => ({
    isLoggedIn: isLoggedIn(state.get('auth')),
  }))(SignUpView)
)

