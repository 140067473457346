import context from 'src/audio/context.js'
import DetunaEffect from 'src/audio/detuna/DetunaEffect.js'

export default class Gain extends DetunaEffect {
  constructor(params) {
    super()
    this.input = context.createGain()
    this.output = this.input
    this.initialise(params)
  }

  set gain(value) {
    this.input.gain.setValueAtTime(value, 0)
  }
}
