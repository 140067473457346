import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslators } from 'lioness'

import { isTrackEnabled } from 'src/utils/track-controls.js'
import TrackControls from './TrackControls.js'
import TrackControlsPlaceholder from './TrackControlsPlaceholder.js'

import './Mixer.scss'

/**
 * Renders a set of track controls, or placeholders if no
 * tracks are provided
 */
class Mixer extends Component {
  static propTypes = {
    tracks: PropTypes.object,
    isEnabled: PropTypes.bool.isRequired,
    isSoloMuteEnabled: PropTypes.bool,
    showFftGraphs: PropTypes.bool,
    selectedTrackId: PropTypes.string,
    onSelectTrack: PropTypes.func.isRequired,
    onTrackSoloChange: PropTypes.func.isRequired,
    onTrackMuteChange: PropTypes.func.isRequired,
    onTrackVolumeChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    tracks: null,
    isSoloMuteEnabled: true,
    showFftGraphs: true,
    selectedTrackId: null,
    className: '',
  }

  render() {
    const {
      tracks,
      isEnabled,
      isSoloMuteEnabled,
      showFftGraphs,
      selectedTrackId,
      onSelectTrack,
      onTrackSoloChange,
      onTrackMuteChange,
      onTrackVolumeChange,
      className,
      t,
    } = this.props

    return (
      <div className={classNames('Mixer', className)}>
        {tracks
          ? tracks
              .map(x => (
                <TrackControls
                  key={x.get('id')}
                  className="Mixer-track"
                  track={x}
                  isEnabled={isEnabled && isTrackEnabled(tracks, x)}
                  isSelected={x.get('id') === selectedTrackId}
                  isSoloMuteEnabled={isSoloMuteEnabled}
                  showFftGraphs={showFftGraphs}
                  onSelect={() => onSelectTrack(x)}
                  onVolumeChange={volume => onTrackVolumeChange(x, volume)}
                  onSoloChange={() => onTrackSoloChange(x, !x.get('isSoloed'))}
                  onMuteChange={() => onTrackMuteChange(x, !x.get('isMuted'))}
                />
              ))
              .toArray()
          : [
              <TrackControlsPlaceholder
                key={1}
                trackNumber={1}
                label={t('Track 1')}
                volume={0.7}
              />,
              <TrackControlsPlaceholder
                key={2}
                trackNumber={2}
                label={t('Track 2')}
                volume={0.8}
              />,
              <TrackControlsPlaceholder
                key={3}
                trackNumber={3}
                label={t('Track 3')}
                volume={0.5}
              />,
              <TrackControlsPlaceholder
                key={4}
                trackNumber={4}
                label={t('Track 4')}
                volume={0.6}
              />,
            ]}
      </div>
    )
  }
}

export default withTranslators(Mixer)
