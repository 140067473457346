/* eslint no-loop-func: 0 */
import { call, cancel, cancelled, fork, put, select, take } from 'redux-saga/effects'
import { debounceFor } from 'redux-saga-debounce-effect'
import { pick } from 'lodash'

import { getUserMix, updateUserMix } from 'src/api.js'
import { ActionTypes } from 'src/constants.js'
import { selectAuthToken, selectUserId, selectSongTracks } from 'src/selectors.js'
import {
  applyUserMix,
  getUserMixSuccess,
  getUserMixError,
  updateUserMixSuccess,
  updateUserMixError,
} from 'src/actions/mixer.actions.js'
import { request } from 'src/sagas/saga-utils.js'

const mixFields = ['id', 'isSoloed', 'isMuted', 'position', 'volume']

/**
 * Returns the id of the user whose VHA and mixer settings are
 * currently being controlled; that is, either a current session's
 * patient's id, or the logged in user's id.
 */
function* getCurrentlyControlledUserId() {
  const [user, patient] = yield [
    select(state => state.getIn(['user', 'user'])),
    select(state => state.getIn(['consultant', 'session', 'patient'])),
  ]

  if (patient !== null) {
    return patient.get('_id')
  } else if (user !== null) {
    return user.get('_id')
  }

  return null
}

/**
 * Fetches and applies a user's mix on a song after singing in
 */
function* applyUserMixAfterSignIn() {
  while (true) {
    yield take(ActionTypes.GET_ME_SUCCESS)

    const songId = yield select(state => state.getIn(['player', 'song']))
    if (songId !== null) {
      const userId = yield select(selectUserId)
      yield call(fetchAndMergeUserMix, songId, userId)
    }

    yield take(ActionTypes.LOGOUT)
  }
}

/**
 * Triggers a fetch-and-merge of a user's mix of a song when
 * a new song (and hence new tracks) are set.
 */
function* applyUserMixToNewSong() {
  while (true) {
    yield take(ActionTypes.SET_MIXER_TRACKS)

    const songId = yield select(state => state.getIn(['player', 'song']))
    const userId = yield call(getCurrentlyControlledUserId)

    if (songId !== null && userId !== null) {
      yield call(fetchAndMergeUserMix, songId, userId)
    }
  }
}

/**
 * Fetches a user's mix for a given song and merges it into
 * the app state.
 */
export function* fetchAndMergeUserMix(songId, userId) {
  try {
    const token = yield select(selectAuthToken)
    const mix = yield call(request, () => token, getUserMix(userId, songId))

    const songTracks = yield select(selectSongTracks(songId))
    const songTrackKeys = songTracks.keySeq().toArray()
    const mixWithCorrectTracks = {
      ...mix,
      tracks: pick(mix.tracks, songTrackKeys),
    }

    yield put(getUserMixSuccess(mixWithCorrectTracks))
    yield put(applyUserMix(pick(mixWithCorrectTracks, ['tracks'])))
  } catch (err) {
    yield put(getUserMixError(err))
  }
}

/**
 * Posts a user's mix to the API
 */
function* storeUserMix(token, userId) {
  try {
    const [mixerTracks, songId] = yield [
      select(state => state.getIn(['mixer', 'tracks'])),
      select(state => state.getIn(['player', 'song'])),
    ]

    const tracks = mixerTracks.map(track => pick(track.toJS(), mixFields)).toJS()
    const mixPayload = { userId, songId, tracks }

    const results = yield call(request, () => token, updateUserMix(mixPayload))
    yield put(updateUserMixSuccess(results))
  } catch (err) {
    yield put(updateUserMixError(err))
  }
}

/**
 * Creates a saga effect that listenes to a bunch of mixing related
 * actions and triggers an upstream sync of the mix.
 *
 * If forked and cancelled, it will upload the mix one last time.
 */
function* initialiseMixSync(token, userId) {
  try {
    yield debounceFor(
      [
        ActionTypes.SET_TRACK_VOLUME,
        ActionTypes.SET_TRACK_PAN,
        ActionTypes.SET_TRACK_DISTANCE,
        ActionTypes.SET_TRACK_SOLOED,
        ActionTypes.SET_TRACK_MUTED,
        ActionTypes.SET_TRACK_POSITION,
      ],
      function* storeUserMixAfterDebounce() {
        yield call(storeUserMix, token, userId)
      },
      2000
    )
  } finally {
    if (yield cancelled()) {
      yield call(storeUserMix, token, userId)
    }
  }
}

/**
 * Orchestrates upstream synchronisation of users' mixes.
 *
 * For more details on the flow here, check out the corresponding
 * function in user-vha.sagas.js.
 */
function* continuouslyStoreUserMixes() {
  let syncMixTask

  while (true) {
    yield take(ActionTypes.GET_ME_SUCCESS)

    const token = yield select(selectAuthToken)
    const userId = yield select(selectUserId)

    const patientSessionTask = yield fork(function* continuouslySynchronisePatientSessions() {
      while (true) {
        syncMixTask = yield fork(initialiseMixSync, token, userId)

        const { payload: { patient } } = yield take(ActionTypes.START_PATIENT_SESSION)

        yield cancel(syncMixTask)
        const patientSyncMixTask = yield fork(initialiseMixSync, token, patient.get('_id'))

        yield take(ActionTypes.END_PATIENT_SESSION)
        yield cancel(patientSyncMixTask)
      }
    })

    yield take(ActionTypes.LOGOUT)
    yield cancel(patientSessionTask)

    if (syncMixTask.isRunning() === true) {
      yield cancel(syncMixTask)
    }
  }
}

/**
 * Fetches and merges the user's and their patients' mixer settings
 * alternatingly when sessions are started and ended.
 */
function* applyPersonalMixesInAndOutOfPatientSessions() {
  const syncFinishActions = [ActionTypes.UPDATE_USER_MIX_ERROR, ActionTypes.UPDATE_USER_MIX_SUCCESS]

  while (true) {
    const { payload: { patient } } = yield take(ActionTypes.START_PATIENT_SESSION)

    // Wait for the logged in user's upstream mix sync to finish
    yield take(syncFinishActions)

    // If a song is playing when a session starts, fetch and apply
    // the patient's mix
    const songIdStart = yield select(state => state.getIn(['player', 'song']))
    if (songIdStart !== null) {
      // First, set the tracks to have their default values
      yield call(applyDefaultMix, songIdStart)

      // Fetch and merge the patient's mix
      yield call(fetchAndMergeUserMix, songIdStart, patient.get('_id'))
    }

    // If a song is playing when a session ends, fetch and apply the
    // logged in user's mix
    yield take(ActionTypes.END_PATIENT_SESSION)

    // Wait for the patient's upstream mix sync to finish
    yield take(syncFinishActions)

    const songIdEnd = yield select(state => state.getIn(['player', 'song']))
    if (songIdEnd !== null) {
      // First, set the tracks to have their default values
      yield call(applyDefaultMix, songIdEnd)

      // Fetch and merge
      const userId = yield select(selectUserId)
      yield call(fetchAndMergeUserMix, songIdEnd, userId)
    }
  }
}

function* applyDefaultMix(songId) {
  const tracks = yield select(state =>
    state
      .get('songs')
      .find(x => x.get('id') === songId)
      .get('tracks')
  )
  yield put(applyUserMix({ tracks }))
}

export default function* userMixSagas() {
  yield fork(applyUserMixAfterSignIn)
  yield fork(applyUserMixToNewSong)
  yield fork(continuouslyStoreUserMixes)
  yield fork(applyPersonalMixesInAndOutOfPatientSessions)
}
