import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { T, withTranslators } from 'lioness'

import BinauralTrackPositioner from './BinauralTrackPositioner.js'
import BinauralTrackPositionerPlaceholder from './BinauralTrackPositionerPlaceholder.js'

import './BinauralMixer.scss'

/**
 * Binaural mixer component
 */
class BinauralMixer extends Component {
  static propTypes = {
    isEnabled: PropTypes.bool.isRequired,
    tracks: PropTypes.object,
    selectedTrack: PropTypes.object,
    onSelectTrack: PropTypes.func.isRequired,
    onTrackPositionChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    tracks: null,
  }

  constructor(props) {
    super(props)

    this.visualiserElem = null
  }

  render() {
    const { isEnabled, tracks, selectedTrack, onSelectTrack, onTrackPositionChange, t } = this.props

    const activeTrack = selectedTrack || (tracks ? tracks.first() : null)

    return (
      <div className={cx('BinauralMixer', { isDisabled: !isEnabled })}>
        <div className="BinauralMixer-directionLabel mod-front">
          <T>In front</T>
        </div>
        <div className="BinauralMixer-directionLabel mod-right">
          <T>Right</T>
        </div>
        <div className="BinauralMixer-directionLabel mod-back">
          <T>Behind</T>
        </div>
        <div className="BinauralMixer-directionLabel mod-left">
          <T>Left</T>
        </div>

        <div className="BinauralMixer-inner" ref={c => (this.visualiserElem = c)}>
          <svg className="BinauralMixer-kidney" viewBox="0 0 192 146">
            <path d="M96,97 C118.911672,97 139.94869,136.714262 156.452671,143.46838 C178.140146,152.343801 192,126.107664 192,96 C192,42.980664 149.019336,0 96,0 C42.980664,0 0,42.980664 0,96 C0,125.85833 13.6312469,152.403818 35.010135,143.687815 C51.5936663,136.926841 72.8389941,97 96,97 Z" />
          </svg>

          <div className="BinauralMixer-circle mod-outer" />
          <div className="BinauralMixer-circle mod-inner" />

          <div className="BinauralMixer-line mod-n" />
          <div className="BinauralMixer-line mod-e" />

          <div className="BinauralMixer-handles">
            {tracks !== null
              ? tracks.toArray().map(track => (
                  <BinauralTrackPositioner
                    key={track.get('id')}
                    track={track}
                    isEnabled={isEnabled}
                    isSelected={track === activeTrack}
                    onSelect={() => onSelectTrack(track)}
                    bounds={() => {
                      const bounds = this.visualiserElem.getBoundingClientRect()
                      const borderWidth = 1
                      const handleWidth = 16
                      const padding = borderWidth + handleWidth / 2

                      return {
                        top: bounds.top + window.scrollY + padding,
                        left: bounds.left + window.scrollX + padding,
                        width: this.visualiserElem.clientWidth - padding * 2,
                        height: this.visualiserElem.clientHeight - padding * 2,
                      }
                    }}
                    onPosition={newPos => onTrackPositionChange(track, newPos)}
                  />
                ))
              : [
                  <BinauralTrackPositionerPlaceholder
                    key={1}
                    name={t('Track 1')}
                    position={{ x: -0.7, z: 0.1 }}
                  />,
                  <BinauralTrackPositionerPlaceholder
                    key={2}
                    name={t('Track 2')}
                    position={{ x: -0.3, z: -0.4 }}
                  />,
                  <BinauralTrackPositionerPlaceholder
                    key={3}
                    name={t('Track 3')}
                    position={{ x: 0.1, z: -0.2 }}
                  />,
                  <BinauralTrackPositionerPlaceholder
                    key={4}
                    name={t('Track 4')}
                    position={{ x: 0.35, z: -0.6 }}
                  />,
                ]}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslators(BinauralMixer)
