import { fromJS } from 'immutable'

import { ActionTypes, OnboardingTours } from 'src/constants.js'
import { MixerTourSteps } from 'src/tours/mixer.tour.js'

const initialState = fromJS({
  tours: {
    [OnboardingTours.VHA]: {
      isActive: false,
      numSteps: 5,
      currentStep: null,
      prerequisiteActions: {},
      completedActions: {},
      isCompleted: false,
    },
    [OnboardingTours.MIXER]: {
      isActive: false,
      numSteps: Object.keys(MixerTourSteps).length,
      currentStep: null,
      prerequisiteActions: {
        [ActionTypes.SET_HEADPHONES_ENSURED]: false,
      },
      completedActions: {
        [ActionTypes.SET_TRACK_VOLUME]: false,
        [ActionTypes.SET_TRACK_SOLOED]: false,
        [ActionTypes.SET_TRACK_MUTED]: false,
        [ActionTypes.SET_SELECTED_TRACK]: false,
        [ActionTypes.SET_TRACK_PAN]: false,
        [ActionTypes.SET_TRACK_DISTANCE]: false,
      },
      isCompleted: false,
    },
  },
})

export const hasActiveTour = state => {
  return state.get('tours').count(tour => tour.get('isActive')) > 0
}

export const hasCompletedTour = (state, tourName) => {
  return state.getIn(['tours', tourName, 'isCompleted'])
}

export const hasCompletedPrerequisiteActions = (state, tourName) => {
  return state
    .getIn(['tours', tourName, 'prerequisiteActions'])
    .every(actionCompleted => actionCompleted)
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_ONBOARDING_TOUR_ACTIVE:
      return state.setIn(['tours', payload.tourName, 'isActive'], payload.isActive)
    case ActionTypes.SET_ONBOARDING_TOUR_STEP:
      return state.setIn(['tours', payload.tourName, 'currentStep'], payload.step)
    case ActionTypes.SET_TOUR_PREREQUISITE_ACTION_COMPLETED:
      return state.setIn(
        ['tours', payload.tourName, 'prerequisiteActions', payload.actionType],
        payload.isCompleted
      )
    case ActionTypes.SET_TOUR_ACTION_COMPLETED:
      return state.setIn(
        ['tours', payload.tourName, 'completedActions', payload.actionType],
        payload.isCompleted
      )
    case ActionTypes.SET_ONBOARDING_TOUR_COMPLETED:
      return state.setIn(['tours', payload.tourName, 'isCompleted'], payload.isCompleted)
    case ActionTypes.ABORT_ONBOARDING_TOUR:
      return state.updateIn(['tours', payload.tourName], tour => {
        return tour
          .set(
            'completedActions',
            initialState.getIn(['tours', payload.tourName, 'completedActions'])
          )
          .set('isActive', false)
          .set('isCompleted', false)
      })
    case ActionTypes.END_ONBOARDING_TOUR:
      return state.updateIn(['tours', payload.tourName], tour => {
        return tour
          .set(
            'completedActions',
            initialState.getIn(['tours', payload.tourName, 'completedActions'])
          )
          .set('isActive', false)
          .set('isCompleted', true)
      })
    case ActionTypes.START_MAIN_ONBOARDING:
    case ActionTypes.START_ONBOARDING_TOUR:
    default:
      return state
  }
}
