import { AppEnvironments } from 'src/constants.js'
import env from 'src/environment.js'

// import niSongs from './songs-ni.js'
import karaokeSongs from './songs-karaoke.js'
// import bonIverSongs from './songs-boniver.js'
// import richieHawtinSongs from './songs-richiehawtin.js'
// import radioheadSongs from './songs-radiohead.js'
// import miscSongs from './songs-misc.js'
import debugSongs from './songs-debug.js'

const allSongs = env === AppEnvironments.LOCAL ? [karaokeSongs, debugSongs] : [karaokeSongs]

export default allSongs // bonIverSongs, richieHawtinSongs, niSongs, radioheadSongs, miscSongs
  .reduce((aggr, list) => aggr.concat(list), [])
  .map((song, i) => ({
    ...song,
    id: i + 1,
  }))
