import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'
import { omit } from 'lodash'
import { autobind } from 'core-decorators'

import { deletePatient } from 'src/actions/consultant.actions.js'
import Button from 'src/components/Button.js'
import ConfirmationDialog from 'src/components/ConfirmationDialog.js'

/**
 * Delete Patient Button
 */
class DeletePatientButton extends PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
  }

  state = {
    isPrompting: false,
  }

  @autobind
  handleClick() {
    this.setState({ isPrompting: true })
  }

  @autobind
  handleDelete() {
    this.props.onDelete(this.props.userId)
  }

  @autobind
  handleDismiss() {
    this.setState({ isPrompting: false })
  }

  render() {
    const { isDeleting } = this.props
    const { isPrompting } = this.state

    const buttonProps = omit(this.props, ['userId', 'onDelete', 'isDeleting'])

    return (
      <div
        className="DeletePatientButton"
        style={{
          position: 'relative',
        }}
      >
        <Button onClick={this.handleClick} {...buttonProps}>
          <T>Delete patient</T>
        </Button>

        <ConfirmationDialog
          isVisible={isPrompting}
          canConfirm={isDeleting === false}
          confirmLabel={<T>Yes, delete this patient</T>}
          dismissLabel={<T>No, go back</T>}
          onConfirm={this.handleDelete}
          onDismiss={this.handleDismiss}
        >
          <h3>Delete patient</h3>

          <p>
            <T>
              You are about to delete this patient's account. All their data will be permanently
              deleted. This is irreversible and cannot be undone.
            </T>
          </p>

          <p>
            <T>Are you sure you want to continue?</T>
          </p>
        </ConfirmationDialog>
      </div>
    )
  }
}

export default connect(
  state => ({
    isDeleting: state.getIn(['consultant', 'deletePatient', 'isPending']),
  }),
  dispatch => ({
    onDelete: userId => dispatch(deletePatient(userId)),
  })
)(DeletePatientButton)
