import { fromJS } from 'immutable'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  create: {
    isPending: false,
    error: null,
  },
  patientList: {
    isFetching: false,
    error: null,
    data: [],
  },
  patient: {
    isFetching: false,
    error: null,
    data: null,
  },
  addPatient: {
    isPending: false,
    error: null,
  },
  createPatient: {
    isPending: false,
    error: null,
  },
  deletePatient: {
    isPending: false,
    error: null,
  },
  session: {
    patient: null,
  },
  sendAudiograms: {
    isPending: false,
    error: null,
    lastSentAt: null,
  },
})

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    /**
     * Reset
     */
    case ActionTypes.RESET_CONSULTANT:
      return initialState

    /**
     * Creating a consultant account
     */
    case ActionTypes.CREATE_CONSULTANT:
      return state.setIn(['create', 'isPending'], true)
    case ActionTypes.CREATE_CONSULTANT_ERROR:
      return state.setIn(['create', 'isPending'], false).setIn(['create', 'error'], error)
    case ActionTypes.CREATE_CONSULTANT_SUCCESS:
      return state.setIn(['create', 'isPending'], false).setIn(['create', 'error'], null)

    /**
     * Fetching all patients
     */
    case ActionTypes.FETCH_PATIENTS:
      return state.setIn(['patientList', 'isFetching'], true)
    case ActionTypes.FETCH_PATIENTS_ERROR:
      return state
        .setIn(['patientList', 'isFetching'], false)
        .setIn(['patientList', 'error'], fromJS(error))
    case ActionTypes.FETCH_PATIENTS_SUCCESS:
      return state
        .setIn(['patientList', 'isFetching'], false)
        .setIn(['patientList', 'error'], null)
        .setIn(['patientList', 'data'], fromJS(payload.patients))

    /**
     * Fetching a single patient
     */
    case ActionTypes.FETCH_PATIENT:
      return state
        .setIn(['patient', 'isFetching'], true)
        .setIn(['patient', 'error'], null)
        .setIn(['patient', 'data'], null)
    case ActionTypes.FETCH_PATIENT_ERROR:
      return state
        .setIn(['patient', 'isFetching'], false)
        .setIn(['patient', 'error'], fromJS(error))
        .setIn(['patient', 'data'], null)
    case ActionTypes.FETCH_PATIENT_SUCCESS:
      return state
        .setIn(['patient', 'isFetching'], false)
        .setIn(['patient', 'error'], null)
        .setIn(['patient', 'data'], fromJS(payload.patient))

    /**
     * Adding a patient
     */
    case ActionTypes.ADD_PATIENT:
      return state.setIn(['addPatient', 'isPending'], true)
    case ActionTypes.ADD_PATIENT_ERROR:
      return state
        .setIn(['addPatient', 'isPending'], false)
        .setIn(['addPatient', 'error'], fromJS(error))
    case ActionTypes.ADD_PATIENT_SUCCESS:
      return state.setIn(['addPatient', 'isPending'], false).setIn(['addPatient', 'error'], null)

    /**
     * Creating a patient
     */
    case ActionTypes.CREATE_PATIENT:
      return state.setIn(['createPatient', 'isPending'], true)
    case ActionTypes.CREATE_PATIENT_ERROR:
      return state
        .setIn(['createPatient', 'isPending'], false)
        .setIn(['createPatient', 'error'], fromJS(error))
    case ActionTypes.CREATE_PATIENT_SUCCESS:
      return state
        .setIn(['createPatient', 'isPending'], false)
        .setIn(['createPatient', 'error'], null)

    /**
     * Deleting a patient
     */
    case ActionTypes.DELETE_PATIENT:
      return state
        .setIn(['deletePatient', 'isPending'], true)
        .setIn(['deletePatient', 'error'], null)
    case ActionTypes.DELETE_PATIENT_ERROR:
      return state
        .setIn(['deletePatient', 'isPending'], false)
        .setIn(['deletePatient', 'error'], error)
    case ActionTypes.DELETE_PATIENT_SUCCESS:
      return state
        .setIn(['deletePatient', 'isPending'], false)
        .setIn(['deletePatient', 'error'], null)

    /**
     * Patient sessions
     */
    case ActionTypes.START_PATIENT_SESSION:
      return state.setIn(['session', 'patient'], fromJS(payload.patient))

    // See consultant.actions.js for differences between these two
    case ActionTypes.END_PATIENT_SESSION:
    case ActionTypes.RESET_PATIENT_SESSION:
      return state.setIn(['session', 'patient'], null)

    /**
     * Sending audiograms
     */
    case ActionTypes.SEND_PATIENT_AUDIOGRAMS:
      return state.setIn(['sendAudiograms', 'isPending'], true)
    case ActionTypes.SEND_PATIENT_AUDIOGRAMS_ERROR:
      return state
        .setIn(['sendAudiograms', 'isPending'], false)
        .setIn(['sendAudiograms', 'error'], error)
    case ActionTypes.SEND_PATIENT_AUDIOGRAMS_SUCCESS:
      return state
        .setIn(['sendAudiograms', 'isPending'], false)
        .setIn(['sendAudiograms', 'error'], null)
        .setIn(['sendAudiograms', 'lastSentAt'], Date.now())

    default:
      return state
  }
}
