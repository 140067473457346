/* eslint no-loop-func: 0 */
import { call, cancel, cancelled, fork, put, select, take } from 'redux-saga/effects'
import { debounceFor } from 'redux-saga-debounce-effect'
import { pick } from 'lodash'

import { getUserVha, updateUserVha } from 'src/api.js'
import { ActionTypes } from 'src/constants.js'
import { selectAuthToken, selectUserId } from 'src/selectors.js'
import {
  getUserVhaError,
  getUserVhaSuccess,
  updateUserVhaSuccess,
  updateUserVhaError,
} from 'src/actions/user.actions.js'
import { request } from 'src/sagas/saga-utils.js'

/**
 * Performs fetching and populating of a user's VHA
 */
function* doGetUserVha(token, userId) {
  try {
    const data = yield call(request, () => token, getUserVha(userId))
    yield put(getUserVhaSuccess(data))
  } catch (err) {
    yield put(getUserVhaError(err))
  }
}

/**
 * Fetches a user's VHA when GET_USER_VHA is dispatched
 */
function* handleGetUserVha() {
  while (true) {
    const { payload: { id } } = yield take(ActionTypes.GET_USER_VHA)
    const token = yield select(selectAuthToken)
    yield call(doGetUserVha, token, id)
  }
}

/**
 * Makes sure to fetch the user's VHA after sign in
 */
function* loadUserVhaAfterSignIn() {
  while (true) {
    yield take(ActionTypes.LOGIN_SUCCESS)
    yield take(ActionTypes.GET_ME_SUCCESS)
    const token = yield select(selectAuthToken)
    const userId = yield select(selectUserId)
    yield call(doGetUserVha, token, userId)
  }
}

/**
 * Uploads the current VHA to the user's upstream account
 */
function* uploadUserVhaSettings(token, userId) {
  const vha = yield select(state => state.get('vha'))
  const vhaData = pick(vha.toJS(), ['aid', 'toneControl', 'compression', 'levels'])

  const vhaPayload = { id: userId, vha: vhaData }

  try {
    yield call(request, () => token, updateUserVha(vhaPayload))
    yield put(updateUserVhaSuccess(vhaPayload))
  } catch (err) {
    console.log(err)
    yield put(updateUserVhaError(err))
  }
}

/**
 * Stores the logged in user's VHA after sign up
 */
function* storeUserVhaSettingsOnSignUp() {
  while (true) {
    yield take(ActionTypes.CREATE_ACCOUNT_SUCCESS)
    yield take(ActionTypes.GET_ME_SUCCESS)
    const token = yield select(selectAuthToken)
    const userId = yield select(selectUserId)
    yield call(uploadUserVhaSettings, token, userId)
  }
}

/**
 * Creates a saga effect that listenes to a bunch of VHA related
 * actions and triggers an upstream sync of the VHA.
 *
 * If forked and cancelled, it will upload upload the VHA one
 * last time.
 */
export function* initialiseVhaSync(token, userId) {
  try {
    yield debounceFor(
      [
        ActionTypes.SET_VHA_FITTING_MODE,
        ActionTypes.SET_VHA_PRESET,
        ActionTypes.SET_VHA_HEARING_LOSS_CODE,
        ActionTypes.SET_VHA_AUDIOGRAM,
        ActionTypes.SET_VHA_AUDIOGRAM_FREQUENCY_VALUE,
        ActionTypes.SET_TONE_CONTROL_MIRRORED,
        ActionTypes.SET_TONE_CONTROL_GAIN,
        ActionTypes.SET_COMPRESSION_MIRRORED,
        ActionTypes.SET_COMPRESSION_AMOUNT,
        ActionTypes.SET_LEVEL_ADJUSTMENT,
      ],
      function* doStoreUserVhaSettingsForUser() {
        yield call(uploadUserVhaSettings, token, userId)
      },
      2000
    )
  } finally {
    if (yield cancelled()) {
      yield call(uploadUserVhaSettings, token, userId)
    }
  }
}

/**
 * Orchestrates upstream synchronisation of users' VHAs. The flow
 * looks like this:
 *
 *  - When a logged in user session is initialised (either via login
 *    or by already being logged in), start syncing their VHA
 *  - When a patient session starts, switch over to syncing that
 *    patient's VHA
 *  - Download the patient's VHA and apply it
 *  - When a patient session ends, switch syncing back to the logged
 *    in user
 *  - Download the user's VHA and apply it
 *  - When logging out, end whatever sync is running
 */
function* orchestrateUserAndPatientVhas() {
  let syncVhaTask

  const syncFinishActions = [ActionTypes.UPDATE_USER_VHA_ERROR, ActionTypes.UPDATE_USER_VHA_SUCCESS]

  while (true) {
    yield take(ActionTypes.GET_ME_SUCCESS)

    const token = yield select(selectAuthToken)
    const userId = yield select(selectUserId)

    const patientSessionTask = yield fork(function* continuouslySynchronisePatientSessions() {
      while (true) {
        // This will always be called, thus setting up sync for the
        // logged in user even if no patient session are ever run
        syncVhaTask = yield fork(initialiseVhaSync, token, userId)

        // Wait for a patient session to start
        const { payload: { patient } } = yield take(ActionTypes.START_PATIENT_SESSION)

        // Cancel the current sync task and wait for the clean-up
        // syncs to finish
        yield cancel(syncVhaTask)
        yield take(syncFinishActions)

        // Fetch and merge the patient's VHA
        yield call(doGetUserVha, token, patient.get('_id'))

        // Start syncing the patient's VHA
        const patientSyncVhaTask = yield fork(initialiseVhaSync, token, patient.get('_id'))

        // Wait for session to end
        yield take(ActionTypes.END_PATIENT_SESSION)

        // Cancel the patient's sync task and wait for the clean-up
        // syncs to finish
        yield cancel(patientSyncVhaTask)
        yield take(syncFinishActions)

        // Fetch and merge the user's VHA
        yield call(doGetUserVha, token, userId)
      }
    })

    yield take(ActionTypes.LOGOUT)
    yield cancel(patientSessionTask)

    if (syncVhaTask.isRunning() === true) {
      yield cancel(syncVhaTask)
    }
  }
}

export default function* userVhaSagas() {
  yield fork(handleGetUserVha)
  yield fork(loadUserVhaAfterSignIn)
  yield fork(storeUserVhaSettingsOnSignUp)
  yield fork(orchestrateUserAndPatientVhas)
}
