import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { T, withTranslators } from 'lioness'
import { isEmpty, merge } from 'lodash'
import { autobind } from 'core-decorators'

import { ConsultantOccupation } from 'src/constants.js'
import Button, { Loudness } from 'src/components/Button.js'
import FormField from 'src/components/forms/FormField.js'
import FormFieldGroup from 'src/components/forms/FormFieldGroup.js'
import ConsultantOccupationField from 'src/components/user/ConsultantOccupationField.js'
import { ConsultantSignUpSchema } from 'src/schemas/user.schemas.js'
import { validateObject } from 'src/schemas/validate.js'

import './ConsultantSignUpForm.scss'

const validateSignUpData = validateObject(ConsultantSignUpSchema)

/**
 * Consultant Sign Up Form
 */
class ConsultantSignUpForm extends PureComponent {
  static propTypes = {
    initialEmail: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialEmail: '',
    isSubmitting: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      data: {
        email: props.initialEmail,
        password: '',
        verifiedPassword: '',
        placeOfWork: '',
        occupation: {
          [ConsultantOccupation.AUDIOLOGIST]: false,
          [ConsultantOccupation.HEARING_THERAPIST]: false,
          [ConsultantOccupation.OTHER]: '',
        },
      },
      errors: null,
      hasSubmitted: false,
    }
  }

  handleChange(field, value) {
    const data = merge({}, this.state.data, { [field]: value })
    const errors = validateSignUpData(data)

    this.setState(() => ({ data, errors }))
  }

  @autobind
  handleSubmit(evt) {
    evt.preventDefault()

    const { onSubmit } = this.props
    const { data } = this.state

    const errors = validateSignUpData(data)

    if (isEmpty(errors)) {
      onSubmit(data)
    }

    this.setState(() => ({ errors, hasSubmitted: true }))
  }

  render() {
    const { isSubmitting, t } = this.props
    const { data, errors, hasSubmitted } = this.state
    const { firstName, lastName, email, password, verifiedPassword, placeOfWork, occupation } = data

    return (
      <form className="ConsultantSignUpForm" onSubmit={this.handleSubmit}>
        <FormFieldGroup>
          <FormField
            name="firstName"
            label={t('First name')}
            type="text"
            placeholder=""
            value={firstName}
            errors={errors ? errors.firstName : []}
            onChange={value => this.handleChange('firstName', value)}
            showErrors={hasSubmitted}
          />

          <FormField
            name="lastName"
            label={t('Last name')}
            type="text"
            placeholder=""
            value={lastName}
            errors={errors ? errors.lastName : []}
            onChange={value => this.handleChange('lastName', value)}
            showErrors={hasSubmitted}
          />
        </FormFieldGroup>

        <FormField
          name="email"
          label={t('Email')}
          type="text"
          placeholder={t('jane.doe@example.com')}
          value={email}
          errors={errors ? errors.email : []}
          onChange={value => this.handleChange('email', value)}
          showErrors={hasSubmitted}
        />
        <FormFieldGroup>
          <FormField
            name="password"
            label={t('Password')}
            type="password"
            placeholder=""
            value={password}
            errors={errors ? errors.password : []}
            onChange={value => this.handleChange('password', value)}
            showErrors={hasSubmitted}
          />

          <FormField
            name="verifiedPassword"
            label={t('Verify password')}
            type="password"
            placeholder=""
            value={verifiedPassword}
            errors={errors ? errors.verifiedPassword : []}
            onChange={value => this.handleChange('verifiedPassword', value)}
            showErrors={hasSubmitted}
          />
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField
            name="placeOfWork"
            label={t('Place of work')}
            type="text"
            placeholder=""
            value={placeOfWork}
            errors={errors ? errors.placeOfWork : []}
            onChange={value => this.handleChange('placeOfWork', value)}
            showErrors={hasSubmitted}
          />

          <FormField
            name="occupation"
            type="ConsultantOccupationField"
            component={ConsultantOccupationField}
            label={t('Occupation')}
            value={occupation}
            errors={errors ? errors.occupation : []}
            onChange={value => this.handleChange('occupation', value)}
            showErrors={hasSubmitted}
          />
        </FormFieldGroup>

        <Button
          className="ConsultantSignUpForm-submit"
          loudness={Loudness.YELL}
          type="submit"
          isEnabled={isSubmitting === false}
        >
          {isSubmitting ? <T>Creating account...</T> : <T>Create account</T>}
        </Button>
      </form>
    )
  }
}

export default withTranslators(ConsultantSignUpForm)
