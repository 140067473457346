import { takeEvery } from 'redux-saga'
import { take, put, call } from 'redux-saga/effects'

import { AccountTypes, ActionTypes, ErrorTypes } from 'src/constants.js'
import { authorize } from 'src/api.js'
import { loginSuccess, loginError } from 'src/actions/auth.actions.js'
import { displayErrorOfType } from 'src/actions/errors.actions.js'
import { getMe } from 'src/actions/user.actions.js'

/**
 * This saga manages requests for logging in and logout
 * of the application.
 *
 * TODO: Handle race conditions
 */
function* loginFlow() {
  while (true) {
    const { payload: { email, password } } = yield take(ActionTypes.LOGIN)
    const { data, errors } = yield call(authorize({ email, password }))

    if (errors) {
      yield put(loginError(errors))
      yield put(displayErrorOfType(ErrorTypes.FAILED_TO_LOGIN, errors[0]))
    } else {
      yield put(loginSuccess(data.token))

      yield put(getMe())
      const { payload: user } = yield take(ActionTypes.GET_ME_SUCCESS)

      const history = require('src/history.js').default
      const destination =
        user.accountType === AccountTypes.CONSULTANT ? '/me/consultant-dashboard' : '/me'
      yield call([history, history.push], destination)

      yield take(ActionTypes.LOGOUT)
      yield call([history, history.push], '/')
    }
  }
}

// Export main saga
export default function* authSagas() {
  yield [loginFlow()]
}
