import { fromJS } from 'immutable'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  isActive: false,
  submission: {
    isPending: false,
    result: null,
    error: null,
  },
  data: {
    isFetching: false,
    entities: [],
  },
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_FEEDBACK_FORM_OPEN:
      return state.set('isActive', payload)
    case ActionTypes.SET_FEEDBACK_DATA:
      return state.mergeIn(['data'], payload)
    case ActionTypes.SUBMIT_FEEDBACK:
      return state.setIn(['submission', 'isPending'], true)
    case ActionTypes.SUBMIT_FEEDBACK_SUCCESS:
      return state.mergeIn(['submission'], {
        isPending: false,
        result: payload,
        error: null,
      })
    case ActionTypes.SUBMIT_FEEDBACK_ERROR:
      return state.mergeIn(['submission'], {
        isPending: false,
        result: null,
        error: payload,
      })
    case ActionTypes.FETCH_FEEDBACKS:
      return state.setIn(['data', 'isFetching'], true)
    case ActionTypes.FETCH_FEEDBACKS_SUCCESS:
      return state.setIn(['data', 'entities'], fromJS(payload)).setIn(['data', 'isFetching'], false)
    case ActionTypes.FETCH_FEEDBACKS_ERROR:
      return state.setIn(['data', 'isFetching'], false)
    default:
      return state
  }
}
