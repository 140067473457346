import { call, fork, put, select, take } from 'redux-saga/effects'

import {
  associatePatientToConsultant,
  createConsultant,
  createUser,
  deleteUser,
  getPatient,
  getPatients,
  sendPatientAudiograms,
  withToken,
} from 'src/api.js'
import { AccountTypes, ActionTypes, ErrorTypes } from 'src/constants.js'
import { redirectTo } from 'src/history.js'
import { selectAuthToken, selectUserId } from 'src/selectors.js'
import { login } from 'src/actions/auth.actions.js'
import {
  resetConsultant,
  createConsultantError,
  createConsultantSuccess,
  addPatientError,
  addPatientSuccess,
  createPatientError,
  createPatientSuccess,
  deletePatientError,
  deletePatientSuccess,
  fetchPatientError,
  fetchPatientSuccess,
  fetchPatientsError,
  fetchPatientsSuccess,
  sendPatientAudiogramsSuccess,
  sendPatientAudiogramsError,
  startPatientSession,
  resetPatientSession,
} from 'src/actions/consultant.actions.js'
import { displayErrorOfType } from 'src/actions/errors.actions.js'
import { cascade, isCascaded, request } from 'src/sagas/saga-utils.js'

/**
 * Reset consultant on sign out
 */
function* resetConsultantOnSignOut() {
  while (true) {
    yield take(ActionTypes.LOGOUT)
    yield put(resetConsultant())
  }
}

/**
 * Creates a new consultant accoutn
 */
function* doCreateConsultant() {
  while (true) {
    const {
      payload: { firstName, lastName, email, password, placeOfWork, occupation },
    } = yield take(ActionTypes.CREATE_CONSULTANT)

    // Create a user account
    try {
      const accountType = AccountTypes.CONSULTANT
      const userData = { firstName, lastName, email, password, accountType }
      const newUser = yield call(request, selectAuthToken, createUser(userData))

      // Create a consultant entity for this new user
      const userId = newUser._id
      const consultantData = { userId, placeOfWork, occupation }
      const consultantResult = yield call(
        request,
        selectAuthToken,
        createConsultant(consultantData)
      )

      yield put(createConsultantSuccess(consultantResult.data))

      // Login the user
      yield put(login({ email, password }))
    } catch (err) {
      // Give error feedback
      if (err.code === ErrorTypes.EMAIL_ALREADY_EXISTS) {
        yield put(displayErrorOfType(ErrorTypes.EMAIL_ALREADY_EXISTS, err))
      } else {
        yield put(displayErrorOfType(ErrorTypes.FAILED_TO_CREATE_ACCOUNT, err))
      }
      yield put(createConsultantError(err))
    }
  }
}

/**
 * Fetches all a consultant's patients
 */
function* doFetchPatients() {
  while (true) {
    const { payload: { consultantId } } = yield take(ActionTypes.FETCH_PATIENTS)

    try {
      const patients = yield call(request, selectAuthToken, getPatients(consultantId))
      yield put(fetchPatientsSuccess(patients))
    } catch (err) {
      yield put(fetchPatientsError(err))
    }
  }
}

/**
 * Fetches a single patient
 */
function* doFetchPatient() {
  while (true) {
    const { payload: { patientId } } = yield take(ActionTypes.FETCH_PATIENT)

    try {
      const token = yield select(state => state.getIn(['auth', 'token']))
      const getPatientWithToken = yield call(withToken, getPatient(patientId), token)
      const { data, errors } = yield call(getPatientWithToken)

      if (Array.isArray(errors) === true && errors.length > 0) {
        yield put(fetchPatientError(errors[0]))
      }

      yield put(fetchPatientSuccess(data))
    } catch (err) {
      yield put(fetchPatientError(err))
    }
  }
}

/**
 * Adds an existing user as a patient
 */
function* doAddPatient() {
  while (true) {
    const { payload: { userId } } = yield take(ActionTypes.ADD_PATIENT)

    try {
      const thisUserId = yield select(selectUserId)
      const patientCoupling = yield call(
        request,
        selectAuthToken,
        associatePatientToConsultant({
          consultantId: thisUserId,
          patientId: userId,
        })
      )

      yield put(addPatientSuccess(patientCoupling))
      yield put(redirectTo(`/me/consultant-dashboard/patients/${userId}`))
    } catch (err) {
      yield put(displayErrorOfType(ErrorTypes.UNKNOWN, err))
      yield put(addPatientError(err))
    }
  }
}

/**
 * Creates a patient
 *
 * This functionality is pretty much identical to that in user.sagas.js,
 * but I thought it'd be better to have some duplicate-ish code than
 * to couple side effects.
 */
function* doCreatePatient() {
  while (true) {
    const { payload: { firstName, lastName, email, password } } = yield take(
      ActionTypes.CREATE_PATIENT
    )

    try {
      // Create a new account for this patient
      const patientUser = yield call(
        request,
        selectAuthToken,
        createUser({ firstName, lastName, email, password })
      )

      // Associate this new patient account with this account
      const thisUserId = yield select(selectUserId)
      const patientId = patientUser._id

      const patientCoupling = yield call(
        request,
        selectAuthToken,
        associatePatientToConsultant({
          patientId: patientId,
          consultantId: thisUserId,
        })
      )

      yield put(createPatientSuccess(patientCoupling))
      yield put(redirectTo(`/me/consultant-dashboard/patients/${patientId}`))
    } catch (err) {
      // Give error feedback
      if (err.code === ErrorTypes.EMAIL_ALREADY_EXISTS) {
        yield put(displayErrorOfType(ErrorTypes.EMAIL_ALREADY_EXISTS, err))
      } else {
        yield put(displayErrorOfType(ErrorTypes.FAILED_TO_CREATE_PATIENT, err))
      }

      yield put(createPatientError(err))
    }
  }
}

/**
 * Deletes a patient
 */
function* doDeletePatient() {
  while (true) {
    try {
      const { payload: { patientId } } = yield take(ActionTypes.DELETE_PATIENT)
      const token = yield select(state => state.getIn(['auth', 'token']))
      const deleteUserUsingToken = yield call(withToken, deleteUser(patientId), token)

      const { errors } = yield call(deleteUserUsingToken)

      if (errors) {
        yield put(deletePatientError(errors[0]))
      } else {
        yield put(deletePatientSuccess())
        yield put(redirectTo('/me/consultant-dashboard'))
      }
    } catch (err) {
      yield put(deletePatientError(err))
    }
  }
}

/**
 * Sends a patient's audiograms to them
 */
function* doSendPatientAudiograms() {
  while (true) {
    try {
      const { payload: { patientId } } = yield take(ActionTypes.SEND_PATIENT_AUDIOGRAMS)
      const token = yield select(state => state.getIn(['auth', 'token']))
      const sendPatientAudiogramsUsingToken = yield call(
        withToken,
        sendPatientAudiograms(patientId),
        token
      )

      const { errors } = yield call(sendPatientAudiogramsUsingToken)

      if (errors) {
        yield put(sendPatientAudiogramsError(errors[0]))
      } else {
        yield put(sendPatientAudiogramsSuccess())
      }
    } catch (err) {
      yield put(sendPatientAudiogramsError(err))
    }
  }
}

/**
 * Dispatches a START_PATIENT_SESSION after the app has booted if an
 * active patient session was found in the persisted app state.
 */
function* dispatchPersistedPatientSessionOnBoot() {
  yield take(ActionTypes.APP_BOOTED)

  const patient = yield select(state => state.getIn(['consultant', 'session', 'patient']))
  if (patient !== null) {
    yield put(cascade(startPatientSession(patient)))
  }
}

/**
 * Redirects the user (consultant) to the Browse page after starting
 * a patient session.
 */
function* redirectToBrowsePageWhenStartingSession() {
  while (true) {
    const action = yield take(ActionTypes.START_PATIENT_SESSION)
    if (isCascaded(action) === false) {
      yield put(redirectTo('/browse'))
    }
  }
}

/**
 * Ends any active patient session when logging out.
 */
function* resetPatientSessionOnLogout() {
  while (true) {
    yield take(ActionTypes.LOGOUT)
    yield put(resetPatientSession())
  }
}

export default function* consultantSagas() {
  yield fork(resetConsultantOnSignOut)
  yield fork(doCreateConsultant)
  yield fork(doFetchPatients)
  yield fork(doFetchPatient)
  yield fork(doAddPatient)
  yield fork(doCreatePatient)
  yield fork(doDeletePatient)
  yield fork(doSendPatientAudiograms)
  yield fork(dispatchPersistedPatientSessionOnBoot)
  yield fork(redirectToBrowsePageWhenStartingSession)
  yield fork(resetPatientSessionOnLogout)
}
