import React from 'react'
import classNames from 'classnames'

import './TrackVolumeControl.scss'

import SliderControl from './SliderControl.js'

const getClasses = (track, extraClasses) =>
  classNames('TrackVolumeControl', `mod-trackNo-${track.get('trackNumber')}`, extraClasses)

const TrackVolumeControl = ({ track, isEnabled, onVolumeChange, className }) => (
  <SliderControl
    className={getClasses(track, className)}
    initialValue={track.get('volume')}
    isEnabled={isEnabled}
    onValueChange={onVolumeChange}
  />
)

export default TrackVolumeControl
