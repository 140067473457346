import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { T } from 'lioness'

import { OnboardingTours } from 'src/constants.js'
import Icon from 'src/components/Icon.js'
import View from 'src/components/View.js'
import ViewHeader from 'src/components/ViewHeader.js'
import OnboardingTourStartButton from 'src/components/onboarder/OnboardingTourStartButton.js'
import VHAInterface from 'src/components/vha/VHAInterface.js'
import VHASwitch from 'src/components/vha/VHASwitch.js'
import VHAUndoButton from 'src/components/vha/VHAUndoButton.js'

const VHAView = () => (
  <View className="VHAView">
    <Helmet title="Listening Method" />

    <ViewHeader>
      <h1 className="ViewTitle">
        <Icon name="ear" /> <T>How would you like to listen today?</T>
      </h1>
      <div className="ViewHeader-content">
        <div className="ViewHeader-description">
          <p>
          <T>
            Your choice of listening method with Musiclarity may depend on a number of factors 
            such as individual preference, style of hearing aid and your hearing loss.
          </T>
          </p>
          <p>
          <T>
            Please choose one of the following three options. You may have to experiment to find which works best for you.
          </T>
          </p>
        </div>
      </div>
    </ViewHeader>

    <div className="View-content">
      <VHAInterface />
    </div>
  </View>
)

export default connect()(VHAView)
