import { take, select, put, call } from 'redux-saga/effects'

import { ActionTypes } from 'src/constants.js'
import { appBooted } from 'src/actions/boot.actions.js'
import { getMe, getUserVha } from 'src/actions/user.actions.js'

/**
 * Runs any effects that needs to happen before rendering the
 * actual app.
 */
function* runBootstrapActions() {
  yield take(ActionTypes.BOOT_APP)

  const token = yield select(state => state.getIn(['auth', 'token']))

  if (token) {
    yield [call(fetchUserInfo), call(fetchUserVha)]
  }

  yield put(appBooted())

  // Remove initial loading styles
  yield call([document.body.classList, document.body.classList.remove], 'is-loading')
}

function* fetchUserInfo() {
  yield put(getMe())
  yield take([ActionTypes.GET_ME_SUCCESS, ActionTypes.GET_ME_ERROR])
}

function* fetchUserVha() {
  const userId = yield select(state => state.getIn(['user', 'user', '_id']))
  yield put(getUserVha(userId))
  yield take([ActionTypes.GET_USER_VHA_SUCCESS, ActionTypes.GET_USER_VHA_ERROR])
}

export default function* bootSagas() {
  yield [runBootstrapActions()]
}
