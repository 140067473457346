import { combineReducers } from 'redux-immutable'
import { routerReducer } from 'react-router-redux'

import mixerReducer from './mixer.reducer.js'
import playerReducer from './player.reducer.js'
import songsReducer from './songs.reducer.js'
import vhaReducer from './vha.reducer.js'
import visualizerReducer from './visualizer.reducer.js'
import lyricsReducer from './lyrics.reducer.js'
import effectsReducer from './effects.reducer.js'
import audiogramReducer from './audiogram.reducer.js'
import errorsReducer from './errors.reducer.js'
import authReducer from './auth.reducer.js'
import userReducer from './user.reducer.js'
import bootReducer from './boot.reducer.js'
import onboarderReducer from './onboarder.reducer.js'
import barkerReducer from './barker.reducer.js'
import feedbackReducer from './feedback.reducer.js'
import l10nReducer from './l10n.reducer.js'
import a11yReducer from './a11y.reducer.js'
import browsingReducer from './browsing.reducer.js'
import consultantReducer from './consultant.reducer.js'

export default combineReducers({
  mixer: mixerReducer,
  player: playerReducer,
  songs: songsReducer,
  vha: vhaReducer,
  routing: routerReducer,
  visualizer: visualizerReducer,
  lyrics: lyricsReducer,
  effects: effectsReducer,
  audiogram: audiogramReducer,
  errors: errorsReducer,
  auth: authReducer,
  user: userReducer,
  boot: bootReducer,
  onboarder: onboarderReducer,
  barker: barkerReducer,
  feedback: feedbackReducer,
  l10n: l10nReducer,
  a11y: a11yReducer,
  browsing: browsingReducer,
  consultant: consultantReducer,
})
