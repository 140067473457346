import React, { PureComponent } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { T } from 'lioness'

import Button, { Loudness, Size } from 'src/components/Button.js'
import AddNewPatientView from 'src/components/consultant/AddNewPatientView.js'
import PatientList from 'src/components/consultant/PatientList.js'
import PatientView from 'src/components/consultant/PatientView.js'

import './ConsultantDashboard.scss'

/**
 * Consultant Dashboard
 */
class ConsultantDashboard extends PureComponent {
  render() {
    return (
      <div className="ConsultantDashboard">
        <Switch>
          <Route
            exact
            path="/me/consultant-dashboard"
            render={() => (
              <div>
                <h2>
                  <T>Consultant Dashboard</T>
                </h2>

                <div className="ConsultantDashboard-headingWithActions">
                  <h3 className="Legend ConsultantDashboard-headingWithActions-heading">
                    <T>Patients</T>
                  </h3>
                  <div>
                    <Button
                      component={Link}
                      to="/me/consultant-dashboard/add-patient"
                      loudness={Loudness.YELL}
                      size={Size.SMALL}
                    >
                      <T>Add patient</T>
                    </Button>
                  </div>
                </div>

                <PatientList />
              </div>
            )}
          />

          <Route
            path="/me/consultant-dashboard/patients/:id"
            render={({ match }) => (
              <div>
                <h4>
                  <T>Consultant Dashboard</T>
                </h4>
                <h2>
                  <T>Patient</T>
                </h2>

                <PatientView patientId={match.params.id} />
              </div>
            )}
          />

          <Route
            path="/me/consultant-dashboard/add-patient"
            render={() => (
              <div>
                <h4>
                  <T>Consultant Dashboard</T>
                </h4>
                <h2>
                  <T>Add a new patient</T>
                </h2>

                <AddNewPatientView />
              </div>
            )}
          />
        </Switch>
      </div>
    )
  }
}

export default ConsultantDashboard
