import { validate } from 'joi-browser'
import { curry, filter, groupBy } from 'lodash'

/**
 * Validates `values` against `schema`, groups the resulting errors
 * by field and returns the ones passing `filterFields`.
 *
 * @param  {Function}    filterFields A field filter function
 * @param  {Object}      schema       A Joi schema
 * @param  {Object}      values       An object with key/value data
 * @return {Object|null}              A collection of errors or null
 */
export const validateFields = curry((filterFields, schema, values) => {
  const { error } = validate(values, schema, { abortEarly: false })

  return error ? groupBy(filter(error.details, filterFields), 'path') : null
})

export const validateObject = validateFields(() => true)

export const validateField = fieldName => validateFields(field => field.path === fieldName)
