import { ActionTypes } from 'src/constants.js'

export const setAudiogramIntroAsSeen = () => ({
  type: ActionTypes.SET_AUDIOGRAM_INTRO_AS_SEEN,
})

export const setAudiogram = (ear, values) => ({
  type: ActionTypes.SET_AUDIOGRAM,
  payload: { ear, values },
})

export const setAudiogramFrequencyValue = (ear, frequency, value) => ({
  type: ActionTypes.SET_AUDIOGRAM_FREQUENCY_VALUE,
  payload: { ear, frequency, value },
})

export const setAudiogramEnabled = isEnabled => ({
  type: ActionTypes.SET_AUDIOGRAM_ENABLED,
  payload: isEnabled,
})
