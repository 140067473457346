import { takeLatest } from 'redux-saga'
import { call, put, select, take } from 'redux-saga/effects'
import { values } from 'lodash'

import { ActionTypes, Ears, VhaLibraries } from 'src/constants.js'
import { setAudiogram } from 'src/actions/audiogram.actions.js'
import { batchSetEffectParams, setEffectParam } from 'src/actions/effects.actions.js'
import createAudiogramEqCurve from 'src/audio/createAudiogramEqCurve.js'

function* applyAudiogram(action) {
  const vhaLibrary = yield select(state => state.getIn(['effects', 'vhaLibrary']))

  if (vhaLibrary === VhaLibraries.WAAPI) {
    yield call(setFilterParamsFromAudiogram, action)
  } else if (vhaLibrary === VhaLibraries.TOOLKIT) {
    const audiogramData = yield select(state =>
      state.getIn(['audiogram', 'values', action.payload.ear])
    )
    const audiogramAttr =
      action.payload.ear === Ears.LEFT ? 'leftEarAudiogram' : 'rightEarAudiogram'
    yield put(setEffectParam(666, audiogramAttr, values(audiogramData.toJS())))
  }
}

/**
 * Listens for changes in audiogram thresholds and dispatches
 * actions that applies a new EQ curve for the corresponding
 * ear.
 */
export function* setFilterParamsFromAudiogram(action) {
  const state = yield select()

  // Get the ear's filter effects
  const earFilterIds = state
    .getIn(['effects', 'units'])
    .find(x => x.getIn(['meta', 'ear']) === action.payload.ear)
    .get('effects')

  const filters = state
    .getIn(['effects', 'effects'])
    .filter(x => earFilterIds.includes(x.get('id')))

  // Get a new EQ curve for this ear
  const audiogramData = state.getIn(['audiogram', 'values', action.payload.ear])
  const eqCurve = createAudiogramEqCurve(audiogramData.toJS())

  // Apply on filters
  const updates = filters.map(filter => ({
    effectId: filter.get('id'),
    param: 'gain',
    value: eqCurve[filter.getIn(['params', 'frequency'])],
  }))
  yield put(batchSetEffectParams(updates))
}

function* applyAudiogramOnVhaLibraryChange() {
  while (true) {
    yield take(ActionTypes.SET_VHA_LIBRARY)
    const audiograms = yield select(state => state.getIn(['audiogram', 'values']))
    yield put(setAudiogram(Ears.LEFT, audiograms.get(Ears.LEFT)))
    yield put(setAudiogram(Ears.RIGHT, audiograms.get(Ears.RIGHT)))
  }
}

export default function* audiogramSagas() {
  yield [
    takeLatest(
      [ActionTypes.SET_AUDIOGRAM, ActionTypes.SET_AUDIOGRAM_FREQUENCY_VALUE],
      applyAudiogram
    ),
    applyAudiogramOnVhaLibraryChange(),
  ]
}
