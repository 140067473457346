import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isEmpty, pickBy, has } from 'lodash'
import { autobind } from 'core-decorators'

import './FormField.scss'

/**
 * A form field component that abstracts away form field DOM
 * layout and error display.
 */
export default class FormField extends Component {
  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.oneOf(['input', 'textarea']), PropTypes.func]),
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    helpText: PropTypes.node,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    isEnabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.array,
    showErrors: PropTypes.bool,
    placeholder: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    component: 'input',
    helpText: null,
    value: '',
    isEnabled: true,
    errors: [],
    showErrors: false,
    placeholder: '',
    style: {},
  }

  state = {
    isPristine: true,
    hasBlurred: false,
    hasChanged: false,
  }

  inputEl = null

  @autobind
  handleBlur() {
    this.setState(() => ({ hasBlurred: true, isPristine: false }))
  }

  handleChange(value) {
    const { onChange } = this.props

    if (value !== this.props.value) {
      onChange(value)
      this.setState(() => ({ value, hasChanged: true, isPristine: false }))
    }
  }

  shouldShowErrors() {
    const { showErrors } = this.props
    const { hasBlurred, hasChanged } = this.state

    // Form has been submitted: show all errors
    // Form has not been submitted: show error if field has been modified
    return showErrors === true || (hasBlurred === true && hasChanged === true)
  }

  focus() {
    this.inputEl.focus()
  }

  render() {
    const {
      component,
      type,
      name,
      label,
      helpText,
      value,
      placeholder,
      style,
      isEnabled,
      errors,
      ...restProps
    } = this.props
    const { isPristine, hasBlurred, hasChanged } = this.state

    const extraProps = pickBy(
      restProps,
      (prop, propName) => has(FormField.propTypes, propName) === false
    )

    const displayErrors =
      this.shouldShowErrors() && isEmpty(errors) === false ? errors.slice(0, 1) : []

    const commonClasses = cx(
      `type-${type}`,
      { isPristine, hasBlurred, hasChanged },
      { hasError: isEmpty(displayErrors) === false }
    )

    return (
      <label className={cx('FormField', commonClasses)} style={style}>
        <span className="FormField-label">{label}</span>

        {helpText && <span className="FormField-helpText">{helpText}</span>}

        {React.createElement(component, {
          type: type,
          name: name,
          value: value,
          placeholder: placeholder,
          disabled: isEnabled === false,
          onBlur: this.handleBlur,
          onChange: evt => this.handleChange(evt.target.value),
          className: cx('FormField-input', commonClasses),
          ref: el => (this.inputEl = el),
          ...extraProps,
        })}

        {displayErrors.map(error => (
          <div className="FormField-error" key={error.message}>
            {error.message}
          </div>
        ))}
      </label>
    )
  }
}
