import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { T } from 'lioness'
import cx from 'classnames'

import { AccountTypes } from 'src/constants.js'
import HomeMiniTour from 'src/components/HomeMiniTour.js'
import LoginForm from 'src/components/LoginForm.js'
import AboutAims from 'src/components/about/AboutAims.js'
import AboutHorizons2020 from 'src/components/about/AboutHorizons2020.js'

import './HomeView.scss'

class HomeView extends Component {
  static propTypes = {
    hasConsultantBar: PropTypes.bool.isRequired,
  }

  render() {
    const { hasConsultantBar } = this.props

    return (
      <div className="HomeView">
        <div className={cx('HomeView-top', { hasConsultantBar })}>
          <div className="HomeView-top-content">
            <div className="HomeView-top-content-intro">
              <h1>
                <T>Tailoring music to your level of hearing</T>
              </h1>
              <p>
                <T>
                  Musiclarity is an interactive music listening service dedicated to improving the
                  sound of music for hearing aid users.
                </T>
              </p>
            </div>

            <div className="HomeView-top-content-signIn">
              <h3 className="Legend">
                <T>Sign in to your account</T>
              </h3>

              <LoginForm />
            </div>
          </div>
        </div>

        <div className="HomeView-onboarding">
          <div className="HomeView-onboarding-content">
            <div className="HomeView-onboarding-tryItOut">
              {/*<T>Try it out</T>*/}
              <T>Coming Soon</T>
            </div>
            <div className="HomeView-readMore">
              <T>Musiclarity is undergoing development and will be returning shortly. For any queries, please send an email to ragnar@musiclarity.com.</T>
            </div>
            {/*<HomeMiniTour />*/}
          </div>
        </div>

        <div className="HomeView-about">
          <AboutAims className="HomeView-about-incentives" />
          <AboutHorizons2020 className="HomeView-about-project" />

          <div className="HomeView-readMore">
            {/*
            <Link to="/about">
              <T>Read more about Musiclarity</T>
            </Link>
            */}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  hasConsultantBar: state.getIn(['user', 'user', 'accountType']) === AccountTypes.CONSULTANT,
}))(HomeView)
