import { ActionTypes } from 'src/constants.js'

export const setEffectsEnabled = isEnabled => ({
  type: ActionTypes.SET_EFFECTS_ENABLED,
  payload: isEnabled,
})

export const setEffectsActive = isActive => ({
  type: ActionTypes.SET_EFFECTS_ACTIVE,
  payload: isActive,
})

export const setVhaLibrary = library => ({
  type: ActionTypes.SET_VHA_LIBRARY,
  payload: library,
})

export const setBinauralLibrary = library => ({
  type: ActionTypes.SET_BINAURAL_LIBRARY,
  payload: library,
})

export const initializeEffects = effects => ({
  type: ActionTypes.INITIALIZE_EFFECTS,
  payload: effects,
})

export const setEffectsMounted = () => ({
  type: ActionTypes.SET_EFFECTS_MOUNTED,
})

export const setEffectEnabled = (id, isEnabled) => ({
  type: ActionTypes.SET_EFFECT_ENABLED,
  payload: { id, isEnabled },
})

export const batchSetEffectParams = effectParams => ({
  type: ActionTypes.BATCH_SET_EFFECT_PARAM,
  payload: effectParams,
})

export const setEffectParam = (id, param, value) => ({
  type: ActionTypes.SET_EFFECT_PARAM,
  payload: { id, param, value },
})
