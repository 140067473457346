import { fromJS } from 'immutable'

import { ActionTypes, Barks } from 'src/constants.js'

const initialState = fromJS({
  [Barks.VHA]: {
    isActive: false,
    isCompleted: false,
  },
  [Barks.BETA_WARNING]: {
    isActive: false,
    isCompleted: false,
  },
  [Barks.EULA]: {
    isActive: false,
    isCompleted: false,
  },
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_BARK_ACTIVE:
      return state.setIn([payload.name, 'isActive'], payload.isActive)
    case ActionTypes.SET_BARK_COMPLETED:
      return state.update(payload.name, bark =>
        bark.set('isActive', false).set('isCompleted', payload.isCompleted)
      )
    default:
      return state
  }
}
