import React from 'react'

import SvgIcon from './SvgIcon.js'

export default () => (
  <SvgIcon>
    <g>
      <rect x="20" y="4" width="7" height="26" />
      <rect x="8" y="4" width="7" height="26" />
    </g>
  </SvgIcon>
)
