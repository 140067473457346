import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { T } from 'lioness'

import { OnboardingTours, Positions, VolumeModifications } from 'src/constants.js'
import ButtonGroup, { Directions } from './ButtonGroup.js'
import LocalizedTrackName from './LocalizedTrackName.js'
import TrackControlsLabel from './TrackControlsLabel.js'
import TrackMuteButton from './TrackMuteButton.js'
import TrackSoloButton from './TrackSoloButton.js'
import TrackVolumeControl from './TrackVolumeControl.js'
import TrackVolumeKeyControlsIndicators from './TrackVolumeKeyControlsIndicators.js'
import TrackMiniVisualizer from './TrackMiniVisualizer.js'
import makeOnboardable from 'src/components/onboarder/makeOnboardable.js'
import OnboardingStep from 'src/components/onboarder/OnboardingStep.js'
import { MixerTourSteps, MixerTourStepNames } from 'src/tours/mixer.tour.js'
import { getVolumeChangeForModification } from 'src/utils/track-controls.js'

import './TrackControls.scss'

const showKeyboardVolumeControls = false

const TrackControlsLabelForTrack = ({ track, onClick }) => (
  <TrackControlsLabel onClick={onClick}>
    <LocalizedTrackName trackName={track.get('name')} />
  </TrackControlsLabel>
)

const OnboardableTrackControlsLabel = makeOnboardable(
  TrackControlsLabelForTrack,
  MixerTourSteps[MixerTourStepNames.TRACK_SELECT],
  props => (
    <OnboardingStep
      tour={OnboardingTours.MIXER}
      step={MixerTourSteps[MixerTourStepNames.TRACK_SELECT].step}
      text={MixerTourSteps[MixerTourStepNames.TRACK_SELECT].text(props)}
      scrollAnchorPoint={Positions.BOTTOM}
      tooltipOffset={{ top: -20, left: -10 }}
    >
      <TrackControlsLabelForTrack {...props} />
    </OnboardingStep>
  )
)

const TrackControls = ({
  track,
  isEnabled,
  isSelected,
  isSoloMuteEnabled,
  showFftGraphs,
  onSelect,
  onVolumeChange,
  onSoloChange,
  onMuteChange,
  className,
}) => (
  <div
    className={classNames('TrackControls', className, {
      'is-soloed': track.get('isSoloed'),
      'is-muted': track.get('isMuted'),
      'is-selected': isSelected,
      'is-enabled': isEnabled,
    })}
  >
    <OnboardableTrackControlsLabel track={track} onClick={isEnabled ? onSelect : () => {}} />

    {showFftGraphs && <TrackMiniVisualizer track={track} />}

    {showKeyboardVolumeControls === true && (
      <TrackVolumeKeyControlsIndicators
        trackNumber={track.get('trackNumber')}
        modifications={[VolumeModifications.BIG_INCREASE, VolumeModifications.SMALL_INCREASE]}
        isClickable
        onVolumeModification={modification =>
          onVolumeChange(track.get('volume') + getVolumeChangeForModification(modification))
        }
      />
    )}

    <TrackVolumeControl
      className="TrackControls-volumeSlider"
      track={track}
      isEnabled={isEnabled}
      onVolumeChange={onVolumeChange}
    />

    {showKeyboardVolumeControls === true && (
      <TrackVolumeKeyControlsIndicators
        trackNumber={track.get('trackNumber')}
        modifications={[VolumeModifications.SMALL_DECREASE, VolumeModifications.BIG_DECREASE]}
        isClickable
        onVolumeModification={modification =>
          onVolumeChange(track.get('volume') + getVolumeChangeForModification(modification))
        }
      />
    )}

    {isSoloMuteEnabled && (
      <ButtonGroup className="TrackControls-toggles" direction={Directions.VERTICAL}>
        <TrackSoloButton track={track} onToggle={() => onSoloChange(!track.get('isSoloed'))}>
          <T>Isolate</T>
        </TrackSoloButton>

        <TrackMuteButton track={track} onToggle={() => onMuteChange(!track.get('isMuted'))}>
          <T>Mute</T>
        </TrackMuteButton>
      </ButtonGroup>
    )}
  </div>
)

TrackControls.propTypes = {
  track: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isSoloMuteEnabled: PropTypes.bool,
  showFftGraphs: PropTypes.bool,
  onSoloChange: PropTypes.func,
  onMuteChange: PropTypes.func,
  isEnabled: PropTypes.bool,
  className: PropTypes.string,
}

TrackControls.defaultProps = {
  isSelected: false,
  isSoloMuteEnabled: true,
  showFftGraphs: true,
  onSoloChange: () => {},
  onMuteChange: () => {},
  className: '',
}

export default TrackControls
