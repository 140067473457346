/* eslint no-underscore-dangle: 0 */
import { MIN_DECIBEL } from 'src/constants.js'

// TODO: Make these constants in constants.js?
const FFT_SIZE = 1024
const NUM_BANDS = 8

/**
 * Creates and returns an analyser
 */
export const createAnalyser = context => {
  const analyser = context.createAnalyser()
  analyser.fftSize = FFT_SIZE
  analyser.minDecibels = MIN_DECIBEL
  analyser.maxDecibels = 0

  return analyser
}

/**
 * Returns a function that fetches and returns FFT data for
 * a given audio node
 */
export const createTrackFftSource = (context, node) => {
  const analyser = createAnalyser(context)
  const fftData = new Float32Array(analyser.fftSize / 2)
  node.connect(analyser)

  return () => {
    analyser.getFloatFrequencyData(fftData)
    return octaveAverage(fftData, NUM_BANDS, 3)
  }
}

/**
 * Returns an array of FFT data grouped by octave bands, converting it
 * from linearily distributed bands provided by the input array
 */
export const octaveAverage = (fftData, numBands = 8, pow = 3) => {
  const averages = []

  const numSlices = Math.pow(pow, numBands) - 1
  const sliceSize = fftData.length / numSlices

  let fftIndex = 0

  for (let i = 0; i < numBands; i++) {
    let numFftBins = Math.max(1, Math.round(Math.pow(pow, i) * sliceSize))

    if (i === numBands - 1) {
      numFftBins = fftData.length - fftIndex
    }

    const slice = fftData.slice(fftIndex, fftIndex + numFftBins)

    // if (debug) {
    //   console.log('slice', i, '-', numSlices, sliceSize, '->', fftIndex, numFftBins);
    //   console.log('freqs:', (fftIndex / fftData.length) * 20000, ((fftIndex + numFftBins) / fftData.length) * 20000)
    // }

    averages[i] = Math.max(-120, getAverageDecibels(slice))

    fftIndex += numFftBins
  }

  // if (debug) {
  //   console.log({ numBands, averages });
  //   console.log(fftData);
  //   debug = false;
  // }

  return averages
}

/**
 * Calculates and returns the average decibel from an array
 * of dBFS values.
 */
const getAverageDecibels = dbs => {
  let sum = 0
  for (const db of dbs) {
    const amp = Math.pow(10, db / 20)
    sum += amp * amp
  }
  const rms = Math.sqrt(sum / (dbs.length / 2))
  const avgDb = 20 * Math.log10(rms)

  return avgDb
}
