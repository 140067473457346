import React from 'react'
import { connect } from 'react-redux'
import { withTranslators } from 'lioness'

export default function makeOnboardable(Component, tourStep, OnboardComponent) {
  const OnboardableComponent = connect((state, ownProps) => ({
    ...ownProps,
    completedActions: state.getIn(['onboarder', 'tours', tourStep.tour, 'completedActions']),
    prerequisiteActions: state.getIn(['onboarder', 'tours', tourStep.tour, 'prerequisiteActions']),
  }))(
    withTranslators(
      props =>
        tourStep.predicate(props) &&
        props.prerequisiteActions.every(actionCompleted => actionCompleted === true) ? (
          <OnboardComponent {...props} />
        ) : (
          <Component {...props} />
        )
    )
  )

  OnboardableComponent.displayName = `makeOnboardable(${Component.displayName || Component.name})`
  return OnboardableComponent
}
