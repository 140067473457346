import React from 'react'

import SvgIcon from './SvgIcon.js'

export default () => (
  <SvgIcon>
    <polygon points="-37,27.858 -37,-0.619 -11.371,13.618 " />
    <polygon points="22,7 22,15.042 8,7.265 8,25.214 22,17.436 22,26 25,26 25,7 " />
    <polygon points="0,47.392 -14,55.169 -14,47 -17,47 -17,66 -14,66 -14,57.564 0,65.343 " />
    <g>
      <rect x="69" y="30" width="7" height="26" />
      <rect x="57" y="30" width="7" height="26" />
    </g>
    <rect x="23" y="85" width="34" height="34" />
  </SvgIcon>
)
