import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { T } from 'lioness'
import { connect } from 'react-redux'

import { sendPatientAudiograms } from 'src/actions/consultant.actions.js'
import Button from 'src/components/Button.js'

/**
 * Send Audiograms Button
 */
class SendAudiogramsButton extends PureComponent {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
    isPending: PropTypes.bool.isRequired,
    onSend: PropTypes.func.isRequired,
  }

  render() {
    const { patientId, onSend, isPending, ...props } = this.props

    return (
      <Button onClick={() => onSend(patientId)} isEnabled={isPending === false} {...props}>
        {isPending === true ? <T>Sending...</T> : <T>Send audiograms</T>}
      </Button>
    )
  }
}

export default connect(
  state => ({
    isPending: state.getIn(['consultant', 'sendAudiograms', 'isPending']),
  }),
  dispatch => ({
    onSend: patientId => dispatch(sendPatientAudiograms(patientId)),
  })
)(SendAudiogramsButton)
