import React from 'react'
import cx from 'classnames'

import './VHASplitPane.scss'

export function VHASplitPane({ children, className, ...props }) {
  return (
    <div className={cx('VHASplitPane', className)} {...props}>
      {children}
    </div>
  )
}

export function Pane({ children, isActive = true, ...props }) {
  return (
    <div className={cx('Pane', { isActive })} {...props}>
      {children}
    </div>
  )
}
