import React from 'react'

import './DevGrid.scss'

export default () => (
  <div className="DevGrid">
    <div className="DevGrid-row">
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
      <div className="DevGrid-col" />
    </div>
  </div>
)
