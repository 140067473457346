import { AppEnvironments } from './constants.js'

let appEnv

switch (process.env.APP_ENV) {
  case AppEnvironments.PRODUCTION:
    appEnv = AppEnvironments.PRODUCTION
    break
  case AppEnvironments.STAGE:
    appEnv = AppEnvironments.STAGE
    break
  case AppEnvironments.DEVELOPMENT:
    appEnv = AppEnvironments.DEVELOPMENT
    break
  case AppEnvironments.LOCAL:
  default:
    appEnv = AppEnvironments.LOCAL
}

console.log({ appEnv }, process.env.APP_ENV)

export default appEnv
