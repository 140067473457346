/**
 * Selects the logged in user's token
 */
export const selectAuthToken = state => state.getIn(['auth', 'token'])

/**
 * Selects the logged in user's id
 */
export const selectUserId = state => state.getIn(['user', 'user', '_id'])

/**
 * Selects the a song's tracks
 */
export const selectSongTracks = songId => state =>
  state
    .get('songs')
    .find(x => x.get('id') === songId)
    .get('tracks')
