import { fromJS } from 'immutable'

import { ActionTypes, VisualizerTypes, VisualizerModes } from 'src/constants.js'

const initialState = fromJS({
  isEnabled: false,
  type: VisualizerTypes.LINES,
  mode: VisualizerModes.ADDITIVE,
  mirrorGraphs: false,
  renderSilence: false,
  setEdgesToZero: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_VISUALIZER_ENABLED:
      return state.set('isEnabled', action.payload)
    case ActionTypes.SET_VISUALIZER_TYPE:
      return state.set('type', action.payload)
    case ActionTypes.SET_VISUALIZER_MODE:
      return state.set('mode', action.payload)
    case ActionTypes.SET_VISUALIZER_MIRRORING:
      return state.set('mirrorGraphs', action.payload)
    case ActionTypes.SET_VISUALIZER_SILENCE_RENDERING:
      return state.set('renderSilence', action.payload)
    case ActionTypes.SET_VISUALIZER_ZERO_EDGES:
      return state.set('setEdgesToZero', action.payload)
    default:
      return state
  }
}
