import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { fromJS } from 'immutable'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'
import cx from 'classnames'
import { includes, values } from 'lodash'
import { autobind } from 'core-decorators'

import {
  Ears,
  HearingAidFittings,
  HearingLossGrades,
  ListeningMethods,
  NavigationDirection,
  Positions,
} from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import { startMainOnboarding } from 'src/actions/onboarder.actions.js'
import AlbumCover from 'src/components/AlbumCover.js'
import AudiogramForm from 'src/components/AudiogramForm.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import Icon from 'src/components/Icon.js'
import { AnimatedTooltip, TooltipContent, TooltipActions } from 'src/components/Tooltip.js'
import Checkbox from 'src/components/forms/Checkbox.js'
import { RadioList, RadioListOption } from 'src/components/forms/RadioList.js'
import ListeningMethodPicker from 'src/components/vha/ListeningMethodPicker.js'
import { isValidHearingLossCode, getEmptyAudiogram } from 'src/utils/audiogram-presets.js'

import './HomeMiniTour.scss'

const miniTourSongs = [
  { songId: 29, genre: 'Pop' },
  { songId: 13, genre: 'Rock' },
  { songId: 43, genre: 'Jazz' },
]

const SongGenreName = ({ genre }) => {
  switch (genre) {
    case 'Pop':
      return <T>Pop</T>
    case 'Jazz':
      return <T>Jazz</T>
    case 'Rock':
      return <T>Rock</T>
    default:
      return null
  }
}

const isHearingLossGrade = grade => {
  return includes(values(HearingLossGrades), grade)
}

const ListeningMethodStep = ({ listeningMethod, onListeningMethodChange, onNavigate }) => (
  <div className="HomeMiniTour-step HomeMiniTour-step-1">
    <div className="HomeMiniTour-step-inner">
      <h3 className="HomeMiniTour-step-title">
        <T>First, what kind of audio system are you using?</T>
      </h3>

      <div className={cx('HomeMiniTour-form', { hasSelected: listeningMethod !== null })}>
        <ListeningMethodPicker
          listeningMethod={listeningMethod}
          isInverted
          onChange={onListeningMethodChange}
        />
      </div>

      <div className="HomeMiniTour-stepNavigator">
        <div className="HomeMiniTour-action">
          <Button
            loudness={Loudness.WHISPER}
            isEnabled={listeningMethod !== null}
            onClick={() => onNavigate(NavigationDirection.FORWARD)}
          >
            <T>Go to next step →</T>
          </Button>
        </div>
      </div>
    </div>
  </div>
)

class HearingLossEarForm extends Component {
  static propTypes = {
    ear: PropTypes.oneOf(values(Ears)).isRequired,
    hearing: ImmutablePropTypes.mapContains({
      type: PropTypes.oneOf(values(HearingAidFittings)).isRequired,
      value: PropTypes.any,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    type: null,
    value: null,
    hasCodeError: false,
    isAudiogramModalOpen: false,
  }

  @autobind
  handleChange(inputValue) {
    const { onChange } = this.props

    let type
    let value
    let isAudiogramModalOpen = false

    if (isHearingLossGrade(inputValue)) {
      type = HearingAidFittings.PRESETS
      value = inputValue
    } else if (inputValue === HearingAidFittings.CODES) {
      type = HearingAidFittings.CODES
      value = null
    } else if (inputValue === HearingAidFittings.AUDIOGRAMS) {
      type = HearingAidFittings.AUDIOGRAMS
      value = fromJS(getEmptyAudiogram())
      isAudiogramModalOpen = true
    }

    this.setState(
      () => ({ type, value, isAudiogramModalOpen }),
      () => {
        onChange(type, value)
      }
    )
  }

  @autobind
  handleCode(inputCode) {
    const { onChange } = this.props
    const { type } = this.state

    const code = inputCode.toUpperCase()
    const isValidCode = isValidHearingLossCode(code)

    this.setState(
      () => ({ value: code, hasCodeError: isValidCode === false }),
      () => {
        onChange(type, code)
      }
    )
  }

  @autobind
  handleAudiogramValueChange(frequency, frequencyValue) {
    const { onChange } = this.props
    const { type, value } = this.state

    this.setState(
      () => ({
        value: value.set(frequency, frequencyValue),
      }),
      () => {
        onChange(type, this.state.value)
      }
    )
  }

  @autobind
  setAudiogramModalOpened(isOpen) {
    this.setState(() => ({ isAudiogramModalOpen: isOpen }))
  }

  render() {
    const { ear, hearing, t } = this.props
    const { type, value, hasCodeError, isAudiogramModalOpen } = this.state

    return (
      <RadioList defaultValue={hearing.get('value')} onChange={this.handleChange}>
        <RadioListOption isInverted value={HearingLossGrades.NONE}>
          <T>No hearing loss</T>
        </RadioListOption>
        <RadioListOption isInverted value={HearingLossGrades.MILD}>
          <T>Mild hearing loss</T>
        </RadioListOption>
        <RadioListOption isInverted value={HearingLossGrades.MODERATE}>
          <T>Moderate hearing loss</T>
        </RadioListOption>
        <RadioListOption isInverted value={HearingLossGrades.SEVERE}>
          <T>Severe hearing loss</T>
        </RadioListOption>
        <RadioListOption isInverted value={HearingLossGrades.PROFOUND}>
          <T>Profound hearing loss</T>
        </RadioListOption>
        <RadioListOption
          isInverted
          value={HearingAidFittings.CODES}
          isTextClickable={type !== HearingAidFittings.CODES}
        >
          {type === HearingAidFittings.CODES ? (
            <input
              type="text"
              autoFocus
              placeholder={t('Type your code...')}
              value={value || ''}
              onChange={evt => this.handleCode(evt.target.value)}
              className={cx({
                isValid: isValidHearingLossCode(value),
                hasError: value && value.length >= 2 && hasCodeError,
              })}
            />
          ) : (
            <span>
              <T>Input hearing loss code...</T>
            </span>
          )}
        </RadioListOption>
        <RadioListOption isInverted isEnabled={true} value={HearingAidFittings.AUDIOGRAMS}>
          {type === HearingAidFittings.AUDIOGRAMS ? (
            <div>
              <AnimatedTooltip
                isVisible={isAudiogramModalOpen}
                position={Positions.TOP}
                alignment={{ x: ear === Ears.LEFT ? Positions.LEFT : Positions.RIGHT }}
                offset={{ top: -20, right: ear === Ears.RIGHT ? -20 : 0 }}
                className="AudiogramTooltip"
              >
                <TooltipContent>
                  <h3>
                    <T>Input audiogram here</T>
                  </h3>
                  <p>
                    <T>Some info</T>
                  </p>

                  <AudiogramForm
                    ear={ear}
                    values={value}
                    onValueChange={this.handleAudiogramValueChange}
                    data-debug={{ values: value.toJS() }}
                  />
                </TooltipContent>

                <TooltipActions>
                  <Button
                    onClick={() => this.setAudiogramModalOpened(false)}
                    loudness={Loudness.YELL}
                    size={Size.SMALL}
                  >
                    <T>Save</T>
                  </Button>
                </TooltipActions>
              </AnimatedTooltip>

              <span onClick={() => this.setAudiogramModalOpened(true)}>
                {value.toArray().join(', ')}
              </span>
            </div>
          ) : (
            <span>
              <T>Input audiogram...</T>
            </span>
          )}
        </RadioListOption>
      </RadioList>
    )
  }
}

HearingLossEarForm = withTranslators(HearingLossEarForm)

const HearingLossInfoStep = ({ hearing, onHearingLevelChange, hasSufficientInfo, onNavigate }) => {
  const leftValue = hearing.getIn([Ears.LEFT, 'value'])
  const rightValue = hearing.getIn([Ears.RIGHT, 'value'])

  return (
    <div className="HomeMiniTour-step HomeMiniTour-step-2">
      <h3 className="HomeMiniTour-step-title">
        <T>Next, tell us about your hearing</T>
      </h3>
      <div className="HomeMiniTour-step-subtitle">
        <T>(You can always change this later.)</T>
      </div>

      <div className="HomeMiniTour-earForms">
        <div
          className={cx('HomeMiniTour-earForm', 'HomeMiniTour-form', {
            hasSelected: leftValue !== '',
          })}
        >
          <div className="HomeMiniTour-earForm-ear">
            <label>
              <T>Left ear</T>
            </label>
            <Icon name="earLeft" />
          </div>

          <HearingLossEarForm
            ear={Ears.LEFT}
            hearing={hearing.get(Ears.LEFT)}
            onChange={(type, value) => onHearingLevelChange(Ears.LEFT, type, value)}
          />

          <PresetVolumeWarning hearingLossGrade={leftValue} />
        </div>

        <div className={cx('HomeMiniTour-earForm', { hasSelected: rightValue !== '' })}>
          <div className="HomeMiniTour-earForm-ear">
            <label>
              <T>Right ear</T>
            </label>
            <Icon name="earRight" />
          </div>

          <HearingLossEarForm
            ear={Ears.RIGHT}
            hearing={hearing.get(Ears.RIGHT)}
            onChange={(type, value) => onHearingLevelChange(Ears.RIGHT, type, value)}
          />

          <PresetVolumeWarning hearingLossGrade={hearing.getIn([Ears.RIGHT, 'value'])} />
        </div>
      </div>

      <div className="HomeMiniTour-stepNavigator">
        <div className="HomeMiniTour-action">
          <Button
            loudness={Loudness.WHISPER}
            isEnabled
            onClick={() => onNavigate(NavigationDirection.BACK)}
          >
            <T>← Provide listening method</T>
          </Button>
        </div>

        <div className="HomeMiniTour-action">
          <Button
            loudness={Loudness.WHISPER}
            isEnabled={hasSufficientInfo}
            onClick={() => onNavigate(NavigationDirection.FORWARD)}
          >
            <T>Next, pick a genre →</T>
          </Button>
        </div>
      </div>
    </div>
  )
}

const GenrePickerStep = ({ songs, currentSongId, onSelectSong, onNavigate, onPlay }) => {
  return (
    <div className="HomeMiniTour-step HomeMiniTour-step-3">
      <div className="HomeMiniTour-step-inner">
        <h3 className="HomeMiniTour-step-title">
          <T>Now, pick a genre you want to listen to</T>
        </h3>

        <div className={cx('HomeMiniTour-songList', { hasSelected: currentSongId !== null })}>
          {songs
            .map(song => (
              <div
                key={song.get('id')}
                className={cx('HomeMiniTour-songList-item', {
                  isSelected: currentSongId === song.get('id'),
                })}
                onClick={() => onSelectSong(song.get('id'))}
              >
                <div className="HomeMiniTour-songList-selectItem">
                  <div className="HomeMiniTour-songList-item-image">
                    <AlbumCover src={song.get('image')} />
                  </div>

                  <div className="HomeMiniTour-songList-item-info">
                    <span className="HomeMiniTour-songList-item-info-genre">
                      <SongGenreName
                        genre={miniTourSongs.filter(x => x.songId === song.get('id')).pop().genre}
                      />
                    </span>

                    <span className="HomeMiniTour-songList-item-info-artist">
                      {song.get('artist')} - {song.get('title').replace(' (Karaoke Version)', '')}
                    </span>
                  </div>

                  <Checkbox isInverted isChecked={song.get('id') === currentSongId} />
                </div>
              </div>
            ))
            .toArray()}
        </div>

        <div className="HomeMiniTour-stepNavigator">
          <div className="HomeMiniTour-action">
            <Button
              loudness={Loudness.WHISPER}
              onClick={() => onNavigate(NavigationDirection.BACK)}
            >
              <T>← Go back</T>
            </Button>
          </div>

          <div className="HomeMiniTour-action">
            <Button
              isEnabled={currentSongId !== null}
              size={Size.NORMAL}
              loudness={Loudness.SCREAM}
              onClick={onPlay}
            >
              <T>Listen now</T>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const PresetVolumeWarning = withTranslators(({ hearingLossGrade, tc }) => {
  return (
    (hearingLossGrade === HearingLossGrades.SEVERE ||
      hearingLossGrade === HearingLossGrades.PROFOUND) && (
      <div className="HomeMiniTour-earForm-warning">
        {tc(
          `{{ strong:Warning: }} The {{ em:Severe }} and {{ em:Profound }} hearing loss options might cause very loud volumes. Be sure to lower the volume of your headphones before continuing.`,
          {
            strong: <strong />,
            em: <em />,
          }
        )}
      </div>
    )
  )
})

/**
 * Home Mini Tour
 */
class HomeMiniTour extends Component {
  static propTypes = {
    songs: ImmutablePropTypes.listOf(CustomPropTypes.song).isRequired,
    onPlaySong: PropTypes.func.isRequired,
  }

  state = {
    step: 1,
    listeningMethod: null,
    hearing: fromJS({
      [Ears.LEFT]: {
        type: HearingAidFittings.PRESETS,
        value: '',
      },
      [Ears.RIGHT]: {
        type: HearingAidFittings.PRESETS,
        value: '',
      },
    }),
    hasProvidedSufficientInfo: false,
    currentSongId: null,
  }

  @autobind
  goToStep(direction) {
    const { step, listeningMethod } = this.state

    let nextStep

    if (step === 1) {
      nextStep = listeningMethod === ListeningMethods.HEADPHONES ? 2 : 3
    } else if (step === 2) {
      nextStep = direction === NavigationDirection.BACK ? 1 : 3
    } else if (step === 3) {
      nextStep = listeningMethod === ListeningMethods.HEADPHONES ? 2 : 1
    }

    if (nextStep !== step) {
      this.setState(() => ({ step: nextStep }))
    }
  }

  @autobind
  handleListeningMethodChange(newListeningMethod) {
    const oldListeningMethod = this.state.listeningMethod

    this.setState(
      () => ({ listeningMethod: newListeningMethod }),
      () => {
        if (oldListeningMethod === null) {
          const nextStep = newListeningMethod === ListeningMethods.HEADPHONES ? 2 : 3
          window.setTimeout(() => this.goToStep(nextStep), 600)
        }
      }
    )
  }

  @autobind
  handleHearingLevelChange(ear, type, value) {
    const { hearing, hasProvidedSufficientInfo } = this.state

    const newHearing = hearing.update(ear, info => info.set('type', type).set('value', value))

    this.setState(
      () => ({
        hearing: newHearing,
        hasProvidedSufficientInfo:
          hasProvidedSufficientInfo || this.hasSufficientHearingInfo(newHearing),
      }),
      () => {
        if (
          this.state.hearing.every(earInfo => earInfo.get('type') === HearingAidFittings.PRESETS)
        ) {
          const hasSevereHeaingLoss = this.state.hearing.some(
            earInfo => earInfo.get('value') === HearingLossGrades.SEVERE
          )

          // Auto-navigate if suitable
          if (
            hasProvidedSufficientInfo === false &&
            this.state.hasProvidedSufficientInfo === true &&
            hasSevereHeaingLoss === false
          ) {
            window.setTimeout(() => this.goToStep(3), 600)
          }
        }
      }
    )
  }

  hasSufficientHearingInfo(hearing = this.state.hearing) {
    const hasEar = ear => {
      const { type, value } = hearing.get(ear).toObject()

      if (type === HearingAidFittings.PRESETS) {
        return includes(values(HearingLossGrades), value)
      } else if (type === HearingAidFittings.CODES) {
        return isValidHearingLossCode(value)
      } else if (type === HearingAidFittings.AUDIOGRAMS) {
        return value !== null
      }

      return false
    }

    return hasEar(Ears.LEFT) && hasEar(Ears.RIGHT)
  }

  @autobind
  setCurrentSongId(songId) {
    if (this.state.currentSongId !== songId) {
      this.setState(() => ({ currentSongId: songId }))
    } else {
      this.setState(() => ({ currentSongId: null }))
    }
  }

  render() {
    const { songs, onPlaySong } = this.props
    const { step, currentSongId, hearing, listeningMethod } = this.state

    return (
      <div className={cx('HomeMiniTour', `isAtStep-${step}`)}>
        <div className="HomeMiniTour-inner">
          <ListeningMethodStep
            listeningMethod={listeningMethod}
            onListeningMethodChange={this.handleListeningMethodChange}
            onNavigate={this.goToStep}
          />

          <HearingLossInfoStep
            key="step-1"
            step={1}
            currentStep={step}
            hearing={hearing}
            hasSufficientInfo={this.hasSufficientHearingInfo()}
            onHearingLevelChange={this.handleHearingLevelChange}
            onNavigate={this.goToStep}
          />

          <GenrePickerStep
            key="step-2"
            step={2}
            currentStep={step}
            songs={songs}
            currentSongId={currentSongId}
            onSelectSong={this.setCurrentSongId}
            onNavigate={this.goToStep}
            onPlay={() => onPlaySong(listeningMethod, hearing, currentSongId)}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    songs: state
      .getIn(['songs'])
      .filter(x => miniTourSongs.map(song => song.songId).includes(x.get('id'))),
  }),
  dispatch => ({
    onPlaySong: (listeningMethod, hearingInfo, songId) =>
      dispatch(startMainOnboarding(listeningMethod, hearingInfo, songId)),
  })
)(HomeMiniTour)
