import configs from 'src/configs.js'
import { Genres, SongTags } from 'src/constants.js'
import { getDefaultZForTrackWithNumber } from 'src/utils/track-controls.js'

const getTrackDefaults = trackNumber => ({
  volume: 0.8,
  position: { x: 0, y: 0, z: getDefaultZForTrackWithNumber(trackNumber) },
  isSoloed: false,
  isMuted: false,
})

const karaokeSongs = [
  {
    title: 'Whatever It Takes',
    artist: 'Imagine Dragons',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Imagine_Dragons_-_Whatever_It_Takes/Imagine_Dragons_-_Whatever_It_Takes-Cover.jpg`,
    lyricsUrl: '/media/whatever-it-takes.srt',
    length: 300,
    genre: [Genres.POP, Genres.ROCK, Genres.RAP],
    tags: [SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Whatever_It_Takes/Imagine_Dragons_-_Whatever_It_Takes-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Whatever_It_Takes/Imagine_Dragons_-_Whatever_It_Takes-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Whatever_It_Takes/Imagine_Dragons_-_Whatever_It_Takes-Drums`,
        trackNumber: 3,
        name: 'Drums',
      },
      Keys: {
        ...getTrackDefaults(4),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Whatever_It_Takes/Imagine_Dragons_-_Whatever_It_Takes-Keys`,
        trackNumber: 4,
        name: 'Keys',
      },
    },
  },
  {
    title: 'When Tomorrow Comes',
    artist: 'Eurythmics',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Eurythmics_-_When_Tomorrow_Comes/Eurythmics_-_When_Tomorrow_Comes-Cover.jpg`,
    lyricsUrl: '/media/when-tomorrow-comes.srt',
    length: 270,
    genre: [Genres.POP],
    tags: [SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Eurythmics_-_When_Tomorrow_Comes/Eurythmics_-_When_Tomorrow_Comes-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Eurythmics_-_When_Tomorrow_Comes/Eurythmics_-_When_Tomorrow_Comes-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Eurythmics_-_When_Tomorrow_Comes/Eurythmics_-_When_Tomorrow_Comes-Rhythm`,
        trackNumber: 3,
        name: 'Rhythm',
      },
      Other: {
        ...getTrackDefaults(4),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Eurythmics_-_When_Tomorrow_Comes/Eurythmics_-_When_Tomorrow_Comes-Other`,
        trackNumber: 4,
        name: 'Other',
      },
    },
  },
  {
    title: 'Roar',
    artist: 'Katy Perry',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Katy_Perry_-_Roar/Katy_Perry_-_Roar-Cover.jpg`,
    lyricsUrl: '/media/roar.srt',
    length: 225,
    genre: [Genres.POP],
    tags: [SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Katy_Perry_-_Roar/Katy_Perry_-_Roar-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${configs.s3.bucketUrl}/Katy_Perry_-_Roar/Katy_Perry_-_Roar-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${configs.s3.bucketUrl}/Katy_Perry_-_Roar/Katy_Perry_-_Roar-Drums`,
        trackNumber: 3,
        name: 'Drums',
      },
      Synths: {
        ...getTrackDefaults(4),
        id: 'Synths',
        filepath: `${configs.s3.bucketUrl}/Katy_Perry_-_Roar/Katy_Perry_-_Roar-Synths`,
        trackNumber: 4,
        name: 'Synths',
      },
    },
  },
  {
    title: 'Hallelujah',
    artist: 'Lenoard Cohen',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Leonard_Cohen_-_Hallelujah/Leonard_Cohen_-_Hallelujah-Cover.jpg`,
    lyricsUrl: '/media/hallelujah.srt',
    length: 296,
    genre: [Genres.ROCK, Genres.FOLK],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Leonard_Cohen_-_Hallelujah/Leonard_Cohen_-_Hallelujah-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Leonard_Cohen_-_Hallelujah/Leonard_Cohen_-_Hallelujah-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Leonard_Cohen_-_Hallelujah/Leonard_Cohen_-_Hallelujah-Rhythm`,
        trackNumber: 3,
        name: 'Rhythm',
      },
      Other: {
        ...getTrackDefaults(4),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Leonard_Cohen_-_Hallelujah/Leonard_Cohen_-_Hallelujah-Other`,
        trackNumber: 4,
        name: 'Other',
      },
    },
  },
  {
    title: 'Enjoy the Silence',
    artist: 'Depeche Mode',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Depeche_Mode_-_Enjoy_The_Silence/Depeche_Mode_-_Enjoy_The_Silence-Cover.jpg`,
    lyricsUrl: '/media/enjoy-the-silence.srt',
    length: 254,
    genre: [Genres.POP],
    tags: [SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Depeche_Mode_-_Enjoy_The_Silence/Depeche_Mode_-_Enjoy_The_Silence-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Depeche_Mode_-_Enjoy_The_Silence/Depeche_Mode_-_Enjoy_The_Silence-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Synths: {
        ...getTrackDefaults(3),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Depeche_Mode_-_Enjoy_The_Silence/Depeche_Mode_-_Enjoy_The_Silence-Synths`,
        trackNumber: 3,
        name: 'Synths',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Depeche_Mode_-_Enjoy_The_Silence/Depeche_Mode_-_Enjoy_The_Silence-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Purple Rain',
    artist: 'Prince and the Revolution',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Prince_-_Purple_Rain/Prince_-_Purple_Rain-Cover.jpg`,
    lyricsUrl: '/media/purple-rain.srt',
    length: 274,
    genre: [Genres.POP],
    tags: [SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Prince_-_Purple_Rain/Prince_-_Purple_Rain-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${configs.s3.bucketUrl}/Prince_-_Purple_Rain/Prince_-_Purple_Rain-Guitars`,
        trackNumber: 2,
        name: 'Guitars & Bass',
      },
      Piano_Strings: {
        ...getTrackDefaults(3),
        id: 'Piano_Strings',
        filepath: `${configs.s3.bucketUrl}/Prince_-_Purple_Rain/Prince_-_Purple_Rain-Piano_Strings`,
        trackNumber: 3,
        name: 'Piano & Strings',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${configs.s3.bucketUrl}/Prince_-_Purple_Rain/Prince_-_Purple_Rain-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'I Can See Clearly Now',
    artist: 'Jimmy Cliff',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Jimmy_Cliff_-_I_Can_See_Clearly_Now/Jimmy_Cliff_-_I_Can_See_Clearly_Now-Cover.jpg`,
    lyricsUrl: '/media/i-can-see-clearly-now.srt',
    length: 189,
    genre: [Genres.REGGAE],
    tags: [SongTags.EADY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Jimmy_Cliff_-_I_Can_See_Clearly_Now/Jimmy_Cliff_-_I_Can_See_Clearly_Now-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Jimmy_Cliff_-_I_Can_See_Clearly_Now/Jimmy_Cliff_-_I_Can_See_Clearly_Now-Guitars`,
        trackNumber: 2,
        name: 'Guitars & Bass',
      },
      Other: {
        ...getTrackDefaults(3),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Jimmy_Cliff_-_I_Can_See_Clearly_Now/Jimmy_Cliff_-_I_Can_See_Clearly_Now-Other`,
        trackNumber: 3,
        name: 'Piano, Organ (& Brass)',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Jimmy_Cliff_-_I_Can_See_Clearly_Now/Jimmy_Cliff_-_I_Can_See_Clearly_Now-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: "Superman (It's Not Easy)",
    artist: 'Five For Fighting',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Five_for_Fighting_-_Superman_(It_s_Not_Easy)/Five_for_Fighting_-_Superman_(It_s_Not_Easy)-Cover.jpg`,
    lyricsUrl: '/media/superman.srt',
    length: 222,
    genre: [Genres.POP],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Five_for_Fighting_-_Superman_(It_s_Not_Easy)/Five_for_Fighting_-_Superman_(It_s_Not_Easy)-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Five_for_Fighting_-_Superman_(It_s_Not_Easy)/Five_for_Fighting_-_Superman_(It_s_Not_Easy)-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Piano_Strings: {
        ...getTrackDefaults(3),
        id: 'Piano_Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Five_for_Fighting_-_Superman_(It_s_Not_Easy)/Five_for_Fighting_-_Superman_(It_s_Not_Easy)-Piano_Strings`,
        trackNumber: 3,
        name: 'Piano+Strings',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Five_for_Fighting_-_Superman_(It_s_Not_Easy)/Five_for_Fighting_-_Superman_(It_s_Not_Easy)-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Dancing Queen',
    artist: 'ABBA',
    featured: 0,
    image: `${configs.s3.bucketUrl}/ABBA_-_Dancing_Queen/ABBA_-_Dancing_Queen-Cover.jpg`,
    lyricsUrl: '/media/dancing-queen.srt',
    length: 237,
    genre: [Genres.POP],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/ABBA_-_Dancing_Queen/ABBA_-_Dancing_Queen-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${configs.s3.bucketUrl}/ABBA_-_Dancing_Queen/ABBA_-_Dancing_Queen-Bass_Guitar`,
        trackNumber: 2,
        name: 'Bass & Guitars',
      },
      Keys_Strings: {
        ...getTrackDefaults(3),
        id: 'Keys_Strings',
        filepath: `${configs.s3.bucketUrl}/ABBA_-_Dancing_Queen/ABBA_-_Dancing_Queen-Keys_Strings`,
        trackNumber: 3,
        name: 'Keys & Strings',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${configs.s3.bucketUrl}/ABBA_-_Dancing_Queen/ABBA_-_Dancing_Queen-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Half the World Away',
    artist: 'Aurora Aksnes',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Aurora_Aksnes_-_Half_The_World_Away/Aurora_Aksnes_-_Half_The_World_Away-Cover.jpg`,
    lyricsUrl: '/media/half-the-world-away.srt',
    length: 237,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Aurora_Aksnes_-_Half_The_World_Away/Aurora_Aksnes_-_Half_The_World_Away-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Keys: {
        ...getTrackDefaults(2),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/Aurora_Aksnes_-_Half_The_World_Away/Aurora_Aksnes_-_Half_The_World_Away-Keys`,
        trackNumber: 2,
        name: 'Keys',
      },
      Other: {
        ...getTrackDefaults(3),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Aurora_Aksnes_-_Half_The_World_Away/Aurora_Aksnes_-_Half_The_World_Away-Other`,
        trackNumber: 3,
        name: 'Other',
      },
      Rhythm: {
        ...getTrackDefaults(4),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Aurora_Aksnes_-_Half_The_World_Away/Aurora_Aksnes_-_Half_The_World_Away-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Thunder',
    artist: 'Imagine Dragons',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Imagine_Dragons_-_Thunder/Imagine_Dragons_-_Thunder-Cover.jpg`,
    lyricsUrl: '/media/thunder.srt',
    length: 237,
    genre: [Genres.POP, Genres.ROCK, Genres.RAP],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Thunder/Imagine_Dragons_-_Thunder-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Thunder/Imagine_Dragons_-_Thunder-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Synths: {
        ...getTrackDefaults(3),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Thunder/Imagine_Dragons_-_Thunder-Synths`,
        trackNumber: 3,
        name: 'Synths',
      },
      Rhythm: {
        ...getTrackDefaults(4),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Imagine_Dragons_-_Thunder/Imagine_Dragons_-_Thunder-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Comfortably Numb',
    artist: 'Pink Floyd',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Pink_Floyd_-_Comfortably_Numb/Pink_Floyd_-_Comfortably_Numb-Cover.jpg`,
    lyricsUrl: '/media/comfortably-numb.srt',
    length: 391,
    genre: [Genres.ROCK],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Pink_Floyd_-_Comfortably_Numb/Pink_Floyd_-_Comfortably_Numb-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Pink_Floyd_-_Comfortably_Numb/Pink_Floyd_-_Comfortably_Numb-Bass_Guitars`,
        trackNumber: 2,
        name: 'Bass & Guitars',
      },
      Synths: {
        ...getTrackDefaults(3),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Pink_Floyd_-_Comfortably_Numb/Pink_Floyd_-_Comfortably_Numb-Synths`,
        trackNumber: 3,
        name: 'Keys & Synth Pads',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Pink_Floyd_-_Comfortably_Numb/Pink_Floyd_-_Comfortably_Numb-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: '20th Century Boy',
    artist: 'T-Rex',
    featured: 0,
    image: `${configs.s3.bucketUrl}/T_Rex_-_20th_Century_Boy/T_Rex_-_20th_Century_Boy-Cover.jpg`,
    lyricsUrl: '/media/20th-century-boy.srt',
    length: 222,
    genre: [Genres.ROCK],
    tags: [SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/T_Rex_-_20th_Century_Boy/T_Rex_-_20th_Century_Boy-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/T_Rex_-_20th_Century_Boy/T_Rex_-_20th_Century_Boy-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Saxophone: {
        ...getTrackDefaults(3),
        id: 'Saxophone',
        filepath: `${
          configs.s3.bucketUrl
        }/T_Rex_-_20th_Century_Boy/T_Rex_-_20th_Century_Boy-Saxophone`,
        trackNumber: 3,
        name: 'Saxophone',
      },
      Rhythm: {
        ...getTrackDefaults(4),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/T_Rex_-_20th_Century_Boy/T_Rex_-_20th_Century_Boy-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'I Feel It Coming',
    artist: 'The Weeknd feat. Daft Punk',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/The_Weeknd_-_I_Feel_It_Coming/The_Weeknd_-_I_Feel_It_Coming-Cover.jpg`,
    lyricsUrl: '/media/i-feel-it-coming.srt',
    length: 271,
    genre: [Genres.POP, Genres.DANCE],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Weeknd_-_I_Feel_It_Coming/The_Weeknd_-_I_Feel_It_Coming-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Weeknd_-_I_Feel_It_Coming/The_Weeknd_-_I_Feel_It_Coming-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Keys: {
        ...getTrackDefaults(3),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Weeknd_-_I_Feel_It_Coming/The_Weeknd_-_I_Feel_It_Coming-Keys`,
        trackNumber: 3,
        name: 'Keys',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Weeknd_-_I_Feel_It_Coming/The_Weeknd_-_I_Feel_It_Coming-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Baby Can I Hold You',
    artist: 'Tracy Chapman',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Tracy_Chapman_-_Baby_Can_I_Hold_You/Tracy_Chapman_-_Baby_Can_I_Hold_You-Cover.jpg`,
    lyricsUrl: '/media/baby-can-i-hold-you.srt',
    length: 194,
    genre: [Genres.POP, Genres.FOLK],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Tracy_Chapman_-_Baby_Can_I_Hold_You/Tracy_Chapman_-_Baby_Can_I_Hold_You-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Tracy_Chapman_-_Baby_Can_I_Hold_You/Tracy_Chapman_-_Baby_Can_I_Hold_You-Guitars`,
        trackNumber: 2,
        name: 'Bass & Guitars',
      },
      Other: {
        ...getTrackDefaults(3),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Tracy_Chapman_-_Baby_Can_I_Hold_You/Tracy_Chapman_-_Baby_Can_I_Hold_You-Other`,
        trackNumber: 3,
        name: 'Keys & Strings',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Tracy_Chapman_-_Baby_Can_I_Hold_You/Tracy_Chapman_-_Baby_Can_I_Hold_You-Drums`,
        trackNumber: 4,
        name: 'Drums & Percussion',
      },
    },
  },
  {
    title: 'Stand By Me',
    artist: 'Ben E King',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Ben_E_King_-_Stand_By_Me/Ben_E_King_-_Stand_By_Me-Cover.jpg`,
    lyricsUrl: '/media/stand-by-me.srt',
    length: 182,
    genre: [Genres.SOUL, Genres.RNB],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Ben_E_King_-_Stand_By_Me/Ben_E_King_-_Stand_By_Me-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/Ben_E_King_-_Stand_By_Me/Ben_E_King_-_Stand_By_Me-Guitar`,
        trackNumber: 2,
        name: 'Guitar',
      },
      Strings: {
        ...getTrackDefaults(3),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Ben_E_King_-_Stand_By_Me/Ben_E_King_-_Stand_By_Me-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Rhythm: {
        ...getTrackDefaults(4),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Ben_E_King_-_Stand_By_Me/Ben_E_King_-_Stand_By_Me-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Come As You Are',
    artist: 'Nirvana',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Nirvana_-_Come_As_You_Are/Nirvana_-_Come_As_You_Are-Cover.jpg`,
    lyricsUrl: '/media/come-as-you-are.srt',
    length: 218,
    genre: [Genres.ROCK],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Nirvana_-_Come_As_You_Are/Nirvana_-_Come_As_You_Are-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Nirvana_-_Come_As_You_Are/Nirvana_-_Come_As_You_Are-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Bass: {
        ...getTrackDefaults(3),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Nirvana_-_Come_As_You_Are/Nirvana_-_Come_As_You_Are-Bass`,
        trackNumber: 3,
        name: 'Bass',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Nirvana_-_Come_As_You_Are/Nirvana_-_Come_As_You_Are-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'True',
    artist: 'Spandau Ballet',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Spandau_Ballet_-_True/Spandau_Ballet_-_True-Cover.jpg`,
    lyricsUrl: '/media/true.srt',
    length: 334,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Spandau_Ballet_-_True/Spandau_Ballet_-_True-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${configs.s3.bucketUrl}/Spandau_Ballet_-_True/Spandau_Ballet_-_True-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Other: {
        ...getTrackDefaults(3),
        id: 'Other',
        filepath: `${configs.s3.bucketUrl}/Spandau_Ballet_-_True/Spandau_Ballet_-_True-Other`,
        trackNumber: 3,
        name: 'Other',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${configs.s3.bucketUrl}/Spandau_Ballet_-_True/Spandau_Ballet_-_True-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Eye Of the Tiger',
    artist: 'Survivor',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Survivor_-_Eye_Of_The_Tiger/Survivor_-_Eye_Of_The_Tiger-Cover.jpg`,
    lyricsUrl: '/media/eye-of-the-tiger.srt',
    length: 249,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Survivor_-_Eye_Of_The_Tiger/Survivor_-_Eye_Of_The_Tiger-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Survivor_-_Eye_Of_The_Tiger/Survivor_-_Eye_Of_The_Tiger-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Piano: {
        ...getTrackDefaults(3),
        id: 'Piano',
        filepath: `${
          configs.s3.bucketUrl
        }/Survivor_-_Eye_Of_The_Tiger/Survivor_-_Eye_Of_The_Tiger-Piano`,
        trackNumber: 3,
        name: 'Piano',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Survivor_-_Eye_Of_The_Tiger/Survivor_-_Eye_Of_The_Tiger-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: "It Ain't Over Till It's Over",
    artist: 'Lenny Kravitz',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over-Cover.jpg`,
    lyricsUrl: '/media/it-aint-over-till-its-over.srt',
    length: 261,
    genre: [Genres.ROCK],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Strings: {
        ...getTrackDefaults(3),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over/Lenny_Kravitz_-_It_Ain_t_Over_Till_It_s_Over-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Lean On Me',
    artist: 'Bill Withers',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Bill_Withers_-_Lean_On_Me/Bill_Withers_-_Lean_On_Me-Cover.jpg`,
    lyricsUrl: '/media/lean-on-me.srt',
    length: 260,
    genre: [Genres.RNB],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Bill_Withers_-_Lean_On_Me/Bill_Withers_-_Lean_On_Me-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Bill_Withers_-_Lean_On_Me/Bill_Withers_-_Lean_On_Me-Bass_Guitar`,
        trackNumber: 2,
        name: 'Bass+Guitar',
      },
      Strings: {
        ...getTrackDefaults(3),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Bill_Withers_-_Lean_On_Me/Bill_Withers_-_Lean_On_Me-Piano_Strings`,
        trackNumber: 3,
        name: 'Piano+Strings',
      },
      Drums: {
        ...getTrackDefaults(4),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Bill_Withers_-_Lean_On_Me/Bill_Withers_-_Lean_On_Me-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'True Love',
    artist: 'Bing Crosby and Grace Kelly',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Bing_Crosby_-_True_Love/Bing_Crosby_-_True_Love-Cover.jpg`,
    lyricsUrl: '/media/true-love.srt',
    length: 184,
    genre: [Genres.JAZZ, Genres.BIGBAND],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Bing_Crosby_-_True_Love/Bing_Crosby_-_True_Love-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Bass: {
        ...getTrackDefaults(2),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Bing_Crosby_-_True_Love/Bing_Crosby_-_True_Love-Bass_Accordion`,
        trackNumber: 2,
        name: 'Bass+Accordion',
      },
      Strings: {
        ...getTrackDefaults(3),
        id: 'Strings',
        filepath: `${configs.s3.bucketUrl}/Bing_Crosby_-_True_Love/Bing_Crosby_-_True_Love-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Brass: {
        ...getTrackDefaults(4),
        id: 'Brass',
        filepath: `${
          configs.s3.bucketUrl
        }/Bing_Crosby_-_True_Love/Bing_Crosby_-_True_Love-Brass_Woodwinds`,
        trackNumber: 4,
        name: 'Brass+Woodwinds',
      },
    },
  },
  {
    title: 'All The Things You Are',
    artist: 'Ella Fitzgerald',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Ella_Fitzgerald_-_All_The_Things_You_Are/Ella_Fitzgerald_-_All_The_Things_You_Are-Cover.jpg`,
    lyricsUrl: '/media/all-of-the-things-you-are.srt',
    length: 197,
    genre: [Genres.JAZZ, Genres.BIGBAND],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Ella_Fitzgerald_-_All_The_Things_You_Are/Ella_Fitzgerald_-_All_The_Things_You_Are-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Piano: {
        ...getTrackDefaults(2),
        id: 'Piano',
        filepath: `${
          configs.s3.bucketUrl
        }/Ella_Fitzgerald_-_All_The_Things_You_Are/Ella_Fitzgerald_-_All_The_Things_You_Are-Piano_Guitar`,
        trackNumber: 2,
        name: 'Piano & Guitar',
      },
      Other: {
        ...getTrackDefaults(3),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Ella_Fitzgerald_-_All_The_Things_You_Are/Ella_Fitzgerald_-_All_The_Things_You_Are-Other`,
        trackNumber: 3,
        name: 'Brass & Strings',
      },
      Rhythm: {
        ...getTrackDefaults(4),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Ella_Fitzgerald_-_All_The_Things_You_Are/Ella_Fitzgerald_-_All_The_Things_You_Are-Rhythm`,
        trackNumber: 4,
        name: 'Drums & Bass',
      },
    },
  },
  {
    title: 'Jailhouse Rock',
    artist: 'Elvis Presley',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Elvis_Presley_-_Jailhouse_Rock/Elvis_Presley_-_Jailhouse_Rock-Cover.jpg`,
    lyricsUrl: '/media/jailhouse-rock.srt',
    length: 150,
    genre: [Genres.ROCK],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Elvis_Presley_-_Jailhouse_Rock/Elvis_Presley_-_Jailhouse_Rock-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Piano: {
        ...getTrackDefaults(2),
        id: 'Piano',
        filepath: `${
          configs.s3.bucketUrl
        }/Elvis_Presley_-_Jailhouse_Rock/Elvis_Presley_-_Jailhouse_Rock-Piano`,
        trackNumber: 2,
        name: 'Piano',
      },
      Guitars: {
        ...getTrackDefaults(3),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Elvis_Presley_-_Jailhouse_Rock/Elvis_Presley_-_Jailhouse_Rock-Guitars`,
        trackNumber: 3,
        name: 'Guitars',
      },
      Rhythm: {
        ...getTrackDefaults(4),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Elvis_Presley_-_Jailhouse_Rock/Elvis_Presley_-_Jailhouse_Rock-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Over the Rainbow',
    artist: 'Judy Garland',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Judy_Garland_Somewhere_Over_The_Rainbow/Judy_Garland_Somewhere_Over_The_Rainbow-Cover.jpg`,
    lyricsUrl: '/media/over-the-rainbow.srt',
    length: 227,
    genre: [Genres.BALLAD],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Judy_Garland_Somewhere_Over_The_Rainbow/Judy_Garland_Somewhere_Over_The_Rainbow-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Bass: {
        ...getTrackDefaults(2),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Judy_Garland_Somewhere_Over_The_Rainbow/Judy_Garland_Somewhere_Over_The_Rainbow-Bass_Bells`,
        trackNumber: 2,
        name: 'Bass+Bells',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Judy_Garland_Somewhere_Over_The_Rainbow/Judy_Garland_Somewhere_Over_The_Rainbow-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Brass: {
        ...getTrackDefaults(3),
        id: 'Brass',
        filepath: `${
          configs.s3.bucketUrl
        }/Judy_Garland_Somewhere_Over_The_Rainbow/Judy_Garland_Somewhere_Over_The_Rainbow-Brass_Woodwind`,
        trackNumber: 4,
        name: 'Brass+Woodwinds',
      },
    },
  },
  {
    title: "Can't Get You Out Of My Head",
    artist: 'Kylie Minogue',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head-Cover.jpg`,
    lyricsUrl: '/media/cant-get-you-out-of-my-head.srt',
    length: 231,
    genre: [Genres.POP],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Bass: {
        ...getTrackDefaults(2),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head-Bass_Organ`,
        trackNumber: 2,
        name: 'Bass & Organ',
      },
      Other: {
        ...getTrackDefaults(4),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head-Other`,
        trackNumber: 3,
        name: 'Synth Keys & Strings',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head/Kylie_Minogue_-_Can_t_Get_You_Out_Of_My_Head-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Heard It Through the Grapevine',
    artist: 'Marvin Gaye',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine-Cover.jpg`,
    lyricsUrl: '/media/heard-it-through-the-grapevine.srt',
    length: 198,
    genre: [Genres.SOUL, Genres.RNB],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine-Guitar_Piano`,
        trackNumber: 2,
        name: 'Guitar+Piano',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine-Strings_Horns`,
        trackNumber: 3,
        name: 'Strings+Horns',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine/Marvin_Gaye_-_I_Heard_It_Through_The_Grapevine-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Wonderful World',
    artist: 'Sam Cooke',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Sam_Cooke_-_Wonderful_World/Sam_Cooke_-_Wonderful_World-Cover.jpg`,
    lyricsUrl: '/media/wonderful-world.srt',
    length: 131,
    genre: [Genres.SOUL, Genres.RNB],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Sam_Cooke_-_Wonderful_World/Sam_Cooke_-_Wonderful_World-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Sam_Cooke_-_Wonderful_World/Sam_Cooke_-_Wonderful_World-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Sam_Cooke_-_Wonderful_World/Sam_Cooke_-_Wonderful_World-Piano_Strings`,
        trackNumber: 3,
        name: 'Strings+Piano',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Sam_Cooke_-_Wonderful_World/Sam_Cooke_-_Wonderful_World-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: "I'm Every Woman",
    artist: 'Whitney Houston',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Whitney_Houston_-_I_m_Every_Woman/Whitney_Houston_-_I_m_Every_Woman-Cover.jpg`,
    lyricsUrl: '/media/im-every-woman.srt',
    length: 291,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Whitney_Houston_-_I_m_Every_Woman/Whitney_Houston_-_I_m_Every_Woman-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Whitney_Houston_-_I_m_Every_Woman/Whitney_Houston_-_I_m_Every_Woman-Bass_Guitar`,
        trackNumber: 2,
        name: 'Bass+Guitar',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Whitney_Houston_-_I_m_Every_Woman/Whitney_Houston_-_I_m_Every_Woman-Keys_Strings`,
        trackNumber: 3,
        name: 'Strings+Keys',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Whitney_Houston_-_I_m_Every_Woman/Whitney_Houston_-_I_m_Every_Woman-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Hello',
    artist: 'Lionel Richie',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Lionel_Richie_-_Hello/Lionel_Richie_-_Hello-Cover.jpg`,
    lyricsUrl: '/media/hello.srt',
    length: 247,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Lionel_Richie_-_Hello/Lionel_Richie_-_Hello-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${configs.s3.bucketUrl}/Lionel_Richie_-_Hello/Lionel_Richie_-_Hello-Bass_Guitar`,
        trackNumber: 2,
        name: 'Bass+Guitar',
      },
      Other: {
        ...getTrackDefaults(4),
        id: 'Other',
        filepath: `${configs.s3.bucketUrl}/Lionel_Richie_-_Hello/Lionel_Richie_-_Hello-Other`,
        trackNumber: 3,
        name: 'Other',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${configs.s3.bucketUrl}/Lionel_Richie_-_Hello/Lionel_Richie_-_Hello-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'High and Dry',
    artist: 'Radiohead',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Radiohead_-_High_And_Dry/Radiohead_-_High_And_Dry-Cover.jpg`,
    lyricsUrl: '/media/high-and-dry.srt',
    length: 259,
    genre: [Genres.ROCK],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Radiohead_-_High_And_Dry/Radiohead_-_High_And_Dry-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Acoustic: {
        ...getTrackDefaults(2),
        id: 'Acoustic',
        filepath: `${
          configs.s3.bucketUrl
        }/Radiohead_-_High_And_Dry/Radiohead_-_High_And_Dry-AcousticGtr`,
        trackNumber: 2,
        name: 'Acoustic',
      },
      Electric: {
        ...getTrackDefaults(4),
        id: 'Electric',
        filepath: `${
          configs.s3.bucketUrl
        }/Radiohead_-_High_And_Dry/Radiohead_-_High_And_Dry-ElectricGtr`,
        trackNumber: 3,
        name: 'Electric',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Radiohead_-_High_And_Dry/Radiohead_-_High_And_Dry-Rhythm`,
        trackNumber: 4,
        name: 'Rhyhthm',
      },
    },
  },
  {
    title: 'I Knew You Were Waiting',
    artist: 'Aretha Franklin and George Michael',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Aretha_Franklin_I_Knew_You_Were_Waiting/Aretha_Franklin_I_Knew_You_Were_Waiting-Cover.jpg`,
    lyricsUrl: '/media/i-knew-you-were-waiting.srt',
    length: 244,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Aretha_Franklin_I_Knew_You_Were_Waiting/Aretha_Franklin_I_Knew_You_Were_Waiting-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Aretha_Franklin_I_Knew_You_Were_Waiting/Aretha_Franklin_I_Knew_You_Were_Waiting-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Synths: {
        ...getTrackDefaults(4),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Aretha_Franklin_I_Knew_You_Were_Waiting/Aretha_Franklin_I_Knew_You_Were_Waiting-Synths`,
        trackNumber: 3,
        name: 'Synths',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Aretha_Franklin_I_Knew_You_Were_Waiting/Aretha_Franklin_I_Knew_You_Were_Waiting-Rhythm`,
        trackNumber: 4,
        name: 'Rhyhthm',
      },
    },
  },
  {
    title: "Can't Get Enough",
    artist: 'Bad Company',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Bad_Company_-_Can_t_Get_Enough/Bad_Company_-_Can_t_Get_Enough-Cover.jpg`,
    lyricsUrl: '/media/cant-get-enough.srt',
    length: 254,
    genre: [Genres.ROCK],
    tags: [SongTags.NON_EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Bad_Company_-_Can_t_Get_Enough/Bad_Company_-_Can_t_Get_Enough-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Bad_Company_-_Can_t_Get_Enough/Bad_Company_-_Can_t_Get_Enough-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Bass: {
        ...getTrackDefaults(4),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Bad_Company_-_Can_t_Get_Enough/Bad_Company_-_Can_t_Get_Enough-Bass`,
        trackNumber: 3,
        name: 'Bass',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Bad_Company_-_Can_t_Get_Enough/Bad_Company_-_Can_t_Get_Enough-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: "You're The First, The Last, My Everything",
    artist: 'Barry White',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Barry_White_-_You_re_The_First_The_Last_My_Everything/Barry_White_-_You_re_The_First_The_Last_My_Everything-Cover.jpg`,
    lyricsUrl: '/media/youre-the-first-my-last-my-everything.srt',
    length: 196,
    genre: [Genres.SOUL],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Barry_White_-_You_re_The_First_The_Last_My_Everything/Barry_White_-_You_re_The_First_The_Last_My_Everything-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Barry_White_-_You_re_The_First_The_Last_My_Everything/Barry_White_-_You_re_The_First_The_Last_My_Everything-Keys_Guitars`,
        trackNumber: 2,
        name: 'Guitars+Keys',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Barry_White_-_You_re_The_First_The_Last_My_Everything/Barry_White_-_You_re_The_First_The_Last_My_Everything-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Barry_White_-_You_re_The_First_The_Last_My_Everything/Barry_White_-_You_re_The_First_The_Last_My_Everything-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'The Power of Love',
    artist: 'Franky Goes to Hollywood',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love-Cover.jpg`,
    lyricsUrl: '/media/the-power-of-love.srt',
    length: 329,
    genre: [Genres.BALLAD],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love-Piano_Guitar`,
        trackNumber: 2,
        name: 'Piano+Guitar',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love/Frankie_Goes_to_Hollywood_-_The_Power_Of_Love-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Everlasting Love',
    artist: 'Jamie Cullum',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Jamie_Cullum_-_Everlasting_Love/Jamie_Cullum_-_Everlasting_Love-Cover.jpg`,
    lyricsUrl: '/media/everlasting-love.srt',
    length: 209,
    genre: [Genres.JAZZ],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Jamie_Cullum_-_Everlasting_Love/Jamie_Cullum_-_Everlasting_Love-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Keys: {
        ...getTrackDefaults(2),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/Jamie_Cullum_-_Everlasting_Love/Jamie_Cullum_-_Everlasting_Love-Keys`,
        trackNumber: 2,
        name: 'Keys',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Jamie_Cullum_-_Everlasting_Love/Jamie_Cullum_-_Everlasting_Love-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Jamie_Cullum_-_Everlasting_Love/Jamie_Cullum_-_Everlasting_Love-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Dear Jessie',
    artist: 'Madonna',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Madonna_-_Dear_Jessie/Madonna_-_Dear_Jessie-Cover.jpg`,
    lyricsUrl: '/media/dear-jessie.srt',
    length: 264,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Madonna_-_Dear_Jessie/Madonna_-_Dear_Jessie-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${configs.s3.bucketUrl}/Madonna_-_Dear_Jessie/Madonna_-_Dear_Jessie-Guitar`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Keys: {
        ...getTrackDefaults(4),
        id: 'Keys',
        filepath: `${configs.s3.bucketUrl}/Madonna_-_Dear_Jessie/Madonna_-_Dear_Jessie-Keys`,
        trackNumber: 3,
        name: 'Keys',
      },
      Other: {
        ...getTrackDefaults(3),
        id: 'Other',
        filepath: `${configs.s3.bucketUrl}/Madonna_-_Dear_Jessie/Madonna_-_Dear_Jessie-Other`,
        trackNumber: 4,
        name: 'Orchestra & Percussion',
      },
    },
  },
  {
    title: 'You Got It',
    artist: 'Roy Orbison',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Roy_Orbison_-_You_Got_It/Roy_Orbison_-_You_Got_It-Cover.jpg`,
    lyricsUrl: '/media/you-got-it.srt',
    length: 209,
    genre: [Genres.BLUES, Genres.COUNTRY],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Roy_Orbison_-_You_Got_It/Roy_Orbison_-_You_Got_It-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Roy_Orbison_-_You_Got_It/Roy_Orbison_-_You_Got_It-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Roy_Orbison_-_You_Got_It/Roy_Orbison_-_You_Got_It-Keys_Strings`,
        trackNumber: 3,
        name: 'Keys+Strings',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Roy_Orbison_-_You_Got_It/Roy_Orbison_-_You_Got_It-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Stop',
    artist: 'Sam Brown',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Sam_Brown_-_Stop/Sam_Brown_-_Stop-Cover.jpg`,
    lyricsUrl: '/media/stop.srt',
    length: 302,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Sam_Brown_-_Stop/Sam_Brown_-_Stop-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${configs.s3.bucketUrl}/Sam_Brown_-_Stop/Sam_Brown_-_Stop-Bass_Guitar`,
        trackNumber: 2,
        name: 'Bass+Guitar',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${configs.s3.bucketUrl}/Sam_Brown_-_Stop/Sam_Brown_-_Stop-Keys_Strings`,
        trackNumber: 3,
        name: 'Keys+Strings',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${configs.s3.bucketUrl}/Sam_Brown_-_Stop/Sam_Brown_-_Stop-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: "You're Still the One",
    artist: 'Shania Twain',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Shania_Twain_-_You_re_Still_The_One/Shania_Twain_-_You_re_Still_The_One-Cover.jpg`,
    lyricsUrl: '/media/youre-still-the-one.srt',
    length: 213,
    genre: [Genres.ROCK, Genres.COUNTRY],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Shania_Twain_-_You_re_Still_The_One/Shania_Twain_-_You_re_Still_The_One-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Shania_Twain_-_You_re_Still_The_One/Shania_Twain_-_You_re_Still_The_One-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Keys: {
        ...getTrackDefaults(4),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/Shania_Twain_-_You_re_Still_The_One/Shania_Twain_-_You_re_Still_The_One-Keys`,
        trackNumber: 3,
        name: 'Keys',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Shania_Twain_-_You_re_Still_The_One/Shania_Twain_-_You_re_Still_The_One-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Baby Love',
    artist: 'The Supremes',
    featured: 0,
    image: `${configs.s3.bucketUrl}/The_Supremes_-_Baby_Love/The_Supremes_-_Baby_Love-Cover.jpg`,
    lyricsUrl: '/media/baby-love.srt',
    length: 163,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Supremes_-_Baby_Love/The_Supremes_-_Baby_Love-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Supremes_-_Baby_Love/The_Supremes_-_Baby_Love-Bass_Guitar`,
        trackNumber: 2,
        name: 'Bass & Guitar',
      },
      Brass: {
        ...getTrackDefaults(4),
        id: 'Brass',
        filepath: `${configs.s3.bucketUrl}/The_Supremes_-_Baby_Love/The_Supremes_-_Baby_Love-Keys_Brass`,
        trackNumber: 3,
        name: 'Brass & Keys',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Supremes_-_Baby_Love/The_Supremes_-_Baby_Love-Drums`,
        trackNumber: 4,
        name: 'Drums & Percussion',
      },
    },
  },
  {
    title: "What's Love Got to Do With It",
    artist: 'Tina Turner',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Tina_Turner_What_s_Love_Got_To_Do_With_It/Tina_Turner_What_s_Love_Got_To_Do_With_It-Cover.jpg`,
    lyricsUrl: '/media/whats-love-got-to-do-with-it.srt',
    length: 238,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Tina_Turner_What_s_Love_Got_To_Do_With_It/Tina_Turner_What_s_Love_Got_To_Do_With_It-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Tina_Turner_What_s_Love_Got_To_Do_With_It/Tina_Turner_What_s_Love_Got_To_Do_With_It-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Synths: {
        ...getTrackDefaults(4),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Tina_Turner_What_s_Love_Got_To_Do_With_It/Tina_Turner_What_s_Love_Got_To_Do_With_It-Synths`,
        trackNumber: 3,
        name: 'Synths',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Tina_Turner_What_s_Love_Got_To_Do_With_It/Tina_Turner_What_s_Love_Got_To_Do_With_It-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Mad About the Boy',
    artist: 'Dinah Washington',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Dinah_Washington_-_Mad_About_The_Boy/Dinah_Washington_-_Mad_About_The_Boy-Cover.jpg`,
    lyricsUrl: '/media/mad-about-the-boy.srt',
    length: 169,
    genre: [Genres.BLUES, Genres.JAZZ],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Dinah_Washington_-_Mad_About_The_Boy/Dinah_Washington_-_Mad_About_The_Boy-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Dinah_Washington_-_Mad_About_The_Boy/Dinah_Washington_-_Mad_About_The_Boy-Guitar_Keys`,
        trackNumber: 2,
        name: 'Guitar+Keys',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Dinah_Washington_-_Mad_About_The_Boy/Dinah_Washington_-_Mad_About_The_Boy-Brass_Strings`,
        trackNumber: 3,
        name: 'Brass+Strings',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Dinah_Washington_-_Mad_About_The_Boy/Dinah_Washington_-_Mad_About_The_Boy-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Que Sera, Sera',
    artist: 'Doris Day',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)-Cover.jpg`,
    lyricsUrl: '/media/que-sera-sera.srt',
    length: 128,
    genre: [Genres.BIGBAND],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)-Strings_Brass`,
        trackNumber: 2,
        name: 'Synths',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)-Rhythm`,
        trackNumber: 3,
        name: 'Rhythm',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)/Doris_Day_-_Que_Sera_Sera_(Whatever_Will_Be_Will_Be)-Guitar_Mandolin`,
        trackNumber: 4,
        name: 'Guitar+Mandolin',
      },
    },
  },
  {
    title: 'Shape of You',
    artist: 'Ed Sheeran',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Ed_Sheeran_-_Shape_of_You/Ed_Sheeran_-_Shape_of_You-Cover.jpg`,
    lyricsUrl: '/media/shape-of-you.srt',
    length: 232,
    genre: [Genres.POP, Genres.FOLK],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Ed_Sheeran_-_Shape_of_You/Ed_Sheeran_-_Shape_of_You-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Marimba: {
        ...getTrackDefaults(4),
        id: 'Marimba',
        filepath: `${
          configs.s3.bucketUrl
        }/Ed_Sheeran_-_Shape_of_You/Ed_Sheeran_-_Shape_of_You-Marimba_Oboe`,
        trackNumber: 2,
        name: 'Marimba+Oboe',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Ed_Sheeran_-_Shape_of_You/Ed_Sheeran_-_Shape_of_You-Rhythm`,
        trackNumber: 3,
        name: 'Rhythm',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Ed_Sheeran_-_Shape_of_You/Ed_Sheeran_-_Shape_of_You-Guitars`,
        trackNumber: 4,
        name: 'Guitar+Mandolin',
      },
    },
  },
  {
    title: "C'mon Everybody",
    artist: 'Eddie Cochran',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Eddie_Cochran_-_C_mon_Everybody/Eddie_Cochran_-_C_mon_Everybody-Cover.jpg`,
    lyricsUrl: '/media/cmon-everybody.srt',
    length: 121,
    genre: [Genres.ROCK],
    tags: [SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Eddie_Cochran_-_C_mon_Everybody/Eddie_Cochran_-_C_mon_Everybody-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Eddie_Cochran_-_C_mon_Everybody/Eddie_Cochran_-_C_mon_Everybody-Guitar`,
        trackNumber: 2,
        name: 'Guitar',
      },
      Bass: {
        ...getTrackDefaults(4),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Eddie_Cochran_-_C_mon_Everybody/Eddie_Cochran_-_C_mon_Everybody-Bass`,
        trackNumber: 3,
        name: 'Bass',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Eddie_Cochran_-_C_mon_Everybody/Eddie_Cochran_-_C_mon_Everybody-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'In the Mood',
    artist: 'Glenn Miller',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Glenn_Miller_-_In_The_Mood/Glenn_Miller_-_In_The_Mood-Cover.jpg`,
    lyricsUrl: '/media/in-the-mood.srt',
    length: 215,
    genre: [Genres.JAZZ, Genres.BIGBAND],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.INSTRUMENTAL],
    tracks: {
      Brass: {
        ...getTrackDefaults(1),
        id: 'Brass',
        filepath: `${
          configs.s3.bucketUrl
        }/Glenn_Miller_-_In_The_Mood/Glenn_Miller_-_In_The_Mood-Brass`,
        trackNumber: 1,
        name: 'Brass',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Glenn_Miller_-_In_The_Mood/Glenn_Miller_-_In_The_Mood-Guitar`,
        trackNumber: 2,
        name: 'Guitar',
      },
      Bass: {
        ...getTrackDefaults(4),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Glenn_Miller_-_In_The_Mood/Glenn_Miller_-_In_The_Mood-Bass`,
        trackNumber: 3,
        name: 'Bass',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Glenn_Miller_-_In_The_Mood/Glenn_Miller_-_In_The_Mood-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Super Duper Love',
    artist: 'Joss Stone',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Joss_Stone_-_Super_Duper_Love/Joss_Stone_-_Super_Duper_Love-Cover.jpg`,
    lyricsUrl: '/media/super-duper-love.srt',
    length: 258,
    genre: [Genres.SOUL],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Joss_Stone_-_Super_Duper_Love/Joss_Stone_-_Super_Duper_Love-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Joss_Stone_-_Super_Duper_Love/Joss_Stone_-_Super_Duper_Love-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Keys: {
        ...getTrackDefaults(4),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/Joss_Stone_-_Super_Duper_Love/Joss_Stone_-_Super_Duper_Love-Keys`,
        trackNumber: 3,
        name: 'Keys',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Joss_Stone_-_Super_Duper_Love/Joss_Stone_-_Super_Duper_Love-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Born Free',
    artist: 'Matt Monro',
    featured: 0,
    image: `${configs.s3.bucketUrl}/Matt_Monro_-_Born_Free/Matt_Monro_-_Born_Free-Cover.jpg`,
    lyricsUrl: '/media/born-free.srt',
    length: 168,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${configs.s3.bucketUrl}/Matt_Monro_-_Born_Free/Matt_Monro_-_Born_Free-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${configs.s3.bucketUrl}/Matt_Monro_-_Born_Free/Matt_Monro_-_Born_Free-Strings`,
        trackNumber: 2,
        name: 'Orchestra',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${configs.s3.bucketUrl}/Matt_Monro_-_Born_Free/Matt_Monro_-_Born_Free-Rhythm`,
        trackNumber: 3,
        name: 'Bass & Drums',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Matt_Monro_-_Born_Free/Matt_Monro_-_Born_Free-Guitar_Accordion`,
        trackNumber: 4,
        name: 'Guitar & Accordion',
      },
    },
  },
  {
    title: 'Autumn Leaves',
    artist: 'Nat King Cole',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Nat_King_Cole_-_Autumn_Leaves/Nat_King_Cole_-_Autumn_Leaves-Cover.jpg`,
    lyricsUrl: '/media/autumn-leaves.srt',
    length: 160,
    genre: [Genres.JAZZ],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Nat_King_Cole_-_Autumn_Leaves/Nat_King_Cole_-_Autumn_Leaves-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Nat_King_Cole_-_Autumn_Leaves/Nat_King_Cole_-_Autumn_Leaves-Bass_Drums`,
        trackNumber: 2,
        name: 'Bass & Drums',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Nat_King_Cole_-_Autumn_Leaves/Nat_King_Cole_-_Autumn_Leaves-Piano_Strings`,
        trackNumber: 3,
        name: 'Piano & Strings',
      },
      Brass: {
        ...getTrackDefaults(2),
        id: 'Brass',
        filepath: `${
          configs.s3.bucketUrl
        }/Nat_King_Cole_-_Autumn_Leaves/Nat_King_Cole_-_Autumn_Leaves-Brass_Woodwinds`,
        trackNumber: 4,
        name: 'Brass & Woodwinds',
      },
    },
  },
  {
    title: 'Under the Bridge',
    artist: 'Red Hot Chili Peppers',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Red_Hot_Chili_Peppers_-_Under_The_Bridge/Red_Hot_Chili_Peppers_-_Under_The_Bridge-Cover.jpg`,
    lyricsUrl: '/media/under-the-bridge.srt',
    length: 266,
    genre: [Genres.ROCK],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Red_Hot_Chili_Peppers_-_Under_The_Bridge/Red_Hot_Chili_Peppers_-_Under_The_Bridge-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Red_Hot_Chili_Peppers_-_Under_The_Bridge/Red_Hot_Chili_Peppers_-_Under_The_Bridge-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Keys: {
        ...getTrackDefaults(4),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/Red_Hot_Chili_Peppers_-_Under_The_Bridge/Red_Hot_Chili_Peppers_-_Under_The_Bridge-Keys`,
        trackNumber: 3,
        name: 'Keys',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Red_Hot_Chili_Peppers_-_Under_The_Bridge/Red_Hot_Chili_Peppers_-_Under_The_Bridge-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'I Just Called to Say I Love You',
    artist: 'Stevie Wonder',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You-Cover.jpg`,
    lyricsUrl: '/media/i-just-called-to-say-i-love-you.srt',
    length: 264,
    genre: [Genres.POP, Genres.SOUL],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Synths: {
        ...getTrackDefaults(2),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You-Synths`,
        trackNumber: 2,
        name: 'Synths',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You/Stevie_Wonder_-_I_Just_Called_To_Say_I_Love_You-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'The Lady In Red',
    artist: 'Chris De Burgh',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Chris_De_Burgh_-_The_Lady_In_Red/Chris_De_Burgh_-_The_Lady_In_Red-Cover.jpg`,
    lyricsUrl: '/media/the-lady-in-red.srt',
    length: 259,
    genre: [Genres.BALLAD],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Chris_De_Burgh_-_The_Lady_In_Red/Chris_De_Burgh_-_The_Lady_In_Red-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Synths: {
        ...getTrackDefaults(2),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Chris_De_Burgh_-_The_Lady_In_Red/Chris_De_Burgh_-_The_Lady_In_Red-Synths_Guitar`,
        trackNumber: 2,
        name: 'Synths & Guitar',
      },
      Bass: {
        ...getTrackDefaults(4),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Chris_De_Burgh_-_The_Lady_In_Red/Chris_De_Burgh_-_The_Lady_In_Red-Bass`,
        trackNumber: 3,
        name: 'Bass',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Chris_De_Burgh_-_The_Lady_In_Red/Chris_De_Burgh_-_The_Lady_In_Red-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Vincent (Starry Starry Night)',
    artist: 'Don McClean',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Don_McLean_-_Vincent/Don_McLean_-_Vincent-Cover.jpg`,
    lyricsUrl: '/media/vincent.srt',
    length: 248,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Don_McLean_-_Vincent/Don_McLean_-_Vincent-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Don_McLean_-_Vincent/Don_McLean_-_Vincent-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Marimba: {
        ...getTrackDefaults(4),
        id: 'Marimba',
        filepath: `${
          configs.s3.bucketUrl
        }/Don_McLean_-_Vincent/Don_McLean_-_Vincent-Marimba_Strings`,
        trackNumber: 3,
        name: 'Marimba+Strings',
      },
      Synths: {
        ...getTrackDefaults(3),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/Don_McLean_-_Vincent/Don_McLean_-_Vincent-Synth_Accordion`,
        trackNumber: 4,
        name: 'Synths+Accordion',
      },
    },
  },
  {
    title: 'The Dock of the Bay',
    artist: 'Otis Redding',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay-Cover.jpg`,
    lyricsUrl: '/media/the-dock-of-the-bay.srt',
    length: 163,
    genre: [Genres.RNB],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Piano: {
        ...getTrackDefaults(4),
        id: 'Piano',
        filepath: `${
          configs.s3.bucketUrl
        }/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay-Piano_Other`,
        trackNumber: 3,
        name: 'Piano+Other',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay/Otis_Redding_-_(Sittin_On)_The_Dock_Of_The_Bay-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Angels',
    artist: 'Robbie Williams',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Robbie_Williams_-_Angels/Robbie_Williams_-_Angels-Cover.jpg`,
    lyricsUrl: '/media/angels.srt',
    length: 265,
    genre: [Genres.POP],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Robbie_Williams_-_Angels/Robbie_Williams_-_Angels-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Robbie_Williams_-_Angels/Robbie_Williams_-_Angels-Bass_Guitars`,
        trackNumber: 2,
        name: 'Bass & Guitars',
      },
      Other: {
        ...getTrackDefaults(4),
        id: 'Other',
        filepath: `${
          configs.s3.bucketUrl
        }/Robbie_Williams_-_Angels/Robbie_Williams_-_Angels-Other`,
        trackNumber: 3,
        name: 'Piano & Orchestra',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Robbie_Williams_-_Angels/Robbie_Williams_-_Angels-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Edelweiss',
    artist: 'Bill Lee',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/The_Sound_of_Music_-_Edelweiss/The_Sound_of_Music_-_Edelweiss-Cover.jpg`,
    lyricsUrl: '/media/edelweiss.srt',
    length: 153,
    genre: [Genres.BALLAD],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Sound_of_Music_-_Edelweiss/The_Sound_of_Music_-_Edelweiss-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Sound_of_Music_-_Edelweiss/The_Sound_of_Music_-_Edelweiss-Guitar_Piano`,
        trackNumber: 2,
        name: 'Guitar+Piano',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Sound_of_Music_-_Edelweiss/The_Sound_of_Music_-_Edelweiss-Strings_Flute`,
        trackNumber: 3,
        name: 'Strings+Flute',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/The_Sound_of_Music_-_Edelweiss/The_Sound_of_Music_-_Edelweiss-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'Feels Like Home',
    artist: 'Edwina Hayes',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Edwina_Hayes_-_Feels_Like_Home/Edwina_Hayes_-_Feels_Like_Home-Cover.jpg`,
    lyricsUrl: '/media/feels-like-home.srt',
    length: 257,
    genre: [Genres.POP, Genres.FOLK],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Edwina_Hayes_-_Feels_Like_Home/Edwina_Hayes_-_Feels_Like_Home-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/Edwina_Hayes_-_Feels_Like_Home/Edwina_Hayes_-_Feels_Like_Home-Guitar`,
        trackNumber: 2,
        name: 'Guitar',
      },
    },
  },
  {
    title: 'Love Me Tender',
    artist: 'Elvis Presley',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Elvis_Presley_-_Love_Me_Tender/Elvis_Presley_-_Love_Me_Tender-Cover.jpg`,
    lyricsUrl: '/media/love-me-tender.srt',
    length: 166,
    genre: [Genres.BALLAD],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Elvis_Presley_-_Love_Me_Tender/Elvis_Presley_-_Love_Me_Tender-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Backing_Vocals: {
        ...getTrackDefaults(1),
        id: 'Backing_Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Elvis_Presley_-_Love_Me_Tender/Elvis_Presley_-_Love_Me_Tender-Backing_Vocals`,
        trackNumber: 2,
        name: 'Backing Vocals',
      },
      Guitar: {
        ...getTrackDefaults(2),
        id: 'Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/Elvis_Presley_-_Love_Me_Tender/Elvis_Presley_-_Love_Me_Tender-Guitar`,
        trackNumber: 3,
        name: 'Guitar',
      },
    },
  },
  {
    title: 'You Are My Sunshine',
    artist: 'Gene Autry',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Gene_Autry_-_You_Are_My_Sunshine/Gene_Autry_-_You_Are_My_Sunshine-Cover.jpg`,
    lyricsUrl: '/media/you-are-my-sunshine.srt',
    length: 158,
    genre: [Genres.COUNTRY],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Gene_Autry_-_You_Are_My_Sunshine/Gene_Autry_-_You_Are_My_Sunshine-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Gene_Autry_-_You_Are_My_Sunshine/Gene_Autry_-_You_Are_My_Sunshine-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Strings: {
        ...getTrackDefaults(4),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/Gene_Autry_-_You_Are_My_Sunshine/Gene_Autry_-_You_Are_My_Sunshine-Strings`,
        trackNumber: 3,
        name: 'Strings',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Gene_Autry_-_You_Are_My_Sunshine/Gene_Autry_-_You_Are_My_Sunshine-Rhythm`,
        trackNumber: 4,
        name: 'Rhythm',
      },
    },
  },
  {
    title: 'A Different Corner',
    artist: 'George Michael',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/George_Michael_-_A_Different_Corner/George_Michael_-_A_Different_Corner-Cover.jpg`,
    lyricsUrl: '/media/a-different-corner.srt',
    length: 238,
    genre: [Genres.BALLAD],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/George_Michael_-_A_Different_Corner/George_Michael_-_A_Different_Corner-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Keys: {
        ...getTrackDefaults(2),
        id: 'Keys',
        filepath: `${
          configs.s3.bucketUrl
        }/George_Michael_-_A_Different_Corner/George_Michael_-_A_Different_Corner-Keys`,
        trackNumber: 2,
        name: 'Keys',
      },
      Synths: {
        ...getTrackDefaults(4),
        id: 'Synths',
        filepath: `${
          configs.s3.bucketUrl
        }/George_Michael_-_A_Different_Corner/George_Michael_-_A_Different_Corner-Synths`,
        trackNumber: 3,
        name: 'Synth Pads',
      },
      Bass_Guitar: {
        ...getTrackDefaults(3),
        id: 'Bass_Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/George_Michael_-_A_Different_Corner/George_Michael_-_A_Different_Corner-Bass_Guitar`,
        trackNumber: 4,
        name: 'Bass & Guitar',
      },
    },
  },
  {
    title: 'A Nightingale Sang In Berkeley Street',
    artist: 'Vera Lynn',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square-Cover.jpg`,
    lyricsUrl: '/media/a-nightingale-sang-in-berkeley-square.srt',
    length: 236,
    genre: [Genres.JAZZ],
    tags: [SongTags.EASY_LISTENING, SongTags.MEDIUM_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Piano_Guitar: {
        ...getTrackDefaults(2),
        id: 'Piano_Guitar',
        filepath: `${
          configs.s3.bucketUrl
        }/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square-Piano_Guitar`,
        trackNumber: 2,
        name: 'Piano & Guitar',
      },
      Orchestra: {
        ...getTrackDefaults(4),
        id: 'Orchestra',
        filepath: `${
          configs.s3.bucketUrl
        }/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square-Orchestra`,
        trackNumber: 3,
        name: 'Orchestra',
      },
      Rhythm: {
        ...getTrackDefaults(3),
        id: 'Rhythm',
        filepath: `${
          configs.s3.bucketUrl
        }/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square/Vera_Lynn_-_A_Nightingale_Sang_In_Berkeley_Square-Rhythm`,
        trackNumber: 4,
        name: 'Bass & Drums',
      },
    },
  },
  {
    title: 'The Joker',
    artist: 'Steve Miller Band',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/Steve_Miller_Band_-_The_Joker/Steve_Miller_Band_-_The_Joker-Cover.jpg`,
    lyricsUrl: '/media/the-joker.srt',
    length: 236,
    genre: [Genres.ROCK],
    tags: [SongTags.EASY_LISTENING, SongTags.VOCALIZED],
    tracks: {
      Vocals: {
        ...getTrackDefaults(1),
        id: 'Vocals',
        filepath: `${
          configs.s3.bucketUrl
        }/Steve_Miller_Band_-_The_Joker/Steve_Miller_Band_-_The_Joker-Vocals`,
        trackNumber: 1,
        name: 'Vocals',
      },
      Guitars: {
        ...getTrackDefaults(2),
        id: 'Guitars',
        filepath: `${
          configs.s3.bucketUrl
        }/Steve_Miller_Band_-_The_Joker/Steve_Miller_Band_-_The_Joker-Guitars`,
        trackNumber: 2,
        name: 'Guitars',
      },
      Bass: {
        ...getTrackDefaults(4),
        id: 'Bass',
        filepath: `${
          configs.s3.bucketUrl
        }/Steve_Miller_Band_-_The_Joker/Steve_Miller_Band_-_The_Joker-Bass`,
        trackNumber: 3,
        name: 'Bass',
      },
      Drums: {
        ...getTrackDefaults(3),
        id: 'Drums',
        filepath: `${
          configs.s3.bucketUrl
        }/Steve_Miller_Band_-_The_Joker/Steve_Miller_Band_-_The_Joker-Drums`,
        trackNumber: 4,
        name: 'Drums',
      },
    },
  },
  {
    title: 'Beethoven\'s Symphony No. 7',
    artist: 'NY Philharmonic',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/NY_Philharmonic_-_Beethoven_Symphony_7/NY_Philharmonic_-_Beethoven_Symphony_7-Cover.jpg`,
    lyricsUrl: '/media/the-joker.srt',
    length: 236,
    genre: [Genres.CLASSICAL],
    tags: [SongTags.EASY_LISTENING, SongTags.INSTRUMENTAL],
    tracks: {
      Brass: {
        ...getTrackDefaults(1),
        id: 'Brass',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Beethoven_Symphony_7/NY_Philharmonic_-_Beethoven_Symphony_7-Brass`,
        trackNumber: 1,
        name: 'Brass',
      },
      Strings: {
        ...getTrackDefaults(2),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Beethoven_Symphony_7/NY_Philharmonic_-_Beethoven_Symphony_7-Strings`,
        trackNumber: 2,
        name: 'Strings',
      },
      Timpani: {
        ...getTrackDefaults(4),
        id: 'Timpani',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Beethoven_Symphony_7/NY_Philharmonic_-_Beethoven_Symphony_7-Timpani`,
        trackNumber: 3,
        name: 'Timpani',
      },
      Woodwinds: {
        ...getTrackDefaults(3),
        id: 'Woodwinds',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Beethoven_Symphony_7/NY_Philharmonic_-_Beethoven_Symphony_7-Woodwinds`,
        trackNumber: 4,
        name: 'Woodwinds',
      },
    },
  },
  {
    title: 'Mozart\'s Jupiter Symphony',
    artist: 'NY Philharmonic',
    featured: 0,
    image: `${
      configs.s3.bucketUrl
    }/NY_Philharmonic_-_Mozart_Jupiter/NY_Philharmonic_-_Mozart_Jupiter-Cover.jpg`,
    lyricsUrl: '/media/the-joker.srt',
    length: 236,
    genre: [Genres.CLASSICAL],
    tags: [SongTags.EASY_LISTENING, SongTags.INSTRUMENTAL],
    tracks: {
      Brass: {
        ...getTrackDefaults(1),
        id: 'Brass',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Mozart_Jupiter/NY_Philharmonic_-_Mozart_Jupiter-Brass`,
        trackNumber: 1,
        name: 'Brass',
      },
      Strings: {
        ...getTrackDefaults(2),
        id: 'Strings',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Mozart_Jupiter/NY_Philharmonic_-_Mozart_Jupiter-Strings`,
        trackNumber: 2,
        name: 'Strings',
      },
      Timpani: {
        ...getTrackDefaults(4),
        id: 'Timpani',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Mozart_Jupiter/NY_Philharmonic_-_Mozart_Jupiter-Timpani`,
        trackNumber: 3,
        name: 'Timpani',
      },
      Woodwinds: {
        ...getTrackDefaults(3),
        id: 'Woodwinds',
        filepath: `${
          configs.s3.bucketUrl
        }/NY_Philharmonic_-_Mozart_Jupiter/NY_Philharmonic_-_Mozart_Jupiter-Woodwinds`,
        trackNumber: 4,
        name: 'Woodwinds',
      },
    },
  },
]

export default karaokeSongs
