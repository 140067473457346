import React, { Component } from 'react'

import AdminFeedbackList from 'src/components/admin/AdminFeedbackList.js'

/**
 * Admin Feedback View
 */
class AdminFeedbackView extends Component {
  render() {
    const {} = this.props

    return (
      <div className="AdminFeedbackView">
        <h2>Feedback</h2>

        <AdminFeedbackList />
      </div>
    )
  }
}

export default AdminFeedbackView
