import { connect } from 'react-redux'

import {
  setSelectedTrack,
  setTrackSoloed,
  setTrackMuted,
  setTrackVolume,
} from 'src/actions/mixer.actions.js'
import { hasLoadedSong } from 'src/reducers/player.reducer.js'
import Mixer from './Mixer.js'

/**
 * SongMixer is a Mixer with some action dispatch props
 * already hooked up.
 */
const SongMixer = connect(
  state => ({
    isEnabled: hasLoadedSong(state.get('player')),
    tracks: state.getIn(['mixer', 'tracks']),
    selectedTrackId: state.getIn(['mixer', 'selectedTrackId']),
  }),
  dispatch => ({
    onSelectTrack: track => dispatch(setSelectedTrack(track.get('id'))),
    onTrackSoloChange: (track, isSoloed) => dispatch(setTrackSoloed(track.get('id'), isSoloed)),
    onTrackMuteChange: (track, isMuted) => dispatch(setTrackMuted(track.get('id'), isMuted)),
    onTrackVolumeChange: (track, volume) => dispatch(setTrackVolume(track.get('id'), volume)),
  })
)(Mixer)

export default SongMixer
