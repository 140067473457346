import { fromJS } from 'immutable'
import { curry, sortBy } from 'lodash'

import songs from 'src/fixtures/songs.js'

const initialState = fromJS(sortBy(songs, ['featured', 'artist', 'title']))

const songsReducer = (state = initialState) => state

export default songsReducer

export const getSong = curry((id, state) => {
  return state.reduce((aggr, song) => (song.get('id') === id ? song : aggr))
})
