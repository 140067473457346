import { isNumber } from 'lodash'
import { fromJS, Set } from 'immutable'

let id = 0
let sources = new Set()
let subscribers = new Set()

/**
 * Notifies all subscribers with FFT data
 */
const loop = () => {
  subscribers.forEach(subscriber => {
    sources.forEach(source => {
      const track = source.get('track')
      const getFftData = source.get('getFftData')
      const predicate = subscriber.get('predicate')
      const callback = subscriber.get('callback')

      if (predicate(track)) {
        callback(getFftData(), track)
      }
    })
  })

  window.requestAnimationFrame(loop)
}

/**
 * Clears FFT sources
 */
export const clearFftSources = () => {
  sources = new Set()
}

/**
 * Stores an FFT data source for a given track
 */
export const setFftSourceForTrack = (track, getFftData) => {
  const fetcher = fromJS({ track, getFftData })
  sources = sources.add(fetcher)
}

/**
 * Stores a subscriber that will get FFT data for sources that
 * passes the given predicate.
 *
 * Returns an unsubscribe function.
 */
export const subscribe = (trackPredicate, callback) => {
  // If `trackPredicate` is a number, treat it as a track ID and
  // create a predicate function the compares tracks to that ID
  const predicate = isNumber(trackPredicate)
    ? track => track.get('id') === trackPredicate
    : trackPredicate

  // Create and add subscriber
  const subscriptionId = ++id
  const subscriber = fromJS({ id: subscriptionId, predicate, callback })
  subscribers = subscribers.add(subscriber)

  // Returns an unsubscribe function
  return () => {
    subscribers = subscribers.delete(subscriber)
  }
}

// TODO: Don't do this out here in the loose?
loop()
