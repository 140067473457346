import { take, put, call, select } from 'redux-saga/effects'

import {
  withToken,
  createUser,
  getMe as getMefromApi,
  deleteUser,
  updateUserSettings,
  resetUserPassword,
  sendUserProfile,
} from 'src/api.js'
import { ActionTypes, ErrorTypes } from 'src/constants.js'
import { login, logout } from 'src/actions/auth.actions.js'
import { displayErrorOfType } from 'src/actions/errors.actions.js'
import {
  createAccountSuccess,
  createAccountFailure,
  getMeSuccess,
  getMeFailure,
  resetMe,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateUserSettingsSuccess,
  updateUserSettingsError,
  resetPasswordError,
  resetPasswordSuccess,
  sendUserProfileSuccess,
  sendUserProfileError,
} from 'src/actions/user.actions.js'

function* handleCreateAccount() {
  while (true) {
    const { payload: { firstName, lastName, email, password } } = yield take(
      ActionTypes.CREATE_ACCOUNT
    )

    try {
      const { data: user, errors } = yield call(
        createUser({ firstName, lastName, email, password })
      )

      if (errors) {
        yield put(createAccountFailure(errors))
      } else if (!user) {
        yield put(createAccountFailure(user))
      } else {
        yield put(createAccountSuccess(user))
      }
    } catch (err) {
      yield put(createAccountFailure(err))
    }
  }
}

function* displaySignUpErrors() {
  while (true) {
    const { payload: err } = yield take(ActionTypes.CREATE_ACCOUNT_ERROR)

    const errorType =
      err && err.length > 0 && err[0].code === ErrorTypes.EMAIL_ALREADY_EXISTS
        ? ErrorTypes.EMAIL_ALREADY_EXISTS
        : ErrorTypes.FAILED_TO_CREATE_ACCOUNT

    yield put(displayErrorOfType(errorType, err))
  }
}

function* loginAfterSignUp() {
  while (true) {
    const { payload: { email, password } } = yield take(ActionTypes.CREATE_ACCOUNT)
    const { type } = yield take([
      ActionTypes.CREATE_ACCOUNT_SUCCESS,
      ActionTypes.CREATE_ACCOUNT_ERROR,
    ])

    if (type === ActionTypes.CREATE_ACCOUNT_SUCCESS) {
      yield put(login({ email, password }))
    }
  }
}

/**
 * TODO: Take into consideration concurrency with f.e.
 * logout actions.
 */
function* doFetchMe() {
  while (true) {
    yield take(ActionTypes.GET_ME)

    const token = yield select(state => state.getIn(['auth', 'token']))
    const getUserUsingToken = yield call(withToken, getMefromApi, token)
    const { data: user, errors } = yield call(getUserUsingToken)

    if (errors || !user) {
      yield put(getMeFailure(errors))
    } else {
      yield put(getMeSuccess(user))
    }
  }
}

function* resetMeAfterLogout() {
  while (true) {
    yield take(ActionTypes.LOGOUT)
    yield put(resetMe())
  }
}

/**
 * Attempts to delete a user account
 */
function* doDeleteAccount() {
  while (true) {
    const { payload: { id } } = yield take(ActionTypes.DELETE_ACCOUNT)
    const token = yield select(state => state.getIn(['auth', 'token']))
    const deleteAccountUsingToken = yield call(withToken, deleteUser(id), token)

    const { errors } = yield call(deleteAccountUsingToken)

    if (errors) {
      yield put(deleteAccountFailure(errors))
    } else {
      // NOTE: A reverse order of these two actions causes an error
      // in `getNameFromEmail()` in `MeView`.
      //
      // TODO: Make it not so.
      yield put(logout())
      yield put(deleteAccountSuccess())
    }
  }
}

function* displayAccountDeletionErrors() {
  while (true) {
    const { payload: err } = yield take(ActionTypes.DELETE_ACCOUNT_ERROR)
    yield put(displayErrorOfType(ErrorTypes.FAILED_TO_DELETE_ACCOUNT, err))
  }
}

function* handleUpdateSettings() {
  while (true) {
    const { payload: { id, firstName, lastName } } = yield take(ActionTypes.UPDATE_USER_SETTINGS)

    const token = yield select(state => state.getIn(['auth', 'token']))
    const updateUserSettingsUsingToken = yield call(
      withToken,
      updateUserSettings({ id, firstName, lastName }),
      token
    )

    const { errors } = yield call(updateUserSettingsUsingToken)

    if (errors) {
      yield put(updateUserSettingsError(errors))
    } else {
      yield put(updateUserSettingsSuccess({ firstName, lastName }))
    }
  }
}

function* doResetPassword() {
  while (true) {
    try {
      const { payload: { userId } } = yield take(ActionTypes.RESET_PASSWORD)
      const token = yield select(state => state.getIn(['auth', 'token']))
      const resetPasswordWithToken = yield call(withToken, resetUserPassword(userId), token)

      const { errors } = yield call(resetPasswordWithToken)

      if (Array.isArray(errors) && errors.length > 0) {
        yield put(resetPasswordError(errors[0]))
      } else {
        yield put(resetPasswordSuccess())
      }
    } catch (err) {
      yield put(resetPasswordError(err))
    }
  }
}

function* doSendUserProfile() {
  while (true) {
    const { payload: { id, recipientEmail } } = yield take(ActionTypes.SEND_USER_PROFILE)

    const token = yield select(state => state.getIn(['auth', 'token']))
    const requestPayload = { userId: id, recipientEmail }
    const sendUserProfileUsingToken = yield call(withToken, sendUserProfile(requestPayload), token)

    const { errors } = yield call(sendUserProfileUsingToken)

    if (errors) {
      yield put(sendUserProfileError(errors))
      yield put(displayErrorOfType(ErrorTypes.FAILED_TO_SEND_USER_PROFILE, errors[0]))
    } else {
      yield put(sendUserProfileSuccess(requestPayload))
    }
  }
}

export default function*() {
  yield [
    handleCreateAccount(),
    displaySignUpErrors(),
    loginAfterSignUp(),
    doFetchMe(),
    resetMeAfterLogout(),
    doDeleteAccount(),
    displayAccountDeletionErrors(),
    handleUpdateSettings(),
    doResetPassword(),
    doSendUserProfile(),
  ]
}
