import React from 'react'

import SongPlayerItem from 'src/components/SongPlayerItem.js'

import './SongLink.scss'

const SongLink = ({ song }) => (
  <div className="SongLink">
    <SongPlayerItem song={song} />

    <h3 className="SongLink-title">{song.get('title')}</h3>
    <div className="SongLink-artist">{song.get('artist')}</div>
  </div>
)

SongLink.propTypes = {
  song: React.PropTypes.object.isRequired,
}

export default SongLink
