import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { isString } from 'lodash'
import { T } from 'lioness'
import * as FeatherIcon from 'react-feather'

import { OnboardingTours } from 'src/constants.js'
import Icon from 'src/components/Icon.js'
import UserNavigationLink from 'src/components/UserNavigationLink.js'
import SignUpBarkTooltip from 'src/components/barker/SignUpBarkTooltip.js'
import FeedbackButton from 'src/components/feedback/FeedbackButton.js'
import LocalePicker from 'src/components/l10n/LocalePicker.js'
import OnboardingTourStarter from 'src/components/onboarder/OnboardingTourStarter.js'
import { OnboardingStepHighlighterAnimation } from 'src/components/onboarder/OnboardingStepHighlighter.js'
import { isLoggedIn as getIsLoggedIn } from 'src/reducers/auth.reducer.js'
import { hasActiveTour, hasCompletedTour } from 'src/reducers/onboarder.reducer.js'
import { hasLoadedSong } from 'src/reducers/player.reducer.js'

import './Navigation.scss'

const navigationLinkProps = {
  className: 'Navigation-link',
  activeClassName: 'is-active',
}

const OnboardableMixerMenuItem = connect(state => ({
  isVisible:
    hasActiveTour(state.get('onboarder')) === false &&
    hasCompletedTour(state.get('onboarder'), OnboardingTours.MIXER) === false &&
    hasLoadedSong(state.get('player')),
}))(({ isVisible, children }) => (
  <span style={{ position: 'relative' }}>
    {isVisible === true && (
      <OnboardingTourStarter
        tour={OnboardingTours.MIXER}
        style={{
          position: 'absolute',
          top: 8,
          right: -6,
          zIndex: 10,
          margin: 0,
          padding: 0,
          background: 'none',
          border: 'none',
          WebkitAppearance: 'none',
        }}
      >
        <OnboardingStepHighlighterAnimation />
      </OnboardingTourStarter>
    )}

    {children}
  </span>
))

const OnboardableVhaMenuItem = connect(state => ({
  isVisible:
    hasActiveTour(state.get('onboarder')) === false &&
    hasCompletedTour(state.get('onboarder'), OnboardingTours.MIXER) === true &&
    hasCompletedTour(state.get('onboarder'), OnboardingTours.VHA) === false,
}))(({ isVisible, children }) => (
  <span style={{ position: 'relative' }}>
    {isVisible === true && (
      <OnboardingTourStarter
        tour={OnboardingTours.VHA}
        style={{
          position: 'absolute',
          top: 8,
          right: -6,
          zIndex: 10,
          margin: 0,
          padding: 0,
          background: 'none',
          border: 'none',
          WebkitAppearance: 'none',
        }}
      >
        <OnboardingStepHighlighterAnimation />
      </OnboardingTourStarter>
    )}

    {children}
  </span>
))

const Navigation = ({ isTransparent, isLoggedIn }) => (
  <div className={classNames('Navigation', { isTransparent })}>
    <div className="Navigation-inner">
      <Link className="Navigation-logo" to="/">
        Musiclarity
      </Link>
      {/*
      <FeedbackButton className="Navigation-feedbackButton" />

      <LocalePicker /> 
      */}

      <nav className="Navigation-links">
      {!isLoggedIn ? (
         <div></div>
      ) : (
      [
        <NavLink to="/browse" {...navigationLinkProps}>
          <FeatherIcon.Music className="Navigation-link-icon" />
          <T>Browse songs</T>
        </NavLink>,
        <NavLink to="/vha" {...navigationLinkProps}>
          <OnboardableVhaMenuItem>
            <Icon name="ear" className="Navigation-link-icon isCustom" />
            <T>Choose Listening Method</T>
          </OnboardableVhaMenuItem>
        </NavLink>,
        <NavLink to="/mixer" {...navigationLinkProps}>
          <OnboardableMixerMenuItem>
            <Icon name="mixer" className="Navigation-link-icon isCustom" />
            <T>Use Mixer</T>
          </OnboardableMixerMenuItem>
        </NavLink>,
        <NavLink to="/about" {...navigationLinkProps}>
          <FeatherIcon.HelpCircle className="Navigation-link-icon" />
          <T>About & Help</T>
        </NavLink>,
        ]
        )}

        <span className="Navigation-linkGroupSeparator" />

        {isLoggedIn ? (
          <UserNavigationLink {...navigationLinkProps} />
        ) : (
          [
            <NavLink to="/login" key="login" {...navigationLinkProps}>
              <T>Login</T>
            </NavLink>,

            <span className="Navigation-linkItem" key="signup">
            {/*
              <NavLink to="/signup" {...navigationLinkProps}>
                <T>Sign up</T>
              </NavLink>
              <SignUpBarkTooltip />
            */}
            </span>,
          ]
        )}
      </nav>
    </div>
  </div>
)

Navigation.propTypes = {
  isTransparent: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
}

Navigation.defaultProps = {
  isTransparent: false,
  isLoggedIn: false,
}

export default withRouter(
  connect(state => ({
    isLoggedIn:
      getIsLoggedIn(state.get('auth')) && isString(state.getIn(['user', 'user', 'email'])),
  }))(Navigation)
)
