import { fromJS } from 'immutable'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  globalTextSize: 1,
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_GLOBAL_TEXT_SIZE:
      return state.set('globalTextSize', payload)
    default:
      return state
  }
}
