import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'

import { Ears, HearingAidFittings } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import { deletePatient, fetchPatient } from 'src/actions/consultant.actions.js'
import AudiogramForm from 'src/components/AudiogramForm.js'
import Button, { Size } from 'src/components/Button.js'
import ResetPasswordButton from 'src/components/ResetPasswordButton.js'
import DeletePatientButton from 'src/components/consultant/DeletePatientButton.js'
import SendAudiogramsButton from 'src/components/consultant/SendAudiogramsButton.js'
import StartPatientSessionButton from 'src/components/consultant/StartPatientSessionButton.js'

import './PatientView.scss'

/**
 * Patient View
 */
class PatientView extends PureComponent {
  static propTypes = {
    fetchPatient: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    patientId: PropTypes.string.isRequired,
    patient: ImmutablePropTypes.contains({
      user: ImmutablePropTypes.contains({
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
      vha: CustomPropTypes.vha,
    }),
    error: ImmutablePropTypes.map,
    onDeletePatient: PropTypes.func.isRequired,
  }

  static defaultProps = {
    patient: null,
    error: null,
  }

  componentDidMount() {
    this.props.fetchPatient()
  }

  render() {
    const { isFetching, patient, error } = this.props

    if (isFetching === true || patient === null) {
      return (
        <div>
          <T>Fetching patient...</T>
        </div>
      )
    }

    if (error) {
      return <pre>{JSON.stringify(error.toJS(), null, 2)}</pre>
    }

    const user = patient.get('user')
    const vha = patient.get('vha')

    return (
      <div className="PatientView">
        <header className="PatientView-userCard">
          <h3 className="PatientView-userCard-name">
            {user.get('firstName')} {user.get('lastName')}
          </h3>
          <div className="PatientView-userCard-email">{user.get('email')}</div>
        </header>

        <div className="PatientView-actions">
          <StartPatientSessionButton patient={user} size={Size.SMALL} />
          <SendAudiogramsButton patientId={user.get('_id')} size={Size.SMALL} />
          <ResetPasswordButton userId={user.get('_id')} size={Size.SMALL} />
          <DeletePatientButton userId={user.get('_id')} size={Size.SMALL} />
        </div>

        <h4 className="Legend">Audiograms</h4>
        {vha !== null && vha.getIn(['aid', 'fittingMode']) === HearingAidFittings.AUDIOGRAMS ? (
          <div className="PatientView-audiograms">
            <AudiogramForm
              ear={Ears.RIGHT}
              values={vha.getIn(['aid', 'values', HearingAidFittings.AUDIOGRAMS, Ears.RIGHT])}
              isInteractive={false}
              style={{ marginLeft: 8 }}
            />
            <AudiogramForm
              ear={Ears.LEFT}
              values={vha.getIn(['aid', 'values', HearingAidFittings.AUDIOGRAMS, Ears.LEFT])}
              isInteractive={false}
              style={{ marginRight: 8 }}
            />
          </div>
        ) : (
          <div>
            <p>
              <em>
                <T>This patient does not have any audiograms configured yet.</T>
              </em>
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  state => ({
    isFetching: state.getIn(['consultant', 'patient', 'isFetching']),
    patient: state.getIn(['consultant', 'patient', 'data']),
    error: state.getIn(['consultant', 'patient', 'error']),
  }),
  (dispatch, { patientId }) => ({
    fetchPatient: () => dispatch(fetchPatient(patientId)),
    onDeletePatient: () => dispatch(deletePatient(patientId)),
  })
)(withTranslators(PatientView))
