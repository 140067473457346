import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Sticky from 'react-stickynode'
import { withRouter } from 'react-router-dom'
import { autobind } from 'core-decorators'

import Navigation from 'src/components/Navigation.js'
import ConsultantTopBar from 'src/components/consultant/ConsultantTopBar.js'

import './StickyNavigation.scss'

/**
 * Sticky Navigation
 */
class StickyNavigation extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
  }

  state = {
    isSticky: false,
  }

  @autobind
  handleStickyStateChange({ status }) {
    this.setState({
      isSticky: status === Sticky.STATUS_FIXED,
    })
  }

  render() {
    const { location } = this.props
    const { isSticky } = this.state

    const isTransparent = location.pathname === '/' && isSticky === false

    return (
      <Sticky
        top={0}
        onStateChange={this.handleStickyStateChange}
        className="StickyNavigation"
        activeClass="isSticky"
      >
        <Navigation isTransparent={isTransparent} />
        <ConsultantTopBar isTransparent={isTransparent} />
      </Sticky>
    )
  }
}

export default withRouter(StickyNavigation)
