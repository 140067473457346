import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { round, values } from 'lodash'
import { T } from 'lioness'

import { Ears, ListeningMethods } from 'src/constants.js'
import { setLevelAdjustment } from 'src/actions/vha.actions.js'
import { VHASplitPane, Pane } from 'src/components/vha/VHASplitPane.js'
import SliderControl from 'src/components/SliderControl.js'

/**
 * Level Adjustment Controls
 */
class LevelAdjustmentControls extends Component {
  static propTypes = {
    leftAmount: PropTypes.number.isRequired,
    rightAmount: PropTypes.number.isRequired,
    listeningMethod: PropTypes.oneOf(values(ListeningMethods)),
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { leftAmount, rightAmount, listeningMethod, onChange } = this.props

    return (
      <div className="LevelAdjustmentControls">
        {listeningMethod === ListeningMethods.HEARING_LOOP ? (
          <SliderControl
            initialValue={leftAmount}
            upperThreshold={20}
            lowerThreshold={-20}
            onValueChange={amount => onChange(Ears.LEFT, amount)}
            valueFormatter={value => `${round(value, 1)} dB`}
            style={{ width: 50 }}
          />
        ) : (
          <VHASplitPane>
            <Pane>
              <label>
                <T>Left ear</T>
              </label>

              <SliderControl
                initialValue={leftAmount}
                upperThreshold={20}
                lowerThreshold={-20}
                onValueChange={amount => onChange(Ears.LEFT, amount)}
                valueFormatter={value => `${round(value, 1)} dB`}
                style={{ width: 50 }}
              />
            </Pane>

            <Pane>
              <label>
                <T>Right ear</T>
              </label>

              <SliderControl
                initialValue={rightAmount}
                upperThreshold={20}
                lowerThreshold={-20}
                onValueChange={amount => onChange(Ears.RIGHT, amount)}
                valueFormatter={value => `${round(value, 1)} dB`}
                style={{ width: 50 }}
              />
            </Pane>
          </VHASplitPane>
        )}
      </div>
    )
  }
}

export default connect(
  state => ({
    leftAmount: state.getIn(['vha', 'levels', 'values', Ears.LEFT]),
    rightAmount: state.getIn(['vha', 'levels', 'values', Ears.RIGHT]),
    listeningMethod: state.getIn(['vha', 'listeningMethod']),
  }),
  dispatch => ({
    onChange: (ear, amount) => dispatch(setLevelAdjustment(ear, amount)),
  })
)(LevelAdjustmentControls)
