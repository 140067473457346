import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withTranslators } from 'lioness'

/**
 * Localized Track Name
 */
class LocalizedTrackName extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    trackName: PropTypes.string,
  }

  static defaultProps = {
    trackName: '',
  }

  constructor(props) {
    super(props)

    const { t } = props

    this.dictionairy = {
      Bass: t('Bass'),
      Brass: t('Brass'),
      Drums: t('Drums'),
      Guitar: t('Guitar'),
      Guitars: t('Guitars'),
      Keys: t('Keys'),
      Other: t('Other'),
      Percussion: t('Percussion'),
      Piano: t('Piano'),
      Rhythm: t('Rhythm'),
      Strings: t('Strings'),
      'Track 1': t('Track 1'),
      'Track 2': t('Track 2'),
      'Track 3': t('Track 3'),
      'Track 4': t('Track 4'),
      Vocals: t('Vocals'),
      Woodwind: t('Woodwind'),
    }
  }

  render() {
    const { trackName } = this.props

    const maybeLocalizedName =
      this.dictionairy[trackName] !== undefined ? this.dictionairy[trackName] : trackName

    return <span>{maybeLocalizedName}</span>
  }
}

export default withTranslators(LocalizedTrackName)
