import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { capitalize, words } from 'lodash'
import { List } from 'immutable'

import { FilterGroups } from 'src/constants.js'
import { setSongsFilter } from 'src/actions/browsing.actions.js'
import SongFilterTag, { SongFilterTagColors } from 'src/components/browsing/SongFilterTag.js'

import './SongsFilter.scss'

/**
 * Songs Filter
 */
class SongsFilter extends PureComponent {
  static propTypes = {
    songs: ImmutablePropTypes.list.isRequired,
    filters: ImmutablePropTypes.map.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
  }

  render() {
    const { songs, filters, onChangeFilter } = this.props

    return (
      <div className="SongsFilter">
        <div className="SongsFilter-group">
          {filters
            .get(FilterGroups.LISTENING_LEVEL)
            .map((isChecked, level) => (
              <SongFilterTag
                key={level}
                label={capitalize(words(level).join(' '))}
                isChecked={isChecked}
                matchCount={
                  songs.filter(x => (x.get('tags') || new List()).includes(level) === true).size
                }
                color={SongFilterTagColors.GREEN}
                onChange={newValue => onChangeFilter(FilterGroups.LISTENING_LEVEL, level, newValue)}
              />
            ))
            .toArray()}
        </div>

        <div className="SongsFilter-group">
          {filters
            .get(FilterGroups.VOCALS)
            .map((isChecked, tag) => (
              <SongFilterTag
                key={tag}
                label={capitalize(tag)}
                isChecked={isChecked}
                matchCount={
                  songs.filter(x => (x.get('tags') || new List()).includes(tag) === true).size
                }
                color={SongFilterTagColors.TURQUOISE}
                onChange={newValue => onChangeFilter(FilterGroups.VOCALS, tag, newValue)}
              />
            ))
            .toArray()}
        </div>

        <div className="SongsFilter-group">
          {filters
            .get(FilterGroups.GENRE)
            .map((isChecked, genre) => (
              <SongFilterTag
                key={genre}
                label={capitalize(genre)}
                isChecked={isChecked}
                matchCount={songs.filter(x => x.get('genre') === genre).size}
                color={SongFilterTagColors.ROYALBLUE}
                onChange={newValue => onChangeFilter(FilterGroups.GENRE, genre, newValue)}
              />
            ))
            .toArray()}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    songs: state.get('songs'),
    filters: state.getIn(['browsing', 'filters']),
  }),
  dispatch => ({
    onChangeFilter: (group, filter, value) => dispatch(setSongsFilter(group, filter, value)),
  })
)(SongsFilter)
