import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SoundOnButton, SoundOffButton, VolumeSlider } from 'react-player-controls'
import classNames from 'classnames'

import { setMasterVolume, setMasterMuted } from 'src/actions/mixer.actions.js'
import Icon from './Icon.js'

import './MasterVolumeControls.scss'

const propTypes = {
  volume: PropTypes.number.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
  isMuted: PropTypes.bool.isRequired,
  onSetMute: PropTypes.func.isRequired,
}

class MasterVolumeControls extends Component {
  constructor(props) {
    super(props)

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.state = {
      isVolumeSliderVisible: false,
    }
  }

  handleMouseEnter() {
    this.setState({
      isVolumeSliderVisible: true,
    })
  }

  handleMouseLeave() {
    this.setState({
      isVolumeSliderVisible: false,
    })
  }

  render() {
    const { volume, onVolumeChange, isMuted, onSetMute } = this.props
    const { isVolumeSliderVisible } = this.state

    return (
      <div
        className={classNames('MasterVolumeControls', { isMuted })}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="MasterVolumeControls-onOff">
          {isMuted ? (
            <SoundOffButton onClick={() => onSetMute(false)}>
              <Icon name="audio" />
            </SoundOffButton>
          ) : (
            <SoundOnButton onClick={() => onSetMute(true)}>
              <Icon name="audio" />
            </SoundOnButton>
          )}
        </div>

        <div className={classNames('VolumeSliderContainer', { isVisible: isVolumeSliderVisible })}>
          <VolumeSlider isEnabled={!isMuted} volume={volume} onVolumeChange={onVolumeChange} />
        </div>
      </div>
    )
  }
}

MasterVolumeControls.propTypes = propTypes

export default connect(
  state => ({
    volume: state.getIn(['mixer', 'master', 'volume']),
    isMuted: state.getIn(['mixer', 'master', 'isMuted']),
  }),
  dispatch => ({
    onSetMute: mute => dispatch(setMasterMuted(mute)),
    onVolumeChange: volume => dispatch(setMasterVolume(volume)),
  })
)(MasterVolumeControls)
