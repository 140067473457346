'use strict'

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i]
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }
    return target
  }

var _react = require('react')

var _react2 = _interopRequireDefault(_react)

var _classnames = require('classnames')

var _classnames2 = _interopRequireDefault(_classnames)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    })
  } else {
    obj[key] = value
  }
  return obj
}

function _objectWithoutProperties(obj, keys) {
  var target = {}
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue
    target[i] = obj[i]
  }
  return target
}

module.exports = _react2.default.createClass({
  displayName: 'TabPanel',

  propTypes: {
    children: _react.PropTypes.oneOfType([
      _react.PropTypes.array,
      _react.PropTypes.object,
      _react.PropTypes.string,
    ]),
    className: _react.PropTypes.string,
    id: _react.PropTypes.string,
    selected: _react.PropTypes.bool,
    selectedClassName: _react.PropTypes.string,
    style: _react.PropTypes.object,
    tabId: _react.PropTypes.string,
  },

  contextTypes: {
    forceRenderTabPanel: _react.PropTypes.bool,
  },

  getDefaultProps: function getDefaultProps() {
    return {
      selected: false,
      selectedClassName: null,
      style: {},
      id: null,
      tabId: null,
    }
  },
  render: function render() {
    var _props = this.props,
      className = _props.className,
      children = _props.children,
      selected = _props.selected,
      selectedClassName = _props.selectedClassName,
      id = _props.id,
      tabId = _props.tabId,
      style = _props.style,
      attributes = _objectWithoutProperties(_props, [
        'className',
        'children',
        'selected',
        'selectedClassName',
        'id',
        'tabId',
        'style',
      ])

    // Only hide using inline styles if `selectedClassName` isn't provided

    var hiddenStyle = selectedClassName ? style : _extends({}, style, { display: 'none' })

    return _react2.default.createElement(
      'div',
      _extends({}, attributes, {
        className: (0, _classnames2.default)(
          'ReactTabs__TabPanel',
          className,
          _defineProperty({}, selectedClassName || 'ReactTabs__TabPanel--selected', selected)
        ),
        role: 'tabpanel',
        id: id,
        'aria-labelledby': tabId,
        style: selected ? style : hiddenStyle,
      }),
      this.context.forceRenderTabPanel || selected ? children : null
    )
  },
})
