import { last } from 'lodash'

import { EffectTypes } from 'src/constants.js'
import audioContext from 'src/audio/context.js'
import Compressor from 'src/audio/detuna/Compressor.js'
import Filter from 'src/audio/detuna/Filter.js'
import Gain from 'src/audio/detuna/Gain.js'
import HearingAid from 'src/audio/detuna/HearingAid.js'
import Mono from 'src/audio/detuna/Mono.js'
import Splitter from 'src/audio/detuna/Splitter.js'

// Effect map
const effects = {}

// Effects input and output nodes
let fxIn = null
let fxOut = null
let enabledEffects = []

if (audioContext !== null) {
  fxIn = audioContext.createGain()
  fxOut = audioContext.createGain()
  fxIn.connect(fxOut)
}

export { fxIn, fxOut }

/**
 * Returns an effect of the given type with the given params
 */
export const createEffect = (type, params) => {
  switch (type) {
    case EffectTypes.FILTER:
      return new Filter(params)
    case EffectTypes.SPLITTER:
      return new Splitter(params)
    case EffectTypes.COMPRESSOR:
      return new Compressor(params)
    case EffectTypes.GAIN:
      return new Gain(params)
    case EffectTypes.HEARING_AID:
      return new HearingAid(params)
    case EffectTypes.MONO:
      return new Mono(params)
    default:
      return null
  }
}

/**
 * Recursively swaps ids in `effectIds` for the effect in
 * `effectsMap` that has the corresponding id.
 */
const deepResolveEffects = (effectIds, effectsMap) => {
  return effectIds.map(id => {
    if (Array.isArray(id)) {
      return deepResolveEffects(id, effectsMap)
    }

    return effectsMap[id]
  })
}

/**
 * Creates effects and stores them in the `effects` object.
 */
export const initializeEffects = effectList => {
  effectList.forEach(effectInfo => {
    const { id, type, params } = effectInfo.toJS()

    // Resolve id referenced children effects
    if (params.effects) {
      params.effects = deepResolveEffects(params.effects, effects)
    }

    const effect = createEffect(type, params)
    effects[id] = effect
  })
}

/**
 * Adds all effects matching an id in `effectIds` to the
 * effect chain.
 */
export const mountEffects = effectIds => {
  enabledEffects = effectIds.map(id => effects[id]).toJS()
  reconnect(enabledEffects)
}

/**
 * Re-assembles the effect chain, omitting disabled effects.
 */
const reconnect = nodes => {
  fxIn.disconnect()
  fxIn.connect(nodes[0])
  nodes.forEach((node, i) => {
    node.disconnect()
    if (i < nodes.length - 1) {
      node.connect(nodes[i + 1])
    }
  })
  last(nodes).connect(fxOut)
}

/**
 * Enables or bypasses the effects chain.
 */
export const setBypassed = isBypassed => {
  if (isBypassed === true) {
    fxIn.disconnect()
    fxIn.connect(fxOut)
  } else {
    reconnect(enabledEffects)
  }
}

/**
 * Updates an effect's parameter value
 */
export const setEffectParam = (effectId, param, value) => {
  const effect = effects[effectId]
  effect[param] = value
}

/**
 * Applies a set of effect param updates
 *
 * @param  {Array} updates An array of { effectId, param, value } objects
 */
export const batchSetEffectParams = updates => {
  for (const { effectId, param, value } of updates) {
    setEffectParam(effectId, param, value)
  }
}
