import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { T } from 'lioness'

import { isLoggedIn as getIsLoggedIn } from 'src/reducers/auth.reducer.js'
import { whenNotLoggedIn } from 'src/components/composers/auth-composers.js'
import View from './View.js'
import LoginForm from './LoginForm.js'

import './LoginView.scss'

const LoginView = () => (
  <View className="LoginView">
    <Helmet title="Login" />

    <div className="LoginView-content">
      <h1 className="ViewTitle">
        <T>Login</T>
      </h1>
      <LoginForm />
    </div>
  </View>
)

export default whenNotLoggedIn(
  connect(state => ({
    isLoggedIn: getIsLoggedIn(state.get('auth')),
  }))(LoginView)
)
