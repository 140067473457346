import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty, merge } from 'lodash'
import { T, withTranslators } from 'lioness'

import { login } from 'src/actions/auth.actions.js'
import { UserLoginSchema } from 'src/schemas/user.schemas.js'
import { validateObject } from 'src/schemas/validate.js'
import Button, { Loudness } from 'src/components/Button.js'
import FormField from 'src/components/forms/FormField.js'

// Create a prepared login validation function
const validateLoginData = validateObject(UserLoginSchema)

/**
 * Login form
 */
class LoginForm extends Component {
  static propTypes = {
    initialEmail: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialEmail: '',
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      data: {
        email: props.initialEmail,
        password: '',
      },
      errors: null,
      hasSubmitted: false,
    }
  }

  handleChange(field, value) {
    const data = merge({}, this.state.data, { [field]: value })
    const errors = validateLoginData(data)

    this.setState(() => ({ data, errors }))
  }

  handleSubmit(evt) {
    evt.preventDefault()

    const { onSubmit } = this.props
    const { email, password } = this.state.data

    const errors = validateLoginData(this.state.data)

    if (isEmpty(errors)) {
      onSubmit({ email, password })
    }

    // TOOD: Focus first erronous field

    this.setState(() => ({ errors, hasSubmitted: true }))
  }

  render() {
    const { t } = this.props
    const { data, errors, hasSubmitted } = this.state
    const { email, password } = data

    return (
      <form className="LoginForm" onSubmit={this.handleSubmit}>
        <FormField
          name="email"
          label={t('Email')}
          type="text"
          placeholder={t('jane.doe@example.com')}
          value={email}
          onChange={value => this.handleChange('email', value)}
          errors={errors ? errors.email : []}
          showErrors={hasSubmitted}
        />

        <FormField
          name="password"
          label={t('Password')}
          type="password"
          placeholder=""
          value={password}
          onChange={value => this.handleChange('password', value)}
          errors={errors ? errors.password : []}
          showErrors={hasSubmitted}
        />

        <Button loudness={Loudness.YELL} type="submit">
          <T>Login</T>
        </Button>
      </form>
    )
  }
}

export default connect(
  state => ({
    initialEmail: state.getIn(['auth', 'email']) || '',
  }),
  dispatch => ({
    onSubmit: ({ email, password }) => dispatch(login({ email, password })),
  })
)(withTranslators(LoginForm))
