import { List, Map } from 'immutable'
import { v1 as generateId } from 'uuid'

import { ActionTypes } from 'src/constants.js'

const initialState = new List()

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.DISPLAY_ERROR:
      return state.push(
        new Map({
          id: generateId(),
          message: payload.message,
          details: {
            message: payload.details.message || payload.details.detail,
            stack: payload.details.stack,
          },
        })
      )
    case ActionTypes.DISMISS_ERROR:
      return state.filterNot(x => x.get('id') === payload.id)
    default:
      return state
  }
}
