import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { T } from 'lioness'

import { Barks, Positions } from 'src/constants.js'
import { setBarkCompleted } from 'src/actions/barker.actions.js'
import {
  AnimatedTooltip,
  TooltipStyles,
  TooltipContent,
  TooltipActions,
} from 'src/components/Tooltip.js'

import './SignUpBarkTooltip.scss'

/**
 * Sign Up Bark Tooltip
 */
class SignUpBarkTooltip extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
  }

  render() {
    const { isActive, onDismiss } = this.props

    return (
      <AnimatedTooltip
        isVisible={isActive}
        position={Positions.BOTTOM}
        alignment={{ x: Positions.RIGHT }}
        tooltipStyle={TooltipStyles.SCREAM}
        className="SignUpBarkTooltip"
      >
        <TooltipContent>
          <Link to="/signup" onClick={onDismiss}>
            <T>Sign up to store all your VHA settings now!</T>
          </Link>
        </TooltipContent>

        <TooltipActions>
          <div className="SignUpBarkTooltip-reject" onClick={onDismiss}>
            <T>No, thanks</T>
          </div>
        </TooltipActions>
      </AnimatedTooltip>
    )
  }
}

export default connect(
  state => ({
    isActive: state.getIn(['barker', Barks.VHA, 'isActive']),
  }),
  dispatch => ({
    onDismiss: () => dispatch(setBarkCompleted(Barks.VHA, true)),
  })
)(SignUpBarkTooltip)
