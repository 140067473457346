import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { VisualizerTypes, VisualizerModes } from 'src/constants.js'
import {
  setVisualizerType,
  setVisualizerMode,
  setVisualizerMirroring,
  setVisualizerSilenceRendering,
  setVisualizerZeroEdges,
} from 'src/actions/visualizer.actions.js'

import './VisualizerSettings.scss'

const VisualizerSettings = props => {
  const {
    type,
    mode,
    mirrorGraphs,
    renderSilence,
    setEdgesToZero,
    onSetType,
    onSetMode,
    onSetMirroring,
    onSetSilenceRendering,
    onSetZeroEdges,
    ...otherProps
  } = props

  return (
    <div className="VisualizerSettings" {...otherProps}>
      <span className="VisualizerSettings-title">Visualizer Settings</span>

      <label>
        <span>Type</span>

        <select value={type} onChange={evt => onSetType(evt.target.value)}>
          <option value={VisualizerTypes.LINES}>Lines</option>
          <option value={VisualizerTypes.BARS}>Bars</option>
        </select>
      </label>

      <label>
        <span>Mode</span>

        <select value={mode} onChange={evt => onSetMode(evt.target.value)}>
          <option value={VisualizerModes.ADDITIVE}>Additive</option>
          <option value={VisualizerModes.SEPARATE}>Separate</option>
        </select>
      </label>

      <label className={classNames({ isDisabled: type !== VisualizerTypes.LINES })}>
        <span>Mirror graphs</span>

        <input
          type="checkbox"
          checked={mirrorGraphs}
          onChange={() => onSetMirroring(!mirrorGraphs)}
        />
      </label>

      <label className={classNames({ isDisabled: mode !== VisualizerModes.SEPARATE })}>
        <span>Render silence</span>

        <input
          type="checkbox"
          checked={renderSilence}
          onChange={() => onSetSilenceRendering(!renderSilence)}
        />
      </label>

      <label className={classNames({ isDisabled: type === VisualizerTypes.BARS })}>
        <span>Set edges to zero</span>

        <input
          type="checkbox"
          checked={setEdgesToZero}
          onChange={() => onSetZeroEdges(!setEdgesToZero)}
        />
      </label>
    </div>
  )
}

export default connect(
  state => {
    const visualizerState = state.get('visualizer')

    return {
      type: visualizerState.get('type'),
      mode: visualizerState.get('mode'),
      mirrorGraphs: visualizerState.get('mirrorGraphs'),
      renderSilence: visualizerState.get('renderSilence'),
      setEdgesToZero: visualizerState.get('setEdgesToZero'),
    }
  },
  dispatch => ({
    onSetType: type => dispatch(setVisualizerType(type)),
    onSetMode: mode => dispatch(setVisualizerMode(mode)),
    onSetMirroring: shouldMirror => dispatch(setVisualizerMirroring(shouldMirror)),
    onSetSilenceRendering: shouldRender => dispatch(setVisualizerSilenceRendering(shouldRender)),
    onSetZeroEdges: setToZero => dispatch(setVisualizerZeroEdges(setToZero)),
  })
)(VisualizerSettings)
