import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'

import { createConsultant } from 'src/actions/consultant.actions.js'
import ConsultantSignUpForm from 'src/components/user/ConsultantSignUpForm.js'

import './ConsultantSignUp.scss'

const ConnectedConsultantSignUpForm = connect(
  state => ({
    initialEmail: state.getIn(['auth', 'email']) || '',
    isSubmitting: state.getIn(['consultant', 'create', 'isPending']),
  }),
  dispatch => ({
    onSubmit: ({ firstName, lastName, email, password, placeOfWork, occupation }) => {
      dispatch(
        createConsultant({
          firstName,
          lastName,
          email,
          password,
          placeOfWork,
          occupation,
        })
      )
    },
  })
)(ConsultantSignUpForm)

/**
 * Consultant Sign Up
 */
class ConsultantSignUp extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props

    return (
      <div className="ConsultantSingUp">
        <Helmet title={t('Sign up for Musiclarity')} />

        <header className="ConsultantSignUp-header">
          <h1 className="ViewTitle">
            <T>Sign up as a consultant</T>
          </h1>

          <Link to="/signup/user">
            <T>Sign up as a regular user</T>
            {' →'}
          </Link>
        </header>

        <div className="ConsultantSignUp-content">
          <div className="ConsultantSignUp-form">
            <h3 className="Legend">
              <T>Your details</T>
            </h3>
            <ConnectedConsultantSignUpForm />
          </div>

          <div className="ConsultantSignUp-info">
            <h3 className="Legend">
              <T>What you get</T>
            </h3>

            <ul>
              <li>
                <T>
                  Create an accounts for patients while in-session, and input their audiogram for
                  them
                </T>
              </li>
              <li>
                <T>Create recommended lists of songs for patients</T>
              </li>
              <li>
                <T>Monitor listening behaviours of patients over time</T>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslators(ConsultantSignUp)
