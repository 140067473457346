import { fromSrt } from 'subtitles-parser'
import { chunk } from 'lodash'

import { LyricsFormats } from 'src/constants.js'

const LYRICS_FORMAT_EXTENSIONS = {
  srt: LyricsFormats.SUBRIP,
  vtt: LyricsFormats.WEBVTT,
}

/**
 * Determines from a state instance whether the current song, if any,
 * has lyrics
 */
export const currentSongHasLyrics = state => {
  const currentSong = state.get('songs').find(x => x.get('id') === state.getIn(['player', 'song']))
  return currentSong && !!currentSong.get('lyricsUrl') ? true : false
}

/**
 * Fetches lyrics contents from a URL
 */
export const fetchLyrics = url => fetch(url).then(response => response.text())

/**
 * Fetches and parses lyrics from a URL
 */
export const getLyricsFromUrl = url => {
  const extension = url
    .split('.')
    .pop()
    .toLowerCase()
  const format = LYRICS_FORMAT_EXTENSIONS[extension]

  return fetchLyrics(url).then(lyricsContent => {
    switch (format) {
      case LyricsFormats.SUBRIP:
        return getSubripLyrics(lyricsContent)
      case LyricsFormats.WEBVTT:
        return getWebVttLyrics(lyricsContent)
      default:
        return null
    }
  })
}

/**
 * Parses SubRip contents and returns an array of lyrics lines
 */
const getSubripLyrics = srt => {
  const lyrics = fromSrt(srt, true)
  return lyrics.map(line => ({
    ...line,
    startTime: line.startTime / 1000,
    endTime: line.endTime / 1000,
    texts: [
      {
        startTime: line.startTime / 1000,
        text: line.text,
      },
    ],
  }))
}

/**
 * Parses WebVTT text and returns an array of lyrics lines
 */
const getWebVttLyrics = vtt => {
  const lyrics = vtt
    .split(/\n{2,}/)
    .filter(x => x.trim())
    .slice(1)
    .map((x, id) => {
      const [times, text] = x.split(/\n/)
      const timestamps = times.split('-->').map(t => t.trim())

      const [startTime, endTime] = timestamps.map(getVttTime)

      let texts = text.split(/<(\d{2}:\d{2}.\d{3})>/g)
      texts = [timestamps[0]].concat(texts)
      texts = chunk(texts, 2)
      texts = texts.map(pair => ({
        startTime: getVttTime(pair[0]),
        text: pair[1],
      }))

      return { startTime, endTime, text, texts, id }
    })

  return lyrics
}

const getVttTime = timestamp => {
  const [m, s, ms] = timestamp.split(/\D/).map(parseFloat)
  return m * 60 + s + ms / 1000
}
