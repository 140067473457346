import { connect } from 'react-redux'

import { setSelectedTrack, setTrackPan, setTrackDistance } from 'src/actions/mixer.actions.js'
import { hasLoadedSong } from 'src/reducers/player.reducer.js'
import BinauralMixer from './BinauralMixer.js'

export default connect(
  state => {
    const mixerTracks = state.getIn(['mixer', 'tracks'])
    const selectedTrack = mixerTracks
      ? mixerTracks.find(x => x.get('id') === state.getIn(['mixer', 'selectedTrackId']))
      : null

    return {
      isEnabled: hasLoadedSong(state.get('player')),
      tracks: state.getIn(['mixer', 'tracks']),
      selectedTrack: selectedTrack,
    }
  },
  dispatch => ({
    onSelectTrack: track => dispatch(setSelectedTrack(track.get('id'))),
    onTrackPositionChange: (track, position) => {
      dispatch(setTrackPan(track.get('id'), position.get('x')))
      dispatch(setTrackDistance(track.get('id'), position.get('z')))
    },
  })
)(BinauralMixer)
