import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedTime } from 'react-player-controls'
import { capitalize } from 'lodash'
import { connect } from 'react-redux'
import cx from 'classnames'
import * as FeatherIcon from 'react-feather'

import { requestPlaySong, setPlaybackState } from 'src/actions/player.actions.js'
import AlbumCover from 'src/components/AlbumCover.js'

import './SongTable.scss'

/**
 * Song Table
 */
class SongTable extends PureComponent {
  static propTypes = {
    songs: ImmutablePropTypes.list.isRequired,
    currentSong: ImmutablePropTypes.map,
    isPlaying: PropTypes.bool.isRequired,
    onPlaySong: PropTypes.func.isRequired,
  }

  render() {
    const { songs, currentSong, isPlaying, onPlaySong } = this.props

    return (
      <table className="SongTable">
        <thead>
          <tr>
            <th />
            <th>Title</th>
            <th>Artist</th>
            <th>Genre</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {songs.map(song => {
            const isCurrentSong = currentSong && currentSong.get('id') === song.get('id')

            return (
              <tr
                key={song.get('id')}
                className={cx('SongTable-song', { isCurrentSong })}
                onClick={() => onPlaySong(song, isPlaying, isCurrentSong)}
              >
                <td className="SongTable-song-albumCover">
                  <AlbumCover
                    className="SongTable-song-image"
                    src={song.get('image')}
                    alt={song.get('title')}
                  />

                  <div className="SongTable-song-albumCover-overlay">
                    {isCurrentSong === true && isPlaying === true ? (
                      <FeatherIcon.Pause className="SongTable-song-albumCover-overlay-icon" />
                    ) : (
                      <FeatherIcon.Play className="SongTable-song-albumCover-overlay-icon" />
                    )}
                  </div>
                </td>
                <td>{song.get('title')}</td>
                <td>{song.get('artist')}</td>
                <td>
                  {song
                    .get('genre')
                    .map(capitalize)
                    .toArray()
                    .join(', ')}
                </td>
                <td>
                  <FormattedTime numSeconds={song.get('length')} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
}

export default connect(
  state => ({
    currentSong: state.get('songs').find(x => x.get('id') === state.getIn(['player', 'song'])),
    isPlaying: state.getIn(['player', 'isPlaying']),
  }),
  dispatch => ({
    onPlaySong: (song, isPlaying, isCurrentSong) => {
      if (isCurrentSong === true) {
        dispatch(setPlaybackState(!isPlaying))
      } else {
        dispatch(requestPlaySong(song.get('id')))
      }
    },
  })
)(SongTable)
