import { ActionTypes } from 'src/constants.js'

export const setVhaInitialised = () => ({
  type: ActionTypes.SET_VHA_INITIALIZED,
})

export const setEnabled = isEnabled => ({
  type: ActionTypes.SET_VHA_ENABLED,
  payload: isEnabled,
})

export const setVha = vha => ({
  type: ActionTypes.SET_VHA,
  payload: vha,
})

export const resetVha = initialSettings => ({
  type: ActionTypes.RESET_VHA,
  payload: initialSettings,
})

/* Hearing loss presets */

export const setVhaListeningMethod = listeningMethod => ({
  type: ActionTypes.SET_VHA_LISTENING_METHOD,
  payload: { listeningMethod },
})

export const setVhaFittingMode = fittingMode => ({
  type: ActionTypes.SET_VHA_FITTING_MODE,
  payload: fittingMode,
})

export const setVhaPreset = (ear, preset) => ({
  type: ActionTypes.SET_VHA_PRESET,
  payload: { ear, preset },
})

export const setVhaHearingLossCode = (ear, code) => ({
  type: ActionTypes.SET_VHA_HEARING_LOSS_CODE,
  payload: { ear, code },
})

export const setVhaAudiogram = (ear, audiogram) => ({
  type: ActionTypes.SET_VHA_AUDIOGRAM,
  payload: { ear, audiogram },
})

export const setVhaAudiogramFrequencyValue = (ear, frequency, value) => ({
  type: ActionTypes.SET_VHA_AUDIOGRAM_FREQUENCY_VALUE,
  payload: { ear, frequency, value },
})

/* Noise reduction */

export const setNoiseReductionAmount = amount => ({
  type: ActionTypes.SET_NOISE_REDUCTION_AMOUNT,
  payload: amount,
})

/* Tone control */

export const setToneControlMirrored = isMirrored => ({
  type: ActionTypes.SET_TONE_CONTROL_MIRRORED,
  payload: isMirrored,
})

export const setToneControlGain = (ear, band, gain) => ({
  type: ActionTypes.SET_TONE_CONTROL_GAIN,
  payload: { ear, band, gain },
})

export const setToneControlGains = (ear, bandGains) => ({
  type: ActionTypes.SET_TONE_CONTROL_GAINS,
  payload: { ear, bandGains },
})

/* Compression controls */

export const setCompressionMirrored = isMirrored => ({
  type: ActionTypes.SET_COMPRESSION_MIRRORED,
  payload: isMirrored,
})

export const setCompressionAmount = (ear, amount) => ({
  type: ActionTypes.SET_COMPRESSION_AMOUNT,
  payload: { ear, amount },
})

/* Per-ear level adjustments */

export const setLevelAdjustment = (ear, amount) => ({
  type: ActionTypes.SET_LEVEL_ADJUSTMENT,
  payload: { ear, amount },
})

/* Undo */

export const undoVhaAction = () => ({
  type: ActionTypes.UNDO_VHA_ACTION,
})

export const setHasVhaUndoActions = hasUndoActions => ({
  type: ActionTypes.SET_HAS_VHA_UNDO_ACTIONS,
  payload: hasUndoActions,
})
