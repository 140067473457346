import React from 'react'

export default ({ size = 34, children }) => (
  <svg
    className="SvgIcon"
    x="0px"
    y="0px"
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    enableBackground={`new 0 0 ${size} ${size}`}
  >
    {children}
  </svg>
)
