import React, { PureComponent } from 'react'
import { T } from 'lioness'

import ScreenTakeOverMessage from 'src/components/ScreenTakeOverMessage.js'

/**
 * Dinosaur Browser Notice
 */
class DinosaurBrowserNotice extends PureComponent {
  render() {
    return (
      <ScreenTakeOverMessage>
        <h1>
          <T>Our apologies</T>
        </h1>
        <p>
          <T>
            You are using a browser that doesn't support the web audio technology that we use to
            build Musiclarity.
          </T>
        </p>
        <p>
          <T link={<a href="http://outdatedbrowser.com" />}>
            {'{{ link:Get a modern web browser }}'}
          </T>
        </p>
      </ScreenTakeOverMessage>
    )
  }
}

export default DinosaurBrowserNotice
