import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { round, values } from 'lodash'
import { T } from 'lioness'

import { Ears, ListeningMethods } from 'src/constants.js'
import { setCompressionMirrored, setCompressionAmount } from 'src/actions/vha.actions.js'
import { VHASplitPane, Pane } from 'src/components/vha/VHASplitPane.js'
import EarMirroringForm from 'src/components/vha/EarMirroringForm.js'
import SliderControl from 'src/components/SliderControl.js'

/**
 * Compression Controls
 */
class CompressionControls extends Component {
  static propTypes = {
    leftAmount: PropTypes.number.isRequired,
    rightAmount: PropTypes.number.isRequired,
    isMirrored: PropTypes.bool.isRequired,
    listeningMethod: PropTypes.oneOf(values(ListeningMethods)),
    onChange: PropTypes.func.isRequired,
    onSetMirrored: PropTypes.func.isRequired,
  }

  render() {
    const {
      leftAmount,
      rightAmount,
      isMirrored,
      listeningMethod,
      onChange,
      onSetMirrored,
    } = this.props

    return (
      <div className="CompressionControls">
        {listeningMethod === ListeningMethods.HEARING_LOOP ? (
          <SliderControl
            initialValue={leftAmount}
            upperThreshold={10}
            lowerThreshold={0}
            onValueChange={value => onChange(Ears.LEFT, value)}
            valueFormatter={value => round(value, 1)}
            style={{ width: 50 }}
          />
        ) : (
          <div>
            <EarMirroringForm isMirrored={isMirrored} onChange={onSetMirrored} />

            <VHASplitPane>
              <Pane style={{ maxWidth: 'calc(50% - 8px)' }}>
                <label>
                  <T>Left ear</T>
                </label>

                <SliderControl
                  initialValue={leftAmount}
                  upperThreshold={10}
                  lowerThreshold={0}
                  onValueChange={value => onChange(Ears.LEFT, value)}
                  valueFormatter={value => round(value, 1)}
                  style={{ width: 50 }}
                />
              </Pane>

              <Pane style={{ maxWidth: 'calc(50% - 8px)' }} isActive={isMirrored === false}>
                <label>
                  <T>Right ear</T>
                </label>

                <SliderControl
                  initialValue={rightAmount}
                  upperThreshold={10}
                  lowerThreshold={0}
                  onValueChange={value => onChange(Ears.RIGHT, value)}
                  valueFormatter={value => round(value, 1)}
                  style={{ width: 50 }}
                />
              </Pane>
            </VHASplitPane>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  state => ({
    leftAmount: state.getIn(['vha', 'compression', 'values', Ears.LEFT]),
    rightAmount: state.getIn(['vha', 'compression', 'values', Ears.RIGHT]),
    isMirrored: state.getIn(['vha', 'compression', 'isMirrored']),
    listeningMethod: state.getIn(['vha', 'listeningMethod']),
  }),
  dispatch => ({
    onChange: (ear, amount) => dispatch(setCompressionAmount(ear, amount)),
    onSetMirrored: isMirrored => dispatch(setCompressionMirrored(isMirrored)),
  })
)(CompressionControls)
