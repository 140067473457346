import React from 'react'

import { ActionTypes, OnboardingTours } from 'src/constants.js'
import { OnboardingStepTooltipTextSet } from 'src/components/onboarder/OnboardingStepTooltipTextSet.js'

export const MixerTourStepNames = {
  LEVELS_MIXER: 'LEVELS_MIXER',
  TRACK_SELECT: 'TRACK_SELECT',
  BINAURAL_MIXER: 'BINAURAL_MIXER',
}

export const MixerTourSteps = {
  [MixerTourStepNames.LEVELS_MIXER]: {
    tour: OnboardingTours.MIXER,
    step: 1,
    predicate: () => true,
    text: ({ completedActions, t }) => {
      const hasChangedVolume = completedActions.get(ActionTypes.SET_TRACK_VOLUME)
      const hasChangedSoloMute = completedActions
        .filter((isCompleted, actionType) =>
          [ActionTypes.SET_TRACK_SOLOED, ActionTypes.SET_TRACK_MUTED].includes(actionType)
        )
        .some(isCompleted => isCompleted)

      return (
        <OnboardingStepTooltipTextSet
          texts={[
            {
              isActive: hasChangedVolume === false && hasChangedSoloMute === false,
              text: t(
                `This is the Volumes view. Use these bars to increase or decrease the volumes of each instrument individually. Give it a go!`
              ),
            },
            {
              isActive: hasChangedVolume === true && hasChangedSoloMute === false,
              text: t(`Great! Try isolating and muting a track.`),
            },
            {
              isActive: hasChangedVolume === false && hasChangedSoloMute === true,
              text: t(`Okay, try dragging the volume bars.`),
            },
            {
              isActive: hasChangedVolume === true && hasChangedSoloMute === true,
              text: t(`Excellent! Let's move on.`),
            },
          ]}
        />
      )
    },
  },
  [MixerTourStepNames.TRACK_SELECT]: {
    tour: OnboardingTours.MIXER,
    step: 2,
    predicate: ({ track }) => track.get('trackNumber') === 2,
    text: ({ completedActions, t }) => {
      const hasPerformedAction = completedActions.get(ActionTypes.SET_SELECTED_TRACK) === true

      return (
        <OnboardingStepTooltipTextSet
          texts={[
            {
              isActive: hasPerformedAction === false,
              text: t(
                `By clicking an instrument's name, you surface it to the top of the Positions view. Try selecting it!`
              ),
            },
            {
              isActive: hasPerformedAction === true,
              text: t(`Perfect. Now let's look at the Positions view.`),
            },
          ]}
        />
      )
    },
  },
  [MixerTourStepNames.BINAURAL_MIXER]: {
    tour: OnboardingTours.MIXER,
    step: 3,
    predicate: () => true,
    text: ({ completedActions, t }) => {
      const acceptableActions = [ActionTypes.SET_TRACK_PAN, ActionTypes.SET_TRACK_DISTANCE]
      const hasPerformedAction = completedActions
        .filter((isCompleted, actionType) => acceptableActions.includes(actionType))
        .some(isCompleted => isCompleted)

      return (
        <OnboardingStepTooltipTextSet
          texts={[
            {
              isActive: hasPerformedAction === false,
              text: t(
                `This is the Position view, where you can move the instruments around your head.`
              ),
            },
            {
              isActive: hasPerformedAction === true,
              text: t(`Just like that! Click 'Done' to continue tweaking.`),
            },
          ]}
        />
      )
    },
  },
}
