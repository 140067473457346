import React from 'react'
import { connect } from 'react-redux'
import { round } from 'lodash'
import { T, withTranslators } from 'lioness'

import { Ears, HearingAidFittings, EqBands } from 'src/constants.js'
import AudiogramGraph from 'src/components/AudiogramGraph.js'
import { createComponentWithClassName } from 'src/utils/react-helpers.js'

import './VHARepresentation.scss'

const VHARepresentationBlock = createComponentWithClassName('VHARepresentationBlock')
const VHARepresentationBlockContent = createComponentWithClassName('VHARepresentationBlockContent')

const FittingModeLabel = withTranslators(({ fittingMode, t }) => {
  let label = ''

  if (fittingMode === HearingAidFittings.PRESETS) {
    label = t('Mild, moderate, severe')
  } else if (fittingMode === HearingAidFittings.CODES) {
    label = t('Hearing loss code')
  } else if (fittingMode === HearingAidFittings.AUDIOGRAMS) {
    label = t('Audiograms (from your audiologist)')
  }

  return <div className="FittingModeLabel">{label}</div>
})

const AudiogramString = ({ ear, audiogram }) => {
  return <AudiogramGraph ear={ear} data={audiogram} isInteractive={false} />
}

/**
 * VHA Representation
 */
const VHARepresentation = ({ vha }) => {
  const fittingMode = vha.getIn(['aid', 'fittingMode'])
  const aidValues = vha.getIn(['aid', 'values', fittingMode])
  const leftAidValue = aidValues.get(Ears.LEFT)
  const rightAidValue = aidValues.get(Ears.RIGHT)

  let leftAidElement = <div>{leftAidValue}</div>
  let rightAidElement = <div>{rightAidValue}</div>

  if (fittingMode === HearingAidFittings.AUDIOGRAMS) {
    leftAidElement = <AudiogramString ear={Ears.LEFT} audiogram={leftAidValue} />
    rightAidElement = <AudiogramString ear={Ears.RIGHT} audiogram={rightAidValue} />
  }

  return (
    <div className="VHARepresentation">
      <VHARepresentationBlock>
        <h3 className="Legend">
          <T>General hearing level</T>
        </h3>

        <VHARepresentationBlockContent className="isWide">
          <label>
            <T>Described using</T>
          </label>
          <FittingModeLabel fittingMode={fittingMode} />
        </VHARepresentationBlockContent>

        <VHARepresentationBlockContent>
          <label>
            <T>Right ear</T>
          </label>
          {rightAidElement}
        </VHARepresentationBlockContent>

        <VHARepresentationBlockContent>
          <label>
            <T>Left ear</T>
          </label>
          {leftAidElement}
        </VHARepresentationBlockContent>
      </VHARepresentationBlock>

      <VHARepresentationBlock>
        <h3 className="Legend">
          <T>Tone control</T>
        </h3>

        <VHARepresentationBlockContent>
          <label>
            <T>Left ear</T>
          </label>
          <div>
            <T
              message="Low: {{ value }} dB"
              value={round(vha.getIn(['toneControl', 'values', Ears.LEFT, EqBands.LOW]), 2)}
            />
            <br />
            <T
              message="Mid: {{ value }} dB"
              value={round(vha.getIn(['toneControl', 'values', Ears.LEFT, EqBands.MID]), 2)}
            />
            <br />
            <T
              message="High: {{ value }} dB"
              value={round(vha.getIn(['toneControl', 'values', Ears.LEFT, EqBands.HIGH]), 2)}
            />
            <br />
          </div>
        </VHARepresentationBlockContent>

        <VHARepresentationBlockContent>
          <label>
            <T>Right ear</T>
          </label>
          <div>
            <T
              message="Low: {{ value }} dB"
              value={round(vha.getIn(['toneControl', 'values', Ears.RIGHT, EqBands.LOW]), 2)}
            />
            <br />
            <T
              message="Mid: {{ value }} dB"
              value={round(vha.getIn(['toneControl', 'values', Ears.RIGHT, EqBands.MID]), 2)}
            />
            <br />
            <T
              message="High: {{ value }} dB"
              value={round(vha.getIn(['toneControl', 'values', Ears.RIGHT, EqBands.HIGH]), 2)}
            />
            <br />
          </div>
        </VHARepresentationBlockContent>
      </VHARepresentationBlock>

      <VHARepresentationBlock>
        <h3 className="Legend">
          <T>Compression</T>
        </h3>
        <VHARepresentationBlockContent>
          <label>
            <T>Left ear</T>
          </label>
          <div>{round(vha.getIn(['compression', 'values', Ears.LEFT]), 2)}</div>
        </VHARepresentationBlockContent>

        <VHARepresentationBlockContent>
          <label>
            <T>Right ear</T>
          </label>
          <div>{round(vha.getIn(['compression', 'values', Ears.RIGHT]), 2)}</div>
        </VHARepresentationBlockContent>
      </VHARepresentationBlock>

      <VHARepresentationBlock>
        <h3 className="Legend">
          <T>Overall volume</T>
        </h3>
        <VHARepresentationBlockContent>
          <label>
            <T>Left ear</T>
          </label>
          <div>
            <T
              message="{{ value }} dB"
              value={round(vha.getIn(['levels', 'values', Ears.LEFT]), 2)}
            />
          </div>
        </VHARepresentationBlockContent>

        <VHARepresentationBlockContent>
          <label>
            <T>Right ear</T>
          </label>
          <div>
            <T
              message="{{ value }} dB"
              value={round(vha.getIn(['levels', 'values', Ears.RIGHT]), 2)}
            />
          </div>
        </VHARepresentationBlockContent>
      </VHARepresentationBlock>
    </div>
  )
}

export default connect(state => ({
  vha: state.get('vha'),
}))(VHARepresentation)
