import React from 'react'

import SvgIcon from './SvgIcon.js'

export default props => (
  <SvgIcon {...props}>
    <g>
      <path
        d="M17,29 C23.627417,29 29,23.627417 29,17 C29,10.372583 23.627417,5 17,5 C10.372583,5 5,10.372583 5,17"
        strokeWidth="5"
      />
    </g>
  </SvgIcon>
)
