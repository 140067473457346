import React from 'react'

import './BinauralTrackPositionerPlaceholder.scss'

/**
 * Binaural Track Positioner Placeholder
 */
const BinauralTrackPositionerPlaceholder = ({ position = { x: 0, z: 0 }, name = '' }) => (
  <div
    className="BinauralTrackPositionerPlaceholder"
    style={{
      top: `${50 + position.z * 50}%`,
      left: `${50 + position.x * 50}%`,
    }}
  >
    <div className="BinauralTrackPositionerPlaceholder-name">{name}</div>
  </div>
)

export default BinauralTrackPositionerPlaceholder
