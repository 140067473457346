import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { values } from 'lodash'
import { autobind } from 'core-decorators'

import { AppEnvironments, KeyboardKeys, VhaLibraries } from 'src/constants.js'
import environment from 'src/environment.js'
import { setBinauralLibrary, setVhaLibrary } from 'src/actions/effects.actions.js'
import { displayError } from 'src/actions/errors.actions.js'
import Button, { Loudness } from 'src/components/Button.js'

import './DevTools.scss'

class DevTools extends Component {
  static propTypes = {
    binauralLibrary: PropTypes.oneOf(values(VhaLibraries)).isRequired,
    vhaLibrary: PropTypes.oneOf(values(VhaLibraries)).isRequired,
    onSelectBinauralLibrary: PropTypes.func.isRequired,
    onSelectVhaLibrary: PropTypes.func.isRequired,
    onDispatchError: PropTypes.func.isRequired,
  }

  state = {
    isActive: false,
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUmount() {
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  @autobind
  handleKeyUp(evt) {
    if (evt.shiftKey && evt.which === KeyboardKeys.D && document.activeElement === document.body) {
      this.setState(() => ({ isActive: !this.state.isActive }))
    }
  }

  render() {
    const {
      binauralLibrary,
      vhaLibrary,
      onSelectBinauralLibrary,
      onSelectVhaLibrary,
      onDispatchError,
    } = this.props
    const { isActive } = this.state

    if (isActive === false) {
      return null
    }

    return (
      <div className={cx('DevTools', { isActive })}>
        <h3 style={{ marginTop: 5 }}>
          <code>
            {'<'}DevTools{' />'}
          </code>
        </h3>

        <div className="Devtools-section">
          <label>VHA library</label>
          <label>
            <input
              type="radio"
              name="vhaLibrary"
              checked={vhaLibrary === VhaLibraries.WAAPI}
              readOnly
              onClick={() => onSelectVhaLibrary(VhaLibraries.WAAPI)}
            />
            Web Audio API
          </label>

          <label>
            <input
              type="radio"
              name="vhaLibrary"
              checked={vhaLibrary === VhaLibraries.TOOLKIT}
              readOnly
              onClick={() => onSelectVhaLibrary(VhaLibraries.TOOLKIT)}
            />
            3DTI Toolkit
          </label>

          <label>Binaural</label>
          <label>
            <input
              type="checkbox"
              checked={binauralLibrary === VhaLibraries.TOOLKIT}
              readOnly
              onClick={() =>
                onSelectBinauralLibrary(
                  binauralLibrary === VhaLibraries.WAAPI ? VhaLibraries.TOOLKIT : VhaLibraries.WAAPI
                )
              }
            />
            Use 3DTI's binaural spatialiser
          </label>
        </div>

        <div className="DevTools-section">
          <label>Errors</label>
          <Button loudness={Loudness.TALK} onClick={onDispatchError}>
            Dispatch error message
          </Button>
        </div>

        <div className="DevTools-section">
          <label>Secret places</label>
          <div>
            <Link to="/ui">UI Inventory</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    binauralLibrary: state.getIn(['effects', 'binauralLibrary']),
    vhaLibrary: state.getIn(['effects', 'vhaLibrary']),
  }),
  dispatch => ({
    onSelectBinauralLibrary: library => dispatch(setBinauralLibrary(library)),
    onSelectVhaLibrary: library => dispatch(setVhaLibrary(library)),
    onDispatchError: () => dispatch(displayError('An error occured', 'Some long nasty details')),
  })
)(DevTools)
