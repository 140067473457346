import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'

import { createAccount } from 'src/actions/user.actions.js'
import SignUpForm from 'src/components/SignUpForm.js'

import './UserSignUp.scss'

const ConnectedSignUpForm = connect(
  state => ({
    initialEmail: state.getIn(['auth', 'email']) || '',
  }),
  dispatch => ({
    onSubmit: ({ firstName, lastName, email, password }) =>
      dispatch(createAccount({ firstName, lastName, email, password })),
  })
)(SignUpForm)

/**
 * User Sign Up
 */
class UserSignUp extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props

    return (
      <div className="UserSignUp">
        <Helmet title={t('Sign up for Musiclarity')} />

        <header className="UserSignUp-header">
          <h1 className="ViewTitle">
            <T>Sign up for Musiclarity</T>
          </h1>

          <Link to="/signup/consultant">
            <T>Sign up as a consultant</T>
            {' →'}
          </Link>
        </header>

        <div className="UserSignUp-content">
          <div className="UserSignUp-form">
            <h3 className="Legend">
              <T>Your details</T>
            </h3>
            <ConnectedSignUpForm />
          </div>

          <div className="UserSignUp-info">
            <h3 className="Legend">
              <T>Why create an account?</T>
            </h3>

            <ul>
              <li>
                <T>
                  Your Virtual Hearing Aid settings are remembered for the next time you visit
                  Musiclarity
                </T>
              </li>
              <li>
                <T>Your Volume and Position settings for each song are remembered</T>
              </li>
              <li>
                <T>
                  These settings are also available on other web browsers and the Musiclarity
                  smartphone app (coming soon)
                </T>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslators(UserSignUp)
