/* eslint new-cap: 0 */
/* eslint no-console: 0 */
import { MediaPlayer } from 'dashjs'
import MediaPlayerEvents from 'dashjs/build/es5/src/streaming/MediaPlayerEvents.js'

import { fetchHrirsVector } from 'src/_vendor/3dti-toolkit/hrir.js'
import withBinauralListener from 'src/_vendor/3dti-toolkit/proxy.js'
import { MediaBufferingStates } from 'src/constants.js'
import context from 'src/audio/context.js'
import { fxIn, fxOut } from 'src/audio/effectChain.js'
import hrirUrls from 'src/audio/hrir-urls.js'
import { cast, clampOuter, getFirstFinite } from 'src/utils/math.js'

const decibelsToGain = value => Math.exp(value / 8.6858)

let players = null
let currentMix = new Map()
let numBufferingTracks = 0
const playerState = {
  isPaused: true,
  volume: 1,
}
let bufferStateChangeListeners = []

let masterOut = null

if (context !== null) {
  masterOut = context.createGain()
  fxOut.connect(masterOut)
  masterOut.connect(context.destination)
}

export const getContext = () => {
  return context
}

export const setupBinauralSpatialiserProxy = () => {
  return fetchHrirsVector(hrirUrls, context)
    .then(hrirsVector => {
      return withBinauralListener(context, hrirsVector)
    })
    .catch(err => {
      console.log('could not set up binaural proxy')
      console.error(err)
    })
}

export const setTracks = trackList => {
  if (players !== null) {
    stop()
    players.forEach(player => player.reset())
  }

  numBufferingTracks = trackList.size
  currentMix = trackList

  notifyBufferStateChange(MediaBufferingStates.BUFFERING)

  players = currentMix.toArray().map(track => {
    const audioElement = document.createElement('audio')

    const player = MediaPlayer().create()
    player.getDebug().setLogToBrowserConsole(false)
    player.initialize(audioElement, `${track.get('filepath')}.mpd`, false)

    player.on(MediaPlayerEvents.BUFFER_EMPTY, onBufferEmpty)
    player.on(MediaPlayerEvents.BUFFER_LOADED, onBufferLoaded)

    // Stream the player's audio through a panner and then to the master output
    const audioSourceNode = context.createMediaElementSource(audioElement)
    const panner = context.createPanner()
    audioSourceNode.connect(panner)
    panner.connect(fxIn)
    audioSourceNode.userInfo = { track }

    player.userInfo = { track, audioSourceNode, panner }
    return player
  })
}

export const getAudioNodes = () => {
  return players.map(player => player.userInfo.audioSourceNode)
}

export const onBufferStateChange = listener => {
  bufferStateChangeListeners = [...bufferStateChangeListeners, listener]
}

const notifyBufferStateChange = state => {
  bufferStateChangeListeners.forEach(listener => listener(state))
}

const onBufferEmpty = () => {
  if (isPlaying() === true) {
    internalPause()
  }

  if (numBufferingTracks === 0) {
    notifyBufferStateChange(MediaBufferingStates.BUFFERING)
  }

  numBufferingTracks++
}

const onBufferLoaded = () => {
  numBufferingTracks = Math.max(0, numBufferingTracks - 1)
  if (numBufferingTracks === 0) {
    notifyBufferStateChange(MediaBufferingStates.BUFFERED)

    if (playerState.isPaused === false) {
      internalPlay()
    }
  }
}

const internalPlay = () => {
  players.forEach(player => player.play())
}

export const play = () => {
  context.resume()

  if (numBufferingTracks === 0) {
    internalPlay()
  }
  playerState.isPaused = false
}

const internalPause = () => {
  players.forEach(player => player.pause())
}

export const pause = () => {
  internalPause()
  playerState.isPaused = true
}

export const stop = () => {
  pause()
}

export const isPlaying = () => {
  return playerState.isPaused === false
}

export const getCurrentTime = () => {
  if (players !== null) {
    return players[0].getVideoElement().currentTime
  }

  return 0
}

export const seek = time => {
  players.forEach(player => player.seek(time))
}

export const setVolume = volume => {
  const decibel = cast(0, -60, 1, 0, volume)
  const gain = decibelsToGain(decibel)
  const finalGain = gain >= 0.001 ? gain : 0
  masterOut.gain.value = gain
  playerState.volume = gain
}

export const setTrackVolume = (trackId, volume) => {
  const trackPlayer = players.find(x => x.userInfo.track.get('id') === trackId)
  if (trackPlayer) {
    trackPlayer.setVolume(volume)
  } else {
    console.warn(`Tried to set a volume of ${volume} to non-existing track ${trackId}`)
  }
}

export const setMuted = isMuted => {
  masterOut.gain.value = isMuted ? 0 : playerState.volume
}

export const setMutedTracks = trackIds => {
  players.forEach(player => player.setMute(trackIds.includes(player.userInfo.track.get('id'))))
}

export const setTrackPosition = (trackId, position) => {
  const player = players.find(x => x.userInfo.track.get('id') === trackId)
  const { panner } = player.userInfo
  const currPos = {
    x: panner.positionX.value,
    y: panner.positionY.value,
    z: panner.positionZ.value,
  }

  const clampedZ = clampOuter(getFirstFinite([position.z, currPos.z]), -0.3, 0.3)

  const actualPos = {
    x: getFirstFinite([position.x, currPos.x, 0]),
    y: getFirstFinite([position.y, currPos.y, 0]),
    z: clampedZ,
  }

  panner.setPosition(actualPos.x, actualPos.y, actualPos.z)
}
