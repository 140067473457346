import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './LyricsViewer.scss'

class LyricsViewer extends Component {
  static propTypes = {
    lyrics: PropTypes.array.isRequired,
    currentTime: PropTypes.number.isRequired,
  }

  render() {
    const { lyrics, currentTime } = this.props

    const currentLineIdx = lyrics.reduce(
      (idx, line, i) => (line.startTime < currentTime ? i : idx),
      -1
    )

    let currentPhraseIdx = -1

    if (currentLineIdx >= 0 && currentLineIdx < lyrics.length) {
      currentPhraseIdx = lyrics[currentLineIdx].texts.reduce((idx, phrase, i) => {
        return phrase.startTime < currentTime ? i : idx
      }, -1)
    }

    return (
      <div className="LyricsViewer">
        <div className="LyricsViewer-lines">
          {lyrics.map((line, i) => (
            <div
              key={i}
              className={classNames('LyricsViewer-line', {
                isPast: i < currentLineIdx - 1,
                isPrevious: i === currentLineIdx - 1,
                isCurrent: i === currentLineIdx,
                isNext: i === currentLineIdx + 1,
                isFuture: i > currentLineIdx + 1,
              })}
            >
              {line.texts.map((phrase, p) => (
                <span
                  key={phrase.startTime + phrase.text}
                  data-starttime={phrase.startTime}
                  className={classNames('LyricsViewer-phrase', {
                    isHighlighted: i === currentLineIdx && p === currentPhraseIdx,
                  })}
                >
                  {phrase.text}
                </span>
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default LyricsViewer
