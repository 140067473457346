import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash'
import { T } from 'lioness'

import { ListeningMethods } from 'src/constants.js'
import Checkbox from 'src/components/forms/Checkbox.js'

import './ListeningMethodPicker.scss'

/**
 * Listening Method Picker
 */
class ListeningMethodPicker extends PureComponent {
  static propTypes = {
    listeningMethod: PropTypes.oneOf(values(ListeningMethods)),
    isInverted: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    listeningMethod: null,
    isInverted: false,
  }

  render() {
    const { listeningMethod, isInverted, onChange } = this.props

    return (
      <div className="ListeningMethodPicker">
        <Checkbox
          isChecked={listeningMethod === ListeningMethods.HEADPHONES}
          isInverted={isInverted}
          onChange={() => onChange(ListeningMethods.HEADPHONES)}
        >
          <T>Headphones</T>
          <div className="ListeningMethodPicker-methodInfo">
            <p>
              <T>
                If you choose to remove your hearing aids Musiclarity will automatically compensate for your hearing loss using the results of your hearing test.
              </T>
            </p>
            <p>
              <T>
                This method might work best for people with mild or moderate losses.
              </T>
            </p>
          </div>
        </Checkbox>

        <Checkbox
          isChecked={listeningMethod === ListeningMethods.HEARING_AIDS}
          isInverted={isInverted}
          onChange={() => onChange(ListeningMethods.HEARING_AIDS)}
        >
          <T>Hearing aids with wireless bluetooth technology</T>
          <div className="ListeningMethodPicker-methodInfo">
            <p>
              <T>
                Check with your hearing health care professional whether your hearing aids have wireless connectivity. 
                You may require a bridging device, which will carry the music from your computer direct to your hearing aids.
              </T>
            </p>
            <p>
              <T>
                We recommend this option for people with more severe losses.
              </T>
            </p>
          </div>
        </Checkbox>

        <Checkbox
          isChecked={listeningMethod === ListeningMethods.HEARING_LOOP}
          isInverted={isInverted}
          onChange={() => onChange(ListeningMethods.HEARING_LOOP)}
        >
          <T>Hearing aids with induction loop / telecoil</T>
          <div className="ListeningMethodPicker-methodInfo">
            <T>
              Please check with your hearing health care professional whether you have a telecoil program. 
              You may choose to use this if option 1 or 2 are not available to you.
            </T>
          </div>
        </Checkbox>
      </div>
    )
  }
}

export default ListeningMethodPicker
