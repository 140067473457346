import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { T } from 'lioness'

import configs from 'src/configs.js'
import Button, { Loudness } from 'src/components/Button.js'
import Faq from 'src/components/Faq.js'
import View from 'src/components/View.js'
import AboutAims from 'src/components/about/AboutAims.js'
import AboutHorizons2020 from 'src/components/about/AboutHorizons2020.js'
import AboutPros from 'src/components/about/AboutPros.js'

import './AboutView.scss'

const AboutView = () => (
  <View className="AboutView">
    <Helmet title="About Musiclarity" />

    <div className="AboutView-content">
      <h1 className="ViewTitle AboutView-title">
        <T>About Musiclarity</T>
      </h1>

      <AboutAims className="AboutView-section" />

      <div className="AboutView-section">
        <h2>
          <T>Features</T>
        </h2>
        <AboutPros />
        <Button component={Link} to="/signup" loudness={Loudness.SCREAM}>
          <T>Get your account now</T>
        </Button>
      </div>

      <AboutHorizons2020 className="AboutView-section" />

      <div className="AboutView-section">
        <h2>
          <T>End-user license agreement</T>
        </h2>
        <p>
          <T
            message="You can read the EULA in its entirety at {{ link:this link }}."
            link={<Link to="/eula" />}
          />
        </p>
      </div>

      <div className="AboutView-section">
        <h2>Reactify</h2>

        <p>
          <T
            message={`
              Musiclarity is developed by {{ linkReactify:Reactify }}, with consultation services provided by {{ linkLenaBatra:Lena Batra }}.
              You can contact us through our {{ linkReactifyContact:contact page }}.
            `}
            linkReactify={<a href="http://reactifymusic.com" target="_blank" />}
            linkReactifyContact={<a href="http://reactifymusic.com/contact/" target="_blank" />}
            linkLenaBatra={<a href="http://www.fullcircletherapy.co.uk" target="_blank" />}
          />
        </p>
      </div>

      <Faq />
    </div>
  </View>
)

export default AboutView
