import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { T } from 'lioness'
import { omit } from 'lodash'
import { autobind } from 'core-decorators'

import { Positions } from 'src/constants.js'
import { resetPassword } from 'src/actions/user.actions.js'
import Button, { Loudness } from 'src/components/Button.js'
import { AnimatedTooltip, TooltipContent, TooltipActions } from 'src/components/Tooltip.js'

import './ResetPasswordButton.scss'

/**
 * Reset Password Button
 */
class ResetPasswordButton extends PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    onReset: PropTypes.func.isRequired,
    isResetting: PropTypes.bool.isRequired,
    resetError: ImmutablePropTypes.map,
    resetAt: PropTypes.number,
  }

  static defaultProps = {
    resetError: null,
    resetAt: null,
  }

  state = {
    isPrompting: false,
    didReset: false,
  }

  @autobind
  handleClick() {
    this.setState({
      isPrompting: true,
    })
  }

  @autobind
  handleReset() {
    this.props.onReset(this.props.userId)
  }

  @autobind
  handleCancel() {
    this.setState({
      isPrompting: false,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetAt !== this.props.resetAt) {
      this.setState({
        didReset: true,
      })

      setTimeout(() => {
        this.setState({
          isPrompting: false,
          didReset: false,
        })
      }, 4000)
    }
  }

  render() {
    const { isResetting, resetError } = this.props
    const { isPrompting, didReset } = this.state

    const buttonProps = omit(this.props, [
      'userId',
      'onReset',
      'isResetting',
      'resetError',
      'resetAt',
    ])

    return (
      <div className="ResetPasswordButton">
        <Button onClick={this.handleClick} {...buttonProps}>
          <T>Reset password</T>
        </Button>

        <AnimatedTooltip
          isVisible={isPrompting}
          position={Positions.BOTTOM}
          alignment={{ x: Positions.CENTER }}
          offset={{ top: 16 }}
          className="ResetPasswordButton-prompt"
        >
          {didReset === true ? (
            <div>
              <TooltipContent>
                <T>The password was successfully reset.</T>
              </TooltipContent>
            </div>
          ) : (
            <div>
              <TooltipContent>
                <h3>
                  <T>Reset password</T>
                </h3>

                <p>
                  <T>
                    You are about to reset this user's password. A new password will be sent to this
                    user's email address.
                  </T>
                </p>
                <p>
                  <T>Are you sure you want to proceed?</T>
                </p>

                {resetError !== null && (
                  <p className="ResetPasswordButton-prompt-error">
                    <T>We couldn't reset the user's password at this moment. Please try again!</T>
                  </p>
                )}
              </TooltipContent>

              <TooltipActions>
                <Button
                  loudness={Loudness.YELL}
                  isEnabled={isResetting === false}
                  onClick={this.handleReset}
                >
                  {isResetting === true ? <T>Resetting...</T> : <T>Yes, go ahead</T>}
                </Button>

                <Button
                  loudness={Loudness.WHISPER}
                  isEnabled={isResetting === false}
                  onClick={this.handleCancel}
                >
                  <T>No, abort mission</T>
                </Button>
              </TooltipActions>
            </div>
          )}
        </AnimatedTooltip>
      </div>
    )
  }
}

export default connect(
  state => ({
    isResetting: state.getIn(['user', 'passwordReset', 'isPending']),
    resetError: state.getIn(['user', 'passwordReset', 'error']),
    resetAt: state.getIn(['user', 'passwordReset', 'lastResetAt']),
  }),
  dispatch => ({
    onReset: userId => dispatch(resetPassword(userId)),
  })
)(ResetPasswordButton)
