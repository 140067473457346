export const ActionTypes = {
  // Player actions
  REQUEST_PLAY_SONG: 'REQUEST_PLAY_SONG',
  PLAY_SONG: 'PLAY_SONG',
  SET_CURRENT_SONG: 'SET_CURRENT_SONG',
  SET_PLAYBACK_STATE: 'SET_PLAYBACK_STATE',
  TOGGLE_PLAYBACK: 'TOGGLE_PLAYBACK',
  BEGIN_PLAYBACK: 'BEGIN_PLAYBACK',
  LOAD_SONG_TRACKS: 'LOAD_SONG_TRACKS',
  SET_PLAYER_BUFFERING_STATE: 'SET_PLAYER_BUFFERING_STATE',
  ABORT_PLAYING_SONG: 'ABORT_PLAYING_SONG',
  SEEK_TO: 'SEEK_TO',
  SET_PLAYBACK_TIME: 'SET_PLAYBACK_TIME',

  // Browsing actions
  SET_SONGS_LISTING_STYLE: 'SET_SONGS_LISTING_STYLE',
  SET_SONGS_FILTER: 'SET_SONGS_FILTER',
  SET_SONGS_SORTING: 'SET_SONGS_SORTING',

  // Mixer actions
  SET_MASTER_MUTED: 'SET_MASTER_MUTED',
  SET_MASTER_VOLUME: 'SET_MASTER_VOLUME',
  SET_MIXER_TRACKS: 'SET_MIXER_TRACKS',
  SET_SELECTED_TRACK: 'SET_SELECTED_TRACK',
  COPY_TRACK_SETTINGS: 'COPY_TRACK_SETTINGS',
  SET_TRACK_VOLUME: 'SET_TRACK_VOLUME',
  SET_TRACK_PAN: 'SET_TRACK_PAN',
  SET_TRACK_DISTANCE: 'SET_TRACK_DISTANCE',
  SET_TRACK_POSITION: 'SET_TRACK_POSITION',
  SET_TRACK_SOLOED: 'SET_TRACK_SOLOED',
  SET_TRACK_MUTED: 'SET_TRACK_MUTED',
  SET_BINAURAL_PRESET: 'SET_BINAURAL_PRESET',

  // VHA actions
  SET_VHA_INITIALIZED: 'SET_VHA_INITIALIZED',
  SET_VHA_ENABLED: 'SET_VHA_ENABLED',
  SET_VHA: 'SET_VHA',
  RESET_VHA: 'RESET_VHA',
  SET_VHA_LISTENING_METHOD: 'SET_VHA_LISTENING_METHOD',
  SET_VHA_FITTING_MODE: 'SET_VHA_FITTING_MODE',
  SET_VHA_PRESET: 'SET_VHA_PRESET',
  SET_VHA_HEARING_LOSS_CODE: 'SET_VHA_HEARING_LOSS_CODE',
  SET_VHA_AUDIOGRAM: 'SET_VHA_AUDIOGRAM',
  SET_VHA_AUDIOGRAM_FREQUENCY_VALUE: 'SET_VHA_AUDIOGRAM_FREQUENCY_VALUE',
  SET_NOISE_REDUCTION_AMOUNT: 'SET_NOISE_REDUCTION_AMOUNT',
  SET_TONE_CONTROL_MIRRORED: 'SET_TONE_CONTROL_MIRRORED',
  SET_TONE_CONTROL_GAIN: 'SET_TONE_CONTROL_GAIN',
  SET_TONE_CONTROL_GAINS: 'SET_TONE_CONTROL_GAINS',
  SET_COMPRESSION_MIRRORED: 'SET_COMPRESSION_MIRRORED',
  SET_COMPRESSION_AMOUNT: 'SET_COMPRESSION_AMOUNT',
  SET_LEVEL_ADJUSTMENT: 'SET_LEVEL_ADJUSTMENT',
  UNDO_VHA_ACTION: 'UNDO_VHA_ACTION',
  SET_HAS_VHA_UNDO_ACTIONS: 'SET_HAS_VHA_UNDO_ACTIONS',
  SET_VHA_LIBRARY: 'SET_VHA_LIBRARY',
  SET_BINAURAL_LIBRARY: 'SET_BINAURAL_LIBRARY',

  // Visualizer actions
  SET_VISUALIZER_ENABLED: 'SET_VISUALIZER_ENABLED',
  SET_VISUALIZER_TYPE: 'SET_VISUALISER_TYPE',
  SET_VISUALIZER_MODE: 'SET_VISUALISER_MODE',
  SET_VISUALIZER_MIRRORING: 'SET_VISUALIZER_MIRRORING',
  SET_VISUALIZER_SILENCE_RENDERING: 'SET_VISUALIZER_SILENCE_RENDERING',
  SET_VISUALIZER_ZERO_EDGES: 'SET_VISUALIZER_ZERO_EDGES',

  // Lyrics actions
  SET_LYRICS_ENABLED: 'SET_LYRICS_ENABLED',

  // Effects actions
  SET_EFFECTS_ENABLED: 'SET_EFFECTS_ENABLED',
  SET_EFFECTS_ACTIVE: 'SET_EFFECTS_ACTIVE',
  INITIALIZE_EFFECTS: 'INITIALIZE_EFFECTS',
  SET_EFFECTS_MOUNTED: 'SET_EFFECTS_MOUNTED',
  SET_EFFECT_ENABLED: 'SET_EFFECT_ENABLED',
  BATCH_SET_EFFECT_PARAM: 'BATCH_SET_EFFECT_PARAM',
  SET_EFFECT_PARAM: 'SET_EFFECT_PARAM',

  // Audiogram actions
  SET_AUDIOGRAM_INTRO_AS_SEEN: 'SET_AUDIOGRAM_INTRO_AS_SEEN',
  SET_AUDIOGRAM: 'SET_AUDIOGRAM',
  SET_AUDIOGRAM_FREQUENCY_VALUE: 'SET_AUDIOGRAM_FREQUENCY_VALUE',
  SET_AUDIOGRAM_ENABLED: 'SET_AUDIOGRAM_ENABLED',

  // Error actions
  DISPLAY_ERROR: 'DISPLAY_ERROR',
  DISMISS_ERROR: 'DISMISS_ERROR',

  // Authentication actions
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',

  // User actions
  GET_ME: 'GET_ME',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_ERROR: 'GET_ME_ERROR',
  RESET_ME: 'RESET_ME',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_ERROR: 'CREATE_ACCOUNT_ERROR',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_ERROR: 'DELETE_ACCOUNT_ERROR',
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
  UPDATE_USER_SETTINGS_SUCCESS: 'UPDATE_USER_SETTINGS_SUCCESS',
  UPDATE_USER_SETTINGS_ERROR: 'UPDATE_USER_SETTINGS_ERROR',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

  // User personalization
  GET_USER_VHA: 'GET_USER_VHA',
  GET_USER_VHA_SUCCESS: 'GET_USER_VHA_SUCCESS',
  GET_USER_VHA_ERROR: 'GET_USER_VHA_ERROR',
  UPDATE_USER_VHA: 'UPDATE_USER_VHA',
  UPDATE_USER_VHA_SUCCESS: 'UPDATE_USER_VHA_SUCCESS',
  UPDATE_USER_VHA_ERROR: 'UPDATE_USER_VHA_ERROR',
  GET_USER_MIX: 'GET_USER_MIX',
  GET_USER_MIX_SUCCESS: 'GET_USER_MIX_SUCCESS',
  GET_USER_MIX_ERROR: 'GET_USER_MIX_ERROR',
  UPDATE_USER_MIX: 'UPDATE_USER_MIX',
  UPDATE_USER_MIX_SUCCESS: 'UPDATE_USER_MIX_SUCCESS',
  UPDATE_USER_MIX_ERROR: 'UPDATE_USER_MIX_ERROR',
  APPLY_USER_MIX: 'APPLY_USER_MIX',
  SEND_USER_PROFILE: 'SEND_USER_PROFILE',
  SEND_USER_PROFILE_SUCCESS: 'SEND_USER_PROFILE_SUCCESS',
  SEND_USER_PROFILE_ERROR: 'SEND_USER_PROFILE_ERROR',

  // App boot/startup
  BOOT_APP: 'BOOT_APP',
  APP_BOOTED: 'APP_BOOTED',

  // Onboarding
  START_MAIN_ONBOARDING: 'START_MAIN_ONBOARDING',
  START_ONBOARDING_TOUR: 'START_ONBOARDING_TOUR',
  ABORT_ONBOARDING_TOUR: 'ABORT_ONBOARDING_TOUR',
  SET_ONBOARDING_TOUR_ACTIVE: 'SET_ONBOARDING_TOUR_ACTIVE',
  SET_ONBOARDING_TOUR_STEP: 'SET_ONBOARDING_TOUR_STEP',
  SET_TOUR_PREREQUISITE_ACTION_COMPLETED: 'SET_TOUR_PREREQUISITE_ACTION_COMPLETED',
  SET_TOUR_ACTION_COMPLETED: 'SET_TOUR_ACTION_COMPLETED',
  SET_ONBOARDING_TOUR_COMPLETED: 'SET_ONBOARDING_TOUR_COMPLETED',
  END_ONBOARDING_TOUR: 'END_ONBOARDING_TOUR',

  // Headphones usage
  SET_HEADPHONES_ENSURED: 'SET_HEADPHONES_ENSURED',

  // Barker
  SET_BARK_ACTIVE: 'SET_BARK_ACTIVE',
  SET_BARK_COMPLETED: 'SET_BARK_COMPLETED',

  // Feedback
  SET_FEEDBACK_FORM_OPEN: 'SET_FEEDBACK_FORM_OPEN',
  SET_FEEDBACK_DATA: 'SET_FEEDBACK_DATA',
  SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',
  SUBMIT_FEEDBACK_SUCCESS: 'SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_ERROR: 'SUBMIT_FEEDBACK_ERROR',
  FETCH_FEEDBACKS: 'FETCH_FEEDBACKS',
  FETCH_FEEDBACKS_SUCCESS: 'FETCH_FEEDBACKS_SUCCESS',
  FETCH_FEEDBACKS_ERROR: 'FETCH_FEEDBACKS_ERROR',

  // Localization
  SET_LOCALE: 'SET_LOCALE',

  // Accessibility
  SET_GLOBAL_TEXT_SIZE: 'SET_GLOBAL_TEXT_SIZE',

  // Consultants
  RESET_CONSULTANT: 'RESET_CONSULTANT',
  CREATE_CONSULTANT: 'CREATE_CONSULTANT',
  CREATE_CONSULTANT_SUCCESS: 'CREATE_CONSULTANT_SUCCESS',
  CREATE_CONSULTANT_ERROR: 'CREATE_CONSULTANT_ERROR',
  FETCH_PATIENTS: 'FETCH_PATIENTS',
  FETCH_PATIENTS_SUCCESS: 'FETCH_PATIENTS_SUCCESS',
  FETCH_PATIENTS_ERROR: 'FETCH_PATIENTS_ERROR',
  FETCH_PATIENT: 'FETCH_PATIENT',
  FETCH_PATIENT_SUCCESS: 'FETCH_PATIENT_SUCCESS',
  FETCH_PATIENT_ERROR: 'FETCH_PATIENT_ERROR',
  ADD_PATIENT: 'ADD_PATIENT',
  ADD_PATIENT_SUCCESS: 'ADD_PATIENT_SUCCESS',
  ADD_PATIENT_ERROR: 'ADD_PATIENT_ERROR',
  CREATE_PATIENT: 'CREATE_PATIENT',
  CREATE_PATIENT_SUCCESS: 'CREATE_PATIENT_SUCCESS',
  CREATE_PATIENT_ERROR: 'CREATE_PATIENT_ERROR',
  DELETE_PATIENT: 'DELETE_PATIENT',
  DELETE_PATIENT_SUCCESS: 'DELETE_PATIENT_SUCCESS',
  DELETE_PATIENT_ERROR: 'DELETE_PATIENT_ERROR',
  START_PATIENT_SESSION: 'START_PATIENT_SESSION',
  END_PATIENT_SESSION: 'END_PATIENT_SESSION',
  RESET_PATIENT_SESSION: 'RESET_PATIENT_SESSION',
  SEND_PATIENT_AUDIOGRAMS: 'SEND_PATIENT_AUDIOGRAMS',
  SEND_PATIENT_AUDIOGRAMS_SUCCESS: 'SEND_PATIENT_AUDIOGRAMS_SUCCESS',
  SEND_PATIENT_AUDIOGRAMS_ERROR: 'SEND_PATIENT_AUDIOGRAMS_ERROR',
}

export const SliderDirections = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
}

export const Mixers = {
  LEVELS: 'LEVELS',
  BINAURAL: 'BINAURAL',
}

export const BinauralPresets = {
  SURROUND_A: 'SURROUND_A',
  SURROUND_B: 'SURROUND_B',
  RADIAL_IN_FRONT: 'RADIAL_IN_FRONT',
  EVERYTHING_CENTERED: 'EVERYTHING_CENTERED',
}

export const VisualizerTypes = {
  BARS: 'BARS',
  LINES: 'LINES',
}

export const VisualizerModes = {
  SEPARATE: 'SEPARATE',
  ADDITIVE: 'ADDITIVE',
}

export const MIN_DECIBEL = -120

export const LyricsFormats = {
  SUBRIP: 'SUBRIP',
  WEBVTT: 'WEBVTT',
}

export const AppEnvironments = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGE: 'stage',
  PRODUCTION: 'production',
}

export const EffectTypes = {
  FILTER: 'FILTER',
  SPLITTER: 'SPLITTER',
  COMPRESSOR: 'COMPRESSOR',
  GAIN: 'GAIN',
  HEARING_AID: 'HEARING_AID',
  MONO: 'MONO',
}

export const FilterTypes = {
  LOWPASS: 'LOWPASS',
  HIGHPASS: 'HIGHPASS',
  BANDPASS: 'BANDPASS',
  LOWSHELF: 'LOWSHELF',
  HIGHSHELF: 'HIGHSHELF',
  PEAKING: 'PEAKING',
  NOTCH: 'NOTCH',
  ALLPASS: 'ALLPASS',
}

export const EffectUnits = {
  LEFT_EAR_EQ: 'LEFT_EAR_EQ',
  RIGHT_EAR_EQ: 'RIGHT_EAR_EQ',
  LEFT_EAR_TONE_CONTROL: 'LEFT_EAR_TONE_CONTROL',
  RIGHT_EAR_TONE_CONTROL: 'RIGHT_EAR_TONE_CONTROL',
}

export const EqBands = {
  LOW: 'LOW',
  MID: 'MID',
  HIGH: 'HIGH',
}

export const LeftMonoEffectTypes = {
  TO_LEFT: 'TO_LEFT',
  TO_CENTER: 'TO_CENTER',
}

export const Ears = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
}

export const AudiogramFrequencies = [125, 250, 500, 1000, 2000, 4000, 8000]

export const AudiogramFrequencyValues = [
  -20,
  -10,
  0,
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
]

export const HearingLossGrades = {
  NONE: 'NONE',
  MILD: 'MILD',
  MODERATE: 'MODERATE',
  SEVERE: 'SEVERE',
  PROFOUND: 'PROFOUND',
}

export const HearingAidFittings = {
  PRESETS: 'PRESETS',
  CODES: 'CODES',
  AUDIOGRAMS: 'AUDIOGRAMS',
}

export const EqualizerModes = {
  SINGLE: 'SINGLE',
  PER_EAR: 'PER_EAR',
}

export const KeyboardKeys = {
  SPACE: 32,
  NUMBER_0: 48,
  NUMBER_1: 49,
  NUMBER_2: 50,
  NUMBER_3: 51,
  NUMBER_4: 52,
  NUMBER_5: 53,
  NUMBER_6: 54,
  NUMBER_7: 55,
  NUMBER_8: 56,
  NUMBER_9: 57,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,
}

export const Features = {
  CLARITY: 'CLARITY',
  AUDIOGRAM_EQ_CONTROLS: 'AUDIOGRAM_EQ_CONTROLS',
}

export const ErrorTypes = {
  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  FAILED_TO_CREATE_ACCOUNT: 'FAILED_TO_CREATE_ACCOUNT',
  FAILED_TO_CREATE_PATIENT: 'FAILED_TO_CREATE_PATIENT',
  FAILED_TO_DELETE_ACCOUNT: 'FAILED_TO_DELETE_ACCOUNT',
  FAILED_TO_LOAD_TRACKS: 'FAILED_TO_LOAD_TRACKS',
  FAILED_TO_LOGIN: 'FAILED_TO_LOGIN',
  FAILED_TO_SEND_USER_PROFILE: 'FAILED_TO_SEND_USER_PROFILE',
  FAILED_TO_SUBMIT_FEEDBACK: 'FAILED_TO_SUBMIT_FEEDBACK',
  UNKNOWN: 'UNKNOWN',
}

export const AuthStates = {
  LOGGED_IN: 'LOGGED_IN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
}

export const Positions = {
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
  CENTER: 'CENTER',
}

export const OnboardingTours = {
  VHA: 'VHA',
  MIXER: 'MIXER',
}

export const Barks = {
  VHA: 'VHA',
  BETA_WARNING: 'BETA_WARNING',
  EULA: 'EULA',
}

export const Locales = {
  EN: 'en',
  ES: 'es',
  IT: 'it',
  SV: 'sv',
}

export const LocaleNames = {
  [Locales.EN]: 'English',
  [Locales.ES]: 'Spanish',
  [Locales.IT]: 'Italian',
  [Locales.SV]: 'Swedish',
}

export const VhaLibraries = {
  WAAPI: 'WAAPI',
  TOOLKIT: 'TOOLKIT',
}

export const VolumeModifications = {
  BIG_INCREASE: 'BIG_INCREASE',
  SMALL_INCREASE: 'SMALL_INCREASE',
  SMALL_DECREASE: 'SMALL_DECREASE',
  BIG_DECREASE: 'BIG_DECREASE',
}

export const MediaBufferingStates = {
  BUFFERING: 'BUFFERING',
  BUFFERED: 'BUFFERED',
}

export const SortingDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const FilterGroups = {
  GENRE: 'GENRE',
  LISTENING_LEVEL: 'LISTENING_LEVEL',
  VOCALS: 'VOCALS',
}

export const Genres = {
  BALLAD: 'BALLAD',
  BIGBAND: 'BIG BAND',
  BLUES: 'BLUES',
  CLASSICAL: 'CLASSICAL',
  COUNTRY: 'COUNTRY',
  DANCE: 'DANCE',
  ELECTRONIC: 'ELECTRONIC',
  FOLK: 'FOLK',
  JAZZ: 'JAZZ',
  POP: 'POP',
  RNB: 'R&B',
  RAP: 'RAP',
  REGGAE: 'REGGAE',
  ROCK: 'ROCK',
  SOUL: 'SOUL',
}

export const SongTags = {
  EASY_LISTENING: 'EASY_LISTENING',
  MEDIUM_LISTENING: 'MEDIUM_LISTENING',
  NON_EASY_LISTENING: 'NON_EASY_LISTENING',
  INSTRUMENTAL: 'INSTRUMENTAL',
  VOCALIZED: 'VOCALIZED',
}

export const ListingStyles = {
  GRID: 'GRID',
  LIST: 'LIST',
}

export const ListeningMethods = {
  HEADPHONES: 'HEADPHONES',
  HEARING_AIDS: 'HEARING_AIDS',
  HEARING_LOOP: 'HEARING_LOOP',
}

export const NavigationDirection = {
  BACK: 'BACK',
  FORWARD: 'FORWARD',
}

export const AccountTypes = {
  USER: 'USER',
  CONSULTANT: 'CONSULTANT',
}

export const ConsultantOccupation = {
  AUDIOLOGIST: 'AUDIOLOGIST',
  HEARING_THERAPIST: 'HEARING_THERAPIST',
  OTHER: 'OTHER',
}
