import { fromJS } from 'immutable'

import {
  ActionTypes,
  Ears,
  EqBands,
  HearingAidFittings,
  HearingLossGrades,
  ListeningMethods,
} from 'src/constants.js'
import { getEmptyAudiogram } from 'src/utils/audiogram-presets.js'

/**
 * This state is the source of truth for building UI and for
 * applying audio processing regarding the VHA.
 *
 * It is not representing filter or effect parameters. For that,
 * see effects.*.js.
 */
const initialState = fromJS({
  isActive: false,
  isEnabled: true,
  hasUndoActions: false,
  listeningMethod: ListeningMethods.HEADPHONES,
  aid: {
    fittingMode: HearingAidFittings.PRESETS,
    values: {
      [HearingAidFittings.PRESETS]: {
        [Ears.LEFT]: HearingLossGrades.NONE,
        [Ears.RIGHT]: HearingLossGrades.NONE,
      },
      [HearingAidFittings.CODES]: {
        [Ears.LEFT]: '',
        [Ears.RIGHT]: '',
      },
      [HearingAidFittings.AUDIOGRAMS]: {
        [Ears.LEFT]: getEmptyAudiogram(),
        [Ears.RIGHT]: getEmptyAudiogram(),
      },
    },
  },
  noise: {
    amount: 0,
  },
  toneControl: {
    isMirrored: false,
    values: {
      [Ears.LEFT]: {
        [EqBands.LOW]: 0,
        [EqBands.MID]: 0,
        [EqBands.HIGH]: 0,
      },
      [Ears.RIGHT]: {
        [EqBands.LOW]: 0,
        [EqBands.MID]: 0,
        [EqBands.HIGH]: 0,
      },
    },
  },
  compression: {
    isMirrored: false,
    values: {
      [Ears.LEFT]: 0,
      [Ears.RIGHT]: 0,
    },
  },
  levels: {
    values: {
      [Ears.LEFT]: 0,
      [Ears.RIGHT]: 0,
    },
  },
})

const vhaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_VHA_ACTIVE:
      return state.set('isActive', payload)
    case ActionTypes.SET_VHA_ENABLED:
      return state.set('isEnabled', payload)
    case ActionTypes.SET_VHA:
      return state.mergeDeep(fromJS(payload))
    case ActionTypes.RESET_VHA:
      return initialState.mergeDeep(fromJS(payload))
    case ActionTypes.SET_VHA_LISTENING_METHOD:
      return state.set('listeningMethod', payload.listeningMethod)
    case ActionTypes.SET_VHA_FITTING_MODE:
      return state.setIn(['aid', 'fittingMode'], payload)
    case ActionTypes.SET_VHA_PRESET:
      return state.setIn(['aid', 'values', HearingAidFittings.PRESETS, payload.ear], payload.preset)
    case ActionTypes.SET_VHA_HEARING_LOSS_CODE:
      return state.setIn(['aid', 'values', HearingAidFittings.CODES, payload.ear], payload.code)
    case ActionTypes.SET_VHA_AUDIOGRAM:
      return state.setIn(
        ['aid', 'values', HearingAidFittings.AUDIOGRAMS, payload.ear],
        payload.audiogram
      )
    case ActionTypes.SET_VHA_AUDIOGRAM_FREQUENCY_VALUE:
      return state.setIn(
        ['aid', 'values', HearingAidFittings.AUDIOGRAMS, payload.ear, payload.frequency],
        payload.value
      )
    case ActionTypes.SET_NOISE_REDUCTION_AMOUNT:
      return state.setIn(['noise', 'amount'], payload)
    case ActionTypes.SET_TONE_CONTROL_MIRRORED:
      return state.setIn(['toneControl', 'isMirrored'], payload)
    case ActionTypes.SET_TONE_CONTROL_GAIN:
      return state.setIn(['toneControl', 'values', payload.ear, payload.band], payload.gain)
    case ActionTypes.SET_TONE_CONTROL_GAINS:
      return state.mergeIn(['toneControl', 'values', payload.ear], payload.bandGains)
    case ActionTypes.SET_COMPRESSION_MIRRORED:
      return state.setIn(['compression', 'isMirrored'], payload)
    case ActionTypes.SET_COMPRESSION_AMOUNT:
      return state.setIn(['compression', 'values', payload.ear], payload.amount)
    case ActionTypes.SET_LEVEL_ADJUSTMENT:
      return state.setIn(['levels', 'values', payload.ear], payload.amount)
    case ActionTypes.SET_HAS_VHA_UNDO_ACTIONS:
      return state.set('hasUndoActions', payload)
    case ActionTypes.UNDO_VHA_ACTION:
    default:
      return state
  }
}

export default vhaReducer
