import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'

import './StickyPlayer.scss'

import Player from './Player.js'

const propTypes = {
  isVisible: PropTypes.bool,
}

const defaultProps = {
  isVisible: true,
}

const StickyPlayer = ({ isVisible }) => (
  <div className={classNames('StickyPlayer', { 'is-visible': isVisible })}>
    <div className="StickyPlayer-inner">
      <Player />
    </div>
  </div>
)

StickyPlayer.propTypes = propTypes
StickyPlayer.defaultProps = defaultProps

export default connect(state => ({
  isVisible:
    state.get('songs').find(song => song.get('id') === state.getIn(['player', 'song'])) !==
    undefined,
}))(StickyPlayer)
