import { ActionTypes } from 'src/constants.js'

/**
 * Reset consultant
 */
export const resetConsultant = () => ({
  type: ActionTypes.RESET_CONSULTANT,
})

/**
 * Consultant sign up
 */
export const createConsultant = ({
  firstName,
  lastName,
  email,
  password,
  placeOfWork,
  occupation,
}) => ({
  type: ActionTypes.CREATE_CONSULTANT,
  payload: { firstName, lastName, email, password, placeOfWork, occupation },
})

export const createConsultantSuccess = () => ({
  type: ActionTypes.CREATE_CONSULTANT_SUCCESS,
})

export const createConsultantError = err => ({
  type: ActionTypes.CREATE_CONSULTANT_ERROR,
  error: err,
})

/**
 * Fetch patients
 */
export const fetchPatients = consultantId => ({
  type: ActionTypes.FETCH_PATIENTS,
  payload: { consultantId },
})

export const fetchPatientsSuccess = patients => ({
  type: ActionTypes.FETCH_PATIENTS_SUCCESS,
  payload: { patients },
})

export const fetchPatientsError = err => ({
  type: ActionTypes.FETCH_PATIENTS_ERROR,
  error: err,
})

/**
 * Fetch a patient
 */
export const fetchPatient = patientId => ({
  type: ActionTypes.FETCH_PATIENT,
  payload: { patientId },
})

export const fetchPatientSuccess = patient => ({
  type: ActionTypes.FETCH_PATIENT_SUCCESS,
  payload: { patient },
})

export const fetchPatientError = err => ({
  type: ActionTypes.FETCH_PATIENT_ERROR,
  error: err,
})

/**
 * Add patient
 */
export const addPatient = userId => ({
  type: ActionTypes.ADD_PATIENT,
  payload: { userId },
})

export const addPatientSuccess = () => ({
  type: ActionTypes.ADD_PATIENT_SUCCESS,
})

export const addPatientError = err => ({
  type: ActionTypes.ADD_PATIENT_ERROR,
  error: err,
})

/**
 * Create patient
 */
export const createPatient = ({ email, password, firstName, lastName }) => ({
  type: ActionTypes.CREATE_PATIENT,
  payload: { email, password, firstName, lastName },
})

export const createPatientSuccess = patient => ({
  type: ActionTypes.CREATE_PATIENT_SUCCESS,
  payload: { patient },
})

export const createPatientError = err => ({
  type: ActionTypes.CREATE_PATIENT_ERROR,
  error: err,
})

/**
 * Delete patient
 */
export const deletePatient = patientId => ({
  type: ActionTypes.DELETE_PATIENT,
  payload: { patientId },
})

export const deletePatientSuccess = () => ({
  type: ActionTypes.DELETE_PATIENT_SUCCESS,
})

export const deletePatientError = err => ({
  type: ActionTypes.DELETE_PATIENT_ERROR,
  error: err,
})

/**
 * Patient sessions
 */
export const startPatientSession = patient => ({
  type: ActionTypes.START_PATIENT_SESSION,
  payload: { patient },
})

export const endPatientSession = () => ({
  type: ActionTypes.END_PATIENT_SESSION,
})

// This action is a side-effect-free version of endPatientSession(),
// in that sagas may listen to the above to perform side-effects,
// but not to this one.
export const resetPatientSession = () => ({
  type: ActionTypes.RESET_PATIENT_SESSION,
})

/**
 * Patient audiograms
 */
export const sendPatientAudiograms = patientId => ({
  type: ActionTypes.SEND_PATIENT_AUDIOGRAMS,
  payload: { patientId },
})

export const sendPatientAudiogramsSuccess = () => ({
  type: ActionTypes.SEND_PATIENT_AUDIOGRAMS_SUCCESS,
})

export const sendPatientAudiogramsError = err => ({
  type: ActionTypes.SEND_PATIENT_AUDIOGRAMS_ERROR,
  error: err,
})
