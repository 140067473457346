import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { values, capitalize } from 'lodash'

import './Button.scss'

export const Size = {
  TINY: 'TINY',
  SMALL: 'SMALL',
  NORMAL: 'NORMAL',
}

export const Loudness = {
  SCREAM: 'SCREAM',
  YELL: 'YELL',
  WARN: 'WARN',
  TALK: 'TALK',
  WHISPER: 'WHISPER',
}

export const Span = {
  AUTO: 'AUTO',
  BLOCK: 'BLOCK',
}

/**
 * Button component
 */
const Button = ({
  component,
  isEnabled,
  isActive,
  loudness,
  size,
  span,
  children,
  className,
  ...props
}) => {
  return React.createElement(
    component,
    {
      ...props,
      disabled: isEnabled === false,
      className: classNames(
        'Button',
        className,
        { isEnabled, isActive },
        `mod-${capitalize(loudness)}`,
        `mod-${capitalize(size)}`,
        `mod-${capitalize(span)}`
      ),
    },
    [
      <span className="Button-color" key="button-color" />,
      <span className="Button-content" key="button-content">
        {children}
      </span>,
    ]
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isEnabled: PropTypes.bool,
  isActive: PropTypes.bool,
  loudness: PropTypes.oneOf(values(Loudness)),
  size: PropTypes.oneOf(values(Size)),
  span: PropTypes.oneOf(values(Span)),
  className: PropTypes.string,
}

Button.defaultProps = {
  component: 'button',
  isEnabled: true,
  isActive: false,
  loudness: Loudness.TALK,
  size: Size.NORMAL,
  span: Span.AUTO,
  className: '',
}

export default Button
