import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ReactModal from 'react-modal'
import { values, capitalize } from 'lodash'
import { autobind } from 'core-decorators'

import { freezeBodyScrolling, unfreezeBodyScrolling } from 'src/utils/body-scrolling.js'

export const ModalSizes = {
  MINIMAL: 'MINIMAL',
  NORMAL: 'NORMAL',
}

export const ModalStyles = {
  TALK: 'TALK',
  YELL: 'YELL',
}

export class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    contentLabel: PropTypes.string,
    size: PropTypes.oneOf(values(ModalSizes)),
    modalStyle: PropTypes.oneOf(values(ModalStyles)),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    contentLabel: 'Modal',
    size: ModalSizes.NORMAL,
    modalStyle: ModalStyles.TALK,
    className: '',
  }

  state = {
    hasOpened: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      this.animateOpen()
    } else if (prevProps.isOpen === true && this.props.isOpen === false) {
      this.handleClose()
    }
  }

  componentWillUnmount() {
    unfreezeBodyScrolling()
  }

  @autobind
  animateOpen() {
    freezeBodyScrolling()
    this.setState({ hasOpened: true })
  }

  @autobind
  handleClose() {
    unfreezeBodyScrolling()
    this.setState({ hasOpened: false })
    this.props.onClose()
  }

  render() {
    const { isOpen, contentLabel, size, modalStyle, children, className } = this.props
    const { hasOpened } = this.state

    return (
      <ReactModal
        isOpen={isOpen}
        onAfterOpen={this.animateOpen}
        onRequestClose={this.handleClose}
        closeTimeoutMS={300}
        contentLabel={contentLabel}
        className={cx(
          className,
          'Modal',
          `size-${capitalize(size)}`,
          `mod-${capitalize(modalStyle)}`,
          { hasOpened }
        )}
        overlayClassName={cx('ModalOverlay', { hasOpened })}
      >
        {children}
      </ReactModal>
    )
  }
}

export const ModalHeader = ({ children, ...props }) => (
  <header className="ModalHeader" {...props}>
    {children}
  </header>
)

export const ModalTitle = ({ children, ...props }) => (
  <h3 className="ModalTitle" {...props}>
    {children}
  </h3>
)

export const ModalContent = ({ children, ...props }) => (
  <div className="ModalContent" {...props}>
    {children}
  </div>
)

export const ModalActions = ({ children, ...props }) => (
  <div className="ModalActions" {...props}>
    {children}
  </div>
)

export default Modal
