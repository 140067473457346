import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { T, withTranslators } from 'lioness'
import { autobind } from 'core-decorators'

import { updateUserSettings } from 'src/actions/user.actions.js'
import { UserSettingsSchema } from 'src/schemas/user.schemas.js'
import { validateObject } from 'src/schemas/validate.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import FormField from 'src/components/forms/FormField.js'
import FormFieldGroup from 'src/components/forms/FormFieldGroup.js'

// A prepared settings validation function
const validateSettings = validateObject(UserSettingsSchema)

/**
 * User Settings Form
 */
class UserSettingsForm extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super()

    this.state = {
      data: {
        firstName: props.user.get('firstName'),
        lastName: props.user.get('lastName'),
      },
      errors: null,
      hasSubmitted: false,
    }
  }

  @autobind
  handleChange(field, value) {
    const data = { ...this.state.data, [field]: value }
    const errors = validateSettings(data)

    this.setState(() => ({ data, errors }))
  }

  @autobind
  handleSubmit(evt) {
    evt.preventDefault()

    const { user, onSubmit } = this.props
    const { firstName, lastName } = this.state.data

    const errors = validateSettings({ firstName, lastName })

    if (isEmpty(errors)) {
      onSubmit({ id: user.get('_id'), firstName, lastName })
    }

    // TOOD: Focus first erronous field

    this.setState(() => ({ errors, hasSubmitted: true }))
  }

  render() {
    const { t } = this.props
    const { data, errors, hasSubmitted } = this.state
    const { firstName, lastName } = data

    return (
      <form className="UserSettingsForm" onSubmit={this.handleSubmit}>
        <FormFieldGroup>
          <FormField
            name="firstName"
            label={t('First name')}
            type="text"
            placeholder=""
            value={firstName}
            errors={errors ? errors.firstName : []}
            onChange={value => this.handleChange('firstName', value)}
            showErrors={hasSubmitted}
          />

          <FormField
            name="lastName"
            label={t('Last name')}
            type="text"
            placeholder=""
            value={lastName}
            errors={errors ? errors.lastName : []}
            onChange={value => this.handleChange('lastName', value)}
            showErrors={hasSubmitted}
          />
        </FormFieldGroup>

        <Button loudness={Loudness.YELL} size={Size.NORMAL} type="submit">
          <T>Save settings</T>
        </Button>
      </form>
    )
  }
}

export default connect(
  state => ({
    user: state.getIn(['user', 'user']),
  }),
  dispatch => ({
    onSubmit: ({ id, firstName, lastName }) =>
      dispatch(updateUserSettings({ id, firstName, lastName })),
  })
)(withTranslators(UserSettingsForm))
