import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { noop } from 'lodash'
import { autobind } from 'core-decorators'

import { roundDecimals } from 'src/utils/math.js'
import { SliderDirections } from 'src/constants.js'

import './SliderControl.scss'

const { abs, round } = Math

const toCssPercent = val => `${val * 100}%`

/**
 * Slider control component
 */
class SliderControl extends Component {
  // Props
  static propTypes = {
    initialValue: PropTypes.number,
    upperThreshold: PropTypes.number,
    lowerThreshold: PropTypes.number,
    isEnabled: PropTypes.bool,
    valueFormatter: PropTypes.func,
    onValueChange: PropTypes.func,
    onBeginDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    direction: PropTypes.oneOf([SliderDirections.HORIZONTAL, SliderDirections.VERTICAL]),
    className: PropTypes.string,
    style: PropTypes.object,
  }

  // Default props
  static defaultProps = {
    initialValue: 0.5,
    upperThreshold: 1,
    lowerThreshold: 0,
    isEnabled: true,
    valueFormatter: val => round(val * 100),
    onValueChange: noop,
    onBeginDrag: noop,
    onEndDrag: noop,
    direction: SliderDirections.VERTICAL,
    className: '',
    style: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      isDragging: false,
      value: props.initialValue,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue !== this.state.value) {
      this.setState({
        ...this.state,
        value: nextProps.initialValue,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleDrag)
    window.removeEventListener('mouseup', this.endDrag)
  }

  @autobind
  startDrag() {
    window.addEventListener('mousemove', this.handleDrag)
    window.addEventListener('mouseup', this.endDrag)

    this.setState({
      ...this.state,
      isDragging: true,
    })

    this.props.onBeginDrag()
  }

  @autobind
  endDrag() {
    window.removeEventListener('mousemove', this.handleDrag)

    this.setState({
      ...this.state,
      isDragging: false,
    })

    this.props.onEndDrag()
  }

  @autobind
  handleDrag(evt) {
    const { isDragging } = this.state

    if (isDragging) {
      this.setValueFromMousePosition(evt)
    }
  }

  @autobind
  handleClick(evt) {
    this.setValueFromMousePosition(evt)
  }

  @autobind
  setValueFromMousePosition(mouseEvent) {
    const { upperThreshold, lowerThreshold, direction, onValueChange } = this.props
    const { value } = this.state
    const { slider } = this.refs

    const rangeLength = abs(upperThreshold - lowerThreshold)

    let nextValue = value

    if (direction === SliderDirections.VERTICAL) {
      const bounds = slider.getBoundingClientRect()
      let dTop = mouseEvent.pageY - (bounds.top + window.scrollY)
      dTop = Math.max(dTop, 0)
      dTop = Math.min(dTop, slider.offsetHeight)

      const origoTop = slider.offsetHeight * abs(upperThreshold) / rangeLength

      const relValue = (origoTop - dTop) / slider.offsetHeight
      nextValue = relValue * rangeLength
    } else if (direction === SliderDirections.HORIZONTAL) {
      const bounds = slider.getBoundingClientRect()
      let dLeft = mouseEvent.pageX - (bounds.left + window.scrollX)
      dLeft = Math.max(dLeft, 0)
      dLeft = Math.min(dLeft, bounds.width)

      const origoLeft = bounds.width * abs(lowerThreshold) / rangeLength

      const relValue = (dLeft - origoLeft) / bounds.width
      nextValue = relValue * rangeLength
    }

    this.setState({
      ...this.state,
      value: nextValue,
    })

    onValueChange(nextValue)
  }

  getBarStyles() {
    const { upperThreshold, lowerThreshold, direction } = this.props
    const { value } = this.state

    const rangeLength = abs(upperThreshold - lowerThreshold)
    const origoFromUpper = abs(upperThreshold) / rangeLength
    const origoFromLower = abs(lowerThreshold) / rangeLength

    const styles = {}

    if (direction === SliderDirections.VERTICAL) {
      if (value >= 0) {
        styles.bottom = toCssPercent(origoFromLower)
      } else {
        styles.top = toCssPercent(origoFromUpper)
      }

      styles.height = toCssPercent(abs(value) / rangeLength)
      styles.left = 0
      styles.right = 0
    } else {
      if (value >= 0) {
        styles.left = toCssPercent(origoFromLower)
      } else {
        styles.right = toCssPercent(origoFromUpper)
      }

      styles.width = toCssPercent(abs(value) / rangeLength)
      styles.top = 0
      styles.bottom = 0
    }

    return styles
  }

  render() {
    const { isEnabled, direction, upperThreshold, valueFormatter, className, style } = this.props
    const { value } = this.state

    const barStyles = this.getBarStyles()

    return (
      <div
        ref="slider"
        className={classNames('SliderControl', className, {
          'is-disabled': !isEnabled,
          'is-zero': roundDecimals(2, value) === 0,
          'is-inTopBit': value / upperThreshold >= 0.85,
          'mod-vertical': direction === SliderDirections.VERTICAL,
          'mod-horizontal': direction === SliderDirections.HORIZONTAL,
        })}
        style={style}
      >
        <div className="SliderControl-bar" style={barStyles}>
          <span className="SliderControl-bar-value">{valueFormatter(value)}</span>
        </div>

        <div
          className="SliderControl-invisibleControl"
          onMouseDown={this.startDrag}
          onClick={this.handleClick}
        />
      </div>
    )
  }
}

export default SliderControl
