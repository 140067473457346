/* eslint no-console: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { values } from 'lodash'

import withFftData from 'src/audio/withFftData.js'
import { VisualizerTypes, VisualizerModes } from 'src/constants.js'
import { getAdditiveFftData, getNormalizedDb, getEmptyFftData } from 'src/utils/fft-helpers.js'
import { currentSongHasLyrics } from 'src/utils/lyrics-helpers.js'
import VisualizerSettings from 'src/components/VisualizerSettings.js'
import SongPlayerItem from 'src/components/SongPlayerItem.js'
import BarSpectrumGraph from 'src/components/BarSpectrumGraph.js'
import LineSpectrumGraph from 'src/components/LineSpectrumGraph.js'
import SongMiniMixer from 'src/components/SongMiniMixer.js'

import './Visualizer.scss'

/**
 * A fullscreen-ish visualizer with spectrum graphics for all
 * playing tracks
 */
class Visualizer extends Component {
  static propTypes = {
    tracksFftData: PropTypes.object,
    tracks: PropTypes.object,
    visualizerType: PropTypes.oneOf(values(VisualizerTypes)),
    visualizerMode: PropTypes.oneOf(values(VisualizerModes)),
    song: PropTypes.object,
    mirrorGraphs: PropTypes.bool,
    renderSilence: PropTypes.bool,
    setEdgesToZero: PropTypes.bool,
    hasLyrics: PropTypes.bool,
  }

  static defaultProps = {
    visualizerType: VisualizerTypes.LINES,
    visualizerMode: VisualizerModes.ADDITIVE,
    mirrorGraphs: false,
    renderSilence: false,
    setEdgesToZero: false,
    hasLyrics: false,
  }

  render() {
    const {
      isEnabled,
      tracksFftData,
      tracks,
      song,
      visualizerType,
      visualizerMode,
      mirrorGraphs,
      renderSilence,
      setEdgesToZero,
      hasLyrics,
    } = this.props

    if (!tracks) {
      return null
    }

    // Filter and normalize FFT data
    const normalizedFfts = tracksFftData
      .filter((trackFftData, trackId) => tracks.has(trackId))
      .map(trackFftData => trackFftData.map(getNormalizedDb))

    // Prepare FFT data for presentation
    const fftData =
      visualizerMode === VisualizerModes.ADDITIVE
        ? getAdditiveFftData(normalizedFfts)
        : normalizedFfts

    // Reverse tracks, so that they stack correctly
    // TODO: Do this via CSS?
    const sortedTracks = tracks.reverse()

    return (
      <div
        className={classNames(
          'Visualizer',
          `mode-${visualizerMode.toLowerCase()}`,
          `type-${visualizerType.toLowerCase()}`,
          { isEnabled, hasLyrics }
        )}
      >
        <div className="Visualizer-top">
          <div className="Visualizer-top-inner">
            <div className="Visualizer-songInfo">
              <SongPlayerItem song={song} />
              <span className="Visualizer-songInfo-names">
                <span className="Visualizer-songInfo-title">{song.get('title')}</span>
                <span className="Visualizer-songInfo-artist">{song.get('artist')}</span>
              </span>
            </div>

            <div className="Visualizer-mixerArea">
              <SongMiniMixer tracks={tracks} />
            </div>
          </div>
        </div>

        <VisualizerSettings />

        <div className="Visualizer-spectrums">
          {sortedTracks
            .map(track => {
              const trackFftValues = fftData.get(track.get('id')) || getEmptyFftData()

              switch (visualizerType) {
                case VisualizerTypes.BARS:
                  return (
                    <BarSpectrumGraph
                      track={track}
                      values={trackFftValues}
                      renderSilence={renderSilence}
                      key={track.get('id')}
                      className="Visualizer-spectrum"
                    />
                  )
                case VisualizerTypes.LINES:
                default:
                  return (
                    <LineSpectrumGraph
                      track={track}
                      values={trackFftValues}
                      width={window.innerWidth}
                      height={120}
                      mirrorGraph={mirrorGraphs}
                      renderSilence={renderSilence}
                      setEdgesToZero={setEdgesToZero}
                      key={track.get('id')}
                      className="Visualizer-spectrum"
                      style={{
                        height:
                          visualizerMode === VisualizerModes.SEPARATE
                            ? `${100 / fftData.size}%`
                            : null,
                      }}
                    />
                  )
              }
            })
            .toArray()}
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  song: state.get('songs').find(x => x.get('id') === state.getIn(['player', 'song'])),
  tracks: state.getIn(['mixer', 'tracks']),
  visualizerType: state.getIn(['visualizer', 'type']),
  visualizerMode: state.getIn(['visualizer', 'mode']),
  mirrorGraphs: state.getIn(['visualizer', 'mirrorGraphs']),
  renderSilence: state.getIn(['visualizer', 'renderSilence']),
  setEdgesToZero: state.getIn(['visualizer', 'setEdgesToZero']),
  hasLyrics: currentSongHasLyrics(state) && state.getIn(['lyrics', 'isEnabled']),
}))(withFftData(() => true, Visualizer))
