import { fork, put, select, take } from 'redux-saga/effects'

import { ActionTypes } from 'src/constants.js'
import { setTrackPosition } from 'src/actions/mixer.actions.js'
import { getSong } from 'src/reducers/songs.reducer.js'
import { BinauralPresetValues } from 'src/utils/track-controls.js'

function* applyBinauralPresets() {
  while (true) {
    const { payload: { songId, preset } } = yield take(ActionTypes.SET_BINAURAL_PRESET)

    const song = yield select(state => getSong(songId, state.get('songs')))
    const trackIds = song
      .get('tracks')
      .sort(track => -track.get('trackNumber'))
      .map(track => track.get('id'))
      .toArray()

    for (let i = 0; i < trackIds.length; i++) {
      yield put(setTrackPosition(trackIds[i], BinauralPresetValues[preset].get(i)))
    }
  }
}

/**
 * Makes sure binaural positioning is always up-to-date
 */
function* applyBinauralMix() {
  while (true) {
    yield take([ActionTypes.BEGIN_PLAYBACK, ActionTypes.APPLY_USER_MIX])

    const tracks = yield select(state => state.getIn(['mixer', 'tracks']))
    for (const trackId of tracks.keySeq().toArray()) {
      yield put(setTrackPosition(trackId, tracks.get(trackId).get('position')))
    }
  }
}

export default function* mixerSagas() {
  yield fork(applyBinauralPresets)
  yield fork(applyBinauralMix)
}
