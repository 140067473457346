import React from 'react'

import SvgIcon from './SvgIcon.js'

export default () => (
  <SvgIcon>
    <g transform="translate(0,-952.36218)">
      <path d="M17.366,956.83c-3.919,0-7.405,2.366-8.842,5.791c-0.044,0.108-0.06,0.214-0.06,0.331
        c0,0.314,0.21,0.644,0.507,0.77c0.406,0.171,0.93-0.043,1.1-0.448c1.171-2.789,4.054-4.773,7.295-4.773
        c4.345,0,7.234,3.135,7.234,7.513c0,2.778-1.3,4.643-2.703,6.19c-0.702,0.774-1.428,1.439-2.017,2.104
        c-0.59,0.664-1.122,1.36-1.122,2.278c0,2.018-1.6,3.616-3.617,3.616c-2.02,0-3.617-1.598-3.617-3.616v-1.67
        c0.181-0.001,0.361-0.062,0.504-0.172l4.582-3.506c0.247,0.038,0.5,0.062,0.756,0.062c2.756,0,5.009-2.252,5.009-5.01
        c0-2.754-2.252-5.006-5.009-5.006s-5.008,2.252-5.008,5.006c0,0.255,0.024,0.503,0.061,0.749l-3.503,4.581
        c-0.243,0.318-0.211,0.813,0.069,1.097l0.87,0.87v3c0,2.913,2.373,5.285,5.287,5.285s5.286-2.373,5.286-5.285
        c0-0.173,0.198-0.595,0.704-1.165c0.507-0.572,1.241-1.259,2-2.097c1.518-1.671,3.139-4,3.139-7.311
        C26.27,960.863,22.549,956.83,17.366,956.83z M17.366,962.952c1.854,0,3.339,1.485,3.339,3.338c0,1.855-1.485,3.34-3.339,3.34
        c-1.854,0-3.338-1.485-3.338-3.34C14.028,964.436,15.512,962.952,17.366,962.952z M13.097,968.89
        c0.416,0.679,0.983,1.244,1.661,1.66l-3.165,2.419l-0.912-0.914L13.097,968.89z" />
    </g>
  </SvgIcon>
)
