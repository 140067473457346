import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T } from 'lioness'

import { endPatientSession } from 'src/actions/consultant.actions.js'
import Button from 'src/components/Button.js'

/**
 * End Patient Session Button
 */
class EndPatientSessionButton extends PureComponent {
  static propTypes = {
    onEnd: PropTypes.func.isRequired,
    children: PropTypes.node,
  }

  static defaultProps = {
    children: null,
  }

  render() {
    const { onEnd, children, ...buttonProps } = this.props

    return (
      <Button onClick={onEnd} {...buttonProps}>
        {children || <T>End session</T>}
      </Button>
    )
  }
}

export default connect(null, dispatch => ({
  onEnd: () => dispatch(endPatientSession()),
}))(EndPatientSessionButton)
