import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'
import { autobind } from 'core-decorators'

import { sendUserProfile } from 'src/actions/user.actions.js'
import { SILVER, WHITESMOKE } from 'src/styles/colors.js'
import Button, { Size, Loudness } from 'src/components/Button.js'
import { Modal, ModalHeader, ModalTitle, ModalContent, ModalActions } from 'src/components/Modal.js'
import FormField from 'src/components/forms/FormField.js'
import VHARepresentation from 'src/components/vha/VHARepresentation.js'

/**
 * User VHA Profile Modal
 */
class UserVHAProfileModal extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isPending: PropTypes.bool.isRequired,
    lastSent: PropTypes.number.isRequired,
    lastRecipient: PropTypes.string,
    onSendProfile: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    lastRecipient: '',
  }

  constructor(props) {
    super(props)

    this.state = {
      recipientEmail: props.lastRecipient,
      errors: [],
      didSend: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastSent !== this.props.lastSent) {
      this.giveSuccessFeedback()
    }
  }

  @autobind
  onProfileRecipientEmailChange(email) {
    const { t } = this.props

    let errors = []

    if (this.state.recipientEmail.length > 0 && email.length === 0) {
      errors = [{ message: t('The recipient email must not be empty') }]
    }

    this.setState(() => ({ recipientEmail: email, errors }))
  }

  @autobind
  onSendProfileSubmit() {
    const { user, onSendProfile } = this.props
    const { recipientEmail } = this.state

    onSendProfile(user.get('_id'), recipientEmail)
  }

  giveSuccessFeedback() {
    this.setState(() => ({ didSend: true }))

    window.setTimeout(() => {
      this.props.onClose()
      this.setState(() => ({ didSend: false }))
    }, 3000)
  }

  render() {
    const { isOpen, isPending, onClose } = this.props
    const { recipientEmail, errors, didSend } = this.state

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader>
          <ModalTitle>
            <T>Send your Musiclarity profile</T>
          </ModalTitle>
        </ModalHeader>

        <ModalContent>
          <label>Profile preview</label>
          <div
            style={{
              height: 184,
              overflowY: 'scroll',
              marginBottom: 16,
              paddingRight: 16,
              paddingLeft: 16,
              backgroundColor: WHITESMOKE,
              border: `1px solid ${SILVER}`,
              borderRadius: 2,
            }}
          >
            <VHARepresentation />
          </div>

          <FormField
            type="email"
            label="Recipient email"
            helpText="Type in your audiologist's email address"
            name="recipientEmail"
            value={recipientEmail}
            placeholder="firstname.lastname@example.com"
            showErrors
            errors={errors}
            onChange={this.onProfileRecipientEmailChange}
            autoFocus
            style={{ marginBottom: 0 }}
          />

          <div>
            <small>
              Sent with Mailgun (<a href="https://www.mailgun.com/terms">T&Cs</a>)
            </small>
          </div>
        </ModalContent>

        <ModalActions>
          <Button
            isEnabled={
              isPending === false &&
              didSend === false &&
              errors.length === 0 &&
              recipientEmail.length > 0
            }
            size={Size.SMALL}
            loudness={Loudness.YELL}
            onClick={this.onSendProfileSubmit}
          >
            {didSend === true ? <T>Your profile has been sent!</T> : <T>Submit your profile</T>}
          </Button>

          <Button
            isEnabled={isPending === false}
            size={Size.SMALL}
            loudness={Loudness.WHISPER}
            onClick={onClose}
          >
            <T>Cancel</T>
          </Button>
        </ModalActions>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    user: state.getIn(['user', 'user']),
    isPending: state.getIn(['user', 'vhaProfileShare', 'isPending']),
    lastRecipient: state.getIn(['user', 'vhaProfileShare', 'lastRecipient']),
    lastSent: state.getIn(['user', 'vhaProfileShare', 'lastSent']),
  }),
  dispatch => ({
    onSendProfile: (id, recipientEmail) => dispatch(sendUserProfile({ id, recipientEmail })),
  })
)(withTranslators(UserVHAProfileModal))
