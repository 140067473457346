import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Ears, EqBands } from 'src/constants.js'
import { setToneControlGain } from 'src/actions/vha.actions.js'
import FxThreeBandEq from 'src/components/FxThreeBandEq.js'

/**
 * Ear Tone Controls
 */
class EarToneControls extends Component {
  static propTypes = {
    ear: PropTypes.oneOf([Ears.LEFT, Ears.RIGHT]).isRequired,
    lowGain: PropTypes.number.isRequired,
    midGain: PropTypes.number.isRequired,
    highGain: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { lowGain, midGain, highGain, onChange } = this.props

    return (
      <FxThreeBandEq lowGain={lowGain} midGain={midGain} highGain={highGain} onChange={onChange} />
    )
  }
}

export default connect(
  (state, { ear }) => ({
    lowGain: state.getIn(['vha', 'toneControl', 'values', ear, EqBands.LOW]),
    midGain: state.getIn(['vha', 'toneControl', 'values', ear, EqBands.MID]),
    highGain: state.getIn(['vha', 'toneControl', 'values', ear, EqBands.HIGH]),
  }),
  (dispatch, { ear }) => ({
    onChange: (band, gain) => dispatch(setToneControlGain(ear, band, gain)),
  })
)(EarToneControls)
