import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { clamp } from 'lodash'
import { autobind } from 'core-decorators'

import { getColorForTrackNumber } from 'src/utils/colors.js'
import { cast } from 'src/utils/math.js'

import './BinauralTrackPositioner.scss'

const getScale = cast(1.75, 0.25, 1, -1)

const getTrackCssTransform = yPos => `scale(${getScale(yPos)}) translate3d(-50%, -50%, 0)`

/**
 * x/z positioner of a track
 */
class BinauralTrackPositioner extends Component {
  static propTypes = {
    track: PropTypes.object.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    bounds: PropTypes.func.isRequired,
    onPosition: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isSelected: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      isDragging: false,
      position: props.track.get('position'),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      position: nextProps.track.get('position'),
    })
  }

  @autobind
  handlePress() {
    this.setState({
      ...this.state,
      isDragging: true,
    })

    window.addEventListener('mousemove', this.handleDrag)
    window.addEventListener('mouseup', this.handleRelease)

    this.props.onSelect()
  }

  @autobind
  handleDrag(evt) {
    const { bounds, isEnabled, onPosition } = this.props
    const { isDragging, position } = this.state

    if (isEnabled && isDragging) {
      const rect = bounds()

      const constrainedMouseX = clamp(evt.pageX, rect.left, rect.left + rect.width)
      const constrainedMouseY = clamp(evt.pageY, rect.top, rect.top + rect.height)

      let newX = (constrainedMouseX - (rect.left + rect.width / 2)) / (rect.width / 2)
      let newZ = (constrainedMouseY - (rect.top + rect.height / 2)) / (rect.height / 2)

      const vectorLength = Math.sqrt(Math.pow(newX, 2) + Math.pow(newZ, 2))

      // Clamp the x/z values within the unit circle
      if (vectorLength > 1) {
        // The track's z is it's position in 3D space, and is negative when in
        // front of the listener. We negate it here for use when calculating
        // trigonometrical stuff.
        const trigX = newX
        const trigZ = -newZ

        // Who needs a math lib, right?
        const shortestAngle = Math.asin(trigZ / vectorLength)
        let angle = shortestAngle

        if (trigX < 0 && trigZ >= 0) {
          angle = Math.PI - angle
        } else if (trigX < 0 && trigZ < 0) {
          angle = -Math.PI - angle
        }

        const circleEdgeX = Math.cos(angle)
        const circleEdgeY = Math.sin(angle)

        newX = circleEdgeX
        newZ = -circleEdgeY
      }

      const newPos = position.set('x', newX).set('z', newZ)

      this.setState({
        ...this.state,
        position: newPos,
      })

      onPosition(newPos)
    }
  }

  @autobind
  handleRelease() {
    window.removeEventListener('mousemove', this.handleDrag)
    window.removeEventListener('mouseup', this.handleRelease)

    this.setState({
      ...this.state,
      isDragging: false,
    })
  }

  render() {
    const { track, isEnabled, isSelected, onSelect } = this.props
    const { position, isDragging } = this.state

    return (
      <div
        className={classNames('BinauralTrackPositioner', {
          isDragging,
          isSelected,
          isDisabled: !isEnabled,
        })}
        onClick={onSelect}
        style={{
          top: `${50 + position.get('z') * 50}%`,
          left: `${50 + position.get('x') * 50}%`,
          transform: getTrackCssTransform(position.get('y')),
          backgroundColor: getColorForTrackNumber(track.get('trackNumber')),
        }}
        onMouseDown={this.handlePress}
      >
        <div className="BinauralTrackPositioner-name">{track.get('name')}</div>
      </div>
    )
  }
}

export default BinauralTrackPositioner
