import React, { Component } from 'react'
import PropTypes from 'prop-types'
import tinytime from 'tinytime'

import * as CustomPropTypes from 'src/prop-types.js'

/**
 * Pretty Date
 */
class PrettyDate extends Component {
  static propTypes = {
    date: CustomPropTypes.date.isRequired,
    format: PropTypes.string,
  }

  static defaultProps = {
    format: '{MMMM} {Do}, {YYYY}',
  }

  constructor(props) {
    super(props)

    this.renderTime = tinytime(props.format).render

    this.date = props.date instanceof Date ? props.date : new Date(props.date)
    this.dateStr = this.date.toString()
  }

  render() {
    const prettyDate = this.renderTime(this.date)
    return <time dateTime={this.dateStr}>{prettyDate}</time>
  }
}

export default PrettyDate
