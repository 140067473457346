import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LionessProvider } from 'lioness'
import { values } from 'lodash'

import { Locales } from 'src/constants.js'
import messages from 'src/l10n/messages.json'

function trimExcessiveWhitespace(str) {
  if (typeof str !== 'string') {
    return str
  }
  return str.replace(/\s+/g, ' ').trim()
}

/**
 * Configured Lioness Provider
 */
class ConfiguredLionessProvider extends PureComponent {
  static propTypes = {
    locale: PropTypes.oneOf(values(Locales)).isRequired,
    children: PropTypes.node.isRequired,
  }

  render() {
    const { locale, children } = this.props

    return (
      <LionessProvider
        messages={messages}
        locale={locale}
        transformInput={trimExcessiveWhitespace}
        debug={false}
      >
        {children}
      </LionessProvider>
    )
  }
}

export default connect(state => ({
  locale: state.getIn(['l10n', 'locale']),
}))(ConfiguredLionessProvider)
