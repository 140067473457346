import { ActionTypes } from 'src/constants.js'

export const startMainOnboarding = (listeningMethod, hearingInfo, songId) => ({
  type: ActionTypes.START_MAIN_ONBOARDING,
  payload: { listeningMethod, hearingInfo, songId },
})

export const startTour = (tourName, startingStep = 1) => ({
  type: ActionTypes.START_ONBOARDING_TOUR,
  payload: { tourName, startingStep },
})

export const abortTour = tourName => ({
  type: ActionTypes.ABORT_ONBOARDING_TOUR,
  payload: { tourName },
})

export const setTourActive = (tourName, isActive) => ({
  type: ActionTypes.SET_ONBOARDING_TOUR_ACTIVE,
  payload: { tourName, isActive },
})

export const setTourStep = (tourName, step) => ({
  type: ActionTypes.SET_ONBOARDING_TOUR_STEP,
  payload: { tourName, step },
})

export const setTourPrerequisiteCompleted = (tourName, actionType, isCompleted) => ({
  type: ActionTypes.SET_TOUR_PREREQUISITE_ACTION_COMPLETED,
  payload: { tourName, actionType, isCompleted },
})

export const setTourActionCompleted = (tourName, actionType, isCompleted) => ({
  type: ActionTypes.SET_TOUR_ACTION_COMPLETED,
  payload: { tourName, actionType, isCompleted },
})

export const setTourCompleted = (tourName, isCompleted) => ({
  type: ActionTypes.SET_ONBOARDING_TOUR_COMPLETED,
  payload: { tourName, isCompleted },
})

export const endTour = tourName => ({
  type: ActionTypes.END_ONBOARDING_TOUR,
  payload: { tourName },
})

// Tour specific actions

export const setHeadphonesEnsured = isEnsured => ({
  type: ActionTypes.SET_HEADPHONES_ENSURED,
  payload: isEnsured,
})
