import React from 'react'

import SvgIcon from './SvgIcon.js'

export default () => (
  <SvgIcon>
    <g id="Group" transform="translate(2.000000, 6.000000)">
      <rect className="SvgIcon-fill" x="0" y="7.02777778" width="15.365" height="8.30555556" />
      <polygon className="SvgIcon-fill" points="15.50125 0 15.50125 22.533846 3.75 11.266923" />
      <path
        className="SvgIcon-stroke"
        d="M23.11,11.2112222 C23.11,14.0740833 21.605625,16.56 19.375,17.9476667 L24.24625,22.9271667 C27.67,20.2138056 29.88125,15.9805278 29.88125,11.2112222 C29.88125,6.72941667 27.926875,2.71975 24.855,0 L20.030625,4.9335 C21.89,6.37675 23.11,8.63841667 23.11,11.2112222 L23.11,11.2112222 Z"
      />
    </g>
  </SvgIcon>
)
