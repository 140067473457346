import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './ScreenTakeOverMessage.scss'

/**
 * Screen Take Over Message
 */
class ScreenTakeOverMessage extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children } = this.props

    return (
      <div className="ScreenTakeOverMessage">
        <div className="ScreenTakeOverMessage-content">{children}</div>
      </div>
    )
  }
}

export default ScreenTakeOverMessage
