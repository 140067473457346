import React, { Component } from 'react'
import { T } from 'lioness'
import { autobind } from 'core-decorators'

import ScreenTakeOverMessage from 'src/components/ScreenTakeOverMessage.js'

const WIDTH_THRESHOLD = 960

/**
 * Wide Screens Only Notice
 */
class WideScreensOnlyNotice extends Component {
  state = {
    windowWidth: window.innerWidth,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  @autobind
  handleResize() {
    this.setState(() => ({ windowWidth: window.innerWidth }))
  }

  render() {
    if (this.state.windowWidth >= WIDTH_THRESHOLD) {
      return null
    }

    return (
      <ScreenTakeOverMessage>
        <h1>
          <T>Our apologies</T>
        </h1>
        <p>
          <T>
            Musiclarity is currently only supported on larger screens. Please make your browser
            window as large as possible.
          </T>
        </p>
        <p>
          <T>
            You might also try to zoom out the browser window by pressing the Ctrl (or Cmd if you're
            on a Mac) and - keys simulataneously.
          </T>
        </p>
      </ScreenTakeOverMessage>
    )
  }
}

export default WideScreensOnlyNotice
