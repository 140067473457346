import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { values as getValues } from 'lodash'
import { T } from 'lioness'

import { Ears } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import AudiogramGraph from './AudiogramGraph.js'

/**
 * Audiogram form
 */
class AudiogramForm extends Component {
  static propTypes = {
    ear: PropTypes.oneOf(getValues(Ears)),
    values: CustomPropTypes.audiogram.isRequired,
    isInteractive: PropTypes.bool,
    onValueChange: PropTypes.func,
  }

  static defaultProps = {
    isInteractive: true,
    onValueChange: () => {},
  }

  render() {
    const { ear, values, isInteractive, onValueChange, ...props } = this.props

    return (
      <div className="AudiogramForm" {...props}>
        <label>{ear === Ears.LEFT ? <T>Left ear</T> : <T>Right ear</T>}</label>

        <AudiogramGraph
          ear={ear}
          data={values}
          isInteractive={isInteractive}
          onFrequencyValueChange={onValueChange}
        />
      </div>
    )
  }
}

export default AudiogramForm
