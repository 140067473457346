import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, merge } from 'lodash'
import { T, withTranslators } from 'lioness'
import { autobind } from 'core-decorators'

import { UserSignUpSchema } from 'src/schemas/user.schemas.js'
import { validateObject } from 'src/schemas/validate.js'
import Button, { Loudness } from 'src/components/Button.js'
import FormField from 'src/components/forms/FormField.js'
import FormFieldGroup from 'src/components/forms/FormFieldGroup.js'

// Get a prepared sign up validation function
const validateSignUpData = validateObject(UserSignUpSchema)

/**
 * Sign up form
 */
class SignUpForm extends Component {
  static propTypes = {
    initialEmail: PropTypes.string,
    preSubmitText: PropTypes.node,
    submitLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialEmail: '',
    preSubmitText: null,
    submitLabel: null,
    isSubmitting: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      data: {
        email: props.initialEmail,
        password: '',
        verifiedPassword: '',
      },
      errors: null,
      hasSubmitted: false,
    }
  }

  handleChange(field, value) {
    const data = merge({}, this.state.data, { [field]: value })
    const errors = validateSignUpData(data)

    this.setState(() => ({ data, errors }))
  }

  @autobind
  handleSubmit(evt) {
    evt.preventDefault()

    const { onSubmit } = this.props
    const { firstName, lastName, email, password, verifiedPassword } = this.state.data

    const errors = validateSignUpData({ firstName, lastName, email, password, verifiedPassword })

    if (isEmpty(errors)) {
      onSubmit({ firstName, lastName, email, password })
    }

    // TOOD: Focus first erronous field

    this.setState(() => ({ errors, hasSubmitted: true }))
  }

  render() {
    const { preSubmitText, submitLabel, isSubmitting, t } = this.props
    const { data, errors, hasSubmitted } = this.state
    const { firstName, lastName, email, password, verifiedPassword } = data

    return (
      <form className="SignUpForm" onSubmit={this.handleSubmit}>
        <FormFieldGroup>
          <FormField
            name="firstName"
            label={t('First name')}
            type="text"
            placeholder=""
            value={firstName}
            errors={errors ? errors.firstName : []}
            onChange={value => this.handleChange('firstName', value)}
            showErrors={hasSubmitted}
          />

          <FormField
            name="lastName"
            label={t('Last name')}
            type="text"
            placeholder=""
            value={lastName}
            errors={errors ? errors.lastName : []}
            onChange={value => this.handleChange('lastName', value)}
            showErrors={hasSubmitted}
          />
        </FormFieldGroup>

        <FormField
          name="email"
          label={t('Email')}
          type="text"
          placeholder={t('jane.doe@example.com')}
          value={email}
          errors={errors ? errors.email : []}
          onChange={value => this.handleChange('email', value)}
          showErrors={hasSubmitted}
        />

        <FormFieldGroup>
          <FormField
            name="password"
            label={t('Password')}
            type="password"
            placeholder=""
            value={password}
            errors={errors ? errors.password : []}
            onChange={value => this.handleChange('password', value)}
            showErrors={hasSubmitted}
          />

          <FormField
            name="verifiedPassword"
            label={t('Verify password')}
            type="password"
            placeholder=""
            value={verifiedPassword}
            errors={errors ? errors.verifiedPassword : []}
            onChange={value => this.handleChange('verifiedPassword', value)}
            showErrors={hasSubmitted}
          />
        </FormFieldGroup>

        {preSubmitText}

        <Button loudness={Loudness.YELL} type="submit" isEnabled={isSubmitting === false}>
          {submitLabel || <T>Create account</T>}
        </Button>
      </form>
    )
  }
}

export default withTranslators(SignUpForm)
