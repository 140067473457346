import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslators } from 'lioness'

import { FilterTypes, EqBands } from 'src/constants.js'
import FxFilter from 'src/components/FxFilter.js'

import './FxThreeBandEq.scss'

/**
 * Fx Three Band Eq
 */
class FxThreeBandEq extends Component {
  static propTypes = {
    lowGain: PropTypes.number.isRequired,
    midGain: PropTypes.number.isRequired,
    highGain: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { lowGain, midGain, highGain, onChange, t } = this.props

    return (
      <div className="FxThreeBandEq">
        <FxFilter
          label={t('Low')}
          isEnabled
          frequency={250}
          type={FilterTypes.NOTCH}
          Q={0.5}
          gain={lowGain}
          maxGain={10}
          minGain={-10}
          onGainChange={gain => onChange(EqBands.LOW, gain)}
          onToggle={() => {}}
        />

        <FxFilter
          label={t('Mid')}
          isEnabled
          frequency={800}
          type={FilterTypes.NOTCH}
          Q={0.5}
          gain={midGain}
          maxGain={10}
          minGain={-10}
          onGainChange={gain => onChange(EqBands.MID, gain)}
          onToggle={() => {}}
        />

        <FxFilter
          label={t('High')}
          isEnabled
          frequency={2500}
          type={FilterTypes.NOTCH}
          Q={0.5}
          gain={highGain}
          maxGain={10}
          minGain={-10}
          onGainChange={gain => onChange(EqBands.HIGH, gain)}
          onToggle={() => {}}
        />
      </div>
    )
  }
}

export default withTranslators(FxThreeBandEq)
