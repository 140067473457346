import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'
import Select from 'react-select'

import { Ears, HearingLossGrades, HearingAidFittings } from 'src/constants.js'
import { setVhaPreset } from 'src/actions/vha.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import Modal, {
  ModalSizes,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalActions,
} from 'src/components/Modal.js'
import { VHASplitPane, Pane } from 'src/components/vha/VHASplitPane.js'

const presetPropType = PropTypes.oneOf([
  HearingLossGrades.NONE,
  HearingLossGrades.MILD,
  HearingLossGrades.MODERATE,
  HearingLossGrades.SEVERE,
  HearingLossGrades.PROFOUND,
])

/**
 * Hearing Aid Preset Form
 */
class HearingAidPresetForm extends Component {
  static propTypes = {
    leftPreset: presetPropType.isRequired,
    rightPreset: presetPropType.isRequired,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  state = {
    promptedEar: null,
    promptedPreset: null,
  }

  handleChange(ear, value) {
    const { onChange } = this.props

    if (value === HearingLossGrades.SEVERE || value === HearingLossGrades.PROFOUND) {
      this.setState(() => ({
        promptedEar: ear,
        promptedPreset: value,
      }))
    } else {
      onChange(ear, value)
    }
  }

  handleSeverePrompAnswer(isConfirmed) {
    const { onChange } = this.props
    const { promptedEar, promptedPreset } = this.state

    this.setState(
      () => ({ promptedEar: null, promptedPreset: null }),
      () => {
        if (isConfirmed) {
          onChange(promptedEar, promptedPreset)
        }
      }
    )
  }

  render() {
    const { leftPreset, rightPreset, t } = this.props
    const { promptedEar } = this.state

    return (
      <div className="HearingAidPresetForm">
        <p>
          <T>How would you describe your hearing in each ear?</T>
        </p>

        <VHASplitPane>
          <Pane>
            <label>
              <T>Left ear</T>
            </label>
            <Select
              value={leftPreset}
              options={[
                { value: HearingLossGrades.NONE, label: t('Normal hearing') },
                { value: HearingLossGrades.MILD, label: t('Mild hearing loss') },
                { value: HearingLossGrades.MODERATE, label: t('Moderate hearing loss') },
                { value: HearingLossGrades.SEVERE, label: t('Severe hearing loss') },
                { value: HearingLossGrades.PROFOUND, label: t('Profound hearing loss') },
              ]}
              onChange={preset => this.handleChange(Ears.LEFT, preset.value)}
              searchable={false}
              clearable={false}
              style={{
                width: 240,
              }}
            />
          </Pane>

          <Pane>
            <label>
              <T>Right ear</T>
            </label>
            <Select
              value={rightPreset}
              options={[
                { value: HearingLossGrades.NONE, label: t('Normal hearing') },
                { value: HearingLossGrades.MILD, label: t('Mild hearing loss') },
                { value: HearingLossGrades.MODERATE, label: t('Moderate hearing loss') },
                { value: HearingLossGrades.SEVERE, label: t('Severe hearing loss') },
                { value: HearingLossGrades.PROFOUND, label: t('Profound hearing loss') },
              ]}
              onChange={preset => this.handleChange(Ears.RIGHT, preset.value)}
              searchable={false}
              clearable={false}
              style={{
                width: 240,
              }}
            />
          </Pane>
        </VHASplitPane>

        <Modal
          isOpen={promptedEar !== null}
          onClose={() => {}}
          size={ModalSizes.MINIMAL}
          contentLabel={t('Confirm severe hearing loss')}
          className="isMinimal"
        >
          <ModalHeader>
            <ModalTitle>
              <T>A friendly warning</T>
            </ModalTitle>
          </ModalHeader>

          <ModalContent>
            <p>
              <T
                message={`{{ strong:Warning: }} The {{ em:Severe }} and {{ em:Profound }} hearing loss options might cause very loud volumes. Be sure to lower the volume of your headphones before continuing.`}
                strong={<strong />}
                em={<em />}
              />
            </p>

            <p>
              <T>Do you want to apply this setting?</T>
            </p>
          </ModalContent>

          <ModalActions>
            <Button
              loudness={Loudness.YELL}
              size={Size.SMALL}
              onClick={() => this.handleSeverePrompAnswer(true)}
            >
              <T>Yes, apply it</T>
            </Button>
            <Button
              loudness={Loudness.WHISPER}
              size={Size.SMALL}
              onClick={() => this.handleSeverePrompAnswer(false)}
            >
              <T>No, go back</T>
            </Button>
          </ModalActions>
        </Modal>
      </div>
    )
  }
}

export default connect(
  state => ({
    leftPreset: state.getIn(['vha', 'aid', 'values', HearingAidFittings.PRESETS, Ears.LEFT]),
    rightPreset: state.getIn(['vha', 'aid', 'values', HearingAidFittings.PRESETS, Ears.RIGHT]),
  }),
  dispatch => ({
    onChange: (ear, preset) => dispatch(setVhaPreset(ear, preset)),
  })
)(withTranslators(HearingAidPresetForm))
