/* eslint no-underscore-dangle: 0 */
import { takeEvery } from 'redux-saga'
import { call, select } from 'redux-saga/effects'

import { ActionTypes } from 'src/constants.js'
import { getContext, getAudioNodes } from 'src/audio/dashPlayer.js'
import { createTrackFftSource } from 'src/audio/analyser.js'
import { clearFftSources, setFftSourceForTrack } from 'src/audio/fft-emitter.js'

/**
 * Clears track FFT sources whenever a song change occurs.
 */
export function* clearAnalayzersOnSongChange() {
  yield call(clearFftSources)
}

/**
 * Adds track FFT analysers for each track that has begun playback
 */
export function* addAnalyzersToNewTracks() {
  const audioNodes = getAudioNodes()
  const tracks = yield select(state => state.getIn(['mixer', 'tracks']))

  for (const node of audioNodes) {
    const track = tracks.find(x => x.get('id') === node.userInfo.track.get('id'))

    if (!track) {
      continue
    }

    const fftSource = createTrackFftSource(getContext(), node)
    setFftSourceForTrack(track, fftSource)
  }
}

/* - - - - - */

export default function* analyserSagas() {
  yield [
    takeEvery(ActionTypes.LOAD_SONG_TRACKS, clearAnalayzersOnSongChange),
    takeEvery(ActionTypes.BEGIN_PLAYBACK, addAnalyzersToNewTracks),
  ]
}
