import React from 'react'
import cx from 'classnames'

import './FormFieldGroup.scss'

export default ({ type = 'div', className, children, ...props }) =>
  React.createElement(
    type,
    {
      ...props,
      className: cx('FormFieldGroup', className),
    },
    children
  )
