import React from 'react'

import { getColorForTrackNumber } from 'src/utils/colors.js'
import SpectrumLabel from 'src/components/SpectrumLabel.js'

/**
 * A bar spectrum graph for displaying a track's frequency amplitudes
 */
const BarSpectrumGraph = ({ track, values, renderSilence, ...rest }) => {
  let points = []

  for (let i = 0; i < values.length; i++) {
    points.push(
      <span
        key={i}
        className="val"
        style={{
          left: `${100 * i / values.length}%`,
          bottom: 0,
          width: `${100 / values.length}%`,
          height: `${100 * values[i]}%`,
          minHeight: renderSilence ? '1px' : 0,
          backgroundColor: getColorForTrackNumber(track.get('trackNumber')),
        }}
      >
        {' '}
      </span>
    )
  }

  return (
    <div {...rest}>
      <SpectrumLabel track={track} />

      <div className="data">{points}</div>
    </div>
  )
}

export default BarSpectrumGraph
