import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { values } from 'lodash'

import { OnboardingTours } from 'src/constants.js'
import Button, { Loudness } from 'src/components/Button.js'
import OnboardingTourStarter from 'src/components/onboarder/OnboardingTourStarter.js'

/**
 * Onboarding Tour Start Button
 */
class OnboardingTourStartButton extends PureComponent {
  static propTypes = {
    tour: PropTypes.oneOf(values(OnboardingTours)).isRequired,
    isTourCompleted: PropTypes.bool.isRequired,
    loudness: PropTypes.oneOf(values(Loudness)),
    children: PropTypes.node.isRequired,
  }

  render() {
    const { tour, isTourCompleted, loudness, children } = this.props

    return (
      <OnboardingTourStarter
        tour={tour}
        component={Button}
        loudness={isTourCompleted ? loudness : Loudness.SCREAM}
      >
        {children}
      </OnboardingTourStarter>
    )
  }
}

export default connect((state, { tour }) => ({
  isTourCompleted: state.getIn(['onboarder', 'tours', tour, 'isCompleted']),
}))(OnboardingTourStartButton)
