import context from 'src/audio/context.js'
import DetunaEffect from 'src/audio/detuna/DetunaEffect.js'

export default class Filter extends DetunaEffect {
  constructor(params) {
    super()

    this.filter = context.createBiquadFilter()

    this.input = this.filter
    this.output = this.filter

    this.initialise(params)
  }

  set frequency(value) {
    this.filter.frequency.setValueAtTime(value, 0)
  }

  set Q(value) {
    this.filter.Q.setValueAtTime(value, 0)
  }

  set gain(value) {
    this.filter.gain.setValueAtTime(value, 0)
  }

  set filterType(value) {
    this.filter.type = value
  }
}
