export const BLACK = '#0B1C31'
export const DARKGRAY = '#666B72'
export const GRAY = '#9EA0A3'
export const SILVER = '#E2E4E6'
export const WHITESMOKE = '#F7F9FB'
export const WHITE = '#fefefe'

export const BLUE = '#0068e9'
export const PURPLE = '#8300c5'

export const RED = '#ef0043'
export const ORANGE = '#f86347'
export const YELLOW = '#f9f172'
export const GREEN = '#01f3af'
export const TURQUOISE = '#00ede5'
export const ROYALBLUE = '#5286ff'
export const ORCHIDPURPLE = '#b563e2'
