import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { requestPlaySong, setPlaybackState } from 'src/actions/player.actions.js'
import AlbumCover from 'src/components/AlbumCover.js'
import PlaybackButton from 'src/components/PlaybackButton.js'

import './SongPlayerItem.scss'

const SongPlayerItem = ({ song, isPlaying, isLoading, onTogglePlayback }) => (
  <div className={classNames('SongPlayerItem', { isPlaying })}>
    <div className="SongPlayerItem-content">
      <AlbumCover
        className="SongPlayerItem-image"
        src={song.get('image')}
        alt={song.get('title')}
      />

      <PlaybackButton
        isPlayable={true}
        isPlaying={isPlaying}
        isLoading={isLoading}
        onTogglePlayback={onTogglePlayback}
        extraClasses="SongPlayerItem-button"
      />
    </div>
  </div>
)

export default connect(
  (state, { song }) => {
    const isCurrentSong = state.getIn(['player', 'song']) === song.get('id')
    const playbackState = state.getIn(['player', 'isPlaying'])

    return {
      isPlaying: isCurrentSong === true && playbackState === true,
      isLoading: isCurrentSong === true && state.getIn(['player', 'isLoading']),
    }
  },
  (dispatch, { song }) => ({
    onTogglePlayback: isNowPlaying => {
      if (isNowPlaying === true) {
        dispatch(requestPlaySong(song.get('id')))
      } else {
        dispatch(setPlaybackState(false))
      }
    },
  })
)(SongPlayerItem)
