import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { curry, isString } from 'lodash'

import { AuthStates } from 'src/constants.js'
import { isLoggedIn as getIsLoggedIn } from 'src/reducers/auth.reducer.js'

/**
 * Return an component that renders `Comp` when the user's
 * authenticated state matches `authState`.
 *
 * @param  {string}    authState One of `AuthStates`
 * @param  {Component} Comp      A React component class or function
 * @return {Component}           A React component
 */
export const whenInAuthState = curry((authState, Comp) => {
  const toPathname = authState === AuthStates.LOGGED_IN ? '/login' : '/me'

  return connect(state => ({
    isLoggedIn:
      getIsLoggedIn(state.get('auth')) && isString(state.getIn(['user', 'user', 'email'])),
  }))(({ isLoggedIn, ...props }) => {
    const currentState = isLoggedIn ? AuthStates.LOGGED_IN : AuthStates.NOT_LOGGED_IN

    if (authState === currentState) {
      return React.createElement(Comp, props)
    } else {
      return <Redirect to={toPathname} />
    }
  })
})

/**
 * Return an component that renders `Comp` when the user's
 * authenticated state is logged in.
 *
 * @param  {Component} Comp A React component class or function
 * @return {Component}      A React component
 */
export const whenLoggedIn = whenInAuthState(AuthStates.LOGGED_IN)

/**
 * Return an component that renders `Comp` when the user's
 * authenticated state is not logged in.
 *
 * @param  {Component} Comp A React component class or function
 * @return {Component}      A React component
 */
export const whenNotLoggedIn = whenInAuthState(AuthStates.NOT_LOGGED_IN)

export const whenAdmin = Comp => {
  const adminEmails = [
    'office@alexanderwallin.com',
    'yuli@reactifymusic.com',
    'ragnar@reactifymusic.com',
  ]

  return connect(state => ({
    isAdmin:
      getIsLoggedIn(state.get('auth')) && adminEmails.indexOf(state.getIn(['auth', 'email'])) >= 0,
  }))(({ isAdmin, ...props }) => (isAdmin ? <Comp {...props} /> : <Redirect to="/" />))
}
