import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import { GatewayDest } from 'react-gateway'
import { T } from 'lioness'

// Disable default <Tabs> styles
//
// Replace this with npm dependency, hopefully:
// https://github.com/reactjs/react-tabs/pull/139
import { Tabs } from 'src/_vendor/react-tabs/main.js'
Tabs.setUseDefaultStyles(false)

import './App.scss'

// Components
import DevGrid from 'src/components/DevGrid.js'
import DevTools from 'src/components/DevTools.js'
import ErrorNotifier from 'src/components/ErrorNotifier.js'
import KeyboardControls from 'src/components/KeyboardControls.js'
import LyricsContainer from 'src/components/LyricsContainer.js'
import StickyNavigation from 'src/components/StickyNavigation.js'
import StickyPlayer from 'src/components/StickyPlayer.js'
import SubmissiveScrollContainer from 'src/components/SubmissiveScrollContainer.js'
import VisualizerContainer from 'src/components/VisualizerContainer.js'
import WideScreensOnlyNotice from 'src/components/WideScreensOnlyNotice.js'
import GlobalTextSizeControls from 'src/components/a11y/GlobalTextSizeControls.js'
// import BetaNotification from 'src/components/barker/BetaNotification.js'
import EulaPrompt from 'src/components/barker/EulaPrompt.js'
import FeedbackModal from 'src/components/feedback/FeedbackModal.js'

// Views
import BrowseView from 'src/components/BrowseView.js'
import HomeView from 'src/components/HomeView.js'
import LoginView from 'src/components/LoginView.js'
import MeView from 'src/components/MeView.js'
import MixerView from 'src/components/MixerView.js'
import NotFoundView from 'src/components/NotFoundView.js'
import AboutView from 'src/components/about/AboutView.js'
import SignUpView from 'src/components/user/SignUpView.js'
import VHAView from 'src/components/vha/VHAView.js'
import EulaView from 'src/components/EulaView.js'

import UIInventoryView from 'src/components/devtools/UIInventoryView.js'
import AdminView from 'src/components/admin/AdminView.js'

/**
 * Puppet master
 */
class App extends Component {
  static propTypes = {
    hasOpenModalPage: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
  }

  render() {
    const { hasOpenModalPage, location } = this.props

    return (
      <div className="App">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          titleTemplate="%s - Musiclarity"
          defaultTitle="Musiclarity"
          title="Enjoy music your way"
        />

        <SubmissiveScrollContainer className="App-main" isSubmissive={hasOpenModalPage}>
        
          <div className="App-content">
            <StickyNavigation />

            <GlobalTextSizeControls />

            <Switch>
              {/* Public routes */}
              <Route exact path="/" component={HomeView} />
              <Route path="/browse" component={BrowseView} />
              <Route path="/vha" component={VHAView} />
              <Route path="/mixer" component={MixerView} />
              <Route path="/about" component={AboutView} />
              <Route path="/login" component={LoginView} />
              <Route path="/signup" component={SignUpView} />
              <Route path="/me" component={MeView} />
              <Route path="/eula" component={EulaView} />

              {/* Secret places */}
              <Route path="/ui" component={UIInventoryView} />
              <Route path="/admin" component={AdminView} />

              {/* 404 */}
              <Route path="*" component={NotFoundView} />
            </Switch>

            {location.pathname !== '/' && (
              <footer className="App-footer">
                <p>
                  <T>
                    This project has received funding from the European Union’s Horizon 2020
                    research and innovation programme under grant agreement No 644051
                  </T>
                </p>
              </footer>
            )}
          </div>
        </SubmissiveScrollContainer>

        <StickyPlayer />
        <VisualizerContainer />
        <LyricsContainer />
        <KeyboardControls />
        <ErrorNotifier />
        <WideScreensOnlyNotice />
        <EulaPrompt />

        <DevTools />
        <DevGrid />

        <div className="App-modals">
          <FeedbackModal />
        </div>

        <div className="App-gateways">
          <GatewayDest name="onboarding" />
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(state => ({
    hasOpenModalPage: state.getIn(['mixer', 'isActive']) || state.getIn(['vha', 'isActive']),
  }))(App)
)
