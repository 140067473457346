import { fromJS } from 'immutable'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  hasBooted: false,
})

export default (state = initialState, { type }) => {
  switch (type) {
    case ActionTypes.APP_BOOTED:
      return state.set('hasBooted', true)
    default:
      return state
  }
}
