import { take } from 'redux-saga/effects'

import { ActionTypes } from 'src/constants.js'

function* translateGlobalTextSizeTOHtmlFontSize() {
  while (true) {
    const { payload: globalTextSize } = yield take(ActionTypes.SET_GLOBAL_TEXT_SIZE)

    document.querySelector('html').style.fontSize = `${globalTextSize * 100}%`
  }
}

export default function* a11ySagas() {
  yield [translateGlobalTextSizeTOHtmlFontSize()]
}
