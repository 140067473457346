import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { NextButton, PrevButton } from 'react-player-controls'
import { Link } from 'react-router-dom'

import 'src/components/Player.scss'
import 'src/components/extensions/player-controls.scss'

import { playPrevSong, playNextSong, togglePlayback } from 'src/actions/player.actions.js'
import AlbumCover from './AlbumCover.js'
import MasterVolumeControls from './MasterVolumeControls.js'
import PlaybackButton from './PlaybackButton.js'
import PlayerMenu from './PlayerMenu.js'
import PlayerProgress from 'src/components/PlayerProgress.js'

class Player extends Component {
  static propTypes = {
    song: PropTypes.object,
    isPlaying: PropTypes.bool,
    isLoading: PropTypes.bool,
    onTogglePlayback: PropTypes.func.isRequired,
    onPreviousSong: PropTypes.func.isRequired,
    onNextSong: PropTypes.func.isRequired,
  }

  static defaultProps = {
    song: null,
    isPlaying: false,
    isLoading: false,
  }

  render() {
    const { song, isPlaying, isLoading, onTogglePlayback, onPreviousSong, onNextSong } = this.props

    return (
      <div className="Player">
        <div className="Player-currentSong">
          <div className="Player-currentSong-image">
            <AlbumCover src={song ? song.get('image') : null} />
          </div>

          <Link to="/mixer" className="Player-currentSong-info">
            <div className={cx('Player-currentSong-info-title', { isEmpty: !song })}>
              {song ? song.get('title') : ' '}
            </div>
            <div className={cx('Player-currentSong-info-artist', { isEmpty: !song })}>
              {song ? song.get('artist') : ' '}
            </div>
          </Link>
        </div>

        <div className="Player-controls">
          <div className="Player-playbackControls">
            <PrevButton isEnabled={!!song} onClick={onPreviousSong} />
            <PlaybackButton
              isPlayable={!!song}
              isPlaying={isPlaying}
              isLoading={isLoading}
              onTogglePlayback={onTogglePlayback}
            />
            <NextButton isEnabled={!!song} onClick={onNextSong} />
          </div>

          <PlayerProgress className="Player-progress" />

          <MasterVolumeControls />
        </div>

        <PlayerMenu className="Player-menu" />
      </div>
    )
  }
}

export default connect(
  state => ({
    song: state.get('songs').find(song => song.get('id') === state.getIn(['player', 'song'])),
    isPlaying: state.getIn(['player', 'isPlaying']),
    isLoading: state.getIn(['player', 'isLoading']),
  }),
  dispatch => ({
    onPreviousSong: () => dispatch(playPrevSong()),
    onNextSong: () => dispatch(playNextSong()),
    onTogglePlayback: () => dispatch(togglePlayback()),
  })
)(Player)
