import { take, call } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import scrollTo from 'animated-scrollto'

function* scrollToTopOnNavigation() {
  while (true) {
    yield take(LOCATION_CHANGE)
    yield call(scrollTo, document.scrollingElement || document.body, 0, 0)
  }
}

export default function* routingSagas() {
  yield [scrollToTopOnNavigation()]
}
