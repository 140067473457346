import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'
import { List } from 'immutable'

import { FilterGroups, ListingStyles, SortingDirections } from 'src/constants.js'
import SongList from 'src/components/SongList.js'
import View from 'src/components/View.js'
import ViewHeader from 'src/components/ViewHeader.js'
import SongsFilter from 'src/components/browsing/SongsFilter.js'
import SongsListingStylePicker from 'src/components/browsing/SongsListingStylePicker.js'
import SongTable from 'src/components/browsing/SongTable.js'
import SongsSortingPicker from 'src/components/browsing/SongsSortingPicker.js'
import { Legend } from 'src/components/forms/Form.js'

import './BrowseView.scss'

const BrowseView = ({ songs, listingStyle, sorting, filters }) => {
  const chosenTags = filters
    .get(FilterGroups.LISTENING_LEVEL)
    .merge(filters.get(FilterGroups.VOCALS))
    .filter(y => y === true)
    .keySeq()
  const chosenGenres = filters
    .get(FilterGroups.GENRE)
    .filter(y => y === true)
    .keySeq()

  const filteredSongs = songs
    .filter(x => {
      return chosenTags.size > 0
        ? chosenTags.every(tag => (x.get('tags') || new List()).includes(tag))
        : true
    })
    .filter(x => {
      return chosenGenres.size > 0
        ? chosenGenres.some(genre => x.get('genre').includes(genre))
        : true
    })

  const sortedSongs = filteredSongs.sort((a, b) => {
    const field = sorting.get('field')
    const direction = sorting.get('direction')
    const multiple = direction === SortingDirections.DESC ? -1 : 1
    if (a.get(field) > b.get(field)) {
      return 1 * multiple
    } else if (a.get(field) < b.get(field)) {
      return -1 * multiple
    } else {
      return 0
    }
  })

  return (
    <View className="BrowseView">
      <Helmet title="Browse" />

      <ViewHeader>
        <h1 className="ViewTitle">
          <T>Browse songs</T>
        </h1>
      </ViewHeader>

      <div className="BrowseView-songs">
        <div className="BrowseView-songs-header">
          <div className="BrowseView-songs-header-info">
            <Legend>
              <T>Songs</T>
            </Legend>
            <p className="BrowseView-songs-filterSummary">
              <T
                message="Showing {{ numVisible }} of {{ numTotal }} songs"
                numVisible={filteredSongs.size}
                numTotal={songs.size}
              />
            </p>
          </div>

          <div className="BrowseView-songs-header-listButtons">
            <SongsListingStylePicker />
          </div>
        </div>

        {listingStyle === ListingStyles.LIST ? (
          <SongTable songs={sortedSongs} />
        ) : sorting.get('field') === 'genre' ? (
          sortedSongs
            .groupBy(x => x.get('genre'))
            .map((list, genre) => (
              <div className="BrowseView-songs-group" key={genre}>
                <h4>{genre}</h4>
                <SongList songs={list} />
              </div>
            ))
            .toArray()
        ) : (
          <SongList songs={sortedSongs} />
        )}
      </div>

      <aside className="BrowseView-sidebar">
        <div className="BrowseView-sidebar-group">
          <Legend>
            <T>Sort by</T>
          </Legend>
          <SongsSortingPicker />
        </div>

        <div className="BrowseView-sidebar-group">
          <Legend>
            <T>Filter</T>
          </Legend>
          <SongsFilter />
        </div>
      </aside>
    </View>
  )
}

export default connect(state => ({
  songs: state.get('songs'),
  listingStyle: state.getIn(['browsing', 'listingStyle']),
  sorting: state.getIn(['browsing', 'songsSorting']),
  filters: state.getIn(['browsing', 'filters']),
}))(withTranslators(BrowseView))
