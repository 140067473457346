import React from 'react'
import classNames from 'classnames'

import Icon from 'src/components/Icon.js'

const PlaybackLoadingIndicator = ({ extraClasses }) => (
  <span className={classNames('PlaybackLoadingIndicator', extraClasses)}>
    <Icon name="loading" />
  </span>
)

export default PlaybackLoadingIndicator
