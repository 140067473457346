import { ActionTypes } from 'src/constants.js'
import { getMessageForError } from 'src/errors.js'

export const displayError = (message, details) => ({
  type: ActionTypes.DISPLAY_ERROR,
  payload: { message, details },
})

export const displayErrorOfType = (errorType, details = null) =>
  displayError(getMessageForError(errorType), details)

export const dismissError = id => ({
  type: ActionTypes.DISMISS_ERROR,
  payload: { id },
})
