import React, { PropTypes, PureComponent } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import Gravatar from 'react-gravatar'
import { T } from 'lioness'
import { autobind } from 'core-decorators'
import { NavLink, Route } from 'react-router-dom'
import * as FeatherIcon from 'react-feather'

import { AccountTypes } from 'src/constants.js'
import { getUserFirstName } from 'src/utils/names.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import DeleteAccountButton from 'src/components/DeleteAccountButton.js'
import Icon from 'src/components/Icon.js'
import LogoutButton from 'src/components/LogoutButton.js'
import UserSettingsForm from 'src/components/UserSettingsForm.js'
import View from 'src/components/View.js'
import ViewHeader from 'src/components/ViewHeader.js'
import { whenLoggedIn } from 'src/components/composers/auth-composers.js'
import ConsultantDashboard from 'src/components/consultant/ConsultantDashboard.js'

import UserVHAProfileModal from 'src/components/user/UserVHAProfileModal.js'
import VHARepresentation from 'src/components/vha/VHARepresentation.js'

import './MeView.scss'

const navigationLinkProps = {
  className: 'MeView-navigation-link',
  activeClassName: 'isActive',
}

class MeView extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
  }

  state = {
    isSendProfileModalOpen: false,
  }

  @autobind
  onSendProfileClick() {
    this.setState(() => ({ isSendProfileModalOpen: true }))
  }

  @autobind
  onSendProfileModalClose() {
    this.setState(() => ({ isSendProfileModalOpen: false }))
  }

  render() {
    const { user } = this.props
    const { isSendProfileModalOpen } = this.state

    const isConsultant = user.get('accountType') === AccountTypes.CONSULTANT

    return (
      <View className="MeView">
        <Helmet title={getUserFirstName(user)} />

        <ViewHeader className="MeView-header">
          <div className="MeView-userProfile">
            <Gravatar email={user.get('email')} size={64} className="UserImage" />

            <div className="MeView-userProfile-info">
              {user.get('firstName') || user.get('lastName') ? (
                <h1 className="MeView-userProfile-info-name">
                  {[user.get('firstName'), user.get('lastName')].join(' ')}
                </h1>
              ) : null}
              <div className="MeView-userProfile-info-email">{user.get('email')}</div>
            </div>
          </div>

          <LogoutButton />
        </ViewHeader>

        <nav className="MeView-navigation">
          {isConsultant === true ? (
            <NavLink to="/me/consultant-dashboard" {...navigationLinkProps}>
              <FeatherIcon.Home className="MeView-navigation-link-icon" />
              <T>Consultant Dashboard</T>
            </NavLink>
          ) : (
            <NavLink exact to="/me" {...navigationLinkProps}>
              <Icon name="ear" className="MeView-navigation-link-icon isCustom" />
              <T>Your Virtual Hearing Aid</T>
            </NavLink>
          )}
          <NavLink to="/me/settings" {...navigationLinkProps}>
            <FeatherIcon.Settings className="MeView-navigation-link-icon" />
            <T>Settings</T>
          </NavLink>
        </nav>

        <div className="MeView-content">
          <div className="MeView-content-pane">
            <Route
              exact
              path="/me"
              render={() => (
                <div>
                  <h2>
                    <T>Virtual Hearing Aid settings</T>
                  </h2>

                  <VHARepresentation />

                  <Button
                    size={Size.NORMAL}
                    loudness={Loudness.YELL}
                    onClick={this.onSendProfileClick}
                  >
                    <T>Send to your audiologist</T>
                  </Button>

                  <UserVHAProfileModal
                    isOpen={isSendProfileModalOpen}
                    onClose={this.onSendProfileModalClose}
                  />
                </div>
              )}
            />
            <Route
              path="/me/settings"
              render={() => (
                <div>
                  <div className="MeView-settingsSection">
                    <h2>
                      <T>Settings</T>
                    </h2>
                    <UserSettingsForm />
                  </div>

                  <div className="MeView-settingsSection">
                    <h2>
                      <T>Danger zone</T>
                    </h2>
                    <p>
                      <T>Deleting your account is irreversible.</T>
                    </p>
                    <p>
                      <DeleteAccountButton />
                    </p>
                  </div>
                </div>
              )}
            />
            {isConsultant === true && (
              <Route path="/me/consultant-dashboard" component={ConsultantDashboard} />
            )}
          </div>
        </div>
      </View>
    )
  }
}

export default whenLoggedIn(
  connect(state => ({
    user: state.getIn(['user', 'user']),
  }))(MeView)
)
