import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import shallowCompare from 'react-addons-shallow-compare'

import { getLyricsFromUrl } from 'src/utils/lyrics-helpers.js'
import { getCurrentTime as getCurrentTimeFromPlayer } from 'src/audio/dashPlayer.js'
import LyricsViewer from './LyricsViewer.js'

class LyricsContainer extends Component {
  static propTypes = {
    lyricsUrl: PropTypes.string,
    getCurrentTime: PropTypes.func.isRequired,
    showLyrics: PropTypes.bool,
  }

  static defaultProps = {
    lyricsUrl: null,
    showLyrics: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      lyrics: null,
      currentTime: 0,
    }
  }

  componentDidMount() {
    if (this.props.lyricsUrl) {
      this.fetchLyrics()
    }
  }

  componentWillUnmount() {
    this.stopInterval()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lyricsUrl !== this.props.lyricsUrl) {
      this.fetchLyrics()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  fetchLyrics() {
    const { lyricsUrl } = this.props

    this.stopInterval()

    if (!lyricsUrl) {
      this.reset()
      return
    }

    this.setState({
      ...this.state,
      lyrics: null,
      isLoading: true,
    })

    getLyricsFromUrl(lyricsUrl)
      .then(lyrics => {
        this.setState({
          ...this.state,
          lyrics,
          isLoading: false,
        })

        this.startInterval()
      })
      .catch(err => {
        console.error(err)
        this.setState({ ...this.state, isLoading: false })
      })
  }

  startInterval() {
    const { getCurrentTime } = this.props

    this.stopInterval()

    this.intervalId = window.setInterval(() => {
      this.setState({
        ...this.state,
        currentTime: getCurrentTime(),
      })
    }, 100)
  }

  stopInterval() {
    window.clearInterval(this.intervalId)
  }

  reset() {
    this.setState({
      lyrics: null,
      isLoading: false,
      currentTime: 0,
    })
  }

  render() {
    const { showLyrics } = this.props
    const { lyrics, currentTime } = this.state

    if (!lyrics || !showLyrics) {
      return null
    }

    return <LyricsViewer lyrics={lyrics} currentTime={currentTime} />
  }
}

export default connect(state => {
  const currentSong = state.get('songs').find(x => x.get('id') === state.getIn(['player', 'song']))
  const lyricsUrl = currentSong && currentSong.get('lyricsUrl')

  return {
    lyricsUrl: currentSong && currentSong.get('lyricsUrl'),
    showLyrics: lyricsUrl && state.getIn(['lyrics', 'isEnabled']),
    getCurrentTime: getCurrentTimeFromPlayer,
  }
})(LyricsContainer)
