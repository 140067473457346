import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Positions } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import { resovleBoxSizing, getStylesAligningTo } from 'src/utils/box-sizing.js'

import './OnboardingStepHighlighter.scss'

export class OnboardingStepHighlighterAnimation extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const { className, ...props } = this.props

    return <div className={cx('OnboardingStepHighlighterAnimation', className)} {...props} />
  }
}

export default class OnboardingStepHighlighter extends Component {
  static propTypes = {
    bounds: CustomPropTypes.bounds,
    alignment: CustomPropTypes.alignment,
    offset: CustomPropTypes.boxSpacing,
    onClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    bounds: null,
    alignment: {
      x: Positions.LEFT,
      y: Positions.TOP,
    },
    offset: 0,
  }

  render() {
    const { bounds, alignment, offset, onClick } = this.props

    if (!bounds) {
      return null
    }

    const resolvedOffset = resovleBoxSizing(offset)
    const { position, translate } = getStylesAligningTo(
      bounds,
      Positions.LEFT,
      alignment,
      resolvedOffset
    )

    return (
      <OnboardingStepHighlighterAnimation
        className="OnboardingStepHighlighter"
        onClick={onClick}
        style={{
          top: position.top,
          left: position.left,
          translate: `translate3d(${translate.x}, ${translate.y}, 0)`,
        }}
      />
    )
  }
}
