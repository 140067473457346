import {
  rekuest,
  url,
  method,
  uri,
  params,
  query,
  body,
  headers,
  debug,
  handleResponse,
} from 'rekuest'
import qs from 'qs'

import configs from 'src/configs.js'
import { AccountTypes } from 'src/constants.js'

export const send = rekuest(
  url(configs.api.baseUrl),
  debug(false),
  handleResponse(response => response.json())
)

export const get = send(method('GET'))
export const post = send(method('POST'))
export const put = send(method('PUT'))
export const patch = send(method('PATCH'))
export const del = send(method('DELETE'))

export const withToken = (rekuestFn, token) =>
  rekuestFn(
    headers({
      Authorization: `JWT ${token}`,
    })
  )

export const asForm = () =>
  headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  })

export const withEncodedBody = () => req => ({
  ...req,
  body: qs.stringify(req.body),
})

/**
 * Authorization
 */
export const authorize = ({ email, password }) =>
  post(uri('/auth/login'), body({ email, password }), asForm(), withEncodedBody())

/**
 * User management
 */
export const findUsers = q => get(uri('/users/search'), query({ q }))

export const getMe = get(uri('/me'))

export const createUser = ({
  firstName,
  lastName,
  email,
  password,
  accountType = AccountTypes.USER,
}) =>
  post(
    uri('/users'),
    body({ firstName, lastName, email, password, accountType }),
    asForm(),
    withEncodedBody()
  )

export const getUser = id => get(uri('/users/:id'), params({ id }))

export const deleteUser = id => del(uri('/users/:id'), params({ id }))

/**
 * Updates a user's settings
 */
export const updateUserSettings = ({ id, firstName, lastName }) =>
  patch(
    uri('/users/:id'),
    params({ id }),
    body({ firstName, lastName }),
    asForm(),
    withEncodedBody()
  )

export const resetUserPassword = id =>
  patch(uri('/users/:id/actions/reset-password'), params({ id }))

/**
 * User VHA and mixes profiles
 */
export const getUserVha = id => get(uri('/users/:id/vha'), params({ id }))

export const updateUserVha = ({ id, vha }) =>
  put(uri('/users/:id/vha'), params({ id }), body(vha), asForm(), withEncodedBody())

export const getUserMix = (userId, songId) =>
  get(uri('/users/:userId/mixes/:songId'), params({ userId, songId }))

export const updateUserMix = ({ userId, songId, tracks }) =>
  put(
    uri('/users/:userId/mixes/:songId'),
    params({ userId, songId }),
    body({ tracks }),
    asForm(),
    withEncodedBody()
  )

export const sendUserProfile = ({ userId, recipientEmail, profileHtml }) =>
  post(
    uri('/users/:userId/profile/send'),
    params({ userId }),
    body({ recipientEmail, profileHtml }),
    asForm(),
    withEncodedBody()
  )

/**
 * Feedback enpoints
 */
export const getFeedbacks = () => get(uri('/feedbacks'))

export const postFeedback = ({ email, freeText }) =>
  post(uri('/feedbacks'), body({ email, freeText }), asForm(), withEncodedBody())

/**
 * Consultant endpoints
 */
export const createConsultant = ({ userId, placeOfWork, occupation }) =>
  post(uri('/consultants'), body({ userId, placeOfWork, occupation }), asForm(), withEncodedBody())

/**
 * Consultant-patient endpoints
 */
export const getPatient = userId => get(uri('/patients/:userId'), params({ userId }))

export const getPatients = consultantId => get(uri('/patients'), query({ consultantId }))

export const associatePatientToConsultant = ({ consultantId, patientId }) =>
  post(
    uri('/patients'),
    body({
      consultantId: consultantId,
      userId: patientId,
    }),
    asForm(),
    withEncodedBody()
  )

export const sendPatientAudiograms = patientId =>
  post(uri('/users/:userId/actions/send-audiograms'), params({ userId: patientId }))
