import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { autobind } from 'core-decorators'
import { T, withTranslators } from 'lioness'

import { submitFeedback } from 'src/actions/feedback.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import FormField from 'src/components/forms/FormField.js'

function getSubmitLabel(isPending, hasSubmitted) {
  if (hasSubmitted === true) {
    return <T>Thank you!</T>
  } else if (isPending === true) {
    return <T>Submitting...</T>
  }

  return <T>Submit feedback</T>
}

/**
 * Feedback Form
 */
class FeedbackForm extends Component {
  static propTypes = {
    isPending: PropTypes.bool.isRequired,
    hasSubmitted: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    hasSubmitted: false,
  }

  state = {
    email: '',
    freeText: '',
  }

  emailInput = null

  componentDidMount() {
    this.emailInput.focus()
  }

  @autobind
  handleChange(name, value) {
    this.setState(() => ({ [name]: value }))
  }

  render() {
    const { isPending, hasSubmitted, onSubmit, onCancel, t } = this.props
    const { email, freeText } = this.state

    return (
      <div className="FeedbackForm">
        <FormField
          type="text"
          label={t('Email (optional)')}
          helpText={t(
            'Provide your email if you would like us to get back to you regarding your feedback, which we would very much like!'
          )}
          placeholder={t('my.name@example.com')}
          name="email"
          value={email}
          isEnabled={isPending === false}
          onChange={value => this.handleChange('email', value)}
          ref={el => (this.emailInput = el)}
        />

        <FormField
          component="textarea"
          type="text"
          label={t('How can we improve Musiclarity?')}
          name="freeText"
          value={freeText}
          isEnabled={isPending === false}
          onChange={value => this.handleChange('freeText', value)}
          rows={4}
        />

        <Button
          loudness={Loudness.YELL}
          size={Size.SMALL}
          isEnabled={isPending === false && hasSubmitted === false}
          onClick={() => onSubmit({ email, freeText })}
        >
          {getSubmitLabel(isPending, hasSubmitted)}
        </Button>

        <Button loudness={Loudness.WHISPER} size={Size.SMALL} onClick={() => onCancel()}>
          <T>Cancel</T>
        </Button>
      </div>
    )
  }
}

export default connect(
  state => ({
    isPending: state.getIn(['feedback', 'submission', 'isPending']),
    hasSubmitted: state.getIn(['feedback', 'submission', 'result']) === true,
  }),
  dispatch => ({
    onSubmit: data => dispatch(submitFeedback(data)),
  })
)(withTranslators(FeedbackForm))
