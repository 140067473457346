import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { autobind } from 'core-decorators'

export default class SubmissiveScrollContainer extends Component {
  static propTypes = {
    isSubmissive: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    isSubmissive: false,
  }

  containerElement = null
  freezedAtOffset = 0

  componentWillUpdate(nextProps) {
    // Before rendering the folded/hidden/submissive component, store what
    // scroll position to offset this component with
    if (nextProps.isSubmissive === true && this.props.isSubmissive === false) {
      this.freezedAtOffset = document.body.scrollTop
    }
  }

  componentDidUpdate(prevProps) {
    // Enter submissive state
    if (this.props.isSubmissive === true && prevProps.isSubmissive === false) {
      this.containerElement.scrollTop = this.freezedAtOffset
      document.body.scrollTop = 0
    } else if (this.props.isSubmissive === false && prevProps.isSubmissive === true) {
      // Go back to normal
      this.containerElement.scrollTop = 0
      document.body.scrollTop = this.freezedAtOffset
      this.freezedAtOffset = 0
    }
  }

  @autobind
  storeContainerElementRef(element) {
    this.containerElement = element
  }

  render() {
    const { isSubmissive, className, children } = this.props

    return (
      <div
        ref={this.storeContainerElementRef}
        className={cx('SubmissiveScrollContainer', className, { isSubmissive })}
      >
        {Children.toArray(children)}
      </div>
    )
  }
}
