import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { autobind } from 'core-decorators'
import cx from 'classnames'

import Checkbox from 'src/components/forms/Checkbox.js'

import './RadioList.scss'

/**
 * Radio List
 */
export class RadioList extends Component {
  static propTypes = {
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
  }

  static defaultProps = {
    onChange: () => {},
  }

  state = {
    value: '',
  }

  @autobind
  handleSelect(value) {
    this.setState(() => ({ value }))
    this.props.onChange(value)
  }

  render() {
    const { children } = this.props
    const { value } = this.state

    return (
      <div className="RadioList">
        {children.map(opt => {
          return React.cloneElement(opt, {
            isSelected: opt.props.value === value,
            onSelect: this.handleSelect,
            key: opt.props.value,
          })
        })}
      </div>
    )
  }
}

/**
 * Radio List Option
 */
export class RadioListOption extends Component {
  static propTypes = {
    value: PropTypes.string,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    value: '',
    isSelected: false,
    onSelect: () => {},
    className: '',
  }

  @autobind
  handleSelect(isChecked) {
    if (isChecked) {
      this.props.onSelect(this.props.value)
    }
  }

  render() {
    const { isSelected, className, children, ...props } = this.props

    // Passing an onSelect prop to Checkbox will cause Proxy events
    // to be triggered when the component is being clicked
    delete props.onSelect

    return (
      <Checkbox
        className={cx('RadioListOption', className)}
        isChecked={isSelected}
        onChange={isChecked => this.handleSelect(isChecked)}
        {...props}
      >
        {children}
      </Checkbox>
    )
  }
}

export default RadioList
