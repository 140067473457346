import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { T } from 'lioness'

import View from './View.js'
import './NotFoundView.scss'

const NotFoundView = ({ location }) => (
  <View className="NotFoundView">
    <Helmet title="Empty music space (404)" />

    <div className="NotFoundView-content">
      <h1>
        <T>Empty music space</T>
      </h1>
      <p>
        <T
          message={`Unfortunately, {{ pathName }} does not match anything here at Musiclarity. Perhaps you were {{ browseLink:looking for a song }}?`}
          pathName={<span className="NotFoundView-pathname">{location.pathname}</span>}
          browseLink={<Link to="/browse" />}
        />
      </p>
    </div>
  </View>
)

export default NotFoundView
