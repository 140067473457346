import { createElement, Component } from 'react'
import { OrderedMap } from 'immutable'
import { curry } from 'lodash'

import { subscribe } from 'src/audio/fft-emitter.js'

/**
 * Returns a higher-order component that will subscribe to FFT streams
 * and update `ComponentClass` with new FFT data whenever `trackPredicate`
 * returns true.
 */
export default curry((trackPredicate, ComponentClass) => {
  return class FftDataComponent extends Component {
    static displayName = `withFftData(${ComponentClass.displayName || ComponentClass.name})`

    constructor(props) {
      super(props)
      this.state = {
        data: new OrderedMap(),
      }
    }

    componentWillMount() {
      this.unsubscribe = subscribe(trackPredicate, this.onReceiveFftData.bind(this))
    }

    componentWillUnmount() {
      this.unsubscribe()
    }

    onReceiveFftData(newData, track) {
      this.setState({
        data: this.state.data.set(track.get('id'), newData),
      })
    }

    render() {
      const { children, ...props } = this.props
      const tracksFftData = this.state.data

      return createElement(ComponentClass, { ...props, tracksFftData }, children)
    }
  }
})
