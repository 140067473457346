import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './FancyOrderedList.scss'

/**
 * Fancy Ordered List
 */
class FancyOrderedList extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  render() {
    const { children, ...props } = this.props

    return (
      <ol className="FancyOrderedList" {...props}>
        {children}
      </ol>
    )
  }
}

export default FancyOrderedList
