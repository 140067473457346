import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, TabList, Tab, TabPanel } from 'src/_vendor/react-tabs/main.js'
import { T } from 'lioness'

import { HearingAidFittings } from 'src/constants.js'
import { setVhaFittingMode } from 'src/actions/vha.actions.js'
import Checkbox from 'src/components/forms/Checkbox.js'
import HearingAidPresetForm from 'src/components/vha/HearingAidPresetForm.js'
import AudiogramManager from 'src/components/AudiogramManager.js'

import './HearingAidFittingControls.scss'

const fittingModes = [HearingAidFittings.PRESETS, HearingAidFittings.AUDIOGRAMS]

/**
 * Hearing Aid Fitting Controls
 */
class HearingAidFittingControls extends Component {
  static propTypes = {
    fittingMode: PropTypes.oneOf(fittingModes).isRequired,
    onModeChange: PropTypes.func.isRequired,
  }

  render() {
    const { fittingMode, onModeChange } = this.props

    const selectedIndex = fittingModes.indexOf(fittingMode)

    return (
      <div className="HearingAidFittingControls">
        <Tabs
          className="HearingAidFittingControls-tabs"
          forceRenderTabPanel
          selectedIndex={selectedIndex}
          onSelect={index => onModeChange(fittingModes[index])}
        >
          <TabList className="HearingAidFittingControls-tabList" activeTabClassName="isSelected">
            <h3>
              <T>1. Choose way of input</T>
            </h3>
            <Tab>
              <Checkbox isChecked={selectedIndex === 0}>
                <T>Presets (Mild, Moderate, Severe)</T>
              </Checkbox>
            </Tab>
            <Tab>
              <Checkbox isChecked={selectedIndex === 1}>
                <T>Audiogram (from your audiologist)</T>
              </Checkbox>
            </Tab>
          </TabList>

          <TabPanel className="HearingAidFittingControls-tabPanel">
            <h3>2. Choose preset</h3>
            <HearingAidPresetForm />
          </TabPanel>

          <TabPanel className="HearingAidFittingControls-tabPanel">
            <h3>2. Input audiograms</h3>
            <AudiogramManager />
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}

export default connect(
  state => ({
    fittingMode: state.getIn(['vha', 'aid', 'fittingMode']),
  }),
  dispatch => ({
    onModeChange: mode => dispatch(setVhaFittingMode(mode)),
  })
)(HearingAidFittingControls)
