import React, { Component } from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'

import View from 'src/components/View.js'
import AdminDashboardView from 'src/components/admin/AdminDashboardView.js'
import AdminFeedbackView from 'src/components/admin/AdminFeedbackView.js'
import { whenAdmin } from 'src/components/composers/auth-composers.js'

import './AdminView.scss'

/**
 * Admin View
 */
class AdminView extends Component {
  render() {
    return (
      <div className="AdminViewWrap">
        <View className="AdminView">
          <Helmet title="Admin" />

          <div className="AdminView-content">
            <header className="AdminView-header">
              <h1 className="AdminView-title">Admin</h1>

              <nav className="AdminView-navigation">
                <li>
                  <NavLink activeClassName="isActive" exact to="/admin">
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="isActive" to="/admin/feedback">
                    Feedback
                  </NavLink>
                </li>
              </nav>
            </header>

            <Switch>
              <Route exact path="/admin" component={AdminDashboardView} />
              <Route path="/admin/feedback" component={AdminFeedbackView} />
            </Switch>
          </div>
        </View>
      </div>
    )
  }
}

export default whenAdmin(AdminView)
