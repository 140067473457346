import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as FeatherIcon from 'react-feather'
import { T } from 'lioness'
import cx from 'classnames'

import { AccountTypes } from 'src/constants.js'
import * as CustomPropTypes from 'src/prop-types.js'
import { Loudness, Size } from 'src/components/Button.js'
import EndPatientSessionButton from 'src/components/consultant/EndPatientSessionButton.js'

import './ConsultantTopBar.scss'

/**
 * Consultant Top Bar
 */
class ConsultantTopBar extends PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    isTransparent: PropTypes.bool.isRequired,
    sessionPatient: CustomPropTypes.sessionPatient,
  }

  static defaultProps = {
    sessionPatient: null,
  }

  render() {
    const { isVisible, isTransparent, sessionPatient } = this.props

    if (isVisible === false) {
      return null
    }

    return (
      <div className={cx('ConsultantTopBar', { isTransparent })}>
        <div className="ConsultantTopBar-inner">
          {sessionPatient !== null && (
            <div className="ConsultantTopBar-session">
              <span className="ConsultantTopBar-session-arm" />

              <T
                userName={
                  <strong>
                    <Link
                      to={`/me/consultant-dashboard/patients/${sessionPatient.get('_id')}`}
                      className="ConsultantTopBar-session-nameLink"
                    >
                      {sessionPatient.get('firstName')} {sessionPatient.get('lastName')}
                    </Link>
                  </strong>
                }
              >
                {`Session with: {{ userName }}`}
              </T>
              <EndPatientSessionButton
                loudness={Loudness.WHISPER}
                size={Size.SMALL}
                style={{
                  marginLeft: 16,
                  padding: 0,
                  color: 'inherit',
                }}
              >
                <FeatherIcon.XCircle
                  size={16}
                  style={{ marginRight: 8, verticalAlign: 'middle' }}
                />
                <T>End session</T>
              </EndPatientSessionButton>
            </div>
          )}

          <Link to="/me/consultant-dashboard" className="ConsultantTopBar-dashboardLink">
            <FeatherIcon.Home size={16} />
            <span className="ConsultantTopBar-dashboardLink-text">
              <T>Consultant Dashboard</T>
            </span>
          </Link>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  isVisible: state.getIn(['user', 'user', 'accountType']) === AccountTypes.CONSULTANT,
  sessionPatient: state.getIn(['consultant', 'session', 'patient']),
}))(ConsultantTopBar)
