import React from 'react'
import { connect } from 'react-redux'
import { T } from 'lioness'

import environment from 'src/environment.js'
import { AppEnvironments } from 'src/constants.js'
import { dismissError } from 'src/actions/errors.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'
import {
  Modal,
  ModalSizes,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalActions,
} from 'src/components/Modal.js'

const ErrorNotifier = ({ errors, onDismissError }) => (
  <div className="ErrorNotifier">
    {errors
      .map(error => (
        <Modal
          key={error.get('id')}
          isOpen
          onClose={() => onDismissError(error)}
          size={ModalSizes.MINIMAL}
          className="Modal isMinimal"
        >
          <ModalHeader>
            <ModalTitle>
              <T>Oops, something went wrong:</T>
            </ModalTitle>
          </ModalHeader>

          <ModalContent>
            {error.get('message')}

            {error.get('details') &&
              environment !== AppEnvironments.PRODUCTION && (
                <pre style={{ marginBottom: 20 }}>
                  {JSON.stringify(error.get('details'), null, 2)}
                </pre>
              )}
          </ModalContent>

          <ModalActions>
            <Button
              loudness={Loudness.TALK}
              size={Size.SMALL}
              onClick={() => onDismissError(error)}
            >
              <T>Okay, got it</T>
            </Button>
          </ModalActions>
        </Modal>
      ))
      .toArray()}
  </div>
)

export default connect(
  state => ({
    errors: state.get('errors'),
  }),
  dispatch => ({
    onDismissError: error => dispatch(dismissError(error.get('id'))),
  })
)(ErrorNotifier)
