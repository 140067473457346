import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { T, withTranslators } from 'lioness'

import { setFeedbackFormOpen } from 'src/actions/feedback.actions.js'
import Modal, { ModalSizes, ModalHeader, ModalTitle, ModalContent } from 'src/components/Modal.js'
import FeedbackForm from 'src/components/feedback/FeedbackForm.js'

/**
 * Feedback Modal
 */
class FeedbackModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { isOpen, onClose, t } = this.props

    return (
      <Modal
        className="FeedbackModal"
        size={ModalSizes.MINIMAL}
        isOpen={isOpen}
        onClose={onClose}
        contentLabel={t('Give us feedback!')}
      >
        <ModalHeader>
          <ModalTitle>
            <T>Share your thoughts on Musiclarity</T>
          </ModalTitle>
        </ModalHeader>

        <ModalContent>
          <FeedbackForm onCancel={onClose} />
        </ModalContent>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    isOpen: state.getIn(['feedback', 'isActive']),
  }),
  dispatch => ({
    onClose: () => dispatch(setFeedbackFormOpen(false)),
  })
)(withTranslators(FeedbackModal))
