import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { T, withTranslators } from 'lioness'
import { connect } from 'react-redux'
import { values } from 'lodash'

import { ListeningMethods, OnboardingTours, Positions } from 'src/constants.js'
import { Legend } from 'src/components/forms/Form.js'
import OnboardingStep from 'src/components/onboarder/OnboardingStep.js'
import CompressionControls from 'src/components/vha/CompressionControls.js'
import ConnectedListeningMethodPicker from 'src/components/vha/ConnectedListeningMethodPicker.js'
import HearingAidFittingControls from 'src/components/vha/HearingAidFittingControls.js'
import LevelAdjustmentControls from 'src/components/vha/LevelAdjustmentControls.js'
import ToneControls from 'src/components/vha/ToneControls.js'
import { VHASplitPane, Pane } from 'src/components/vha/VHASplitPane.js'

import './VHAInterface.scss'

class VHAInterface extends Component {
  static propTypes = {
    listeningMethod: PropTypes.oneOf(values(ListeningMethods)).isRequired,
    t: PropTypes.func.isRequired,
  }

  render() {
    const { listeningMethod, t } = this.props

    const paddings = {
      top: 10,
      left: 20,
      right: -20,
    }

    const tooltipOffsets = {
      top: -20,
      left: -20,
    }

    const numExtraOnboardingSteps = listeningMethod === ListeningMethods.HEADPHONES ? 1 : 0

    return (
      <VHASplitPane className="VHAInterface">
        <Pane style={{ width: '100%', flexBasis: '100%' }}>
          <OnboardingStep
            tour={OnboardingTours.VHA}
            step={1}
            text={t(
              'Start off by providing information about your listening method. Choosing "Headphones" will enable a "Gerenal hearing level" section below.'
            )}
            padding={{
              ...paddings,
              bottom: 20,
            }}
            tooltipPosition={Positions.BOTTOM}
            tooltipAlignment={{ x: Positions.LEFT }}
            tooltipOffset={{
              top: 30,
              left: -20,
            }}
            highlightOffset={{
              top: 30,
              left: -10,
            }}
            scrollAnchorPoint={Positions.TOP}
            scrollAnchorOffset={{ top: -100 }}
          >
            <Legend>
              <T>Options</T>
            </Legend>

            <ConnectedListeningMethodPicker />
          </OnboardingStep>
        </Pane>

        {listeningMethod === ListeningMethods.HEADPHONES && (
          <Pane style={{ width: '100%', flexBasis: '100%' }}>
            <OnboardingStep
              tour={OnboardingTours.VHA}
              step={2}
              text={t('Here you provide information about your hearing loss.')}
              padding={paddings}
              tooltipPosition={Positions.BOTTOM}
              tooltipAlignment={{ x: Positions.LEFT }}
              tooltipOffset={{
                top: 10,
                left: -20,
              }}
              highlightOffset={{
                top: 30,
                left: -10,
              }}
              scrollAnchorPoint={Positions.TOP}
              scrollAnchorOffset={{ top: -100 }}
            >
              <Legend>
                <T>General hearing level</T>
              </Legend>
              <HearingAidFittingControls />
            </OnboardingStep>
          </Pane>
        )}

        <Pane style={{ flexBasis: '50%' }}>
          <OnboardingStep
            tour={OnboardingTours.VHA}
            step={2 + numExtraOnboardingSteps}
            text={t('Drag these bars to further control these frequency ranges for each ear.')}
            padding={paddings}
            tooltipOffset={tooltipOffsets}
            scrollAnchorPoint={Positions.BOTTOM}
            scrollAnchorOffset={{ bottom: 80 }}
          >
            <Legend>
              <T>Tone control</T>
            </Legend>
            <ToneControls />
          </OnboardingStep>
        </Pane>

        <Pane style={{ flexBasis: '25%' }}>
          <OnboardingStep
            tour={OnboardingTours.VHA}
            step={3 + numExtraOnboardingSteps}
            text={t('Drag these bars to compress the sound in each ear.')}
            padding={paddings}
            tooltipOffset={tooltipOffsets}
            scrollAnchorPoint={Positions.BOTTOM}
            scrollAnchorOffset={{ bottom: 80 }}
          >
            <Legend>
              <T>Compression</T>
            </Legend>
            <CompressionControls />
          </OnboardingStep>
        </Pane>

        <Pane style={{ flexBasis: '25%' }}>
          <OnboardingStep
            tour={OnboardingTours.VHA}
            step={4 + numExtraOnboardingSteps}
            text={t('Drag these bars to change the overall volume in each ear.')}
            padding={paddings}
            tooltipAlignment={{ x: Positions.RIGHT }}
            tooltipOffset={{
              top: -20,
              right: 20,
            }}
            scrollAnchorPoint={Positions.BOTTOM}
            scrollAnchorOffset={{ bottom: 80 }}
          >
            <Legend>
              <T>Overall volume</T>
            </Legend>
            <LevelAdjustmentControls />
          </OnboardingStep>
        </Pane>
      </VHASplitPane>
    )
  }
}

export default connect(state => ({
  listeningMethod: state.getIn(['vha', 'listeningMethod']),
}))(withTranslators(VHAInterface))
