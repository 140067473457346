import { reduce, values, max, min } from 'lodash'

import { cast } from 'src/utils/math.js'

/**
 * Returns an EQ curve based on input audiogram data.
 */
export default audiogramData => {
  const data = audiogramData

  const eqCurve = reduce(
    data,
    (curve, threshold, frequency) => ({
      ...curve,
      [frequency]: threshold / 6,
    }),
    {}
  )

  // NOTE: This justification is too sucky, needs to improve before usage
  const justifiedCurve = eqCurve
  /*
  const averageGain = mean(values(eqCurve))
  const justifiedCurve = reduce(eqCurve, (curve, gain, frequency) => ({
    ...curve,
    [frequency]: gain - averageGain,
  }), {})
  */

  const maxGain = max(values(justifiedCurve))
  const minGain = min(values(justifiedCurve))
  const toMaxGain = Math.min(20, maxGain)
  const toMinGain = Math.max(-20, minGain)

  const amplifiedCurve = reduce(
    justifiedCurve,
    (curve, gain, frequency) => {
      const amplifiedGain = cast(toMaxGain, toMinGain, maxGain, minGain, gain)
      return {
        ...curve,
        [frequency]: amplifiedGain,
      }
    },
    {}
  )

  return amplifiedCurve
}
