import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { T } from 'lioness'

import { OnboardingTours } from 'src/constants.js'
import Icon from 'src/components/Icon.js'
import MixerInterface from 'src/components/MixerInterface.js'
import View from 'src/components/View.js'
import ViewHeader from 'src/components/ViewHeader.js'
import OnboardingTourStartButton from 'src/components/onboarder/OnboardingTourStartButton.js'

const MixerView = () => (
  <View className="MixerView">
    <Helmet title="Mixer" />

    <ViewHeader>
      <h1 className="ViewTitle">
        <Icon name="mixer" /> <T>Mixer</T>
      </h1>

      <div className="ViewHeader-content">
        <div className="ViewHeader-description">
          <T>
            Improve your listening by adjusting the volume and position of individual instruments.
          </T>
        </div>

        <div className="ViewHeader-actions">
          <OnboardingTourStartButton tour={OnboardingTours.MIXER}>
            <T>Take a tour</T>
          </OnboardingTourStartButton>
        </div>
      </div>
    </ViewHeader>

    <MixerInterface />
  </View>
)

export default connect()(MixerView)
