import React from 'react'
import { T } from 'lioness'
import cx from 'classnames'

import './AboutPros.scss'

/**
 * About Pros
 */
const AboutPros = ({ className = '', ...props }) => (
  <div className={cx('AboutPros', className)} {...props}>
    <div className="AboutPros-list">
      <h3>
        <T>For all users</T>
      </h3>

      <ul>
        <li>
          <T>Control individual instruments</T>
        </li>
        <li>
          <T>View timed lyrics</T>
        </li>
        <li>
          <T>View real-time audio visualisation</T>
        </li>
        <li>
          <T>Audiogram support (if you decide to remove your hearing aids and use headphones)</T>
        </li>
      </ul>
    </div>

    <div className="AboutPros-list">
      <h3>When you have a Musiclarity account:</h3>

      <ul>
        <li>
          <T>
            Your Virtual Hearing Aid settings are remembered for the next time you visit Musiclarity
          </T>
        </li>
        <li>
          <T>Your Volume and Position settings for each song are remembered</T>
        </li>
        <li>
          <T>
            These settings are also available on other web browsers and the Musiclarity smartphone
            app (coming soon)
          </T>
        </li>
      </ul>
    </div>
  </div>
)

export default AboutPros
