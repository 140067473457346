import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setGlobalTextSize } from 'src/actions/a11y.actions.js'
import Button, { Loudness, Size } from 'src/components/Button.js'

import './GlobalTextSizeControls.scss'

/**
 * Global Text Size Controls
 */
class GlobalTextSizeControls extends Component {
  static propTypes = {
    size: PropTypes.number.isRequired,
    onIncrease: PropTypes.func.isRequired,
    onDecrease: PropTypes.func.isRequired,
  }

  render() {
    const { size, onIncrease, onDecrease } = this.props

    return (
      <div className="GlobalTextSizeControls">
        <header>
          <strong>
            A<small>A</small>
          </strong>
        </header>

        <Button loudness={Loudness.WHISPER} size={Size.TINY} onClick={onIncrease}>
          +
        </Button>

        <span>{parseInt(size * 100)}%</span>

        <Button
          loudness={Loudness.WHISPER}
          size={Size.TINY}
          onClick={onDecrease}
          isEnabled={size > 0.1}
        >
          -
        </Button>
      </div>
    )
  }
}

export default connect(
  state => ({
    size: state.getIn(['a11y', 'globalTextSize']),
  }),
  dispatch => ({
    onSetSize: size => dispatch(setGlobalTextSize(size)),
  }),
  (stateProps, dispatchProps) => ({
    size: stateProps.size,
    onIncrease: () => dispatchProps.onSetSize(Math.max(0, stateProps.size + 0.1)),
    onDecrease: () => dispatchProps.onSetSize(Math.max(0, stateProps.size - 0.1)),
  })
)(GlobalTextSizeControls)
