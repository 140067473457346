import Joi from 'joi-browser'

import { ConsultantOccupation } from 'src/constants.js'

// TODO: Implement a way to specify the `unique` constraint
export const UserLoginSchema = Joi.object().keys({
  email: Joi.string()
    .email()
    .required(),
  password: Joi.string()
    .min(6)
    .label('Password'),
})

export const UserSignUpSchema = UserLoginSchema.keys({
  firstName: Joi.string(),
  lastName: Joi.string(),
  verifiedPassword: Joi.string().valid(Joi.ref('password')),
})

export const UserSettingsSchema = Joi.object().keys({
  firstName: Joi.string(),
  lastName: Joi.string(),
})

export const ConsultantSignUpSchema = UserSignUpSchema.keys({
  placeOfWork: Joi.string(),
  occupation: Joi.object().keys({
    [ConsultantOccupation.AUDIOLOGIST]: Joi.boolean(),
    [ConsultantOccupation.HEARING_THERAPIST]: Joi.boolean(),
    [ConsultantOccupation.OTHER]: Joi.string().allow(''),
  }),
})
