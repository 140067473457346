import { call, select } from 'redux-saga/effects'

import { withToken } from 'src/api.js'

/**
 * Bakes and fires a rekuest function with an authorisation token
 * selected from the app state. If the request returns any errors,
 * the first one is thrown as is.
 */
export function* request(tokenSelector, fn, args) {
  const token = yield select(tokenSelector)
  const authenticatedFn = yield call(withToken, fn, token)

  const callArgs = args ? [authenticatedFn, args] : [authenticatedFn]
  const { data, errors } = yield call(...callArgs)

  if (Array.isArray(errors) === true && errors.length > 0) {
    throw errors[0]
  }

  return data
}

/**
 * Adds an isCascaded flag to a Redux action, labelling it as an
 * action triggered from a saga.
 */
export function cascade(action) {
  return {
    ...action,
    isCascaded: true,
  }
}

/**
 * Returns whether an action is cascaded or not.
 */
export function isCascaded(action) {
  return action.isCascaded === true
}
