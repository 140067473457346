import React from 'react'

import SvgIcon from './SvgIcon.js'

export default () => (
  <SvgIcon>
    <polygon
      className="IconSvg-fill"
      points="26,8.392 12,16.169 12,8 9,8 9,27 12,27 12,18.564 26,26.343 "
    />
  </SvgIcon>
)
