import { fromJS } from 'immutable'

import { ActionTypes } from 'src/constants.js'

const initialState = fromJS({
  isActive: false,
  tracks: null,
  selectedTrackId: null,
  master: {
    isMuted: false,
    volume: 0.5,
  },
})

const mixerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_MIXER_ACTIVE:
      return state.set('isActive', payload)
    case ActionTypes.SET_MIXER_TRACKS:
      return state.set('tracks', payload)
    case ActionTypes.APPLY_USER_MIX:
      return state.mergeDeep(fromJS(payload))
    case ActionTypes.SET_SELECTED_TRACK:
      return state.set('selectedTrackId', payload)
    case ActionTypes.SET_MASTER_MUTED:
      return state.setIn(['master', 'isMuted'], payload)
    case ActionTypes.SET_MASTER_VOLUME:
      return state.setIn(['master', 'volume'], payload)
    case ActionTypes.SET_TRACK_VOLUME:
      return state.setIn(['tracks', payload.trackId, 'volume'], payload.volume)
    case ActionTypes.SET_TRACK_PAN:
      return state.setIn(['tracks', payload.trackId, 'position', 'x'], payload.pan)
    case ActionTypes.SET_TRACK_DISTANCE:
      return state.setIn(['tracks', payload.trackId, 'position', 'z'], payload.distance)
    case ActionTypes.SET_TRACK_POSITION:
      return state.mergeIn(['tracks', payload.trackId, 'position'], payload.position)
    case ActionTypes.SET_TRACK_SOLOED:
      return state.setIn(['tracks', payload.trackId, 'isSoloed'], payload.isSoloed)
    case ActionTypes.SET_TRACK_MUTED:
      return state.setIn(['tracks', payload.trackId, 'isMuted'], payload.isMuted)
    case ActionTypes.COPY_TRACK_SETTINGS:
      return state.update('tracks', tracks =>
        tracks.map(track => {
          const trackSettings = payload.get(track.get('id'))
          return track
            .set('volume', trackSettings.get('volume'))
            .set('position', trackSettings.get('position'))
            .set('isSoloed', trackSettings.get('isSoloed'))
            .set('isMuted', trackSettings.get('isMuted'))
        })
      )
    default:
      return state
  }
}

export default mixerReducer
