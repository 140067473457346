import React, { Component } from 'react'
import { connect } from 'react-redux'
import { T } from 'lioness'

import { Ears } from 'src/constants.js'
import { setAudiogramIntroAsSeen } from 'src/actions/audiogram.actions.js'
import Button, { Loudness } from 'src/components/Button.js'
import VHAAudiogramForm from 'src/components/vha/VHAAudiogramForm.js'

/**
 * Audiogram introduction
 */
const AudiogramIntro = ({ onAdd }) => (
  <div className="AudiogramIntro">
    <p>
      <T>
        Add your audiogram and Musiclarity will automatically apply audio enhancements to compensate
        for your hearing loss.
      </T>
    </p>
    <p>
      <Button loudness={Loudness.YELL} onClick={onAdd}>
        <T>Add your audiogram</T>
      </Button>
    </p>
  </div>
)

/**
 * Left and right ear audiogram forms
 */
const AudiogramFormView = () => (
  <div className="AudiogramFormView">
    <p>
      <T>Input your audiogram for each ear here. Consult your audiologist for this information.</T>
    </p>

    <div style={{ display: 'flex' }}>
      <VHAAudiogramForm ear={Ears.RIGHT} style={{ flexShrink: 1, paddingRight: 16 }} />
      <VHAAudiogramForm ear={Ears.LEFT} style={{ flexShrink: 1, paddingLeft: 16 }} />
    </div>
  </div>
)

/**
 * Audiogram manager
 */
class AudiogramManager extends Component {
  render() {
    const { hasSeenIntro, onSeenIntro } = this.props

    if (hasSeenIntro === false) {
      return <AudiogramIntro onAdd={onSeenIntro} />
    } else {
      return <AudiogramFormView />
    }
  }
}

export default connect(
  state => ({
    hasSeenIntro: state.getIn(['audiogram', 'hasSeenIntro']),
  }),
  dispatch => ({
    onSeenIntro: () => dispatch(setAudiogramIntroAsSeen()),
  })
)(AudiogramManager)
