import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { capitalize, values } from 'lodash'

import './SongFilterTag.scss'

export const SongFilterTagColors = {
  GREEN: 'GREEN',
  TURQUOISE: 'TURQUOISE',
  ROYALBLUE: 'ROYALBLUE',
}

/**
 * Song Filter Tag
 */
class SongFilterTag extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    matchCount: PropTypes.number, // .isRequired,
    color: PropTypes.oneOf(values(SongFilterTagColors)),
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    color: SongFilterTagColors.ROYALBLUE,
  }

  render() {
    const { label, isChecked, /* matchCount, */ color, onChange } = this.props

    const colorClass = `is${capitalize(color)}`

    return (
      <div
        className={cx('SongFilterTag', colorClass, { isChecked })}
        onClick={() => onChange(!isChecked)}
      >
        <svg className="SongFilterTag-checkmark" width="13px" height="10px" viewBox="0 0 13 10">
          <path d="M1,5.92704311 L4.0379307,9 C4.0379307,9 12,1.02483534 12,1" />
        </svg>

        <span className="SongFilterTag-label">
          {label}

          {/* <span className="SongFilterTag-matchCount">({matchCount})</span> */}
        </span>
      </div>
    )
  }
}

export default SongFilterTag
