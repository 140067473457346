import React from 'react'
import classNames from 'classnames'

import './ViewHeader.scss'

export default ({ children, className, ...rest }) => (
  <header className={classNames('ViewHeader', className)} {...rest}>
    {children}
  </header>
)
