import { clamp } from 'lodash'
import { toGain } from 'decibels'

import context from 'src/audio/context.js'
import DetunaEffect from 'src/audio/detuna/DetunaEffect.js'

export default class Compressor extends DetunaEffect {
  isAutoMakeupEnabled = false

  constructor(params) {
    super()

    this.compressorNode = context.createDynamicsCompressor()
    this.makeupNode = context.createGain()

    this.compressorNode.connect(this.makeupNode)

    this.input = this.compressorNode
    this.output = this.makeupNode

    this.initialise(params)
  }

  getMakeupGain() {
    const magicCoefficient = 4 // raise me if the output is too hot
    const c = this.compressorNode

    return toGain(-(c.threshold.value - c.threshold.value / c.ratio.value) / magicCoefficient)
  }

  updateMakeupGain() {
    this.makeupGain = this.isAutoMakeupEnabled === true ? this.getMakeupGain() : 1
  }

  set automakeup(value) {
    this.isAutoMakeupEnabled = value
  }

  set makeupGain(value) {
    this.makeupNode.gain.setValueAtTime(clamp(value, 0, 100), 0)
  }

  set threshold(decibels) {
    this.compressorNode.threshold.setValueAtTime(clamp(decibels, -60, 0), 0)
    this.updateMakeupGain()
  }

  set release(ms) {
    this.compressorNode.release.setValueAtTime(clamp(ms, 10, 2000) / 1000, 0)
  }

  set attack(ms) {
    this.compressorNode.attack.setValueAtTime(clamp(ms, 0, 1000) / 1000, 0)
  }

  set ratio(value) {
    this.compressorNode.ratio.setValueAtTime(clamp(value, 1, 20), 0)
    this.updateMakeupGain()
  }

  set knee(value) {
    this.compressorNode.knee.setValueAtTime(clamp(value, 0, 40), 0)
  }
}
