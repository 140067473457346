import { ActionTypes } from 'src/constants.js'

export const setMasterMuted = muted => ({
  type: ActionTypes.SET_MASTER_MUTED,
  payload: muted,
})

export const setMasterVolume = volume => ({
  type: ActionTypes.SET_MASTER_VOLUME,
  payload: volume,
})

export const setMixerTracks = tracks => ({
  type: ActionTypes.SET_MIXER_TRACKS,
  payload: tracks,
})

export const copyTrackSettings = tracks => ({
  type: ActionTypes.COPY_TRACK_SETTINGS,
  payload: tracks,
})

export const setSelectedTrack = trackId => ({
  type: ActionTypes.SET_SELECTED_TRACK,
  payload: trackId,
})

export const setTrackVolume = (trackId, volume) => ({
  type: ActionTypes.SET_TRACK_VOLUME,
  payload: { trackId, volume },
})

export const setTrackPan = (trackId, pan) => ({
  type: ActionTypes.SET_TRACK_PAN,
  payload: { trackId, pan },
})

export const setTrackDistance = (trackId, distance) => ({
  type: ActionTypes.SET_TRACK_DISTANCE,
  payload: { trackId, distance },
})

export const setTrackPosition = (trackId, position) => ({
  type: ActionTypes.SET_TRACK_POSITION,
  payload: { trackId, position },
})

export const setTrackSoloed = (trackId, isSoloed) => ({
  type: ActionTypes.SET_TRACK_SOLOED,
  payload: { trackId, isSoloed },
})

export const setTrackMuted = (trackId, isMuted) => ({
  type: ActionTypes.SET_TRACK_MUTED,
  payload: { trackId, isMuted },
})

export const setBinauralPreset = (songId, preset) => ({
  type: ActionTypes.SET_BINAURAL_PRESET,
  payload: { songId, preset },
})

/**
 * User mixes
 */

export const applyUserMix = mix => ({
  type: ActionTypes.APPLY_USER_MIX,
  payload: mix,
})

export const getUserMix = (userId, songId) => ({
  type: ActionTypes.GET_USER_MIX,
  payload: { userId, songId },
})

export const getUserMixSuccess = mix => ({
  type: ActionTypes.GET_USER_MIX_SUCCESS,
  payload: mix,
})

export const getUserMixError = error => ({
  type: ActionTypes.GET_USER_MIX_ERROR,
  payload: error,
})

export const updateUserMix = ({ userId, songId, tracks }) => ({
  type: ActionTypes.UPDATE_USER_MIX,
  payload: { userId, songId, tracks },
})

export const updateUserMixSuccess = mix => ({
  type: ActionTypes.UPDATE_USER_MIX_SUCCESS,
  payload: mix,
})

export const updateUserMixError = error => ({
  type: ActionTypes.UPDATE_USER_MIX_ERROR,
  payload: error,
})
